export const countries = [
  {
    "_id": "CA",
    "code": "CA",
    "name": "Canada",
    "states": [
      {
        "_id": "AB",
        "code": "AB",
        "name": "Alberta"
      },
      {
        "_id": "BC",
        "code": "BC",
        "name": "British Columbia"
      },
      {
        "_id": "MB",
        "code": "MB",
        "name": "Manitoba"
      },
      {
        "_id": "NB",
        "code": "NB",
        "name": "New Brunswick"
      },
      {
        "_id": "NL",
        "code": "NL",
        "name": "Newfoundland and Labrador"
      },
      {
        "_id": "NT",
        "code": "NT",
        "name": "Northwest Territories"
      },
      {
        "_id": "NS",
        "code": "NS",
        "name": "Nova Scotia"
      },
      {
        "_id": "NU",
        "code": "NU",
        "name": "Nunavut"
      },
      {
        "_id": "ON",
        "code": "ON",
        "name": "Ontario"
      },
      {
        "_id": "PE",
        "code": "PE",
        "name": "Prince Edward Island"
      },
      {
        "_id": "QC",
        "code": "QC",
        "name": "Quebec"
      },
      {
        "_id": "SK",
        "code": "SK",
        "name": "Saskatchewan"
      },
      {
        "_id": "YT",
        "code": "YT",
        "name": "Yukon"
      }
    ]
  },
  {
    "_id": "US",
    "code": "US",
    "name": "United States",
    "states": [
      {
        "_id": "alabama",
        "code": "AL",
        "name": "Alabama"
      },
      {
        "_id": "alaska",
        "code": "AK",
        "name": "Alaska"
      },
      {
        "_id": "arizona",
        "code": "AZ",
        "name": "Arizona"
      },
      {
        "_id": "arkansas",
        "code": "AR",
        "name": "Arkansas"
      },
      {
        "_id": "california",
        "code": "CA",
        "name": "California"
      },
      {
        "_id": "colorado",
        "code": "CO",
        "name": "Colorado"
      },
      {
        "_id": "connecticut",
        "code": "CT",
        "name": "Connecticut"
      },
      {
        "_id": "delaware",
        "code": "DE",
        "name": "Delaware"
      },
      {
        "_id": "district_of_columbia",
        "code": "DC",
        "name": "District of Columbia"
      },
      {
        "_id": "florida",
        "code": "FL",
        "name": "Florida"
      },
      {
        "_id": "georgia",
        "code": "GA",
        "name": "Georgia"
      },
      {
        "_id": "hawaii",
        "code": "HI",
        "name": "Hawaii"
      },
      {
        "_id": "idaho",
        "code": "ID",
        "name": "Idaho"
      },
      {
        "_id": "illinois",
        "code": "IL",
        "name": "Illinois"
      },
      {
        "_id": "indiana",
        "code": "IN",
        "name": "Indiana"
      },
      {
        "_id": "iowa",
        "code": "IA",
        "name": "Iowa"
      },
      {
        "_id": "kansas",
        "code": "KS",
        "name": "Kansas"
      },
      {
        "_id": "kentucky",
        "code": "KY",
        "name": "Kentucky"
      },
      {
        "_id": "louisiana",
        "code": "LA",
        "name": "Louisiana"
      },
      {
        "_id": "maine",
        "code": "ME",
        "name": "Maine"
      },
      {
        "_id": "maryland",
        "code": "MD",
        "name": "Maryland"
      },
      {
        "_id": "massachusetts",
        "code": "MA",
        "name": "Massachusetts"
      },
      {
        "_id": "michigan",
        "code": "MI",
        "name": "Michigan"
      },
      {
        "_id": "minnesota",
        "code": "MN",
        "name": "Minnesota"
      },
      {
        "_id": "mississippi",
        "code": "MS",
        "name": "Mississippi"
      },
      {
        "_id": "missouri",
        "code": "MO",
        "name": "Missouri"
      },
      {
        "_id": "montana",
        "code": "MT",
        "name": "Montana"
      },
      {
        "_id": "nebraska",
        "code": "NE",
        "name": "Nebraska"
      },
      {
        "_id": "nevada",
        "code": "NV",
        "name": "Nevada"
      },
      {
        "_id": "new_hampshire",
        "code": "NH",
        "name": "New Hampshire"
      },
      {
        "_id": "new_jersey",
        "code": "NJ",
        "name": "New Jersey"
      },
      {
        "_id": "new_mexico",
        "code": "NM",
        "name": "New Mexico"
      },
      {
        "_id": "new_york",
        "code": "NY",
        "name": "New York"
      },
      {
        "_id": "north_carolina",
        "code": "NC",
        "name": "North Carolina"
      },
      {
        "_id": "north_dakota",
        "code": "ND",
        "name": "North Dakota"
      },
      {
        "_id": "ohio",
        "code": "OH",
        "name": "Ohio"
      },
      {
        "_id": "oklahoma",
        "code": "OK",
        "name": "Oklahoma"
      },
      {
        "_id": "oregon",
        "code": "OR",
        "name": "Oregon"
      },
      {
        "_id": "pennsylvania",
        "code": "PA",
        "name": "Pennsylvania"
      },
      {
        "_id": "rhode_island",
        "code": "RI",
        "name": "Rhode Island"
      },
      {
        "_id": "south_carolina",
        "code": "SC",
        "name": "South Carolina"
      },
      {
        "_id": "south_dakota",
        "code": "SD",
        "name": "South Dakota"
      },
      {
        "_id": "tennessee",
        "code": "TN",
        "name": "Tennessee"
      },
      {
        "_id": "texas",
        "code": "TX",
        "name": "Texas"
      },
      {
        "_id": "utah",
        "code": "UT",
        "name": "Utah"
      },
      {
        "_id": "vermont",
        "code": "VT",
        "name": "Vermont"
      },
      {
        "_id": "virginia",
        "code": "VA",
        "name": "Virginia"
      },
      {
        "_id": "washington",
        "code": "WA",
        "name": "Washington"
      },
      {
        "_id": "west_virginia",
        "code": "WV",
        "name": "West Virginia"
      },
      {
        "_id": "wisconsin",
        "code": "WI",
        "name": "Wisconsin"
      },
      {
        "_id": "wyoming",
        "code": "WY",
        "name": "Wyoming"
      }
    ]
  },
  {
    "_id": "AF",
    "code": "AF",
    "name": "Afghanistan",
    "states": [
      {
        "_id": "badakhshan",
        "code": "badakhshan",
        "name": "Badakhshan"
      },
      {
        "_id": "badgis",
        "code": "badgis",
        "name": "Badgis"
      },
      {
        "_id": "baglan",
        "code": "baglan",
        "name": "Baglan"
      },
      {
        "_id": "balkh",
        "code": "balkh",
        "name": "Balkh"
      },
      {
        "_id": "bamiyan",
        "code": "bamiyan",
        "name": "Bamiyan"
      },
      {
        "_id": "farah",
        "code": "farah",
        "name": "Farah"
      },
      {
        "_id": "faryab",
        "code": "faryab",
        "name": "Faryab"
      },
      {
        "_id": "gawr",
        "code": "gawr",
        "name": "Gawr"
      },
      {
        "_id": "gazni",
        "code": "gazni",
        "name": "Gazni"
      },
      {
        "_id": "herat",
        "code": "herat",
        "name": "Herat"
      },
      {
        "_id": "hilmand",
        "code": "hilmand",
        "name": "Hilmand"
      },
      {
        "_id": "jawzjan",
        "code": "jawzjan",
        "name": "Jawzjan"
      },
      {
        "_id": "kabul",
        "code": "kabul",
        "name": "Kabul"
      },
      {
        "_id": "kapisa",
        "code": "kapisa",
        "name": "Kapisa"
      },
      {
        "_id": "khawst",
        "code": "khawst",
        "name": "Khawst"
      },
      {
        "_id": "kunar",
        "code": "kunar",
        "name": "Kunar"
      },
      {
        "_id": "lagman",
        "code": "lagman",
        "name": "Lagman"
      },
      {
        "_id": "lawghar",
        "code": "lawghar",
        "name": "Lawghar"
      },
      {
        "_id": "nangarhar",
        "code": "nangarhar",
        "name": "Nangarhar"
      },
      {
        "_id": "nimruz",
        "code": "nimruz",
        "name": "Nimruz"
      },
      {
        "_id": "nuristan",
        "code": "nuristan",
        "name": "Nuristan"
      },
      {
        "_id": "paktika",
        "code": "paktika",
        "name": "Paktika"
      },
      {
        "_id": "paktiya",
        "code": "paktiya",
        "name": "Paktiya"
      },
      {
        "_id": "parwan",
        "code": "parwan",
        "name": "Parwan"
      },
      {
        "_id": "qandahar",
        "code": "qandahar",
        "name": "Qandahar"
      },
      {
        "_id": "qunduz",
        "code": "qunduz",
        "name": "Qunduz"
      },
      {
        "_id": "samangan",
        "code": "samangan",
        "name": "Samangan"
      },
      {
        "_id": "sar-e_pul",
        "code": "sar-e_pul",
        "name": "Sar-e Pul"
      },
      {
        "_id": "takhar",
        "code": "takhar",
        "name": "Takhar"
      },
      {
        "_id": "uruzgan",
        "code": "uruzgan",
        "name": "Uruzgan"
      },
      {
        "_id": "wardag",
        "code": "wardag",
        "name": "Wardag"
      },
      {
        "_id": "zabul",
        "code": "zabul",
        "name": "Zabul"
      }
    ]
  },
  {
    "_id": "AL",
    "code": "AL",
    "name": "Albania",
    "states": [
      {
        "_id": "berat",
        "code": "berat",
        "name": "Berat"
      },
      {
        "_id": "bulqize",
        "code": "bulqize",
        "name": "Bulqize"
      },
      {
        "_id": "delvine",
        "code": "delvine",
        "name": "Delvine"
      },
      {
        "_id": "devoll",
        "code": "devoll",
        "name": "Devoll"
      },
      {
        "_id": "dibre",
        "code": "dibre",
        "name": "Dibre"
      },
      {
        "_id": "durres",
        "code": "durres",
        "name": "Durres"
      },
      {
        "_id": "elbasan",
        "code": "elbasan",
        "name": "Elbasan"
      },
      {
        "_id": "fier",
        "code": "fier",
        "name": "Fier"
      },
      {
        "_id": "gjirokaster",
        "code": "gjirokaster",
        "name": "Gjirokaster"
      },
      {
        "_id": "gramsh",
        "code": "gramsh",
        "name": "Gramsh"
      },
      {
        "_id": "has",
        "code": "has",
        "name": "Has"
      },
      {
        "_id": "kavaje",
        "code": "kavaje",
        "name": "Kavaje"
      },
      {
        "_id": "kolonje",
        "code": "kolonje",
        "name": "Kolonje"
      },
      {
        "_id": "korce",
        "code": "korce",
        "name": "Korce"
      },
      {
        "_id": "kruje",
        "code": "kruje",
        "name": "Kruje"
      },
      {
        "_id": "kucove",
        "code": "kucove",
        "name": "Kucove"
      },
      {
        "_id": "kukes",
        "code": "kukes",
        "name": "Kukes"
      },
      {
        "_id": "kurbin",
        "code": "kurbin",
        "name": "Kurbin"
      },
      {
        "_id": "lezhe",
        "code": "lezhe",
        "name": "Lezhe"
      },
      {
        "_id": "librazhd",
        "code": "librazhd",
        "name": "Librazhd"
      },
      {
        "_id": "lushnje",
        "code": "lushnje",
        "name": "Lushnje"
      },
      {
        "_id": "mallakaster",
        "code": "mallakaster",
        "name": "Mallakaster"
      },
      {
        "_id": "malsi_e_madhe",
        "code": "malsi_e_madhe",
        "name": "Malsi e Madhe"
      },
      {
        "_id": "mat",
        "code": "mat",
        "name": "Mat"
      },
      {
        "_id": "mirdite",
        "code": "mirdite",
        "name": "Mirdite"
      },
      {
        "_id": "peqin",
        "code": "peqin",
        "name": "Peqin"
      },
      {
        "_id": "permet",
        "code": "permet",
        "name": "Permet"
      },
      {
        "_id": "pogradec",
        "code": "pogradec",
        "name": "Pogradec"
      },
      {
        "_id": "puke",
        "code": "puke",
        "name": "Puke"
      },
      {
        "_id": "sarande",
        "code": "sarande",
        "name": "Sarande"
      },
      {
        "_id": "shkoder",
        "code": "shkoder",
        "name": "Shkoder"
      },
      {
        "_id": "skrapar",
        "code": "skrapar",
        "name": "Skrapar"
      },
      {
        "_id": "tepelene",
        "code": "tepelene",
        "name": "Tepelene"
      },
      {
        "_id": "tirane",
        "code": "tirane",
        "name": "Tirane"
      },
      {
        "_id": "tropoje",
        "code": "tropoje",
        "name": "Tropoje"
      },
      {
        "_id": "vlore",
        "code": "vlore",
        "name": "Vlore"
      }
    ]
  },
  {
    "_id": "DZ",
    "code": "DZ",
    "name": "Algeria",
    "states": [
      {
        "_id": "ayn_daflah",
        "code": "ayn_daflah",
        "name": "Ayn Daflah"
      },
      {
        "_id": "ayn_tamushanat",
        "code": "ayn_tamushanat",
        "name": "Ayn Tamushanat"
      },
      {
        "_id": "adrar",
        "code": "adrar",
        "name": "Adrar"
      },
      {
        "_id": "algiers",
        "code": "algiers",
        "name": "Algiers"
      },
      {
        "_id": "annabah",
        "code": "annabah",
        "name": "Annabah"
      },
      {
        "_id": "bashshar",
        "code": "bashshar",
        "name": "Bashshar"
      },
      {
        "_id": "batnah",
        "code": "batnah",
        "name": "Batnah"
      },
      {
        "_id": "bijayah",
        "code": "bijayah",
        "name": "Bijayah"
      },
      {
        "_id": "biskrah",
        "code": "biskrah",
        "name": "Biskrah"
      },
      {
        "_id": "blidah",
        "code": "blidah",
        "name": "Blidah"
      },
      {
        "_id": "buirah",
        "code": "buirah",
        "name": "Buirah"
      },
      {
        "_id": "bumardas",
        "code": "bumardas",
        "name": "Bumardas"
      },
      {
        "_id": "burj_bu_arririj",
        "code": "burj_bu_arririj",
        "name": "Burj Bu Arririj"
      },
      {
        "_id": "ghalizan",
        "code": "ghalizan",
        "name": "Ghalizan"
      },
      {
        "_id": "ghardayah",
        "code": "ghardayah",
        "name": "Ghardayah"
      },
      {
        "_id": "ilizi",
        "code": "ilizi",
        "name": "Ilizi"
      },
      {
        "_id": "jijili",
        "code": "jijili",
        "name": "Jijili"
      },
      {
        "_id": "jilfah",
        "code": "jilfah",
        "name": "Jilfah"
      },
      {
        "_id": "khanshalah",
        "code": "khanshalah",
        "name": "Khanshalah"
      },
      {
        "_id": "masilah",
        "code": "masilah",
        "name": "Masilah"
      },
      {
        "_id": "midyah",
        "code": "midyah",
        "name": "Midyah"
      },
      {
        "_id": "milah",
        "code": "milah",
        "name": "Milah"
      },
      {
        "_id": "muaskar",
        "code": "muaskar",
        "name": "Muaskar"
      },
      {
        "_id": "mustaghanam",
        "code": "mustaghanam",
        "name": "Mustaghanam"
      },
      {
        "_id": "naama",
        "code": "naama",
        "name": "Naama"
      },
      {
        "_id": "oran",
        "code": "oran",
        "name": "Oran"
      },
      {
        "_id": "ouargla",
        "code": "ouargla",
        "name": "Ouargla"
      },
      {
        "_id": "qalmah",
        "code": "qalmah",
        "name": "Qalmah"
      },
      {
        "_id": "qustantinah",
        "code": "qustantinah",
        "name": "Qustantinah"
      },
      {
        "_id": "sakikdah",
        "code": "sakikdah",
        "name": "Sakikdah"
      },
      {
        "_id": "satif",
        "code": "satif",
        "name": "Satif"
      },
      {
        "_id": "sayda",
        "code": "sayda",
        "name": "Sayda"
      },
      {
        "_id": "sidi_ban-al-abbas",
        "code": "sidi_ban-al-abbas",
        "name": "Sidi ban-al-Abbas"
      },
      {
        "_id": "suq_ahras",
        "code": "suq_ahras",
        "name": "Suq Ahras"
      },
      {
        "_id": "tamanghasat",
        "code": "tamanghasat",
        "name": "Tamanghasat"
      },
      {
        "_id": "tibazah",
        "code": "tibazah",
        "name": "Tibazah"
      },
      {
        "_id": "tibissah",
        "code": "tibissah",
        "name": "Tibissah"
      },
      {
        "_id": "tilimsan",
        "code": "tilimsan",
        "name": "Tilimsan"
      },
      {
        "_id": "tinduf",
        "code": "tinduf",
        "name": "Tinduf"
      },
      {
        "_id": "tisamsilt",
        "code": "tisamsilt",
        "name": "Tisamsilt"
      },
      {
        "_id": "tiyarat",
        "code": "tiyarat",
        "name": "Tiyarat"
      },
      {
        "_id": "tizi_wazu",
        "code": "tizi_wazu",
        "name": "Tizi Wazu"
      },
      {
        "_id": "umm-al-bawaghi",
        "code": "umm-al-bawaghi",
        "name": "Umm-al-Bawaghi"
      },
      {
        "_id": "wahran",
        "code": "wahran",
        "name": "Wahran"
      },
      {
        "_id": "warqla",
        "code": "warqla",
        "name": "Warqla"
      },
      {
        "_id": "wilaya_d_alger",
        "code": "wilaya_d_alger",
        "name": "Wilaya d Alger"
      },
      {
        "_id": "wilaya_de_bejaia",
        "code": "wilaya_de_bejaia",
        "name": "Wilaya de Bejaia"
      },
      {
        "_id": "wilaya_de_constantine",
        "code": "wilaya_de_constantine",
        "name": "Wilaya de Constantine"
      },
      {
        "_id": "al-aghwat",
        "code": "al-aghwat",
        "name": "al-Aghwat"
      },
      {
        "_id": "al-bayadh",
        "code": "al-bayadh",
        "name": "al-Bayadh"
      },
      {
        "_id": "al-jazair",
        "code": "al-jazair",
        "name": "al-Jazair"
      },
      {
        "_id": "al-wad",
        "code": "al-wad",
        "name": "al-Wad"
      },
      {
        "_id": "ash-shalif",
        "code": "ash-shalif",
        "name": "ash-Shalif"
      },
      {
        "_id": "at-tarif",
        "code": "at-tarif",
        "name": "at-Tarif"
      }
    ]
  },
  {
    "_id": "AS",
    "code": "AS",
    "name": "American Samoa",
    "states": [
      {
        "_id": "eastern",
        "code": "eastern",
        "name": "Eastern"
      },
      {
        "_id": "manua",
        "code": "manua",
        "name": "Manua"
      },
      {
        "_id": "swains_island",
        "code": "swains_island",
        "name": "Swains Island"
      },
      {
        "_id": "western",
        "code": "western",
        "name": "Western"
      }
    ]
  },
  {
    "_id": "AD",
    "code": "AD",
    "name": "Andorra",
    "states": [
      {
        "_id": "andorra_la_vella",
        "code": "andorra_la_vella",
        "name": "Andorra la Vella"
      },
      {
        "_id": "canillo",
        "code": "canillo",
        "name": "Canillo"
      },
      {
        "_id": "encamp",
        "code": "encamp",
        "name": "Encamp"
      },
      {
        "_id": "la_massana",
        "code": "la_massana",
        "name": "La Massana"
      },
      {
        "_id": "les_escaldes",
        "code": "les_escaldes",
        "name": "Les Escaldes"
      },
      {
        "_id": "ordino",
        "code": "ordino",
        "name": "Ordino"
      },
      {
        "_id": "sant_julia_de_loria",
        "code": "sant_julia_de_loria",
        "name": "Sant Julia de Loria"
      }
    ]
  },
  {
    "_id": "AO",
    "code": "AO",
    "name": "Angola",
    "states": [
      {
        "_id": "bengo",
        "code": "bengo",
        "name": "Bengo"
      },
      {
        "_id": "benguela",
        "code": "benguela",
        "name": "Benguela"
      },
      {
        "_id": "bie",
        "code": "bie",
        "name": "Bie"
      },
      {
        "_id": "cabinda",
        "code": "cabinda",
        "name": "Cabinda"
      },
      {
        "_id": "cunene",
        "code": "cunene",
        "name": "Cunene"
      },
      {
        "_id": "huambo",
        "code": "huambo",
        "name": "Huambo"
      },
      {
        "_id": "huila",
        "code": "huila",
        "name": "Huila"
      },
      {
        "_id": "kuando-kubango",
        "code": "kuando-kubango",
        "name": "Kuando-Kubango"
      },
      {
        "_id": "kwanza_norte",
        "code": "kwanza_norte",
        "name": "Kwanza Norte"
      },
      {
        "_id": "kwanza_sul",
        "code": "kwanza_sul",
        "name": "Kwanza Sul"
      },
      {
        "_id": "luanda",
        "code": "luanda",
        "name": "Luanda"
      },
      {
        "_id": "lunda_norte",
        "code": "lunda_norte",
        "name": "Lunda Norte"
      },
      {
        "_id": "lunda_sul",
        "code": "lunda_sul",
        "name": "Lunda Sul"
      },
      {
        "_id": "malanje",
        "code": "malanje",
        "name": "Malanje"
      },
      {
        "_id": "moxico",
        "code": "moxico",
        "name": "Moxico"
      },
      {
        "_id": "namibe",
        "code": "namibe",
        "name": "Namibe"
      },
      {
        "_id": "uige",
        "code": "uige",
        "name": "Uige"
      },
      {
        "_id": "zaire",
        "code": "zaire",
        "name": "Zaire"
      }
    ]
  },
  {
    "_id": "AI",
    "code": "AI",
    "name": "Anguilla",
    "states": [
      {
        "_id": "other_provinces",
        "code": "other_provinces",
        "name": "Other Provinces"
      }
    ]
  },
  {
    "_id": "AQ",
    "code": "AQ",
    "name": "Antarctica",
    "states": [
      {
        "_id": "sector_claimed_by_argentina/ch",
        "code": "sector_claimed_by_argentina/ch",
        "name": "Sector claimed by Argentina/Ch"
      },
      {
        "_id": "sector_claimed_by_argentina/uk",
        "code": "sector_claimed_by_argentina/uk",
        "name": "Sector claimed by Argentina/UK"
      },
      {
        "_id": "sector_claimed_by_australia",
        "code": "sector_claimed_by_australia",
        "name": "Sector claimed by Australia"
      },
      {
        "_id": "sector_claimed_by_france",
        "code": "sector_claimed_by_france",
        "name": "Sector claimed by France"
      },
      {
        "_id": "sector_claimed_by_new_zealand",
        "code": "sector_claimed_by_new_zealand",
        "name": "Sector claimed by New Zealand"
      },
      {
        "_id": "sector_claimed_by_norway",
        "code": "sector_claimed_by_norway",
        "name": "Sector claimed by Norway"
      },
      {
        "_id": "unclaimed_sector",
        "code": "unclaimed_sector",
        "name": "Unclaimed Sector"
      }
    ]
  },
  {
    "_id": "AG",
    "code": "AG",
    "name": "Antigua And Barbuda",
    "states": [
      {
        "_id": "barbuda",
        "code": "barbuda",
        "name": "Barbuda"
      },
      {
        "_id": "saint_george",
        "code": "saint_george",
        "name": "Saint George"
      },
      {
        "_id": "saint_john",
        "code": "saint_john",
        "name": "Saint John"
      },
      {
        "_id": "saint_mary",
        "code": "saint_mary",
        "name": "Saint Mary"
      },
      {
        "_id": "saint_paul",
        "code": "saint_paul",
        "name": "Saint Paul"
      },
      {
        "_id": "saint_peter",
        "code": "saint_peter",
        "name": "Saint Peter"
      },
      {
        "_id": "saint_philip",
        "code": "saint_philip",
        "name": "Saint Philip"
      }
    ]
  },
  {
    "_id": "AR",
    "code": "AR",
    "name": "Argentina",
    "states": [
      {
        "_id": "buenos_aires",
        "code": "buenos_aires",
        "name": "Buenos Aires"
      },
      {
        "_id": "catamarca",
        "code": "catamarca",
        "name": "Catamarca"
      },
      {
        "_id": "chaco",
        "code": "chaco",
        "name": "Chaco"
      },
      {
        "_id": "chubut",
        "code": "chubut",
        "name": "Chubut"
      },
      {
        "_id": "cordoba",
        "code": "cordoba",
        "name": "Cordoba"
      },
      {
        "_id": "corrientes",
        "code": "corrientes",
        "name": "Corrientes"
      },
      {
        "_id": "distrito_federal",
        "code": "distrito_federal",
        "name": "Distrito Federal"
      },
      {
        "_id": "entre_rios",
        "code": "entre_rios",
        "name": "Entre Rios"
      },
      {
        "_id": "formosa",
        "code": "formosa",
        "name": "Formosa"
      },
      {
        "_id": "jujuy",
        "code": "jujuy",
        "name": "Jujuy"
      },
      {
        "_id": "la_pampa",
        "code": "la_pampa",
        "name": "La Pampa"
      },
      {
        "_id": "la_rioja",
        "code": "la_rioja",
        "name": "La Rioja"
      },
      {
        "_id": "mendoza",
        "code": "mendoza",
        "name": "Mendoza"
      },
      {
        "_id": "misiones",
        "code": "misiones",
        "name": "Misiones"
      },
      {
        "_id": "neuquen",
        "code": "neuquen",
        "name": "Neuquen"
      },
      {
        "_id": "rio_negro",
        "code": "rio_negro",
        "name": "Rio Negro"
      },
      {
        "_id": "salta",
        "code": "salta",
        "name": "Salta"
      },
      {
        "_id": "san_juan",
        "code": "san_juan",
        "name": "San Juan"
      },
      {
        "_id": "san_luis",
        "code": "san_luis",
        "name": "San Luis"
      },
      {
        "_id": "santa_cruz",
        "code": "santa_cruz",
        "name": "Santa Cruz"
      },
      {
        "_id": "santa_fe",
        "code": "santa_fe",
        "name": "Santa Fe"
      },
      {
        "_id": "santiago_del_estero",
        "code": "santiago_del_estero",
        "name": "Santiago del Estero"
      },
      {
        "_id": "tierra_del_fuego",
        "code": "tierra_del_fuego",
        "name": "Tierra del Fuego"
      },
      {
        "_id": "tucuman",
        "code": "tucuman",
        "name": "Tucuman"
      }
    ]
  },
  {
    "_id": "AM",
    "code": "AM",
    "name": "Armenia",
    "states": [
      {
        "_id": "aragatsotn",
        "code": "aragatsotn",
        "name": "Aragatsotn"
      },
      {
        "_id": "ararat",
        "code": "ararat",
        "name": "Ararat"
      },
      {
        "_id": "armavir",
        "code": "armavir",
        "name": "Armavir"
      },
      {
        "_id": "gegharkunik",
        "code": "gegharkunik",
        "name": "Gegharkunik"
      },
      {
        "_id": "kotaik",
        "code": "kotaik",
        "name": "Kotaik"
      },
      {
        "_id": "lori",
        "code": "lori",
        "name": "Lori"
      },
      {
        "_id": "shirak",
        "code": "shirak",
        "name": "Shirak"
      },
      {
        "_id": "stepanakert",
        "code": "stepanakert",
        "name": "Stepanakert"
      },
      {
        "_id": "syunik",
        "code": "syunik",
        "name": "Syunik"
      },
      {
        "_id": "tavush",
        "code": "tavush",
        "name": "Tavush"
      },
      {
        "_id": "vayots_dzor",
        "code": "vayots_dzor",
        "name": "Vayots Dzor"
      },
      {
        "_id": "yerevan",
        "code": "yerevan",
        "name": "Yerevan"
      }
    ]
  },
  {
    "_id": "AW",
    "code": "AW",
    "name": "Aruba",
    "states": [
      {
        "_id": "aruba",
        "code": "aruba",
        "name": "Aruba"
      }
    ]
  },
  {
    "_id": "AU",
    "code": "AU",
    "name": "Australia",
    "states": [
      {
        "_id": "auckland",
        "code": "auckland",
        "name": "Auckland"
      },
      {
        "_id": "australian_capital_territory",
        "code": "australian_capital_territory",
        "name": "Australian Capital Territory"
      },
      {
        "_id": "balgowlah",
        "code": "balgowlah",
        "name": "Balgowlah"
      },
      {
        "_id": "balmain",
        "code": "balmain",
        "name": "Balmain"
      },
      {
        "_id": "bankstown",
        "code": "bankstown",
        "name": "Bankstown"
      },
      {
        "_id": "baulkham_hills",
        "code": "baulkham_hills",
        "name": "Baulkham Hills"
      },
      {
        "_id": "bonnet_bay",
        "code": "bonnet_bay",
        "name": "Bonnet Bay"
      },
      {
        "_id": "camberwell",
        "code": "camberwell",
        "name": "Camberwell"
      },
      {
        "_id": "carole_park",
        "code": "carole_park",
        "name": "Carole Park"
      },
      {
        "_id": "castle_hill",
        "code": "castle_hill",
        "name": "Castle Hill"
      },
      {
        "_id": "caulfield",
        "code": "caulfield",
        "name": "Caulfield"
      },
      {
        "_id": "chatswood",
        "code": "chatswood",
        "name": "Chatswood"
      },
      {
        "_id": "cheltenham",
        "code": "cheltenham",
        "name": "Cheltenham"
      },
      {
        "_id": "cherrybrook",
        "code": "cherrybrook",
        "name": "Cherrybrook"
      },
      {
        "_id": "clayton",
        "code": "clayton",
        "name": "Clayton"
      },
      {
        "_id": "collingwood",
        "code": "collingwood",
        "name": "Collingwood"
      },
      {
        "_id": "frenchs_forest",
        "code": "frenchs_forest",
        "name": "Frenchs Forest"
      },
      {
        "_id": "hawthorn",
        "code": "hawthorn",
        "name": "Hawthorn"
      },
      {
        "_id": "jannnali",
        "code": "jannnali",
        "name": "Jannnali"
      },
      {
        "_id": "knoxfield",
        "code": "knoxfield",
        "name": "Knoxfield"
      },
      {
        "_id": "melbourne",
        "code": "melbourne",
        "name": "Melbourne"
      },
      {
        "_id": "new_south_wales",
        "code": "new_south_wales",
        "name": "New South Wales"
      },
      {
        "_id": "northern_territory",
        "code": "northern_territory",
        "name": "Northern Territory"
      },
      {
        "_id": "perth",
        "code": "perth",
        "name": "Perth"
      },
      {
        "_id": "queensland",
        "code": "queensland",
        "name": "Queensland"
      },
      {
        "_id": "south_australia",
        "code": "south_australia",
        "name": "South Australia"
      },
      {
        "_id": "tasmania",
        "code": "tasmania",
        "name": "Tasmania"
      },
      {
        "_id": "templestowe",
        "code": "templestowe",
        "name": "Templestowe"
      },
      {
        "_id": "victoria",
        "code": "victoria",
        "name": "Victoria"
      },
      {
        "_id": "werribee_south",
        "code": "werribee_south",
        "name": "Werribee south"
      },
      {
        "_id": "western_australia",
        "code": "western_australia",
        "name": "Western Australia"
      },
      {
        "_id": "wheeler",
        "code": "wheeler",
        "name": "Wheeler"
      }
    ]
  },
  {
    "_id": "AT",
    "code": "AT",
    "name": "Austria",
    "states": [
      {
        "_id": "bundesland_salzburg",
        "code": "bundesland_salzburg",
        "name": "Bundesland Salzburg"
      },
      {
        "_id": "bundesland_steiermark",
        "code": "bundesland_steiermark",
        "name": "Bundesland Steiermark"
      },
      {
        "_id": "bundesland_tirol",
        "code": "bundesland_tirol",
        "name": "Bundesland Tirol"
      },
      {
        "_id": "burgenland",
        "code": "burgenland",
        "name": "Burgenland"
      },
      {
        "_id": "carinthia",
        "code": "carinthia",
        "name": "Carinthia"
      },
      {
        "_id": "karnten",
        "code": "karnten",
        "name": "Karnten"
      },
      {
        "_id": "liezen",
        "code": "liezen",
        "name": "Liezen"
      },
      {
        "_id": "lower_austria",
        "code": "lower_austria",
        "name": "Lower Austria"
      },
      {
        "_id": "niederosterreich",
        "code": "niederosterreich",
        "name": "Niederosterreich"
      },
      {
        "_id": "oberosterreich",
        "code": "oberosterreich",
        "name": "Oberosterreich"
      },
      {
        "_id": "salzburg",
        "code": "salzburg",
        "name": "Salzburg"
      },
      {
        "_id": "schleswig-holstein",
        "code": "schleswig-holstein",
        "name": "Schleswig-Holstein"
      },
      {
        "_id": "steiermark",
        "code": "steiermark",
        "name": "Steiermark"
      },
      {
        "_id": "styria",
        "code": "styria",
        "name": "Styria"
      },
      {
        "_id": "tirol",
        "code": "tirol",
        "name": "Tirol"
      },
      {
        "_id": "upper_austria",
        "code": "upper_austria",
        "name": "Upper Austria"
      },
      {
        "_id": "vorarlberg",
        "code": "vorarlberg",
        "name": "Vorarlberg"
      },
      {
        "_id": "wien",
        "code": "wien",
        "name": "Wien"
      }
    ]
  },
  {
    "_id": "AZ",
    "code": "AZ",
    "name": "Azerbaijan",
    "states": [
      {
        "_id": "abseron",
        "code": "abseron",
        "name": "Abseron"
      },
      {
        "_id": "baki_sahari",
        "code": "baki_sahari",
        "name": "Baki Sahari"
      },
      {
        "_id": "ganca",
        "code": "ganca",
        "name": "Ganca"
      },
      {
        "_id": "ganja",
        "code": "ganja",
        "name": "Ganja"
      },
      {
        "_id": "kalbacar",
        "code": "kalbacar",
        "name": "Kalbacar"
      },
      {
        "_id": "lankaran",
        "code": "lankaran",
        "name": "Lankaran"
      },
      {
        "_id": "mil-qarabax",
        "code": "mil-qarabax",
        "name": "Mil-Qarabax"
      },
      {
        "_id": "mugan-salyan",
        "code": "mugan-salyan",
        "name": "Mugan-Salyan"
      },
      {
        "_id": "nagorni-qarabax",
        "code": "nagorni-qarabax",
        "name": "Nagorni-Qarabax"
      },
      {
        "_id": "naxcivan",
        "code": "naxcivan",
        "name": "Naxcivan"
      },
      {
        "_id": "priaraks",
        "code": "priaraks",
        "name": "Priaraks"
      },
      {
        "_id": "qazax",
        "code": "qazax",
        "name": "Qazax"
      },
      {
        "_id": "saki",
        "code": "saki",
        "name": "Saki"
      },
      {
        "_id": "sirvan",
        "code": "sirvan",
        "name": "Sirvan"
      },
      {
        "_id": "xacmaz",
        "code": "xacmaz",
        "name": "Xacmaz"
      }
    ]
  },
  {
    "_id": "BS",
    "code": "BS",
    "name": "Bahamas The",
    "states": [
      {
        "_id": "abaco",
        "code": "abaco",
        "name": "Abaco"
      },
      {
        "_id": "acklins_island",
        "code": "acklins_island",
        "name": "Acklins Island"
      },
      {
        "_id": "andros",
        "code": "andros",
        "name": "Andros"
      },
      {
        "_id": "berry_islands",
        "code": "berry_islands",
        "name": "Berry Islands"
      },
      {
        "_id": "biminis",
        "code": "biminis",
        "name": "Biminis"
      },
      {
        "_id": "cat_island",
        "code": "cat_island",
        "name": "Cat Island"
      },
      {
        "_id": "crooked_island",
        "code": "crooked_island",
        "name": "Crooked Island"
      },
      {
        "_id": "eleuthera",
        "code": "eleuthera",
        "name": "Eleuthera"
      },
      {
        "_id": "exuma_and_cays",
        "code": "exuma_and_cays",
        "name": "Exuma and Cays"
      },
      {
        "_id": "grand_bahama",
        "code": "grand_bahama",
        "name": "Grand Bahama"
      },
      {
        "_id": "inagua_islands",
        "code": "inagua_islands",
        "name": "Inagua Islands"
      },
      {
        "_id": "long_island",
        "code": "long_island",
        "name": "Long Island"
      },
      {
        "_id": "mayaguana",
        "code": "mayaguana",
        "name": "Mayaguana"
      },
      {
        "_id": "new_providence",
        "code": "new_providence",
        "name": "New Providence"
      },
      {
        "_id": "ragged_island",
        "code": "ragged_island",
        "name": "Ragged Island"
      },
      {
        "_id": "rum_cay",
        "code": "rum_cay",
        "name": "Rum Cay"
      },
      {
        "_id": "san_salvador",
        "code": "san_salvador",
        "name": "San Salvador"
      }
    ]
  },
  {
    "_id": "BH",
    "code": "BH",
    "name": "Bahrain",
    "states": [
      {
        "_id": "isa",
        "code": "isa",
        "name": "Isa"
      },
      {
        "_id": "badiyah",
        "code": "badiyah",
        "name": "Badiyah"
      },
      {
        "_id": "hidd",
        "code": "hidd",
        "name": "Hidd"
      },
      {
        "_id": "jidd_hafs",
        "code": "jidd_hafs",
        "name": "Jidd Hafs"
      },
      {
        "_id": "mahama",
        "code": "mahama",
        "name": "Mahama"
      },
      {
        "_id": "manama",
        "code": "manama",
        "name": "Manama"
      },
      {
        "_id": "sitrah",
        "code": "sitrah",
        "name": "Sitrah"
      },
      {
        "_id": "al-manamah",
        "code": "al-manamah",
        "name": "al-Manamah"
      },
      {
        "_id": "al-muharraq",
        "code": "al-muharraq",
        "name": "al-Muharraq"
      },
      {
        "_id": "ar-rifaa",
        "code": "ar-rifaa",
        "name": "ar-Rifaa"
      }
    ]
  },
  {
    "_id": "BD",
    "code": "BD",
    "name": "Bangladesh",
    "states": [
      {
        "_id": "bagar_hat",
        "code": "bagar_hat",
        "name": "Bagar Hat"
      },
      {
        "_id": "bandarban",
        "code": "bandarban",
        "name": "Bandarban"
      },
      {
        "_id": "barguna",
        "code": "barguna",
        "name": "Barguna"
      },
      {
        "_id": "barisal",
        "code": "barisal",
        "name": "Barisal"
      },
      {
        "_id": "bhola",
        "code": "bhola",
        "name": "Bhola"
      },
      {
        "_id": "bogora",
        "code": "bogora",
        "name": "Bogora"
      },
      {
        "_id": "brahman_bariya",
        "code": "brahman_bariya",
        "name": "Brahman Bariya"
      },
      {
        "_id": "chandpur",
        "code": "chandpur",
        "name": "Chandpur"
      },
      {
        "_id": "chattagam",
        "code": "chattagam",
        "name": "Chattagam"
      },
      {
        "_id": "chittagong_division",
        "code": "chittagong_division",
        "name": "Chittagong Division"
      },
      {
        "_id": "chuadanga",
        "code": "chuadanga",
        "name": "Chuadanga"
      },
      {
        "_id": "dhaka",
        "code": "dhaka",
        "name": "Dhaka"
      },
      {
        "_id": "dinajpur",
        "code": "dinajpur",
        "name": "Dinajpur"
      },
      {
        "_id": "faridpur",
        "code": "faridpur",
        "name": "Faridpur"
      },
      {
        "_id": "feni",
        "code": "feni",
        "name": "Feni"
      },
      {
        "_id": "gaybanda",
        "code": "gaybanda",
        "name": "Gaybanda"
      },
      {
        "_id": "gazipur",
        "code": "gazipur",
        "name": "Gazipur"
      },
      {
        "_id": "gopalganj",
        "code": "gopalganj",
        "name": "Gopalganj"
      },
      {
        "_id": "habiganj",
        "code": "habiganj",
        "name": "Habiganj"
      },
      {
        "_id": "jaipur_hat",
        "code": "jaipur_hat",
        "name": "Jaipur Hat"
      },
      {
        "_id": "jamalpur",
        "code": "jamalpur",
        "name": "Jamalpur"
      },
      {
        "_id": "jessor",
        "code": "jessor",
        "name": "Jessor"
      },
      {
        "_id": "jhalakati",
        "code": "jhalakati",
        "name": "Jhalakati"
      },
      {
        "_id": "jhanaydah",
        "code": "jhanaydah",
        "name": "Jhanaydah"
      },
      {
        "_id": "khagrachhari",
        "code": "khagrachhari",
        "name": "Khagrachhari"
      },
      {
        "_id": "khulna",
        "code": "khulna",
        "name": "Khulna"
      },
      {
        "_id": "kishorganj",
        "code": "kishorganj",
        "name": "Kishorganj"
      },
      {
        "_id": "koks_bazar",
        "code": "koks_bazar",
        "name": "Koks Bazar"
      },
      {
        "_id": "komilla",
        "code": "komilla",
        "name": "Komilla"
      },
      {
        "_id": "kurigram",
        "code": "kurigram",
        "name": "Kurigram"
      },
      {
        "_id": "kushtiya",
        "code": "kushtiya",
        "name": "Kushtiya"
      },
      {
        "_id": "lakshmipur",
        "code": "lakshmipur",
        "name": "Lakshmipur"
      },
      {
        "_id": "lalmanir_hat",
        "code": "lalmanir_hat",
        "name": "Lalmanir Hat"
      },
      {
        "_id": "madaripur",
        "code": "madaripur",
        "name": "Madaripur"
      },
      {
        "_id": "magura",
        "code": "magura",
        "name": "Magura"
      },
      {
        "_id": "maimansingh",
        "code": "maimansingh",
        "name": "Maimansingh"
      },
      {
        "_id": "manikganj",
        "code": "manikganj",
        "name": "Manikganj"
      },
      {
        "_id": "maulvi_bazar",
        "code": "maulvi_bazar",
        "name": "Maulvi Bazar"
      },
      {
        "_id": "meherpur",
        "code": "meherpur",
        "name": "Meherpur"
      },
      {
        "_id": "munshiganj",
        "code": "munshiganj",
        "name": "Munshiganj"
      },
      {
        "_id": "naral",
        "code": "naral",
        "name": "Naral"
      },
      {
        "_id": "narayanganj",
        "code": "narayanganj",
        "name": "Narayanganj"
      },
      {
        "_id": "narsingdi",
        "code": "narsingdi",
        "name": "Narsingdi"
      },
      {
        "_id": "nator",
        "code": "nator",
        "name": "Nator"
      },
      {
        "_id": "naugaon",
        "code": "naugaon",
        "name": "Naugaon"
      },
      {
        "_id": "nawabganj",
        "code": "nawabganj",
        "name": "Nawabganj"
      },
      {
        "_id": "netrakona",
        "code": "netrakona",
        "name": "Netrakona"
      },
      {
        "_id": "nilphamari",
        "code": "nilphamari",
        "name": "Nilphamari"
      },
      {
        "_id": "noakhali",
        "code": "noakhali",
        "name": "Noakhali"
      },
      {
        "_id": "pabna",
        "code": "pabna",
        "name": "Pabna"
      },
      {
        "_id": "panchagarh",
        "code": "panchagarh",
        "name": "Panchagarh"
      },
      {
        "_id": "patuakhali",
        "code": "patuakhali",
        "name": "Patuakhali"
      },
      {
        "_id": "pirojpur",
        "code": "pirojpur",
        "name": "Pirojpur"
      },
      {
        "_id": "rajbari",
        "code": "rajbari",
        "name": "Rajbari"
      },
      {
        "_id": "rajshahi",
        "code": "rajshahi",
        "name": "Rajshahi"
      },
      {
        "_id": "rangamati",
        "code": "rangamati",
        "name": "Rangamati"
      },
      {
        "_id": "rangpur",
        "code": "rangpur",
        "name": "Rangpur"
      },
      {
        "_id": "satkhira",
        "code": "satkhira",
        "name": "Satkhira"
      },
      {
        "_id": "shariatpur",
        "code": "shariatpur",
        "name": "Shariatpur"
      },
      {
        "_id": "sherpur",
        "code": "sherpur",
        "name": "Sherpur"
      },
      {
        "_id": "silhat",
        "code": "silhat",
        "name": "Silhat"
      },
      {
        "_id": "sirajganj",
        "code": "sirajganj",
        "name": "Sirajganj"
      },
      {
        "_id": "sunamganj",
        "code": "sunamganj",
        "name": "Sunamganj"
      },
      {
        "_id": "tangayal",
        "code": "tangayal",
        "name": "Tangayal"
      },
      {
        "_id": "thakurgaon",
        "code": "thakurgaon",
        "name": "Thakurgaon"
      }
    ]
  },
  {
    "_id": "BB",
    "code": "BB",
    "name": "Barbados",
    "states": [
      {
        "_id": "christ_church",
        "code": "christ_church",
        "name": "Christ Church"
      },
      {
        "_id": "saint_andrew",
        "code": "saint_andrew",
        "name": "Saint Andrew"
      },
      {
        "_id": "saint_george",
        "code": "saint_george",
        "name": "Saint George"
      },
      {
        "_id": "saint_james",
        "code": "saint_james",
        "name": "Saint James"
      },
      {
        "_id": "saint_john",
        "code": "saint_john",
        "name": "Saint John"
      },
      {
        "_id": "saint_joseph",
        "code": "saint_joseph",
        "name": "Saint Joseph"
      },
      {
        "_id": "saint_lucy",
        "code": "saint_lucy",
        "name": "Saint Lucy"
      },
      {
        "_id": "saint_michael",
        "code": "saint_michael",
        "name": "Saint Michael"
      },
      {
        "_id": "saint_peter",
        "code": "saint_peter",
        "name": "Saint Peter"
      },
      {
        "_id": "saint_philip",
        "code": "saint_philip",
        "name": "Saint Philip"
      },
      {
        "_id": "saint_thomas",
        "code": "saint_thomas",
        "name": "Saint Thomas"
      }
    ]
  },
  {
    "_id": "BY",
    "code": "BY",
    "name": "Belarus",
    "states": [
      {
        "_id": "brest",
        "code": "brest",
        "name": "Brest"
      },
      {
        "_id": "homjel",
        "code": "homjel",
        "name": "Homjel"
      },
      {
        "_id": "hrodna",
        "code": "hrodna",
        "name": "Hrodna"
      },
      {
        "_id": "mahiljow",
        "code": "mahiljow",
        "name": "Mahiljow"
      },
      {
        "_id": "mahilyowskaya_voblasts",
        "code": "mahilyowskaya_voblasts",
        "name": "Mahilyowskaya Voblasts"
      },
      {
        "_id": "minsk",
        "code": "minsk",
        "name": "Minsk"
      },
      {
        "_id": "minskaja_voblasts",
        "code": "minskaja_voblasts",
        "name": "Minskaja Voblasts"
      },
      {
        "_id": "petrik",
        "code": "petrik",
        "name": "Petrik"
      },
      {
        "_id": "vicebsk",
        "code": "vicebsk",
        "name": "Vicebsk"
      }
    ]
  },
  {
    "_id": "BE",
    "code": "BE",
    "name": "Belgium",
    "states": [
      {
        "_id": "antwerpen",
        "code": "antwerpen",
        "name": "Antwerpen"
      },
      {
        "_id": "berchem",
        "code": "berchem",
        "name": "Berchem"
      },
      {
        "_id": "brabant",
        "code": "brabant",
        "name": "Brabant"
      },
      {
        "_id": "brabant_wallon",
        "code": "brabant_wallon",
        "name": "Brabant Wallon"
      },
      {
        "_id": "brussel",
        "code": "brussel",
        "name": "Brussel"
      },
      {
        "_id": "east_flanders",
        "code": "east_flanders",
        "name": "East Flanders"
      },
      {
        "_id": "hainaut",
        "code": "hainaut",
        "name": "Hainaut"
      },
      {
        "_id": "liege",
        "code": "liege",
        "name": "Liege"
      },
      {
        "_id": "limburg",
        "code": "limburg",
        "name": "Limburg"
      },
      {
        "_id": "luxembourg",
        "code": "luxembourg",
        "name": "Luxembourg"
      },
      {
        "_id": "namur",
        "code": "namur",
        "name": "Namur"
      },
      {
        "_id": "ontario",
        "code": "ontario",
        "name": "Ontario"
      },
      {
        "_id": "oost-vlaanderen",
        "code": "oost-vlaanderen",
        "name": "Oost-Vlaanderen"
      },
      {
        "_id": "provincie_brabant",
        "code": "provincie_brabant",
        "name": "Provincie Brabant"
      },
      {
        "_id": "vlaams-brabant",
        "code": "vlaams-brabant",
        "name": "Vlaams-Brabant"
      },
      {
        "_id": "wallonne",
        "code": "wallonne",
        "name": "Wallonne"
      },
      {
        "_id": "west-vlaanderen",
        "code": "west-vlaanderen",
        "name": "West-Vlaanderen"
      }
    ]
  },
  {
    "_id": "BZ",
    "code": "BZ",
    "name": "Belize",
    "states": [
      {
        "_id": "belize",
        "code": "belize",
        "name": "Belize"
      },
      {
        "_id": "cayo",
        "code": "cayo",
        "name": "Cayo"
      },
      {
        "_id": "corozal",
        "code": "corozal",
        "name": "Corozal"
      },
      {
        "_id": "orange_walk",
        "code": "orange_walk",
        "name": "Orange Walk"
      },
      {
        "_id": "stann_creek",
        "code": "stann_creek",
        "name": "Stann Creek"
      },
      {
        "_id": "toledo",
        "code": "toledo",
        "name": "Toledo"
      }
    ]
  },
  {
    "_id": "BJ",
    "code": "BJ",
    "name": "Benin",
    "states": [
      {
        "_id": "alibori",
        "code": "alibori",
        "name": "Alibori"
      },
      {
        "_id": "atacora",
        "code": "atacora",
        "name": "Atacora"
      },
      {
        "_id": "atlantique",
        "code": "atlantique",
        "name": "Atlantique"
      },
      {
        "_id": "borgou",
        "code": "borgou",
        "name": "Borgou"
      },
      {
        "_id": "collines",
        "code": "collines",
        "name": "Collines"
      },
      {
        "_id": "couffo",
        "code": "couffo",
        "name": "Couffo"
      },
      {
        "_id": "donga",
        "code": "donga",
        "name": "Donga"
      },
      {
        "_id": "littoral",
        "code": "littoral",
        "name": "Littoral"
      },
      {
        "_id": "mono",
        "code": "mono",
        "name": "Mono"
      },
      {
        "_id": "oueme",
        "code": "oueme",
        "name": "Oueme"
      },
      {
        "_id": "plateau",
        "code": "plateau",
        "name": "Plateau"
      },
      {
        "_id": "zou",
        "code": "zou",
        "name": "Zou"
      }
    ]
  },
  {
    "_id": "BM",
    "code": "BM",
    "name": "Bermuda",
    "states": [
      {
        "_id": "hamilton",
        "code": "hamilton",
        "name": "Hamilton"
      },
      {
        "_id": "saint_george",
        "code": "saint_george",
        "name": "Saint George"
      }
    ]
  },
  {
    "_id": "BT",
    "code": "BT",
    "name": "Bhutan",
    "states": [
      {
        "_id": "bumthang",
        "code": "bumthang",
        "name": "Bumthang"
      },
      {
        "_id": "chhukha",
        "code": "chhukha",
        "name": "Chhukha"
      },
      {
        "_id": "chirang",
        "code": "chirang",
        "name": "Chirang"
      },
      {
        "_id": "daga",
        "code": "daga",
        "name": "Daga"
      },
      {
        "_id": "geylegphug",
        "code": "geylegphug",
        "name": "Geylegphug"
      },
      {
        "_id": "ha",
        "code": "ha",
        "name": "Ha"
      },
      {
        "_id": "lhuntshi",
        "code": "lhuntshi",
        "name": "Lhuntshi"
      },
      {
        "_id": "mongar",
        "code": "mongar",
        "name": "Mongar"
      },
      {
        "_id": "pemagatsel",
        "code": "pemagatsel",
        "name": "Pemagatsel"
      },
      {
        "_id": "punakha",
        "code": "punakha",
        "name": "Punakha"
      },
      {
        "_id": "rinpung",
        "code": "rinpung",
        "name": "Rinpung"
      },
      {
        "_id": "samchi",
        "code": "samchi",
        "name": "Samchi"
      },
      {
        "_id": "samdrup_jongkhar",
        "code": "samdrup_jongkhar",
        "name": "Samdrup Jongkhar"
      },
      {
        "_id": "shemgang",
        "code": "shemgang",
        "name": "Shemgang"
      },
      {
        "_id": "tashigang",
        "code": "tashigang",
        "name": "Tashigang"
      },
      {
        "_id": "timphu",
        "code": "timphu",
        "name": "Timphu"
      },
      {
        "_id": "tongsa",
        "code": "tongsa",
        "name": "Tongsa"
      },
      {
        "_id": "wangdiphodrang",
        "code": "wangdiphodrang",
        "name": "Wangdiphodrang"
      }
    ]
  },
  {
    "_id": "BO",
    "code": "BO",
    "name": "Bolivia",
    "states": [
      {
        "_id": "beni",
        "code": "beni",
        "name": "Beni"
      },
      {
        "_id": "chuquisaca",
        "code": "chuquisaca",
        "name": "Chuquisaca"
      },
      {
        "_id": "cochabamba",
        "code": "cochabamba",
        "name": "Cochabamba"
      },
      {
        "_id": "la_paz",
        "code": "la_paz",
        "name": "La Paz"
      },
      {
        "_id": "oruro",
        "code": "oruro",
        "name": "Oruro"
      },
      {
        "_id": "pando",
        "code": "pando",
        "name": "Pando"
      },
      {
        "_id": "potosi",
        "code": "potosi",
        "name": "Potosi"
      },
      {
        "_id": "santa_cruz",
        "code": "santa_cruz",
        "name": "Santa Cruz"
      },
      {
        "_id": "tarija",
        "code": "tarija",
        "name": "Tarija"
      }
    ]
  },
  {
    "_id": "BA",
    "code": "BA",
    "name": "Bosnia and Herzegovina",
    "states": [
      {
        "_id": "federacija_bosna_i_hercegovina",
        "code": "federacija_bosna_i_hercegovina",
        "name": "Federacija Bosna i Hercegovina"
      },
      {
        "_id": "republika_srpska",
        "code": "republika_srpska",
        "name": "Republika Srpska"
      }
    ]
  },
  {
    "_id": "BW",
    "code": "BW",
    "name": "Botswana",
    "states": [
      {
        "_id": "central_bobonong",
        "code": "central_bobonong",
        "name": "Central Bobonong"
      },
      {
        "_id": "central_boteti",
        "code": "central_boteti",
        "name": "Central Boteti"
      },
      {
        "_id": "central_mahalapye",
        "code": "central_mahalapye",
        "name": "Central Mahalapye"
      },
      {
        "_id": "central_serowe-palapye",
        "code": "central_serowe-palapye",
        "name": "Central Serowe-Palapye"
      },
      {
        "_id": "central_tutume",
        "code": "central_tutume",
        "name": "Central Tutume"
      },
      {
        "_id": "chobe",
        "code": "chobe",
        "name": "Chobe"
      },
      {
        "_id": "francistown",
        "code": "francistown",
        "name": "Francistown"
      },
      {
        "_id": "gaborone",
        "code": "gaborone",
        "name": "Gaborone"
      },
      {
        "_id": "ghanzi",
        "code": "ghanzi",
        "name": "Ghanzi"
      },
      {
        "_id": "jwaneng",
        "code": "jwaneng",
        "name": "Jwaneng"
      },
      {
        "_id": "kgalagadi_north",
        "code": "kgalagadi_north",
        "name": "Kgalagadi North"
      },
      {
        "_id": "kgalagadi_south",
        "code": "kgalagadi_south",
        "name": "Kgalagadi South"
      },
      {
        "_id": "kgatleng",
        "code": "kgatleng",
        "name": "Kgatleng"
      },
      {
        "_id": "kweneng",
        "code": "kweneng",
        "name": "Kweneng"
      },
      {
        "_id": "lobatse",
        "code": "lobatse",
        "name": "Lobatse"
      },
      {
        "_id": "ngamiland",
        "code": "ngamiland",
        "name": "Ngamiland"
      },
      {
        "_id": "ngwaketse",
        "code": "ngwaketse",
        "name": "Ngwaketse"
      },
      {
        "_id": "north_east",
        "code": "north_east",
        "name": "North East"
      },
      {
        "_id": "okavango",
        "code": "okavango",
        "name": "Okavango"
      },
      {
        "_id": "orapa",
        "code": "orapa",
        "name": "Orapa"
      },
      {
        "_id": "selibe_phikwe",
        "code": "selibe_phikwe",
        "name": "Selibe Phikwe"
      },
      {
        "_id": "south_east",
        "code": "south_east",
        "name": "South East"
      },
      {
        "_id": "sowa",
        "code": "sowa",
        "name": "Sowa"
      }
    ]
  },
  {
    "_id": "BV",
    "code": "BV",
    "name": "Bouvet Island",
    "states": [
      {
        "_id": "bouvet_island",
        "code": "bouvet_island",
        "name": "Bouvet Island"
      }
    ]
  },
  {
    "_id": "BR",
    "code": "BR",
    "name": "Brazil",
    "states": [
      {
        "_id": "acre",
        "code": "acre",
        "name": "Acre"
      },
      {
        "_id": "alagoas",
        "code": "alagoas",
        "name": "Alagoas"
      },
      {
        "_id": "amapa",
        "code": "amapa",
        "name": "Amapa"
      },
      {
        "_id": "amazonas",
        "code": "amazonas",
        "name": "Amazonas"
      },
      {
        "_id": "bahia",
        "code": "bahia",
        "name": "Bahia"
      },
      {
        "_id": "ceara",
        "code": "ceara",
        "name": "Ceara"
      },
      {
        "_id": "distrito_federal",
        "code": "distrito_federal",
        "name": "Distrito Federal"
      },
      {
        "_id": "espirito_santo",
        "code": "espirito_santo",
        "name": "Espirito Santo"
      },
      {
        "_id": "estado_de_sao_paulo",
        "code": "estado_de_sao_paulo",
        "name": "Estado de Sao Paulo"
      },
      {
        "_id": "goias",
        "code": "goias",
        "name": "Goias"
      },
      {
        "_id": "maranhao",
        "code": "maranhao",
        "name": "Maranhao"
      },
      {
        "_id": "mato_grosso",
        "code": "mato_grosso",
        "name": "Mato Grosso"
      },
      {
        "_id": "mato_grosso_do_sul",
        "code": "mato_grosso_do_sul",
        "name": "Mato Grosso do Sul"
      },
      {
        "_id": "minas_gerais",
        "code": "minas_gerais",
        "name": "Minas Gerais"
      },
      {
        "_id": "para",
        "code": "para",
        "name": "Para"
      },
      {
        "_id": "paraiba",
        "code": "paraiba",
        "name": "Paraiba"
      },
      {
        "_id": "parana",
        "code": "parana",
        "name": "Parana"
      },
      {
        "_id": "pernambuco",
        "code": "pernambuco",
        "name": "Pernambuco"
      },
      {
        "_id": "piaui",
        "code": "piaui",
        "name": "Piaui"
      },
      {
        "_id": "rio_grande_do_norte",
        "code": "rio_grande_do_norte",
        "name": "Rio Grande do Norte"
      },
      {
        "_id": "rio_grande_do_sul",
        "code": "rio_grande_do_sul",
        "name": "Rio Grande do Sul"
      },
      {
        "_id": "rio_de_janeiro",
        "code": "rio_de_janeiro",
        "name": "Rio de Janeiro"
      },
      {
        "_id": "rondonia",
        "code": "rondonia",
        "name": "Rondonia"
      },
      {
        "_id": "roraima",
        "code": "roraima",
        "name": "Roraima"
      },
      {
        "_id": "santa_catarina",
        "code": "santa_catarina",
        "name": "Santa Catarina"
      },
      {
        "_id": "sao_paulo",
        "code": "sao_paulo",
        "name": "Sao Paulo"
      },
      {
        "_id": "sergipe",
        "code": "sergipe",
        "name": "Sergipe"
      },
      {
        "_id": "tocantins",
        "code": "tocantins",
        "name": "Tocantins"
      }
    ]
  },
  {
    "_id": "IO",
    "code": "IO",
    "name": "British Indian Ocean Territory",
    "states": [
      {
        "_id": "british_indian_ocean_territory",
        "code": "british_indian_ocean_territory",
        "name": "British Indian Ocean Territory"
      }
    ]
  },
  {
    "_id": "BN",
    "code": "BN",
    "name": "Brunei",
    "states": [
      {
        "_id": "belait",
        "code": "belait",
        "name": "Belait"
      },
      {
        "_id": "brunei-muara",
        "code": "brunei-muara",
        "name": "Brunei-Muara"
      },
      {
        "_id": "temburong",
        "code": "temburong",
        "name": "Temburong"
      },
      {
        "_id": "tutong",
        "code": "tutong",
        "name": "Tutong"
      }
    ]
  },
  {
    "_id": "BG",
    "code": "BG",
    "name": "Bulgaria",
    "states": [
      {
        "_id": "blagoevgrad",
        "code": "blagoevgrad",
        "name": "Blagoevgrad"
      },
      {
        "_id": "burgas",
        "code": "burgas",
        "name": "Burgas"
      },
      {
        "_id": "dobrich",
        "code": "dobrich",
        "name": "Dobrich"
      },
      {
        "_id": "gabrovo",
        "code": "gabrovo",
        "name": "Gabrovo"
      },
      {
        "_id": "haskovo",
        "code": "haskovo",
        "name": "Haskovo"
      },
      {
        "_id": "jambol",
        "code": "jambol",
        "name": "Jambol"
      },
      {
        "_id": "kardzhali",
        "code": "kardzhali",
        "name": "Kardzhali"
      },
      {
        "_id": "kjustendil",
        "code": "kjustendil",
        "name": "Kjustendil"
      },
      {
        "_id": "lovech",
        "code": "lovech",
        "name": "Lovech"
      },
      {
        "_id": "montana",
        "code": "montana",
        "name": "Montana"
      },
      {
        "_id": "oblast_sofiya-grad",
        "code": "oblast_sofiya-grad",
        "name": "Oblast Sofiya-Grad"
      },
      {
        "_id": "pazardzhik",
        "code": "pazardzhik",
        "name": "Pazardzhik"
      },
      {
        "_id": "pernik",
        "code": "pernik",
        "name": "Pernik"
      },
      {
        "_id": "pleven",
        "code": "pleven",
        "name": "Pleven"
      },
      {
        "_id": "plovdiv",
        "code": "plovdiv",
        "name": "Plovdiv"
      },
      {
        "_id": "razgrad",
        "code": "razgrad",
        "name": "Razgrad"
      },
      {
        "_id": "ruse",
        "code": "ruse",
        "name": "Ruse"
      },
      {
        "_id": "shumen",
        "code": "shumen",
        "name": "Shumen"
      },
      {
        "_id": "silistra",
        "code": "silistra",
        "name": "Silistra"
      },
      {
        "_id": "sliven",
        "code": "sliven",
        "name": "Sliven"
      },
      {
        "_id": "smoljan",
        "code": "smoljan",
        "name": "Smoljan"
      },
      {
        "_id": "sofija_grad",
        "code": "sofija_grad",
        "name": "Sofija grad"
      },
      {
        "_id": "sofijska_oblast",
        "code": "sofijska_oblast",
        "name": "Sofijska oblast"
      },
      {
        "_id": "stara_zagora",
        "code": "stara_zagora",
        "name": "Stara Zagora"
      },
      {
        "_id": "targovishte",
        "code": "targovishte",
        "name": "Targovishte"
      },
      {
        "_id": "varna",
        "code": "varna",
        "name": "Varna"
      },
      {
        "_id": "veliko_tarnovo",
        "code": "veliko_tarnovo",
        "name": "Veliko Tarnovo"
      },
      {
        "_id": "vidin",
        "code": "vidin",
        "name": "Vidin"
      },
      {
        "_id": "vraca",
        "code": "vraca",
        "name": "Vraca"
      },
      {
        "_id": "yablaniza",
        "code": "yablaniza",
        "name": "Yablaniza"
      }
    ]
  },
  {
    "_id": "BF",
    "code": "BF",
    "name": "Burkina Faso",
    "states": [
      {
        "_id": "bale",
        "code": "bale",
        "name": "Bale"
      },
      {
        "_id": "bam",
        "code": "bam",
        "name": "Bam"
      },
      {
        "_id": "bazega",
        "code": "bazega",
        "name": "Bazega"
      },
      {
        "_id": "bougouriba",
        "code": "bougouriba",
        "name": "Bougouriba"
      },
      {
        "_id": "boulgou",
        "code": "boulgou",
        "name": "Boulgou"
      },
      {
        "_id": "boulkiemde",
        "code": "boulkiemde",
        "name": "Boulkiemde"
      },
      {
        "_id": "comoe",
        "code": "comoe",
        "name": "Comoe"
      },
      {
        "_id": "ganzourgou",
        "code": "ganzourgou",
        "name": "Ganzourgou"
      },
      {
        "_id": "gnagna",
        "code": "gnagna",
        "name": "Gnagna"
      },
      {
        "_id": "gourma",
        "code": "gourma",
        "name": "Gourma"
      },
      {
        "_id": "houet",
        "code": "houet",
        "name": "Houet"
      },
      {
        "_id": "ioba",
        "code": "ioba",
        "name": "Ioba"
      },
      {
        "_id": "kadiogo",
        "code": "kadiogo",
        "name": "Kadiogo"
      },
      {
        "_id": "kenedougou",
        "code": "kenedougou",
        "name": "Kenedougou"
      },
      {
        "_id": "komandjari",
        "code": "komandjari",
        "name": "Komandjari"
      },
      {
        "_id": "kompienga",
        "code": "kompienga",
        "name": "Kompienga"
      },
      {
        "_id": "kossi",
        "code": "kossi",
        "name": "Kossi"
      },
      {
        "_id": "kouritenga",
        "code": "kouritenga",
        "name": "Kouritenga"
      },
      {
        "_id": "kourweogo",
        "code": "kourweogo",
        "name": "Kourweogo"
      },
      {
        "_id": "leraba",
        "code": "leraba",
        "name": "Leraba"
      },
      {
        "_id": "mouhoun",
        "code": "mouhoun",
        "name": "Mouhoun"
      },
      {
        "_id": "nahouri",
        "code": "nahouri",
        "name": "Nahouri"
      },
      {
        "_id": "namentenga",
        "code": "namentenga",
        "name": "Namentenga"
      },
      {
        "_id": "noumbiel",
        "code": "noumbiel",
        "name": "Noumbiel"
      },
      {
        "_id": "oubritenga",
        "code": "oubritenga",
        "name": "Oubritenga"
      },
      {
        "_id": "oudalan",
        "code": "oudalan",
        "name": "Oudalan"
      },
      {
        "_id": "passore",
        "code": "passore",
        "name": "Passore"
      },
      {
        "_id": "poni",
        "code": "poni",
        "name": "Poni"
      },
      {
        "_id": "sanguie",
        "code": "sanguie",
        "name": "Sanguie"
      },
      {
        "_id": "sanmatenga",
        "code": "sanmatenga",
        "name": "Sanmatenga"
      },
      {
        "_id": "seno",
        "code": "seno",
        "name": "Seno"
      },
      {
        "_id": "sissili",
        "code": "sissili",
        "name": "Sissili"
      },
      {
        "_id": "soum",
        "code": "soum",
        "name": "Soum"
      },
      {
        "_id": "sourou",
        "code": "sourou",
        "name": "Sourou"
      },
      {
        "_id": "tapoa",
        "code": "tapoa",
        "name": "Tapoa"
      },
      {
        "_id": "tuy",
        "code": "tuy",
        "name": "Tuy"
      },
      {
        "_id": "yatenga",
        "code": "yatenga",
        "name": "Yatenga"
      },
      {
        "_id": "zondoma",
        "code": "zondoma",
        "name": "Zondoma"
      },
      {
        "_id": "zoundweogo",
        "code": "zoundweogo",
        "name": "Zoundweogo"
      }
    ]
  },
  {
    "_id": "BI",
    "code": "BI",
    "name": "Burundi",
    "states": [
      {
        "_id": "bubanza",
        "code": "bubanza",
        "name": "Bubanza"
      },
      {
        "_id": "bujumbura",
        "code": "bujumbura",
        "name": "Bujumbura"
      },
      {
        "_id": "bururi",
        "code": "bururi",
        "name": "Bururi"
      },
      {
        "_id": "cankuzo",
        "code": "cankuzo",
        "name": "Cankuzo"
      },
      {
        "_id": "cibitoke",
        "code": "cibitoke",
        "name": "Cibitoke"
      },
      {
        "_id": "gitega",
        "code": "gitega",
        "name": "Gitega"
      },
      {
        "_id": "karuzi",
        "code": "karuzi",
        "name": "Karuzi"
      },
      {
        "_id": "kayanza",
        "code": "kayanza",
        "name": "Kayanza"
      },
      {
        "_id": "kirundo",
        "code": "kirundo",
        "name": "Kirundo"
      },
      {
        "_id": "makamba",
        "code": "makamba",
        "name": "Makamba"
      },
      {
        "_id": "muramvya",
        "code": "muramvya",
        "name": "Muramvya"
      },
      {
        "_id": "muyinga",
        "code": "muyinga",
        "name": "Muyinga"
      },
      {
        "_id": "ngozi",
        "code": "ngozi",
        "name": "Ngozi"
      },
      {
        "_id": "rutana",
        "code": "rutana",
        "name": "Rutana"
      },
      {
        "_id": "ruyigi",
        "code": "ruyigi",
        "name": "Ruyigi"
      }
    ]
  },
  {
    "_id": "KH",
    "code": "KH",
    "name": "Cambodia",
    "states": [
      {
        "_id": "banteay_mean_chey",
        "code": "banteay_mean_chey",
        "name": "Banteay Mean Chey"
      },
      {
        "_id": "bat_dambang",
        "code": "bat_dambang",
        "name": "Bat Dambang"
      },
      {
        "_id": "kampong_cham",
        "code": "kampong_cham",
        "name": "Kampong Cham"
      },
      {
        "_id": "kampong_chhnang",
        "code": "kampong_chhnang",
        "name": "Kampong Chhnang"
      },
      {
        "_id": "kampong_spoeu",
        "code": "kampong_spoeu",
        "name": "Kampong Spoeu"
      },
      {
        "_id": "kampong_thum",
        "code": "kampong_thum",
        "name": "Kampong Thum"
      },
      {
        "_id": "kampot",
        "code": "kampot",
        "name": "Kampot"
      },
      {
        "_id": "kandal",
        "code": "kandal",
        "name": "Kandal"
      },
      {
        "_id": "kaoh_kong",
        "code": "kaoh_kong",
        "name": "Kaoh Kong"
      },
      {
        "_id": "kracheh",
        "code": "kracheh",
        "name": "Kracheh"
      },
      {
        "_id": "krong_kaeb",
        "code": "krong_kaeb",
        "name": "Krong Kaeb"
      },
      {
        "_id": "krong_pailin",
        "code": "krong_pailin",
        "name": "Krong Pailin"
      },
      {
        "_id": "krong_preah_sihanouk",
        "code": "krong_preah_sihanouk",
        "name": "Krong Preah Sihanouk"
      },
      {
        "_id": "mondol_kiri",
        "code": "mondol_kiri",
        "name": "Mondol Kiri"
      },
      {
        "_id": "otdar_mean_chey",
        "code": "otdar_mean_chey",
        "name": "Otdar Mean Chey"
      },
      {
        "_id": "phnum_penh",
        "code": "phnum_penh",
        "name": "Phnum Penh"
      },
      {
        "_id": "pousat",
        "code": "pousat",
        "name": "Pousat"
      },
      {
        "_id": "preah_vihear",
        "code": "preah_vihear",
        "name": "Preah Vihear"
      },
      {
        "_id": "prey_veaeng",
        "code": "prey_veaeng",
        "name": "Prey Veaeng"
      },
      {
        "_id": "rotanak_kiri",
        "code": "rotanak_kiri",
        "name": "Rotanak Kiri"
      },
      {
        "_id": "siem_reab",
        "code": "siem_reab",
        "name": "Siem Reab"
      },
      {
        "_id": "stueng_traeng",
        "code": "stueng_traeng",
        "name": "Stueng Traeng"
      },
      {
        "_id": "svay_rieng",
        "code": "svay_rieng",
        "name": "Svay Rieng"
      },
      {
        "_id": "takaev",
        "code": "takaev",
        "name": "Takaev"
      }
    ]
  },
  {
    "_id": "CM",
    "code": "CM",
    "name": "Cameroon",
    "states": [
      {
        "_id": "adamaoua",
        "code": "adamaoua",
        "name": "Adamaoua"
      },
      {
        "_id": "centre",
        "code": "centre",
        "name": "Centre"
      },
      {
        "_id": "est",
        "code": "est",
        "name": "Est"
      },
      {
        "_id": "littoral",
        "code": "littoral",
        "name": "Littoral"
      },
      {
        "_id": "nord",
        "code": "nord",
        "name": "Nord"
      },
      {
        "_id": "nord_extreme",
        "code": "nord_extreme",
        "name": "Nord Extreme"
      },
      {
        "_id": "nordouest",
        "code": "nordouest",
        "name": "Nordouest"
      },
      {
        "_id": "ouest",
        "code": "ouest",
        "name": "Ouest"
      },
      {
        "_id": "sud",
        "code": "sud",
        "name": "Sud"
      },
      {
        "_id": "sudouest",
        "code": "sudouest",
        "name": "Sudouest"
      }
    ]
  },
  {
    "_id": "CV",
    "code": "CV",
    "name": "Cape Verde",
    "states": [
      {
        "_id": "boavista",
        "code": "boavista",
        "name": "Boavista"
      },
      {
        "_id": "brava",
        "code": "brava",
        "name": "Brava"
      },
      {
        "_id": "fogo",
        "code": "fogo",
        "name": "Fogo"
      },
      {
        "_id": "maio",
        "code": "maio",
        "name": "Maio"
      },
      {
        "_id": "sal",
        "code": "sal",
        "name": "Sal"
      },
      {
        "_id": "santo_antao",
        "code": "santo_antao",
        "name": "Santo Antao"
      },
      {
        "_id": "sao_nicolau",
        "code": "sao_nicolau",
        "name": "Sao Nicolau"
      },
      {
        "_id": "sao_tiago",
        "code": "sao_tiago",
        "name": "Sao Tiago"
      },
      {
        "_id": "sao_vicente",
        "code": "sao_vicente",
        "name": "Sao Vicente"
      }
    ]
  },
  {
    "_id": "KY",
    "code": "KY",
    "name": "Cayman Islands",
    "states": [
      {
        "_id": "grand_cayman",
        "code": "grand_cayman",
        "name": "Grand Cayman"
      }
    ]
  },
  {
    "_id": "CF",
    "code": "CF",
    "name": "Central African Republic",
    "states": [
      {
        "_id": "bamingui-bangoran",
        "code": "bamingui-bangoran",
        "name": "Bamingui-Bangoran"
      },
      {
        "_id": "bangui",
        "code": "bangui",
        "name": "Bangui"
      },
      {
        "_id": "basse-kotto",
        "code": "basse-kotto",
        "name": "Basse-Kotto"
      },
      {
        "_id": "haut-mbomou",
        "code": "haut-mbomou",
        "name": "Haut-Mbomou"
      },
      {
        "_id": "haute-kotto",
        "code": "haute-kotto",
        "name": "Haute-Kotto"
      },
      {
        "_id": "kemo",
        "code": "kemo",
        "name": "Kemo"
      },
      {
        "_id": "lobaye",
        "code": "lobaye",
        "name": "Lobaye"
      },
      {
        "_id": "mambere-kadei",
        "code": "mambere-kadei",
        "name": "Mambere-Kadei"
      },
      {
        "_id": "mbomou",
        "code": "mbomou",
        "name": "Mbomou"
      },
      {
        "_id": "nana-gribizi",
        "code": "nana-gribizi",
        "name": "Nana-Gribizi"
      },
      {
        "_id": "nana-mambere",
        "code": "nana-mambere",
        "name": "Nana-Mambere"
      },
      {
        "_id": "ombella_mpoko",
        "code": "ombella_mpoko",
        "name": "Ombella Mpoko"
      },
      {
        "_id": "ouaka",
        "code": "ouaka",
        "name": "Ouaka"
      },
      {
        "_id": "ouham",
        "code": "ouham",
        "name": "Ouham"
      },
      {
        "_id": "ouham-pende",
        "code": "ouham-pende",
        "name": "Ouham-Pende"
      },
      {
        "_id": "sangha-mbaere",
        "code": "sangha-mbaere",
        "name": "Sangha-Mbaere"
      },
      {
        "_id": "vakaga",
        "code": "vakaga",
        "name": "Vakaga"
      }
    ]
  },
  {
    "_id": "TD",
    "code": "TD",
    "name": "Chad",
    "states": [
      {
        "_id": "batha",
        "code": "batha",
        "name": "Batha"
      },
      {
        "_id": "biltine",
        "code": "biltine",
        "name": "Biltine"
      },
      {
        "_id": "bourkou-ennedi-tibesti",
        "code": "bourkou-ennedi-tibesti",
        "name": "Bourkou-Ennedi-Tibesti"
      },
      {
        "_id": "chari-baguirmi",
        "code": "chari-baguirmi",
        "name": "Chari-Baguirmi"
      },
      {
        "_id": "guera",
        "code": "guera",
        "name": "Guera"
      },
      {
        "_id": "kanem",
        "code": "kanem",
        "name": "Kanem"
      },
      {
        "_id": "lac",
        "code": "lac",
        "name": "Lac"
      },
      {
        "_id": "logone_occidental",
        "code": "logone_occidental",
        "name": "Logone Occidental"
      },
      {
        "_id": "logone_oriental",
        "code": "logone_oriental",
        "name": "Logone Oriental"
      },
      {
        "_id": "mayo-kebbi",
        "code": "mayo-kebbi",
        "name": "Mayo-Kebbi"
      },
      {
        "_id": "moyen-chari",
        "code": "moyen-chari",
        "name": "Moyen-Chari"
      },
      {
        "_id": "ouaddai",
        "code": "ouaddai",
        "name": "Ouaddai"
      },
      {
        "_id": "salamat",
        "code": "salamat",
        "name": "Salamat"
      },
      {
        "_id": "tandjile",
        "code": "tandjile",
        "name": "Tandjile"
      }
    ]
  },
  {
    "_id": "CL",
    "code": "CL",
    "name": "Chile",
    "states": [
      {
        "_id": "aisen",
        "code": "aisen",
        "name": "Aisen"
      },
      {
        "_id": "antofagasta",
        "code": "antofagasta",
        "name": "Antofagasta"
      },
      {
        "_id": "araucania",
        "code": "araucania",
        "name": "Araucania"
      },
      {
        "_id": "atacama",
        "code": "atacama",
        "name": "Atacama"
      },
      {
        "_id": "bio_bio",
        "code": "bio_bio",
        "name": "Bio Bio"
      },
      {
        "_id": "coquimbo",
        "code": "coquimbo",
        "name": "Coquimbo"
      },
      {
        "_id": "libertador_general_bernardo_o",
        "code": "libertador_general_bernardo_o",
        "name": "Libertador General Bernardo O"
      },
      {
        "_id": "los_lagos",
        "code": "los_lagos",
        "name": "Los Lagos"
      },
      {
        "_id": "magellanes",
        "code": "magellanes",
        "name": "Magellanes"
      },
      {
        "_id": "maule",
        "code": "maule",
        "name": "Maule"
      },
      {
        "_id": "metropolitana",
        "code": "metropolitana",
        "name": "Metropolitana"
      },
      {
        "_id": "metropolitana_de_santiago",
        "code": "metropolitana_de_santiago",
        "name": "Metropolitana de Santiago"
      },
      {
        "_id": "tarapaca",
        "code": "tarapaca",
        "name": "Tarapaca"
      },
      {
        "_id": "valparaiso",
        "code": "valparaiso",
        "name": "Valparaiso"
      }
    ]
  },
  {
    "_id": "CN",
    "code": "CN",
    "name": "China",
    "states": [
      {
        "_id": "anhui",
        "code": "anhui",
        "name": "Anhui"
      },
      {
        "_id": "anhui_province",
        "code": "anhui_province",
        "name": "Anhui Province"
      },
      {
        "_id": "anhui_sheng",
        "code": "anhui_sheng",
        "name": "Anhui Sheng"
      },
      {
        "_id": "aomen",
        "code": "aomen",
        "name": "Aomen"
      },
      {
        "_id": "beijing",
        "code": "beijing",
        "name": "Beijing"
      },
      {
        "_id": "beijing_shi",
        "code": "beijing_shi",
        "name": "Beijing Shi"
      },
      {
        "_id": "chongqing",
        "code": "chongqing",
        "name": "Chongqing"
      },
      {
        "_id": "fujian",
        "code": "fujian",
        "name": "Fujian"
      },
      {
        "_id": "fujian_sheng",
        "code": "fujian_sheng",
        "name": "Fujian Sheng"
      },
      {
        "_id": "gansu",
        "code": "gansu",
        "name": "Gansu"
      },
      {
        "_id": "guangdong",
        "code": "guangdong",
        "name": "Guangdong"
      },
      {
        "_id": "guangdong_sheng",
        "code": "guangdong_sheng",
        "name": "Guangdong Sheng"
      },
      {
        "_id": "guangxi",
        "code": "guangxi",
        "name": "Guangxi"
      },
      {
        "_id": "guizhou",
        "code": "guizhou",
        "name": "Guizhou"
      },
      {
        "_id": "hainan",
        "code": "hainan",
        "name": "Hainan"
      },
      {
        "_id": "hebei",
        "code": "hebei",
        "name": "Hebei"
      },
      {
        "_id": "heilongjiang",
        "code": "heilongjiang",
        "name": "Heilongjiang"
      },
      {
        "_id": "henan",
        "code": "henan",
        "name": "Henan"
      },
      {
        "_id": "hubei",
        "code": "hubei",
        "name": "Hubei"
      },
      {
        "_id": "hunan",
        "code": "hunan",
        "name": "Hunan"
      },
      {
        "_id": "jiangsu",
        "code": "jiangsu",
        "name": "Jiangsu"
      },
      {
        "_id": "jiangsu_sheng",
        "code": "jiangsu_sheng",
        "name": "Jiangsu Sheng"
      },
      {
        "_id": "jiangxi",
        "code": "jiangxi",
        "name": "Jiangxi"
      },
      {
        "_id": "jilin",
        "code": "jilin",
        "name": "Jilin"
      },
      {
        "_id": "liaoning",
        "code": "liaoning",
        "name": "Liaoning"
      },
      {
        "_id": "liaoning_sheng",
        "code": "liaoning_sheng",
        "name": "Liaoning Sheng"
      },
      {
        "_id": "nei_monggol",
        "code": "nei_monggol",
        "name": "Nei Monggol"
      },
      {
        "_id": "ningxia_hui",
        "code": "ningxia_hui",
        "name": "Ningxia Hui"
      },
      {
        "_id": "qinghai",
        "code": "qinghai",
        "name": "Qinghai"
      },
      {
        "_id": "shaanxi",
        "code": "shaanxi",
        "name": "Shaanxi"
      },
      {
        "_id": "shandong",
        "code": "shandong",
        "name": "Shandong"
      },
      {
        "_id": "shandong_sheng",
        "code": "shandong_sheng",
        "name": "Shandong Sheng"
      },
      {
        "_id": "shanghai",
        "code": "shanghai",
        "name": "Shanghai"
      },
      {
        "_id": "shanxi",
        "code": "shanxi",
        "name": "Shanxi"
      },
      {
        "_id": "sichuan",
        "code": "sichuan",
        "name": "Sichuan"
      },
      {
        "_id": "tianjin",
        "code": "tianjin",
        "name": "Tianjin"
      },
      {
        "_id": "xianggang",
        "code": "xianggang",
        "name": "Xianggang"
      },
      {
        "_id": "xinjiang",
        "code": "xinjiang",
        "name": "Xinjiang"
      },
      {
        "_id": "xizang",
        "code": "xizang",
        "name": "Xizang"
      },
      {
        "_id": "yunnan",
        "code": "yunnan",
        "name": "Yunnan"
      },
      {
        "_id": "zhejiang",
        "code": "zhejiang",
        "name": "Zhejiang"
      },
      {
        "_id": "zhejiang_sheng",
        "code": "zhejiang_sheng",
        "name": "Zhejiang Sheng"
      }
    ]
  },
  {
    "_id": "CX",
    "code": "CX",
    "name": "Christmas Island",
    "states": [
      {
        "_id": "christmas_island",
        "code": "christmas_island",
        "name": "Christmas Island"
      }
    ]
  },
  {
    "_id": "CC",
    "code": "CC",
    "name": "Cocos Keeling Islands",
    "states": [
      {
        "_id": "cocos_(keeling_islands",
        "code": "cocos_(keeling_islands",
        "name": "Cocos (Keeling Islands"
      }
    ]
  },
  {
    "_id": "CO",
    "code": "CO",
    "name": "Colombia",
    "states": [
      {
        "_id": "amazonas",
        "code": "amazonas",
        "name": "Amazonas"
      },
      {
        "_id": "antioquia",
        "code": "antioquia",
        "name": "Antioquia"
      },
      {
        "_id": "arauca",
        "code": "arauca",
        "name": "Arauca"
      },
      {
        "_id": "atlantico",
        "code": "atlantico",
        "name": "Atlantico"
      },
      {
        "_id": "bogota",
        "code": "bogota",
        "name": "Bogota"
      },
      {
        "_id": "bolivar",
        "code": "bolivar",
        "name": "Bolivar"
      },
      {
        "_id": "boyaca",
        "code": "boyaca",
        "name": "Boyaca"
      },
      {
        "_id": "caldas",
        "code": "caldas",
        "name": "Caldas"
      },
      {
        "_id": "caqueta",
        "code": "caqueta",
        "name": "Caqueta"
      },
      {
        "_id": "casanare",
        "code": "casanare",
        "name": "Casanare"
      },
      {
        "_id": "cauca",
        "code": "cauca",
        "name": "Cauca"
      },
      {
        "_id": "cesar",
        "code": "cesar",
        "name": "Cesar"
      },
      {
        "_id": "choco",
        "code": "choco",
        "name": "Choco"
      },
      {
        "_id": "cordoba",
        "code": "cordoba",
        "name": "Cordoba"
      },
      {
        "_id": "cundinamarca",
        "code": "cundinamarca",
        "name": "Cundinamarca"
      },
      {
        "_id": "guainia",
        "code": "guainia",
        "name": "Guainia"
      },
      {
        "_id": "guaviare",
        "code": "guaviare",
        "name": "Guaviare"
      },
      {
        "_id": "huila",
        "code": "huila",
        "name": "Huila"
      },
      {
        "_id": "la_guajira",
        "code": "la_guajira",
        "name": "La Guajira"
      },
      {
        "_id": "magdalena",
        "code": "magdalena",
        "name": "Magdalena"
      },
      {
        "_id": "meta",
        "code": "meta",
        "name": "Meta"
      },
      {
        "_id": "narino",
        "code": "narino",
        "name": "Narino"
      },
      {
        "_id": "norte_de_santander",
        "code": "norte_de_santander",
        "name": "Norte de Santander"
      },
      {
        "_id": "putumayo",
        "code": "putumayo",
        "name": "Putumayo"
      },
      {
        "_id": "quindio",
        "code": "quindio",
        "name": "Quindio"
      },
      {
        "_id": "risaralda",
        "code": "risaralda",
        "name": "Risaralda"
      },
      {
        "_id": "san_andres_y_providencia",
        "code": "san_andres_y_providencia",
        "name": "San Andres y Providencia"
      },
      {
        "_id": "santander",
        "code": "santander",
        "name": "Santander"
      },
      {
        "_id": "sucre",
        "code": "sucre",
        "name": "Sucre"
      },
      {
        "_id": "tolima",
        "code": "tolima",
        "name": "Tolima"
      },
      {
        "_id": "valle_del_cauca",
        "code": "valle_del_cauca",
        "name": "Valle del Cauca"
      },
      {
        "_id": "vaupes",
        "code": "vaupes",
        "name": "Vaupes"
      },
      {
        "_id": "vichada",
        "code": "vichada",
        "name": "Vichada"
      }
    ]
  },
  {
    "_id": "KM",
    "code": "KM",
    "name": "Comoros",
    "states": [
      {
        "_id": "mwali",
        "code": "mwali",
        "name": "Mwali"
      },
      {
        "_id": "njazidja",
        "code": "njazidja",
        "name": "Njazidja"
      },
      {
        "_id": "nzwani",
        "code": "nzwani",
        "name": "Nzwani"
      }
    ]
  },
  {
    "_id": "CK",
    "code": "CK",
    "name": "Cook Islands",
    "states": [
      {
        "_id": "aitutaki",
        "code": "aitutaki",
        "name": "Aitutaki"
      },
      {
        "_id": "atiu",
        "code": "atiu",
        "name": "Atiu"
      },
      {
        "_id": "mangaia",
        "code": "mangaia",
        "name": "Mangaia"
      },
      {
        "_id": "manihiki",
        "code": "manihiki",
        "name": "Manihiki"
      },
      {
        "_id": "mauke",
        "code": "mauke",
        "name": "Mauke"
      },
      {
        "_id": "mitiaro",
        "code": "mitiaro",
        "name": "Mitiaro"
      },
      {
        "_id": "nassau",
        "code": "nassau",
        "name": "Nassau"
      },
      {
        "_id": "pukapuka",
        "code": "pukapuka",
        "name": "Pukapuka"
      },
      {
        "_id": "rakahanga",
        "code": "rakahanga",
        "name": "Rakahanga"
      },
      {
        "_id": "rarotonga",
        "code": "rarotonga",
        "name": "Rarotonga"
      },
      {
        "_id": "tongareva",
        "code": "tongareva",
        "name": "Tongareva"
      }
    ]
  },
  {
    "_id": "CR",
    "code": "CR",
    "name": "Costa Rica",
    "states": [
      {
        "_id": "alajuela",
        "code": "alajuela",
        "name": "Alajuela"
      },
      {
        "_id": "cartago",
        "code": "cartago",
        "name": "Cartago"
      },
      {
        "_id": "guanacaste",
        "code": "guanacaste",
        "name": "Guanacaste"
      },
      {
        "_id": "heredia",
        "code": "heredia",
        "name": "Heredia"
      },
      {
        "_id": "limon",
        "code": "limon",
        "name": "Limon"
      },
      {
        "_id": "puntarenas",
        "code": "puntarenas",
        "name": "Puntarenas"
      },
      {
        "_id": "san_jose",
        "code": "san_jose",
        "name": "San Jose"
      }
    ]
  },
  {
    "_id": "CI",
    "code": "CI",
    "name": "Cote DIvoire Ivory Coast",
    "states": [
      {
        "_id": "abidjan",
        "code": "abidjan",
        "name": "Abidjan"
      },
      {
        "_id": "agneby",
        "code": "agneby",
        "name": "Agneby"
      },
      {
        "_id": "bafing",
        "code": "bafing",
        "name": "Bafing"
      },
      {
        "_id": "denguele",
        "code": "denguele",
        "name": "Denguele"
      },
      {
        "_id": "dix-huit_montagnes",
        "code": "dix-huit_montagnes",
        "name": "Dix-huit Montagnes"
      },
      {
        "_id": "fromager",
        "code": "fromager",
        "name": "Fromager"
      },
      {
        "_id": "haut-sassandra",
        "code": "haut-sassandra",
        "name": "Haut-Sassandra"
      },
      {
        "_id": "lacs",
        "code": "lacs",
        "name": "Lacs"
      },
      {
        "_id": "lagunes",
        "code": "lagunes",
        "name": "Lagunes"
      },
      {
        "_id": "marahoue",
        "code": "marahoue",
        "name": "Marahoue"
      },
      {
        "_id": "moyen-cavally",
        "code": "moyen-cavally",
        "name": "Moyen-Cavally"
      },
      {
        "_id": "moyen-comoe",
        "code": "moyen-comoe",
        "name": "Moyen-Comoe"
      },
      {
        "_id": "nzi-comoe",
        "code": "nzi-comoe",
        "name": "Nzi-Comoe"
      },
      {
        "_id": "sassandra",
        "code": "sassandra",
        "name": "Sassandra"
      },
      {
        "_id": "savanes",
        "code": "savanes",
        "name": "Savanes"
      },
      {
        "_id": "sud-bandama",
        "code": "sud-bandama",
        "name": "Sud-Bandama"
      },
      {
        "_id": "sud-comoe",
        "code": "sud-comoe",
        "name": "Sud-Comoe"
      },
      {
        "_id": "vallee_du_bandama",
        "code": "vallee_du_bandama",
        "name": "Vallee du Bandama"
      },
      {
        "_id": "worodougou",
        "code": "worodougou",
        "name": "Worodougou"
      },
      {
        "_id": "zanzan",
        "code": "zanzan",
        "name": "Zanzan"
      }
    ]
  },
  {
    "_id": "HR",
    "code": "HR",
    "name": "Croatia Hrvatska",
    "states": [
      {
        "_id": "bjelovar-bilogora",
        "code": "bjelovar-bilogora",
        "name": "Bjelovar-Bilogora"
      },
      {
        "_id": "dubrovnik-neretva",
        "code": "dubrovnik-neretva",
        "name": "Dubrovnik-Neretva"
      },
      {
        "_id": "grad_zagreb",
        "code": "grad_zagreb",
        "name": "Grad Zagreb"
      },
      {
        "_id": "istra",
        "code": "istra",
        "name": "Istra"
      },
      {
        "_id": "karlovac",
        "code": "karlovac",
        "name": "Karlovac"
      },
      {
        "_id": "koprivnica-krizhevci",
        "code": "koprivnica-krizhevci",
        "name": "Koprivnica-Krizhevci"
      },
      {
        "_id": "krapina-zagorje",
        "code": "krapina-zagorje",
        "name": "Krapina-Zagorje"
      },
      {
        "_id": "lika-senj",
        "code": "lika-senj",
        "name": "Lika-Senj"
      },
      {
        "_id": "medhimurje",
        "code": "medhimurje",
        "name": "Medhimurje"
      },
      {
        "_id": "medimurska_zupanija",
        "code": "medimurska_zupanija",
        "name": "Medimurska Zupanija"
      },
      {
        "_id": "osijek-baranja",
        "code": "osijek-baranja",
        "name": "Osijek-Baranja"
      },
      {
        "_id": "osjecko-baranjska_zupanija",
        "code": "osjecko-baranjska_zupanija",
        "name": "Osjecko-Baranjska Zupanija"
      },
      {
        "_id": "pozhega-slavonija",
        "code": "pozhega-slavonija",
        "name": "Pozhega-Slavonija"
      },
      {
        "_id": "primorje-gorski_kotar",
        "code": "primorje-gorski_kotar",
        "name": "Primorje-Gorski Kotar"
      },
      {
        "_id": "shibenik-knin",
        "code": "shibenik-knin",
        "name": "Shibenik-Knin"
      },
      {
        "_id": "sisak-moslavina",
        "code": "sisak-moslavina",
        "name": "Sisak-Moslavina"
      },
      {
        "_id": "slavonski_brod-posavina",
        "code": "slavonski_brod-posavina",
        "name": "Slavonski Brod-Posavina"
      },
      {
        "_id": "split-dalmacija",
        "code": "split-dalmacija",
        "name": "Split-Dalmacija"
      },
      {
        "_id": "varazhdin",
        "code": "varazhdin",
        "name": "Varazhdin"
      },
      {
        "_id": "virovitica-podravina",
        "code": "virovitica-podravina",
        "name": "Virovitica-Podravina"
      },
      {
        "_id": "vukovar-srijem",
        "code": "vukovar-srijem",
        "name": "Vukovar-Srijem"
      },
      {
        "_id": "zadar",
        "code": "zadar",
        "name": "Zadar"
      },
      {
        "_id": "zagreb",
        "code": "zagreb",
        "name": "Zagreb"
      }
    ]
  },
  {
    "_id": "CU",
    "code": "CU",
    "name": "Cuba",
    "states": [
      {
        "_id": "camaguey",
        "code": "camaguey",
        "name": "Camaguey"
      },
      {
        "_id": "ciego_de_avila",
        "code": "ciego_de_avila",
        "name": "Ciego de Avila"
      },
      {
        "_id": "cienfuegos",
        "code": "cienfuegos",
        "name": "Cienfuegos"
      },
      {
        "_id": "ciudad_de_la_habana",
        "code": "ciudad_de_la_habana",
        "name": "Ciudad de la Habana"
      },
      {
        "_id": "granma",
        "code": "granma",
        "name": "Granma"
      },
      {
        "_id": "guantanamo",
        "code": "guantanamo",
        "name": "Guantanamo"
      },
      {
        "_id": "habana",
        "code": "habana",
        "name": "Habana"
      },
      {
        "_id": "holguin",
        "code": "holguin",
        "name": "Holguin"
      },
      {
        "_id": "isla_de_la_juventud",
        "code": "isla_de_la_juventud",
        "name": "Isla de la Juventud"
      },
      {
        "_id": "la_habana",
        "code": "la_habana",
        "name": "La Habana"
      },
      {
        "_id": "las_tunas",
        "code": "las_tunas",
        "name": "Las Tunas"
      },
      {
        "_id": "matanzas",
        "code": "matanzas",
        "name": "Matanzas"
      },
      {
        "_id": "pinar_del_rio",
        "code": "pinar_del_rio",
        "name": "Pinar del Rio"
      },
      {
        "_id": "sancti_spiritus",
        "code": "sancti_spiritus",
        "name": "Sancti Spiritus"
      },
      {
        "_id": "santiago_de_cuba",
        "code": "santiago_de_cuba",
        "name": "Santiago de Cuba"
      },
      {
        "_id": "villa_clara",
        "code": "villa_clara",
        "name": "Villa Clara"
      }
    ]
  },
  {
    "_id": "CY",
    "code": "CY",
    "name": "Cyprus",
    "states": [
      {
        "_id": "government_controlled_area",
        "code": "government_controlled_area",
        "name": "Government controlled area"
      },
      {
        "_id": "limassol",
        "code": "limassol",
        "name": "Limassol"
      },
      {
        "_id": "nicosia_district",
        "code": "nicosia_district",
        "name": "Nicosia District"
      },
      {
        "_id": "paphos",
        "code": "paphos",
        "name": "Paphos"
      },
      {
        "_id": "turkish_controlled_area",
        "code": "turkish_controlled_area",
        "name": "Turkish controlled area"
      }
    ]
  },
  {
    "_id": "CZ",
    "code": "CZ",
    "name": "Czech Republic",
    "states": [
      {
        "_id": "central_bohemian",
        "code": "central_bohemian",
        "name": "Central Bohemian"
      },
      {
        "_id": "frycovice",
        "code": "frycovice",
        "name": "Frycovice"
      },
      {
        "_id": "jihocesky_kraj",
        "code": "jihocesky_kraj",
        "name": "Jihocesky Kraj"
      },
      {
        "_id": "jihochesky",
        "code": "jihochesky",
        "name": "Jihochesky"
      },
      {
        "_id": "jihomoravsky",
        "code": "jihomoravsky",
        "name": "Jihomoravsky"
      },
      {
        "_id": "karlovarsky",
        "code": "karlovarsky",
        "name": "Karlovarsky"
      },
      {
        "_id": "klecany",
        "code": "klecany",
        "name": "Klecany"
      },
      {
        "_id": "kralovehradecky",
        "code": "kralovehradecky",
        "name": "Kralovehradecky"
      },
      {
        "_id": "liberecky",
        "code": "liberecky",
        "name": "Liberecky"
      },
      {
        "_id": "lipov",
        "code": "lipov",
        "name": "Lipov"
      },
      {
        "_id": "moravskoslezsky",
        "code": "moravskoslezsky",
        "name": "Moravskoslezsky"
      },
      {
        "_id": "olomoucky",
        "code": "olomoucky",
        "name": "Olomoucky"
      },
      {
        "_id": "olomoucky_kraj",
        "code": "olomoucky_kraj",
        "name": "Olomoucky Kraj"
      },
      {
        "_id": "pardubicky",
        "code": "pardubicky",
        "name": "Pardubicky"
      },
      {
        "_id": "plzensky",
        "code": "plzensky",
        "name": "Plzensky"
      },
      {
        "_id": "praha",
        "code": "praha",
        "name": "Praha"
      },
      {
        "_id": "rajhrad",
        "code": "rajhrad",
        "name": "Rajhrad"
      },
      {
        "_id": "smirice",
        "code": "smirice",
        "name": "Smirice"
      },
      {
        "_id": "south_moravian",
        "code": "south_moravian",
        "name": "South Moravian"
      },
      {
        "_id": "straz_nad_nisou",
        "code": "straz_nad_nisou",
        "name": "Straz nad Nisou"
      },
      {
        "_id": "stredochesky",
        "code": "stredochesky",
        "name": "Stredochesky"
      },
      {
        "_id": "unicov",
        "code": "unicov",
        "name": "Unicov"
      },
      {
        "_id": "ustecky",
        "code": "ustecky",
        "name": "Ustecky"
      },
      {
        "_id": "valletta",
        "code": "valletta",
        "name": "Valletta"
      },
      {
        "_id": "velesin",
        "code": "velesin",
        "name": "Velesin"
      },
      {
        "_id": "vysochina",
        "code": "vysochina",
        "name": "Vysochina"
      },
      {
        "_id": "zlinsky",
        "code": "zlinsky",
        "name": "Zlinsky"
      }
    ]
  },
  {
    "_id": "CD",
    "code": "CD",
    "name": "Democratic Republic Of The Congo",
    "states": [
      {
        "_id": "bandundu",
        "code": "bandundu",
        "name": "Bandundu"
      },
      {
        "_id": "bas-congo",
        "code": "bas-congo",
        "name": "Bas-Congo"
      },
      {
        "_id": "equateur",
        "code": "equateur",
        "name": "Equateur"
      },
      {
        "_id": "haut-congo",
        "code": "haut-congo",
        "name": "Haut-Congo"
      },
      {
        "_id": "kasai-occidental",
        "code": "kasai-occidental",
        "name": "Kasai-Occidental"
      },
      {
        "_id": "kasai-oriental",
        "code": "kasai-oriental",
        "name": "Kasai-Oriental"
      },
      {
        "_id": "katanga",
        "code": "katanga",
        "name": "Katanga"
      },
      {
        "_id": "kinshasa",
        "code": "kinshasa",
        "name": "Kinshasa"
      },
      {
        "_id": "maniema",
        "code": "maniema",
        "name": "Maniema"
      },
      {
        "_id": "nord-kivu",
        "code": "nord-kivu",
        "name": "Nord-Kivu"
      },
      {
        "_id": "sud-kivu",
        "code": "sud-kivu",
        "name": "Sud-Kivu"
      }
    ]
  },
  {
    "_id": "DK",
    "code": "DK",
    "name": "Denmark",
    "states": [
      {
        "_id": "arhus",
        "code": "arhus",
        "name": "Arhus"
      },
      {
        "_id": "bornholm",
        "code": "bornholm",
        "name": "Bornholm"
      },
      {
        "_id": "frederiksborg",
        "code": "frederiksborg",
        "name": "Frederiksborg"
      },
      {
        "_id": "fyn",
        "code": "fyn",
        "name": "Fyn"
      },
      {
        "_id": "hovedstaden",
        "code": "hovedstaden",
        "name": "Hovedstaden"
      },
      {
        "_id": "kobenhavn",
        "code": "kobenhavn",
        "name": "Kobenhavn"
      },
      {
        "_id": "kobenhavns_amt",
        "code": "kobenhavns_amt",
        "name": "Kobenhavns Amt"
      },
      {
        "_id": "kobenhavns_kommune",
        "code": "kobenhavns_kommune",
        "name": "Kobenhavns Kommune"
      },
      {
        "_id": "nordjylland",
        "code": "nordjylland",
        "name": "Nordjylland"
      },
      {
        "_id": "ribe",
        "code": "ribe",
        "name": "Ribe"
      },
      {
        "_id": "ringkobing",
        "code": "ringkobing",
        "name": "Ringkobing"
      },
      {
        "_id": "roervig",
        "code": "roervig",
        "name": "Roervig"
      },
      {
        "_id": "roskilde",
        "code": "roskilde",
        "name": "Roskilde"
      },
      {
        "_id": "roslev",
        "code": "roslev",
        "name": "Roslev"
      },
      {
        "_id": "sjaelland",
        "code": "sjaelland",
        "name": "Sjaelland"
      },
      {
        "_id": "soeborg",
        "code": "soeborg",
        "name": "Soeborg"
      },
      {
        "_id": "sonderjylland",
        "code": "sonderjylland",
        "name": "Sonderjylland"
      },
      {
        "_id": "storstrom",
        "code": "storstrom",
        "name": "Storstrom"
      },
      {
        "_id": "syddanmark",
        "code": "syddanmark",
        "name": "Syddanmark"
      },
      {
        "_id": "toelloese",
        "code": "toelloese",
        "name": "Toelloese"
      },
      {
        "_id": "vejle",
        "code": "vejle",
        "name": "Vejle"
      },
      {
        "_id": "vestsjalland",
        "code": "vestsjalland",
        "name": "Vestsjalland"
      },
      {
        "_id": "viborg",
        "code": "viborg",
        "name": "Viborg"
      }
    ]
  },
  {
    "_id": "DJ",
    "code": "DJ",
    "name": "Djibouti",
    "states": [
      {
        "_id": "ali_sabih",
        "code": "ali_sabih",
        "name": "Ali Sabih"
      },
      {
        "_id": "dikhil",
        "code": "dikhil",
        "name": "Dikhil"
      },
      {
        "_id": "jibuti",
        "code": "jibuti",
        "name": "Jibuti"
      },
      {
        "_id": "tajurah",
        "code": "tajurah",
        "name": "Tajurah"
      },
      {
        "_id": "ubuk",
        "code": "ubuk",
        "name": "Ubuk"
      }
    ]
  },
  {
    "_id": "DM",
    "code": "DM",
    "name": "Dominica",
    "states": [
      {
        "_id": "saint_andrew",
        "code": "saint_andrew",
        "name": "Saint Andrew"
      },
      {
        "_id": "saint_david",
        "code": "saint_david",
        "name": "Saint David"
      },
      {
        "_id": "saint_george",
        "code": "saint_george",
        "name": "Saint George"
      },
      {
        "_id": "saint_john",
        "code": "saint_john",
        "name": "Saint John"
      },
      {
        "_id": "saint_joseph",
        "code": "saint_joseph",
        "name": "Saint Joseph"
      },
      {
        "_id": "saint_luke",
        "code": "saint_luke",
        "name": "Saint Luke"
      },
      {
        "_id": "saint_mark",
        "code": "saint_mark",
        "name": "Saint Mark"
      },
      {
        "_id": "saint_patrick",
        "code": "saint_patrick",
        "name": "Saint Patrick"
      },
      {
        "_id": "saint_paul",
        "code": "saint_paul",
        "name": "Saint Paul"
      },
      {
        "_id": "saint_peter",
        "code": "saint_peter",
        "name": "Saint Peter"
      }
    ]
  },
  {
    "_id": "DO",
    "code": "DO",
    "name": "Dominican Republic",
    "states": [
      {
        "_id": "azua",
        "code": "azua",
        "name": "Azua"
      },
      {
        "_id": "bahoruco",
        "code": "bahoruco",
        "name": "Bahoruco"
      },
      {
        "_id": "barahona",
        "code": "barahona",
        "name": "Barahona"
      },
      {
        "_id": "dajabon",
        "code": "dajabon",
        "name": "Dajabon"
      },
      {
        "_id": "distrito_nacional",
        "code": "distrito_nacional",
        "name": "Distrito Nacional"
      },
      {
        "_id": "duarte",
        "code": "duarte",
        "name": "Duarte"
      },
      {
        "_id": "el_seybo",
        "code": "el_seybo",
        "name": "El Seybo"
      },
      {
        "_id": "elias_pina",
        "code": "elias_pina",
        "name": "Elias Pina"
      },
      {
        "_id": "espaillat",
        "code": "espaillat",
        "name": "Espaillat"
      },
      {
        "_id": "hato_mayor",
        "code": "hato_mayor",
        "name": "Hato Mayor"
      },
      {
        "_id": "independencia",
        "code": "independencia",
        "name": "Independencia"
      },
      {
        "_id": "la_altagracia",
        "code": "la_altagracia",
        "name": "La Altagracia"
      },
      {
        "_id": "la_romana",
        "code": "la_romana",
        "name": "La Romana"
      },
      {
        "_id": "la_vega",
        "code": "la_vega",
        "name": "La Vega"
      },
      {
        "_id": "maria_trinidad_sanchez",
        "code": "maria_trinidad_sanchez",
        "name": "Maria Trinidad Sanchez"
      },
      {
        "_id": "monsenor_nouel",
        "code": "monsenor_nouel",
        "name": "Monsenor Nouel"
      },
      {
        "_id": "monte_cristi",
        "code": "monte_cristi",
        "name": "Monte Cristi"
      },
      {
        "_id": "monte_plata",
        "code": "monte_plata",
        "name": "Monte Plata"
      },
      {
        "_id": "pedernales",
        "code": "pedernales",
        "name": "Pedernales"
      },
      {
        "_id": "peravia",
        "code": "peravia",
        "name": "Peravia"
      },
      {
        "_id": "puerto_plata",
        "code": "puerto_plata",
        "name": "Puerto Plata"
      },
      {
        "_id": "salcedo",
        "code": "salcedo",
        "name": "Salcedo"
      },
      {
        "_id": "samana",
        "code": "samana",
        "name": "Samana"
      },
      {
        "_id": "san_cristobal",
        "code": "san_cristobal",
        "name": "San Cristobal"
      },
      {
        "_id": "san_juan",
        "code": "san_juan",
        "name": "San Juan"
      },
      {
        "_id": "san_pedro_de_macoris",
        "code": "san_pedro_de_macoris",
        "name": "San Pedro de Macoris"
      },
      {
        "_id": "sanchez_ramirez",
        "code": "sanchez_ramirez",
        "name": "Sanchez Ramirez"
      },
      {
        "_id": "santiago",
        "code": "santiago",
        "name": "Santiago"
      },
      {
        "_id": "santiago_rodriguez",
        "code": "santiago_rodriguez",
        "name": "Santiago Rodriguez"
      },
      {
        "_id": "valverde",
        "code": "valverde",
        "name": "Valverde"
      }
    ]
  },
  {
    "_id": "TP",
    "code": "TP",
    "name": "East Timor",
    "states": [
      {
        "_id": "aileu",
        "code": "aileu",
        "name": "Aileu"
      },
      {
        "_id": "ainaro",
        "code": "ainaro",
        "name": "Ainaro"
      },
      {
        "_id": "ambeno",
        "code": "ambeno",
        "name": "Ambeno"
      },
      {
        "_id": "baucau",
        "code": "baucau",
        "name": "Baucau"
      },
      {
        "_id": "bobonaro",
        "code": "bobonaro",
        "name": "Bobonaro"
      },
      {
        "_id": "cova_lima",
        "code": "cova_lima",
        "name": "Cova Lima"
      },
      {
        "_id": "dili",
        "code": "dili",
        "name": "Dili"
      },
      {
        "_id": "ermera",
        "code": "ermera",
        "name": "Ermera"
      },
      {
        "_id": "lautem",
        "code": "lautem",
        "name": "Lautem"
      },
      {
        "_id": "liquica",
        "code": "liquica",
        "name": "Liquica"
      },
      {
        "_id": "manatuto",
        "code": "manatuto",
        "name": "Manatuto"
      },
      {
        "_id": "manufahi",
        "code": "manufahi",
        "name": "Manufahi"
      },
      {
        "_id": "viqueque",
        "code": "viqueque",
        "name": "Viqueque"
      }
    ]
  },
  {
    "_id": "EC",
    "code": "EC",
    "name": "Ecuador",
    "states": [
      {
        "_id": "azuay",
        "code": "azuay",
        "name": "Azuay"
      },
      {
        "_id": "bolivar",
        "code": "bolivar",
        "name": "Bolivar"
      },
      {
        "_id": "canar",
        "code": "canar",
        "name": "Canar"
      },
      {
        "_id": "carchi",
        "code": "carchi",
        "name": "Carchi"
      },
      {
        "_id": "chimborazo",
        "code": "chimborazo",
        "name": "Chimborazo"
      },
      {
        "_id": "cotopaxi",
        "code": "cotopaxi",
        "name": "Cotopaxi"
      },
      {
        "_id": "el_oro",
        "code": "el_oro",
        "name": "El Oro"
      },
      {
        "_id": "esmeraldas",
        "code": "esmeraldas",
        "name": "Esmeraldas"
      },
      {
        "_id": "galapagos",
        "code": "galapagos",
        "name": "Galapagos"
      },
      {
        "_id": "guayas",
        "code": "guayas",
        "name": "Guayas"
      },
      {
        "_id": "imbabura",
        "code": "imbabura",
        "name": "Imbabura"
      },
      {
        "_id": "loja",
        "code": "loja",
        "name": "Loja"
      },
      {
        "_id": "los_rios",
        "code": "los_rios",
        "name": "Los Rios"
      },
      {
        "_id": "manabi",
        "code": "manabi",
        "name": "Manabi"
      },
      {
        "_id": "morona_santiago",
        "code": "morona_santiago",
        "name": "Morona Santiago"
      },
      {
        "_id": "napo",
        "code": "napo",
        "name": "Napo"
      },
      {
        "_id": "orellana",
        "code": "orellana",
        "name": "Orellana"
      },
      {
        "_id": "pastaza",
        "code": "pastaza",
        "name": "Pastaza"
      },
      {
        "_id": "pichincha",
        "code": "pichincha",
        "name": "Pichincha"
      },
      {
        "_id": "sucumbios",
        "code": "sucumbios",
        "name": "Sucumbios"
      },
      {
        "_id": "tungurahua",
        "code": "tungurahua",
        "name": "Tungurahua"
      },
      {
        "_id": "zamora_chinchipe",
        "code": "zamora_chinchipe",
        "name": "Zamora Chinchipe"
      }
    ]
  },
  {
    "_id": "EG",
    "code": "EG",
    "name": "Egypt",
    "states": [
      {
        "_id": "aswan",
        "code": "aswan",
        "name": "Aswan"
      },
      {
        "_id": "asyut",
        "code": "asyut",
        "name": "Asyut"
      },
      {
        "_id": "bani_suwayf",
        "code": "bani_suwayf",
        "name": "Bani Suwayf"
      },
      {
        "_id": "bur_said",
        "code": "bur_said",
        "name": "Bur Said"
      },
      {
        "_id": "cairo",
        "code": "cairo",
        "name": "Cairo"
      },
      {
        "_id": "dumyat",
        "code": "dumyat",
        "name": "Dumyat"
      },
      {
        "_id": "kafr-ash-shaykh",
        "code": "kafr-ash-shaykh",
        "name": "Kafr-ash-Shaykh"
      },
      {
        "_id": "matruh",
        "code": "matruh",
        "name": "Matruh"
      },
      {
        "_id": "muhafazat_ad_daqahliyah",
        "code": "muhafazat_ad_daqahliyah",
        "name": "Muhafazat ad Daqahliyah"
      },
      {
        "_id": "muhafazat_al_fayyum",
        "code": "muhafazat_al_fayyum",
        "name": "Muhafazat al Fayyum"
      },
      {
        "_id": "muhafazat_al_gharbiyah",
        "code": "muhafazat_al_gharbiyah",
        "name": "Muhafazat al Gharbiyah"
      },
      {
        "_id": "muhafazat_al_iskandariyah",
        "code": "muhafazat_al_iskandariyah",
        "name": "Muhafazat al Iskandariyah"
      },
      {
        "_id": "muhafazat_al_qahirah",
        "code": "muhafazat_al_qahirah",
        "name": "Muhafazat al Qahirah"
      },
      {
        "_id": "qina",
        "code": "qina",
        "name": "Qina"
      },
      {
        "_id": "sawhaj",
        "code": "sawhaj",
        "name": "Sawhaj"
      },
      {
        "_id": "sina_al-janubiyah",
        "code": "sina_al-janubiyah",
        "name": "Sina al-Janubiyah"
      },
      {
        "_id": "sina_ash-shamaliyah",
        "code": "sina_ash-shamaliyah",
        "name": "Sina ash-Shamaliyah"
      },
      {
        "_id": "ad-daqahliyah",
        "code": "ad-daqahliyah",
        "name": "ad-Daqahliyah"
      },
      {
        "_id": "al-bahr-al-ahmar",
        "code": "al-bahr-al-ahmar",
        "name": "al-Bahr-al-Ahmar"
      },
      {
        "_id": "al-buhayrah",
        "code": "al-buhayrah",
        "name": "al-Buhayrah"
      },
      {
        "_id": "al-fayyum",
        "code": "al-fayyum",
        "name": "al-Fayyum"
      },
      {
        "_id": "al-gharbiyah",
        "code": "al-gharbiyah",
        "name": "al-Gharbiyah"
      },
      {
        "_id": "al-iskandariyah",
        "code": "al-iskandariyah",
        "name": "al-Iskandariyah"
      },
      {
        "_id": "al-ismailiyah",
        "code": "al-ismailiyah",
        "name": "al-Ismailiyah"
      },
      {
        "_id": "al-jizah",
        "code": "al-jizah",
        "name": "al-Jizah"
      },
      {
        "_id": "al-minufiyah",
        "code": "al-minufiyah",
        "name": "al-Minufiyah"
      },
      {
        "_id": "al-minya",
        "code": "al-minya",
        "name": "al-Minya"
      },
      {
        "_id": "al-qahira",
        "code": "al-qahira",
        "name": "al-Qahira"
      },
      {
        "_id": "al-qalyubiyah",
        "code": "al-qalyubiyah",
        "name": "al-Qalyubiyah"
      },
      {
        "_id": "al-uqsur",
        "code": "al-uqsur",
        "name": "al-Uqsur"
      },
      {
        "_id": "al-wadi_al-jadid",
        "code": "al-wadi_al-jadid",
        "name": "al-Wadi al-Jadid"
      },
      {
        "_id": "as-suways",
        "code": "as-suways",
        "name": "as-Suways"
      },
      {
        "_id": "ash-sharqiyah",
        "code": "ash-sharqiyah",
        "name": "ash-Sharqiyah"
      }
    ]
  },
  {
    "_id": "SV",
    "code": "SV",
    "name": "El Salvador",
    "states": [
      {
        "_id": "ahuachapan",
        "code": "ahuachapan",
        "name": "Ahuachapan"
      },
      {
        "_id": "cabanas",
        "code": "cabanas",
        "name": "Cabanas"
      },
      {
        "_id": "chalatenango",
        "code": "chalatenango",
        "name": "Chalatenango"
      },
      {
        "_id": "cuscatlan",
        "code": "cuscatlan",
        "name": "Cuscatlan"
      },
      {
        "_id": "la_libertad",
        "code": "la_libertad",
        "name": "La Libertad"
      },
      {
        "_id": "la_paz",
        "code": "la_paz",
        "name": "La Paz"
      },
      {
        "_id": "la_union",
        "code": "la_union",
        "name": "La Union"
      },
      {
        "_id": "morazan",
        "code": "morazan",
        "name": "Morazan"
      },
      {
        "_id": "san_miguel",
        "code": "san_miguel",
        "name": "San Miguel"
      },
      {
        "_id": "san_salvador",
        "code": "san_salvador",
        "name": "San Salvador"
      },
      {
        "_id": "san_vicente",
        "code": "san_vicente",
        "name": "San Vicente"
      },
      {
        "_id": "santa_ana",
        "code": "santa_ana",
        "name": "Santa Ana"
      },
      {
        "_id": "sonsonate",
        "code": "sonsonate",
        "name": "Sonsonate"
      },
      {
        "_id": "usulutan",
        "code": "usulutan",
        "name": "Usulutan"
      }
    ]
  },
  {
    "_id": "GQ",
    "code": "GQ",
    "name": "Equatorial Guinea",
    "states": [
      {
        "_id": "annobon",
        "code": "annobon",
        "name": "Annobon"
      },
      {
        "_id": "bioko_norte",
        "code": "bioko_norte",
        "name": "Bioko Norte"
      },
      {
        "_id": "bioko_sur",
        "code": "bioko_sur",
        "name": "Bioko Sur"
      },
      {
        "_id": "centro_sur",
        "code": "centro_sur",
        "name": "Centro Sur"
      },
      {
        "_id": "kie-ntem",
        "code": "kie-ntem",
        "name": "Kie-Ntem"
      },
      {
        "_id": "litoral",
        "code": "litoral",
        "name": "Litoral"
      },
      {
        "_id": "wele-nzas",
        "code": "wele-nzas",
        "name": "Wele-Nzas"
      }
    ]
  },
  {
    "_id": "ER",
    "code": "ER",
    "name": "Eritrea",
    "states": [
      {
        "_id": "anseba",
        "code": "anseba",
        "name": "Anseba"
      },
      {
        "_id": "debub",
        "code": "debub",
        "name": "Debub"
      },
      {
        "_id": "debub-keih-bahri",
        "code": "debub-keih-bahri",
        "name": "Debub-Keih-Bahri"
      },
      {
        "_id": "gash-barka",
        "code": "gash-barka",
        "name": "Gash-Barka"
      },
      {
        "_id": "maekel",
        "code": "maekel",
        "name": "Maekel"
      },
      {
        "_id": "semien-keih-bahri",
        "code": "semien-keih-bahri",
        "name": "Semien-Keih-Bahri"
      }
    ]
  },
  {
    "_id": "EE",
    "code": "EE",
    "name": "Estonia",
    "states": [
      {
        "_id": "harju",
        "code": "harju",
        "name": "Harju"
      },
      {
        "_id": "hiiu",
        "code": "hiiu",
        "name": "Hiiu"
      },
      {
        "_id": "ida-viru",
        "code": "ida-viru",
        "name": "Ida-Viru"
      },
      {
        "_id": "jarva",
        "code": "jarva",
        "name": "Jarva"
      },
      {
        "_id": "jogeva",
        "code": "jogeva",
        "name": "Jogeva"
      },
      {
        "_id": "laane",
        "code": "laane",
        "name": "Laane"
      },
      {
        "_id": "laane-viru",
        "code": "laane-viru",
        "name": "Laane-Viru"
      },
      {
        "_id": "parnu",
        "code": "parnu",
        "name": "Parnu"
      },
      {
        "_id": "polva",
        "code": "polva",
        "name": "Polva"
      },
      {
        "_id": "rapla",
        "code": "rapla",
        "name": "Rapla"
      },
      {
        "_id": "saare",
        "code": "saare",
        "name": "Saare"
      },
      {
        "_id": "tartu",
        "code": "tartu",
        "name": "Tartu"
      },
      {
        "_id": "valga",
        "code": "valga",
        "name": "Valga"
      },
      {
        "_id": "viljandi",
        "code": "viljandi",
        "name": "Viljandi"
      },
      {
        "_id": "voru",
        "code": "voru",
        "name": "Voru"
      }
    ]
  },
  {
    "_id": "ET",
    "code": "ET",
    "name": "Ethiopia",
    "states": [
      {
        "_id": "addis_abeba",
        "code": "addis_abeba",
        "name": "Addis Abeba"
      },
      {
        "_id": "afar",
        "code": "afar",
        "name": "Afar"
      },
      {
        "_id": "amhara",
        "code": "amhara",
        "name": "Amhara"
      },
      {
        "_id": "benishangul",
        "code": "benishangul",
        "name": "Benishangul"
      },
      {
        "_id": "diredawa",
        "code": "diredawa",
        "name": "Diredawa"
      },
      {
        "_id": "gambella",
        "code": "gambella",
        "name": "Gambella"
      },
      {
        "_id": "harar",
        "code": "harar",
        "name": "Harar"
      },
      {
        "_id": "jigjiga",
        "code": "jigjiga",
        "name": "Jigjiga"
      },
      {
        "_id": "mekele",
        "code": "mekele",
        "name": "Mekele"
      },
      {
        "_id": "oromia",
        "code": "oromia",
        "name": "Oromia"
      },
      {
        "_id": "somali",
        "code": "somali",
        "name": "Somali"
      },
      {
        "_id": "southern",
        "code": "southern",
        "name": "Southern"
      },
      {
        "_id": "tigray",
        "code": "tigray",
        "name": "Tigray"
      }
    ]
  },
  {
    "_id": "XA",
    "code": "XA",
    "name": "External Territories of Australia",
    "states": [
      {
        "_id": "christmas_island",
        "code": "christmas_island",
        "name": "Christmas Island"
      },
      {
        "_id": "cocos_islands",
        "code": "cocos_islands",
        "name": "Cocos Islands"
      },
      {
        "_id": "coral_sea_islands",
        "code": "coral_sea_islands",
        "name": "Coral Sea Islands"
      }
    ]
  },
  {
    "_id": "FK",
    "code": "FK",
    "name": "Falkland Islands",
    "states": [
      {
        "_id": "falkland_islands",
        "code": "falkland_islands",
        "name": "Falkland Islands"
      },
      {
        "_id": "south_georgia",
        "code": "south_georgia",
        "name": "South Georgia"
      }
    ]
  },
  {
    "_id": "FO",
    "code": "FO",
    "name": "Faroe Islands",
    "states": [
      {
        "_id": "klaksvik",
        "code": "klaksvik",
        "name": "Klaksvik"
      },
      {
        "_id": "nor_ara_eysturoy",
        "code": "nor_ara_eysturoy",
        "name": "Nor ara Eysturoy"
      },
      {
        "_id": "nor_oy",
        "code": "nor_oy",
        "name": "Nor oy"
      },
      {
        "_id": "sandoy",
        "code": "sandoy",
        "name": "Sandoy"
      },
      {
        "_id": "streymoy",
        "code": "streymoy",
        "name": "Streymoy"
      },
      {
        "_id": "su_uroy",
        "code": "su_uroy",
        "name": "Su uroy"
      },
      {
        "_id": "sy_ra_eysturoy",
        "code": "sy_ra_eysturoy",
        "name": "Sy ra Eysturoy"
      },
      {
        "_id": "torshavn",
        "code": "torshavn",
        "name": "Torshavn"
      },
      {
        "_id": "vaga",
        "code": "vaga",
        "name": "Vaga"
      }
    ]
  },
  {
    "_id": "FJ",
    "code": "FJ",
    "name": "Fiji Islands",
    "states": [
      {
        "_id": "central",
        "code": "central",
        "name": "Central"
      },
      {
        "_id": "eastern",
        "code": "eastern",
        "name": "Eastern"
      },
      {
        "_id": "northern",
        "code": "northern",
        "name": "Northern"
      },
      {
        "_id": "south_pacific",
        "code": "south_pacific",
        "name": "South Pacific"
      },
      {
        "_id": "western",
        "code": "western",
        "name": "Western"
      }
    ]
  },
  {
    "_id": "FI",
    "code": "FI",
    "name": "Finland",
    "states": [
      {
        "_id": "ahvenanmaa",
        "code": "ahvenanmaa",
        "name": "Ahvenanmaa"
      },
      {
        "_id": "etela-karjala",
        "code": "etela-karjala",
        "name": "Etela-Karjala"
      },
      {
        "_id": "etela-pohjanmaa",
        "code": "etela-pohjanmaa",
        "name": "Etela-Pohjanmaa"
      },
      {
        "_id": "etela-savo",
        "code": "etela-savo",
        "name": "Etela-Savo"
      },
      {
        "_id": "etela-suomen_laani",
        "code": "etela-suomen_laani",
        "name": "Etela-Suomen Laani"
      },
      {
        "_id": "ita-suomen_laani",
        "code": "ita-suomen_laani",
        "name": "Ita-Suomen Laani"
      },
      {
        "_id": "ita-uusimaa",
        "code": "ita-uusimaa",
        "name": "Ita-Uusimaa"
      },
      {
        "_id": "kainuu",
        "code": "kainuu",
        "name": "Kainuu"
      },
      {
        "_id": "kanta-hame",
        "code": "kanta-hame",
        "name": "Kanta-Hame"
      },
      {
        "_id": "keski-pohjanmaa",
        "code": "keski-pohjanmaa",
        "name": "Keski-Pohjanmaa"
      },
      {
        "_id": "keski-suomi",
        "code": "keski-suomi",
        "name": "Keski-Suomi"
      },
      {
        "_id": "kymenlaakso",
        "code": "kymenlaakso",
        "name": "Kymenlaakso"
      },
      {
        "_id": "lansi-suomen_laani",
        "code": "lansi-suomen_laani",
        "name": "Lansi-Suomen Laani"
      },
      {
        "_id": "lappi",
        "code": "lappi",
        "name": "Lappi"
      },
      {
        "_id": "northern_savonia",
        "code": "northern_savonia",
        "name": "Northern Savonia"
      },
      {
        "_id": "ostrobothnia",
        "code": "ostrobothnia",
        "name": "Ostrobothnia"
      },
      {
        "_id": "oulun_laani",
        "code": "oulun_laani",
        "name": "Oulun Laani"
      },
      {
        "_id": "paijat-hame",
        "code": "paijat-hame",
        "name": "Paijat-Hame"
      },
      {
        "_id": "pirkanmaa",
        "code": "pirkanmaa",
        "name": "Pirkanmaa"
      },
      {
        "_id": "pohjanmaa",
        "code": "pohjanmaa",
        "name": "Pohjanmaa"
      },
      {
        "_id": "pohjois-karjala",
        "code": "pohjois-karjala",
        "name": "Pohjois-Karjala"
      },
      {
        "_id": "pohjois-pohjanmaa",
        "code": "pohjois-pohjanmaa",
        "name": "Pohjois-Pohjanmaa"
      },
      {
        "_id": "pohjois-savo",
        "code": "pohjois-savo",
        "name": "Pohjois-Savo"
      },
      {
        "_id": "saarijarvi",
        "code": "saarijarvi",
        "name": "Saarijarvi"
      },
      {
        "_id": "satakunta",
        "code": "satakunta",
        "name": "Satakunta"
      },
      {
        "_id": "southern_savonia",
        "code": "southern_savonia",
        "name": "Southern Savonia"
      },
      {
        "_id": "tavastia_proper",
        "code": "tavastia_proper",
        "name": "Tavastia Proper"
      },
      {
        "_id": "uleaborgs_lan",
        "code": "uleaborgs_lan",
        "name": "Uleaborgs Lan"
      },
      {
        "_id": "uusimaa",
        "code": "uusimaa",
        "name": "Uusimaa"
      },
      {
        "_id": "varsinais-suomi",
        "code": "varsinais-suomi",
        "name": "Varsinais-Suomi"
      }
    ]
  },
  {
    "_id": "FR",
    "code": "FR",
    "name": "France",
    "states": [
      {
        "_id": "ain",
        "code": "ain",
        "name": "Ain"
      },
      {
        "_id": "aisne",
        "code": "aisne",
        "name": "Aisne"
      },
      {
        "_id": "albi_le_sequestre",
        "code": "albi_le_sequestre",
        "name": "Albi Le Sequestre"
      },
      {
        "_id": "allier",
        "code": "allier",
        "name": "Allier"
      },
      {
        "_id": "alpes-cote_dazur",
        "code": "alpes-cote_dazur",
        "name": "Alpes-Cote dAzur"
      },
      {
        "_id": "alpes-maritimes",
        "code": "alpes-maritimes",
        "name": "Alpes-Maritimes"
      },
      {
        "_id": "alpes-de-haute-provence",
        "code": "alpes-de-haute-provence",
        "name": "Alpes-de-Haute-Provence"
      },
      {
        "_id": "alsace",
        "code": "alsace",
        "name": "Alsace"
      },
      {
        "_id": "aquitaine",
        "code": "aquitaine",
        "name": "Aquitaine"
      },
      {
        "_id": "ardeche",
        "code": "ardeche",
        "name": "Ardeche"
      },
      {
        "_id": "ardennes",
        "code": "ardennes",
        "name": "Ardennes"
      },
      {
        "_id": "ariege",
        "code": "ariege",
        "name": "Ariege"
      },
      {
        "_id": "aube",
        "code": "aube",
        "name": "Aube"
      },
      {
        "_id": "aude",
        "code": "aude",
        "name": "Aude"
      },
      {
        "_id": "auvergne",
        "code": "auvergne",
        "name": "Auvergne"
      },
      {
        "_id": "aveyron",
        "code": "aveyron",
        "name": "Aveyron"
      },
      {
        "_id": "bas-rhin",
        "code": "bas-rhin",
        "name": "Bas-Rhin"
      },
      {
        "_id": "basse-normandie",
        "code": "basse-normandie",
        "name": "Basse-Normandie"
      },
      {
        "_id": "bouches-du-rhone",
        "code": "bouches-du-rhone",
        "name": "Bouches-du-Rhone"
      },
      {
        "_id": "bourgogne",
        "code": "bourgogne",
        "name": "Bourgogne"
      },
      {
        "_id": "bretagne",
        "code": "bretagne",
        "name": "Bretagne"
      },
      {
        "_id": "brittany",
        "code": "brittany",
        "name": "Brittany"
      },
      {
        "_id": "burgundy",
        "code": "burgundy",
        "name": "Burgundy"
      },
      {
        "_id": "calvados",
        "code": "calvados",
        "name": "Calvados"
      },
      {
        "_id": "cantal",
        "code": "cantal",
        "name": "Cantal"
      },
      {
        "_id": "cedex",
        "code": "cedex",
        "name": "Cedex"
      },
      {
        "_id": "centre",
        "code": "centre",
        "name": "Centre"
      },
      {
        "_id": "charente",
        "code": "charente",
        "name": "Charente"
      },
      {
        "_id": "charente-maritime",
        "code": "charente-maritime",
        "name": "Charente-Maritime"
      },
      {
        "_id": "cher",
        "code": "cher",
        "name": "Cher"
      },
      {
        "_id": "correze",
        "code": "correze",
        "name": "Correze"
      },
      {
        "_id": "corse-du-sud",
        "code": "corse-du-sud",
        "name": "Corse-du-Sud"
      },
      {
        "_id": "cote-dor",
        "code": "cote-dor",
        "name": "Cote-dOr"
      },
      {
        "_id": "cotes-darmor",
        "code": "cotes-darmor",
        "name": "Cotes-dArmor"
      },
      {
        "_id": "creuse",
        "code": "creuse",
        "name": "Creuse"
      },
      {
        "_id": "crolles",
        "code": "crolles",
        "name": "Crolles"
      },
      {
        "_id": "deux-sevres",
        "code": "deux-sevres",
        "name": "Deux-Sevres"
      },
      {
        "_id": "dordogne",
        "code": "dordogne",
        "name": "Dordogne"
      },
      {
        "_id": "doubs",
        "code": "doubs",
        "name": "Doubs"
      },
      {
        "_id": "drome",
        "code": "drome",
        "name": "Drome"
      },
      {
        "_id": "essonne",
        "code": "essonne",
        "name": "Essonne"
      },
      {
        "_id": "eure",
        "code": "eure",
        "name": "Eure"
      },
      {
        "_id": "eure-et-loir",
        "code": "eure-et-loir",
        "name": "Eure-et-Loir"
      },
      {
        "_id": "feucherolles",
        "code": "feucherolles",
        "name": "Feucherolles"
      },
      {
        "_id": "finistere",
        "code": "finistere",
        "name": "Finistere"
      },
      {
        "_id": "franche-comte",
        "code": "franche-comte",
        "name": "Franche-Comte"
      },
      {
        "_id": "gard",
        "code": "gard",
        "name": "Gard"
      },
      {
        "_id": "gers",
        "code": "gers",
        "name": "Gers"
      },
      {
        "_id": "gironde",
        "code": "gironde",
        "name": "Gironde"
      },
      {
        "_id": "haut-rhin",
        "code": "haut-rhin",
        "name": "Haut-Rhin"
      },
      {
        "_id": "haute-corse",
        "code": "haute-corse",
        "name": "Haute-Corse"
      },
      {
        "_id": "haute-garonne",
        "code": "haute-garonne",
        "name": "Haute-Garonne"
      },
      {
        "_id": "haute-loire",
        "code": "haute-loire",
        "name": "Haute-Loire"
      },
      {
        "_id": "haute-marne",
        "code": "haute-marne",
        "name": "Haute-Marne"
      },
      {
        "_id": "haute-saone",
        "code": "haute-saone",
        "name": "Haute-Saone"
      },
      {
        "_id": "haute-savoie",
        "code": "haute-savoie",
        "name": "Haute-Savoie"
      },
      {
        "_id": "haute-vienne",
        "code": "haute-vienne",
        "name": "Haute-Vienne"
      },
      {
        "_id": "hautes-alpes",
        "code": "hautes-alpes",
        "name": "Hautes-Alpes"
      },
      {
        "_id": "hautes-pyrenees",
        "code": "hautes-pyrenees",
        "name": "Hautes-Pyrenees"
      },
      {
        "_id": "hauts-de-seine",
        "code": "hauts-de-seine",
        "name": "Hauts-de-Seine"
      },
      {
        "_id": "herault",
        "code": "herault",
        "name": "Herault"
      },
      {
        "_id": "ile-de-france",
        "code": "ile-de-france",
        "name": "Ile-de-France"
      },
      {
        "_id": "ille-et-vilaine",
        "code": "ille-et-vilaine",
        "name": "Ille-et-Vilaine"
      },
      {
        "_id": "indre",
        "code": "indre",
        "name": "Indre"
      },
      {
        "_id": "indre-et-loire",
        "code": "indre-et-loire",
        "name": "Indre-et-Loire"
      },
      {
        "_id": "isere",
        "code": "isere",
        "name": "Isere"
      },
      {
        "_id": "jura",
        "code": "jura",
        "name": "Jura"
      },
      {
        "_id": "klagenfurt",
        "code": "klagenfurt",
        "name": "Klagenfurt"
      },
      {
        "_id": "landes",
        "code": "landes",
        "name": "Landes"
      },
      {
        "_id": "languedoc-roussillon",
        "code": "languedoc-roussillon",
        "name": "Languedoc-Roussillon"
      },
      {
        "_id": "larcay",
        "code": "larcay",
        "name": "Larcay"
      },
      {
        "_id": "le_castellet",
        "code": "le_castellet",
        "name": "Le Castellet"
      },
      {
        "_id": "le_creusot",
        "code": "le_creusot",
        "name": "Le Creusot"
      },
      {
        "_id": "limousin",
        "code": "limousin",
        "name": "Limousin"
      },
      {
        "_id": "loir-et-cher",
        "code": "loir-et-cher",
        "name": "Loir-et-Cher"
      },
      {
        "_id": "loire",
        "code": "loire",
        "name": "Loire"
      },
      {
        "_id": "loire-atlantique",
        "code": "loire-atlantique",
        "name": "Loire-Atlantique"
      },
      {
        "_id": "loiret",
        "code": "loiret",
        "name": "Loiret"
      },
      {
        "_id": "lorraine",
        "code": "lorraine",
        "name": "Lorraine"
      },
      {
        "_id": "lot",
        "code": "lot",
        "name": "Lot"
      },
      {
        "_id": "lot-et-garonne",
        "code": "lot-et-garonne",
        "name": "Lot-et-Garonne"
      },
      {
        "_id": "lower_normandy",
        "code": "lower_normandy",
        "name": "Lower Normandy"
      },
      {
        "_id": "lozere",
        "code": "lozere",
        "name": "Lozere"
      },
      {
        "_id": "maine-et-loire",
        "code": "maine-et-loire",
        "name": "Maine-et-Loire"
      },
      {
        "_id": "manche",
        "code": "manche",
        "name": "Manche"
      },
      {
        "_id": "marne",
        "code": "marne",
        "name": "Marne"
      },
      {
        "_id": "mayenne",
        "code": "mayenne",
        "name": "Mayenne"
      },
      {
        "_id": "meurthe-et-moselle",
        "code": "meurthe-et-moselle",
        "name": "Meurthe-et-Moselle"
      },
      {
        "_id": "meuse",
        "code": "meuse",
        "name": "Meuse"
      },
      {
        "_id": "midi-pyrenees",
        "code": "midi-pyrenees",
        "name": "Midi-Pyrenees"
      },
      {
        "_id": "morbihan",
        "code": "morbihan",
        "name": "Morbihan"
      },
      {
        "_id": "moselle",
        "code": "moselle",
        "name": "Moselle"
      },
      {
        "_id": "nievre",
        "code": "nievre",
        "name": "Nievre"
      },
      {
        "_id": "nord",
        "code": "nord",
        "name": "Nord"
      },
      {
        "_id": "nord-pas-de-calais",
        "code": "nord-pas-de-calais",
        "name": "Nord-Pas-de-Calais"
      },
      {
        "_id": "oise",
        "code": "oise",
        "name": "Oise"
      },
      {
        "_id": "orne",
        "code": "orne",
        "name": "Orne"
      },
      {
        "_id": "paris",
        "code": "paris",
        "name": "Paris"
      },
      {
        "_id": "pas-de-calais",
        "code": "pas-de-calais",
        "name": "Pas-de-Calais"
      },
      {
        "_id": "pays_de_la_loire",
        "code": "pays_de_la_loire",
        "name": "Pays de la Loire"
      },
      {
        "_id": "pays-de-la-loire",
        "code": "pays-de-la-loire",
        "name": "Pays-de-la-Loire"
      },
      {
        "_id": "picardy",
        "code": "picardy",
        "name": "Picardy"
      },
      {
        "_id": "puy-de-dome",
        "code": "puy-de-dome",
        "name": "Puy-de-Dome"
      },
      {
        "_id": "pyrenees-atlantiques",
        "code": "pyrenees-atlantiques",
        "name": "Pyrenees-Atlantiques"
      },
      {
        "_id": "pyrenees-orientales",
        "code": "pyrenees-orientales",
        "name": "Pyrenees-Orientales"
      },
      {
        "_id": "quelmes",
        "code": "quelmes",
        "name": "Quelmes"
      },
      {
        "_id": "rhone",
        "code": "rhone",
        "name": "Rhone"
      },
      {
        "_id": "rhone-alpes",
        "code": "rhone-alpes",
        "name": "Rhone-Alpes"
      },
      {
        "_id": "saint_ouen",
        "code": "saint_ouen",
        "name": "Saint Ouen"
      },
      {
        "_id": "saint_viatre",
        "code": "saint_viatre",
        "name": "Saint Viatre"
      },
      {
        "_id": "saone-et-loire",
        "code": "saone-et-loire",
        "name": "Saone-et-Loire"
      },
      {
        "_id": "sarthe",
        "code": "sarthe",
        "name": "Sarthe"
      },
      {
        "_id": "savoie",
        "code": "savoie",
        "name": "Savoie"
      },
      {
        "_id": "seine-maritime",
        "code": "seine-maritime",
        "name": "Seine-Maritime"
      },
      {
        "_id": "seine-saint-denis",
        "code": "seine-saint-denis",
        "name": "Seine-Saint-Denis"
      },
      {
        "_id": "seine-et-marne",
        "code": "seine-et-marne",
        "name": "Seine-et-Marne"
      },
      {
        "_id": "somme",
        "code": "somme",
        "name": "Somme"
      },
      {
        "_id": "sophia_antipolis",
        "code": "sophia_antipolis",
        "name": "Sophia Antipolis"
      },
      {
        "_id": "souvans",
        "code": "souvans",
        "name": "Souvans"
      },
      {
        "_id": "tarn",
        "code": "tarn",
        "name": "Tarn"
      },
      {
        "_id": "tarn-et-garonne",
        "code": "tarn-et-garonne",
        "name": "Tarn-et-Garonne"
      },
      {
        "_id": "territoire_de_belfort",
        "code": "territoire_de_belfort",
        "name": "Territoire de Belfort"
      },
      {
        "_id": "treignac",
        "code": "treignac",
        "name": "Treignac"
      },
      {
        "_id": "upper_normandy",
        "code": "upper_normandy",
        "name": "Upper Normandy"
      },
      {
        "_id": "val-doise",
        "code": "val-doise",
        "name": "Val-dOise"
      },
      {
        "_id": "val-de-marne",
        "code": "val-de-marne",
        "name": "Val-de-Marne"
      },
      {
        "_id": "var",
        "code": "var",
        "name": "Var"
      },
      {
        "_id": "vaucluse",
        "code": "vaucluse",
        "name": "Vaucluse"
      },
      {
        "_id": "vellise",
        "code": "vellise",
        "name": "Vellise"
      },
      {
        "_id": "vendee",
        "code": "vendee",
        "name": "Vendee"
      },
      {
        "_id": "vienne",
        "code": "vienne",
        "name": "Vienne"
      },
      {
        "_id": "vosges",
        "code": "vosges",
        "name": "Vosges"
      },
      {
        "_id": "yonne",
        "code": "yonne",
        "name": "Yonne"
      },
      {
        "_id": "yvelines",
        "code": "yvelines",
        "name": "Yvelines"
      }
    ]
  },
  {
    "_id": "GF",
    "code": "GF",
    "name": "French Guiana",
    "states": [
      {
        "_id": "cayenne",
        "code": "cayenne",
        "name": "Cayenne"
      },
      {
        "_id": "saint-laurent-du-maroni",
        "code": "saint-laurent-du-maroni",
        "name": "Saint-Laurent-du-Maroni"
      }
    ]
  },
  {
    "_id": "PF",
    "code": "PF",
    "name": "French Polynesia",
    "states": [
      {
        "_id": "iles_du_vent",
        "code": "iles_du_vent",
        "name": "Iles du Vent"
      },
      {
        "_id": "iles_sous_le_vent",
        "code": "iles_sous_le_vent",
        "name": "Iles sous le Vent"
      },
      {
        "_id": "marquesas",
        "code": "marquesas",
        "name": "Marquesas"
      },
      {
        "_id": "tuamotu",
        "code": "tuamotu",
        "name": "Tuamotu"
      },
      {
        "_id": "tubuai",
        "code": "tubuai",
        "name": "Tubuai"
      }
    ]
  },
  {
    "_id": "TF",
    "code": "TF",
    "name": "French Southern Territories",
    "states": [
      {
        "_id": "amsterdam",
        "code": "amsterdam",
        "name": "Amsterdam"
      },
      {
        "_id": "crozet_islands",
        "code": "crozet_islands",
        "name": "Crozet Islands"
      },
      {
        "_id": "kerguelen",
        "code": "kerguelen",
        "name": "Kerguelen"
      }
    ]
  },
  {
    "_id": "GA",
    "code": "GA",
    "name": "Gabon",
    "states": [
      {
        "_id": "estuaire",
        "code": "estuaire",
        "name": "Estuaire"
      },
      {
        "_id": "haut-ogooue",
        "code": "haut-ogooue",
        "name": "Haut-Ogooue"
      },
      {
        "_id": "moyen-ogooue",
        "code": "moyen-ogooue",
        "name": "Moyen-Ogooue"
      },
      {
        "_id": "ngounie",
        "code": "ngounie",
        "name": "Ngounie"
      },
      {
        "_id": "nyanga",
        "code": "nyanga",
        "name": "Nyanga"
      },
      {
        "_id": "ogooue-ivindo",
        "code": "ogooue-ivindo",
        "name": "Ogooue-Ivindo"
      },
      {
        "_id": "ogooue-lolo",
        "code": "ogooue-lolo",
        "name": "Ogooue-Lolo"
      },
      {
        "_id": "ogooue-maritime",
        "code": "ogooue-maritime",
        "name": "Ogooue-Maritime"
      },
      {
        "_id": "woleu-ntem",
        "code": "woleu-ntem",
        "name": "Woleu-Ntem"
      }
    ]
  },
  {
    "_id": "GM",
    "code": "GM",
    "name": "Gambia The",
    "states": [
      {
        "_id": "banjul",
        "code": "banjul",
        "name": "Banjul"
      },
      {
        "_id": "basse",
        "code": "basse",
        "name": "Basse"
      },
      {
        "_id": "brikama",
        "code": "brikama",
        "name": "Brikama"
      },
      {
        "_id": "janjanbureh",
        "code": "janjanbureh",
        "name": "Janjanbureh"
      },
      {
        "_id": "kanifing",
        "code": "kanifing",
        "name": "Kanifing"
      },
      {
        "_id": "kerewan",
        "code": "kerewan",
        "name": "Kerewan"
      },
      {
        "_id": "kuntaur",
        "code": "kuntaur",
        "name": "Kuntaur"
      },
      {
        "_id": "mansakonko",
        "code": "mansakonko",
        "name": "Mansakonko"
      }
    ]
  },
  {
    "_id": "GE",
    "code": "GE",
    "name": "Georgia",
    "states": [
      {
        "_id": "abhasia",
        "code": "abhasia",
        "name": "Abhasia"
      },
      {
        "_id": "ajaria",
        "code": "ajaria",
        "name": "Ajaria"
      },
      {
        "_id": "guria",
        "code": "guria",
        "name": "Guria"
      },
      {
        "_id": "imereti",
        "code": "imereti",
        "name": "Imereti"
      },
      {
        "_id": "kaheti",
        "code": "kaheti",
        "name": "Kaheti"
      },
      {
        "_id": "kvemo_kartli",
        "code": "kvemo_kartli",
        "name": "Kvemo Kartli"
      },
      {
        "_id": "mcheta-mtianeti",
        "code": "mcheta-mtianeti",
        "name": "Mcheta-Mtianeti"
      },
      {
        "_id": "racha",
        "code": "racha",
        "name": "Racha"
      },
      {
        "_id": "samagrelo-zemo_svaneti",
        "code": "samagrelo-zemo_svaneti",
        "name": "Samagrelo-Zemo Svaneti"
      },
      {
        "_id": "samche-zhavaheti",
        "code": "samche-zhavaheti",
        "name": "Samche-Zhavaheti"
      },
      {
        "_id": "shida_kartli",
        "code": "shida_kartli",
        "name": "Shida Kartli"
      },
      {
        "_id": "tbilisi",
        "code": "tbilisi",
        "name": "Tbilisi"
      }
    ]
  },
  {
    "_id": "DE",
    "code": "DE",
    "name": "Germany",
    "states": [
      {
        "_id": "auvergne",
        "code": "auvergne",
        "name": "Auvergne"
      },
      {
        "_id": "baden-wurttemberg",
        "code": "baden-wurttemberg",
        "name": "Baden-Wurttemberg"
      },
      {
        "_id": "bavaria",
        "code": "bavaria",
        "name": "Bavaria"
      },
      {
        "_id": "bayern",
        "code": "bayern",
        "name": "Bayern"
      },
      {
        "_id": "beilstein_wurtt",
        "code": "beilstein_wurtt",
        "name": "Beilstein Wurtt"
      },
      {
        "_id": "berlin",
        "code": "berlin",
        "name": "Berlin"
      },
      {
        "_id": "brandenburg",
        "code": "brandenburg",
        "name": "Brandenburg"
      },
      {
        "_id": "bremen",
        "code": "bremen",
        "name": "Bremen"
      },
      {
        "_id": "dreisbach",
        "code": "dreisbach",
        "name": "Dreisbach"
      },
      {
        "_id": "freistaat_bayern",
        "code": "freistaat_bayern",
        "name": "Freistaat Bayern"
      },
      {
        "_id": "hamburg",
        "code": "hamburg",
        "name": "Hamburg"
      },
      {
        "_id": "hannover",
        "code": "hannover",
        "name": "Hannover"
      },
      {
        "_id": "heroldstatt",
        "code": "heroldstatt",
        "name": "Heroldstatt"
      },
      {
        "_id": "hessen",
        "code": "hessen",
        "name": "Hessen"
      },
      {
        "_id": "kortenberg",
        "code": "kortenberg",
        "name": "Kortenberg"
      },
      {
        "_id": "laasdorf",
        "code": "laasdorf",
        "name": "Laasdorf"
      },
      {
        "_id": "land_baden-wurttemberg",
        "code": "land_baden-wurttemberg",
        "name": "Land Baden-Wurttemberg"
      },
      {
        "_id": "land_bayern",
        "code": "land_bayern",
        "name": "Land Bayern"
      },
      {
        "_id": "land_brandenburg",
        "code": "land_brandenburg",
        "name": "Land Brandenburg"
      },
      {
        "_id": "land_hessen",
        "code": "land_hessen",
        "name": "Land Hessen"
      },
      {
        "_id": "land_mecklenburg-vorpommern",
        "code": "land_mecklenburg-vorpommern",
        "name": "Land Mecklenburg-Vorpommern"
      },
      {
        "_id": "land_nordrhein-westfalen",
        "code": "land_nordrhein-westfalen",
        "name": "Land Nordrhein-Westfalen"
      },
      {
        "_id": "land_rheinland-pfalz",
        "code": "land_rheinland-pfalz",
        "name": "Land Rheinland-Pfalz"
      },
      {
        "_id": "land_sachsen",
        "code": "land_sachsen",
        "name": "Land Sachsen"
      },
      {
        "_id": "land_sachsen-anhalt",
        "code": "land_sachsen-anhalt",
        "name": "Land Sachsen-Anhalt"
      },
      {
        "_id": "land_thuringen",
        "code": "land_thuringen",
        "name": "Land Thuringen"
      },
      {
        "_id": "lower_saxony",
        "code": "lower_saxony",
        "name": "Lower Saxony"
      },
      {
        "_id": "mecklenburg-vorpommern",
        "code": "mecklenburg-vorpommern",
        "name": "Mecklenburg-Vorpommern"
      },
      {
        "_id": "mulfingen",
        "code": "mulfingen",
        "name": "Mulfingen"
      },
      {
        "_id": "munich",
        "code": "munich",
        "name": "Munich"
      },
      {
        "_id": "neubeuern",
        "code": "neubeuern",
        "name": "Neubeuern"
      },
      {
        "_id": "niedersachsen",
        "code": "niedersachsen",
        "name": "Niedersachsen"
      },
      {
        "_id": "noord-holland",
        "code": "noord-holland",
        "name": "Noord-Holland"
      },
      {
        "_id": "nordrhein-westfalen",
        "code": "nordrhein-westfalen",
        "name": "Nordrhein-Westfalen"
      },
      {
        "_id": "north_rhine-westphalia",
        "code": "north_rhine-westphalia",
        "name": "North Rhine-Westphalia"
      },
      {
        "_id": "osterode",
        "code": "osterode",
        "name": "Osterode"
      },
      {
        "_id": "rheinland-pfalz",
        "code": "rheinland-pfalz",
        "name": "Rheinland-Pfalz"
      },
      {
        "_id": "rhineland-palatinate",
        "code": "rhineland-palatinate",
        "name": "Rhineland-Palatinate"
      },
      {
        "_id": "saarland",
        "code": "saarland",
        "name": "Saarland"
      },
      {
        "_id": "sachsen",
        "code": "sachsen",
        "name": "Sachsen"
      },
      {
        "_id": "sachsen-anhalt",
        "code": "sachsen-anhalt",
        "name": "Sachsen-Anhalt"
      },
      {
        "_id": "saxony",
        "code": "saxony",
        "name": "Saxony"
      },
      {
        "_id": "schleswig-holstein",
        "code": "schleswig-holstein",
        "name": "Schleswig-Holstein"
      },
      {
        "_id": "thuringia",
        "code": "thuringia",
        "name": "Thuringia"
      },
      {
        "_id": "webling",
        "code": "webling",
        "name": "Webling"
      },
      {
        "_id": "weinstrabe",
        "code": "weinstrabe",
        "name": "Weinstrabe"
      },
      {
        "_id": "schlobborn",
        "code": "schlobborn",
        "name": "schlobborn"
      }
    ]
  },
  {
    "_id": "GH",
    "code": "GH",
    "name": "Ghana",
    "states": [
      {
        "_id": "ashanti",
        "code": "ashanti",
        "name": "Ashanti"
      },
      {
        "_id": "brong-ahafo",
        "code": "brong-ahafo",
        "name": "Brong-Ahafo"
      },
      {
        "_id": "central",
        "code": "central",
        "name": "Central"
      },
      {
        "_id": "eastern",
        "code": "eastern",
        "name": "Eastern"
      },
      {
        "_id": "greater_accra",
        "code": "greater_accra",
        "name": "Greater Accra"
      },
      {
        "_id": "northern",
        "code": "northern",
        "name": "Northern"
      },
      {
        "_id": "upper_east",
        "code": "upper_east",
        "name": "Upper East"
      },
      {
        "_id": "upper_west",
        "code": "upper_west",
        "name": "Upper West"
      },
      {
        "_id": "volta",
        "code": "volta",
        "name": "Volta"
      },
      {
        "_id": "western",
        "code": "western",
        "name": "Western"
      }
    ]
  },
  {
    "_id": "GI",
    "code": "GI",
    "name": "Gibraltar",
    "states": [
      {
        "_id": "gibraltar",
        "code": "gibraltar",
        "name": "Gibraltar"
      }
    ]
  },
  {
    "_id": "GR",
    "code": "GR",
    "name": "Greece",
    "states": [
      {
        "_id": "acharnes",
        "code": "acharnes",
        "name": "Acharnes"
      },
      {
        "_id": "ahaia",
        "code": "ahaia",
        "name": "Ahaia"
      },
      {
        "_id": "aitolia_kai_akarnania",
        "code": "aitolia_kai_akarnania",
        "name": "Aitolia kai Akarnania"
      },
      {
        "_id": "argolis",
        "code": "argolis",
        "name": "Argolis"
      },
      {
        "_id": "arkadia",
        "code": "arkadia",
        "name": "Arkadia"
      },
      {
        "_id": "arta",
        "code": "arta",
        "name": "Arta"
      },
      {
        "_id": "attica",
        "code": "attica",
        "name": "Attica"
      },
      {
        "_id": "attiki",
        "code": "attiki",
        "name": "Attiki"
      },
      {
        "_id": "ayion_oros",
        "code": "ayion_oros",
        "name": "Ayion Oros"
      },
      {
        "_id": "crete",
        "code": "crete",
        "name": "Crete"
      },
      {
        "_id": "dodekanisos",
        "code": "dodekanisos",
        "name": "Dodekanisos"
      },
      {
        "_id": "drama",
        "code": "drama",
        "name": "Drama"
      },
      {
        "_id": "evia",
        "code": "evia",
        "name": "Evia"
      },
      {
        "_id": "evritania",
        "code": "evritania",
        "name": "Evritania"
      },
      {
        "_id": "evros",
        "code": "evros",
        "name": "Evros"
      },
      {
        "_id": "evvoia",
        "code": "evvoia",
        "name": "Evvoia"
      },
      {
        "_id": "florina",
        "code": "florina",
        "name": "Florina"
      },
      {
        "_id": "fokis",
        "code": "fokis",
        "name": "Fokis"
      },
      {
        "_id": "fthiotis",
        "code": "fthiotis",
        "name": "Fthiotis"
      },
      {
        "_id": "grevena",
        "code": "grevena",
        "name": "Grevena"
      },
      {
        "_id": "halandri",
        "code": "halandri",
        "name": "Halandri"
      },
      {
        "_id": "halkidiki",
        "code": "halkidiki",
        "name": "Halkidiki"
      },
      {
        "_id": "hania",
        "code": "hania",
        "name": "Hania"
      },
      {
        "_id": "heraklion",
        "code": "heraklion",
        "name": "Heraklion"
      },
      {
        "_id": "hios",
        "code": "hios",
        "name": "Hios"
      },
      {
        "_id": "ilia",
        "code": "ilia",
        "name": "Ilia"
      },
      {
        "_id": "imathia",
        "code": "imathia",
        "name": "Imathia"
      },
      {
        "_id": "ioannina",
        "code": "ioannina",
        "name": "Ioannina"
      },
      {
        "_id": "iraklion",
        "code": "iraklion",
        "name": "Iraklion"
      },
      {
        "_id": "karditsa",
        "code": "karditsa",
        "name": "Karditsa"
      },
      {
        "_id": "kastoria",
        "code": "kastoria",
        "name": "Kastoria"
      },
      {
        "_id": "kavala",
        "code": "kavala",
        "name": "Kavala"
      },
      {
        "_id": "kefallinia",
        "code": "kefallinia",
        "name": "Kefallinia"
      },
      {
        "_id": "kerkira",
        "code": "kerkira",
        "name": "Kerkira"
      },
      {
        "_id": "kiklades",
        "code": "kiklades",
        "name": "Kiklades"
      },
      {
        "_id": "kilkis",
        "code": "kilkis",
        "name": "Kilkis"
      },
      {
        "_id": "korinthia",
        "code": "korinthia",
        "name": "Korinthia"
      },
      {
        "_id": "kozani",
        "code": "kozani",
        "name": "Kozani"
      },
      {
        "_id": "lakonia",
        "code": "lakonia",
        "name": "Lakonia"
      },
      {
        "_id": "larisa",
        "code": "larisa",
        "name": "Larisa"
      },
      {
        "_id": "lasithi",
        "code": "lasithi",
        "name": "Lasithi"
      },
      {
        "_id": "lesvos",
        "code": "lesvos",
        "name": "Lesvos"
      },
      {
        "_id": "levkas",
        "code": "levkas",
        "name": "Levkas"
      },
      {
        "_id": "magnisia",
        "code": "magnisia",
        "name": "Magnisia"
      },
      {
        "_id": "messinia",
        "code": "messinia",
        "name": "Messinia"
      },
      {
        "_id": "nomos_attikis",
        "code": "nomos_attikis",
        "name": "Nomos Attikis"
      },
      {
        "_id": "nomos_zakynthou",
        "code": "nomos_zakynthou",
        "name": "Nomos Zakynthou"
      },
      {
        "_id": "pella",
        "code": "pella",
        "name": "Pella"
      },
      {
        "_id": "pieria",
        "code": "pieria",
        "name": "Pieria"
      },
      {
        "_id": "piraios",
        "code": "piraios",
        "name": "Piraios"
      },
      {
        "_id": "preveza",
        "code": "preveza",
        "name": "Preveza"
      },
      {
        "_id": "rethimni",
        "code": "rethimni",
        "name": "Rethimni"
      },
      {
        "_id": "rodopi",
        "code": "rodopi",
        "name": "Rodopi"
      },
      {
        "_id": "samos",
        "code": "samos",
        "name": "Samos"
      },
      {
        "_id": "serrai",
        "code": "serrai",
        "name": "Serrai"
      },
      {
        "_id": "thesprotia",
        "code": "thesprotia",
        "name": "Thesprotia"
      },
      {
        "_id": "thessaloniki",
        "code": "thessaloniki",
        "name": "Thessaloniki"
      },
      {
        "_id": "trikala",
        "code": "trikala",
        "name": "Trikala"
      },
      {
        "_id": "voiotia",
        "code": "voiotia",
        "name": "Voiotia"
      },
      {
        "_id": "west_greece",
        "code": "west_greece",
        "name": "West Greece"
      },
      {
        "_id": "xanthi",
        "code": "xanthi",
        "name": "Xanthi"
      },
      {
        "_id": "zakinthos",
        "code": "zakinthos",
        "name": "Zakinthos"
      }
    ]
  },
  {
    "_id": "GL",
    "code": "GL",
    "name": "Greenland",
    "states": [
      {
        "_id": "aasiaat",
        "code": "aasiaat",
        "name": "Aasiaat"
      },
      {
        "_id": "ammassalik",
        "code": "ammassalik",
        "name": "Ammassalik"
      },
      {
        "_id": "illoqqortoormiut",
        "code": "illoqqortoormiut",
        "name": "Illoqqortoormiut"
      },
      {
        "_id": "ilulissat",
        "code": "ilulissat",
        "name": "Ilulissat"
      },
      {
        "_id": "ivittuut",
        "code": "ivittuut",
        "name": "Ivittuut"
      },
      {
        "_id": "kangaatsiaq",
        "code": "kangaatsiaq",
        "name": "Kangaatsiaq"
      },
      {
        "_id": "maniitsoq",
        "code": "maniitsoq",
        "name": "Maniitsoq"
      },
      {
        "_id": "nanortalik",
        "code": "nanortalik",
        "name": "Nanortalik"
      },
      {
        "_id": "narsaq",
        "code": "narsaq",
        "name": "Narsaq"
      },
      {
        "_id": "nuuk",
        "code": "nuuk",
        "name": "Nuuk"
      },
      {
        "_id": "paamiut",
        "code": "paamiut",
        "name": "Paamiut"
      },
      {
        "_id": "qaanaaq",
        "code": "qaanaaq",
        "name": "Qaanaaq"
      },
      {
        "_id": "qaqortoq",
        "code": "qaqortoq",
        "name": "Qaqortoq"
      },
      {
        "_id": "qasigiannguit",
        "code": "qasigiannguit",
        "name": "Qasigiannguit"
      },
      {
        "_id": "qeqertarsuaq",
        "code": "qeqertarsuaq",
        "name": "Qeqertarsuaq"
      },
      {
        "_id": "sisimiut",
        "code": "sisimiut",
        "name": "Sisimiut"
      },
      {
        "_id": "udenfor_kommunal_inddeling",
        "code": "udenfor_kommunal_inddeling",
        "name": "Udenfor kommunal inddeling"
      },
      {
        "_id": "upernavik",
        "code": "upernavik",
        "name": "Upernavik"
      },
      {
        "_id": "uummannaq",
        "code": "uummannaq",
        "name": "Uummannaq"
      }
    ]
  },
  {
    "_id": "GD",
    "code": "GD",
    "name": "Grenada",
    "states": [
      {
        "_id": "carriacou-petite_martinique",
        "code": "carriacou-petite_martinique",
        "name": "Carriacou-Petite Martinique"
      },
      {
        "_id": "saint_andrew",
        "code": "saint_andrew",
        "name": "Saint Andrew"
      },
      {
        "_id": "saint_davids",
        "code": "saint_davids",
        "name": "Saint Davids"
      },
      {
        "_id": "saint_georges",
        "code": "saint_georges",
        "name": "Saint Georges"
      },
      {
        "_id": "saint_john",
        "code": "saint_john",
        "name": "Saint John"
      },
      {
        "_id": "saint_mark",
        "code": "saint_mark",
        "name": "Saint Mark"
      },
      {
        "_id": "saint_patrick",
        "code": "saint_patrick",
        "name": "Saint Patrick"
      }
    ]
  },
  {
    "_id": "GP",
    "code": "GP",
    "name": "Guadeloupe",
    "states": [
      {
        "_id": "basse-terre",
        "code": "basse-terre",
        "name": "Basse-Terre"
      },
      {
        "_id": "grande-terre",
        "code": "grande-terre",
        "name": "Grande-Terre"
      },
      {
        "_id": "iles_des_saintes",
        "code": "iles_des_saintes",
        "name": "Iles des Saintes"
      },
      {
        "_id": "la_desirade",
        "code": "la_desirade",
        "name": "La Desirade"
      },
      {
        "_id": "marie-galante",
        "code": "marie-galante",
        "name": "Marie-Galante"
      },
      {
        "_id": "saint_barthelemy",
        "code": "saint_barthelemy",
        "name": "Saint Barthelemy"
      },
      {
        "_id": "saint_martin",
        "code": "saint_martin",
        "name": "Saint Martin"
      }
    ]
  },
  {
    "_id": "GU",
    "code": "GU",
    "name": "Guam",
    "states": [
      {
        "_id": "agana_heights",
        "code": "agana_heights",
        "name": "Agana Heights"
      },
      {
        "_id": "agat",
        "code": "agat",
        "name": "Agat"
      },
      {
        "_id": "barrigada",
        "code": "barrigada",
        "name": "Barrigada"
      },
      {
        "_id": "chalan-pago-ordot",
        "code": "chalan-pago-ordot",
        "name": "Chalan-Pago-Ordot"
      },
      {
        "_id": "dededo",
        "code": "dededo",
        "name": "Dededo"
      },
      {
        "_id": "hagatna",
        "code": "hagatna",
        "name": "Hagatna"
      },
      {
        "_id": "inarajan",
        "code": "inarajan",
        "name": "Inarajan"
      },
      {
        "_id": "mangilao",
        "code": "mangilao",
        "name": "Mangilao"
      },
      {
        "_id": "merizo",
        "code": "merizo",
        "name": "Merizo"
      },
      {
        "_id": "mongmong-toto-maite",
        "code": "mongmong-toto-maite",
        "name": "Mongmong-Toto-Maite"
      },
      {
        "_id": "santa_rita",
        "code": "santa_rita",
        "name": "Santa Rita"
      },
      {
        "_id": "sinajana",
        "code": "sinajana",
        "name": "Sinajana"
      },
      {
        "_id": "talofofo",
        "code": "talofofo",
        "name": "Talofofo"
      },
      {
        "_id": "tamuning",
        "code": "tamuning",
        "name": "Tamuning"
      },
      {
        "_id": "yigo",
        "code": "yigo",
        "name": "Yigo"
      },
      {
        "_id": "yona",
        "code": "yona",
        "name": "Yona"
      }
    ]
  },
  {
    "_id": "GT",
    "code": "GT",
    "name": "Guatemala",
    "states": [
      {
        "_id": "alta_verapaz",
        "code": "alta_verapaz",
        "name": "Alta Verapaz"
      },
      {
        "_id": "baja_verapaz",
        "code": "baja_verapaz",
        "name": "Baja Verapaz"
      },
      {
        "_id": "chimaltenango",
        "code": "chimaltenango",
        "name": "Chimaltenango"
      },
      {
        "_id": "chiquimula",
        "code": "chiquimula",
        "name": "Chiquimula"
      },
      {
        "_id": "el_progreso",
        "code": "el_progreso",
        "name": "El Progreso"
      },
      {
        "_id": "escuintla",
        "code": "escuintla",
        "name": "Escuintla"
      },
      {
        "_id": "guatemala",
        "code": "guatemala",
        "name": "Guatemala"
      },
      {
        "_id": "huehuetenango",
        "code": "huehuetenango",
        "name": "Huehuetenango"
      },
      {
        "_id": "izabal",
        "code": "izabal",
        "name": "Izabal"
      },
      {
        "_id": "jalapa",
        "code": "jalapa",
        "name": "Jalapa"
      },
      {
        "_id": "jutiapa",
        "code": "jutiapa",
        "name": "Jutiapa"
      },
      {
        "_id": "peten",
        "code": "peten",
        "name": "Peten"
      },
      {
        "_id": "quezaltenango",
        "code": "quezaltenango",
        "name": "Quezaltenango"
      },
      {
        "_id": "quiche",
        "code": "quiche",
        "name": "Quiche"
      },
      {
        "_id": "retalhuleu",
        "code": "retalhuleu",
        "name": "Retalhuleu"
      },
      {
        "_id": "sacatepequez",
        "code": "sacatepequez",
        "name": "Sacatepequez"
      },
      {
        "_id": "san_marcos",
        "code": "san_marcos",
        "name": "San Marcos"
      },
      {
        "_id": "santa_rosa",
        "code": "santa_rosa",
        "name": "Santa Rosa"
      },
      {
        "_id": "solola",
        "code": "solola",
        "name": "Solola"
      },
      {
        "_id": "suchitepequez",
        "code": "suchitepequez",
        "name": "Suchitepequez"
      },
      {
        "_id": "totonicapan",
        "code": "totonicapan",
        "name": "Totonicapan"
      },
      {
        "_id": "zacapa",
        "code": "zacapa",
        "name": "Zacapa"
      }
    ]
  },
  {
    "_id": "XU",
    "code": "XU",
    "name": "Guernsey and Alderney",
    "states": [
      {
        "_id": "alderney",
        "code": "alderney",
        "name": "Alderney"
      },
      {
        "_id": "castel",
        "code": "castel",
        "name": "Castel"
      },
      {
        "_id": "forest",
        "code": "forest",
        "name": "Forest"
      },
      {
        "_id": "saint_andrew",
        "code": "saint_andrew",
        "name": "Saint Andrew"
      },
      {
        "_id": "saint_martin",
        "code": "saint_martin",
        "name": "Saint Martin"
      },
      {
        "_id": "saint_peter_port",
        "code": "saint_peter_port",
        "name": "Saint Peter Port"
      },
      {
        "_id": "saint_pierre_du_bois",
        "code": "saint_pierre_du_bois",
        "name": "Saint Pierre du Bois"
      },
      {
        "_id": "saint_sampson",
        "code": "saint_sampson",
        "name": "Saint Sampson"
      },
      {
        "_id": "saint_saviour",
        "code": "saint_saviour",
        "name": "Saint Saviour"
      },
      {
        "_id": "sark",
        "code": "sark",
        "name": "Sark"
      },
      {
        "_id": "torteval",
        "code": "torteval",
        "name": "Torteval"
      },
      {
        "_id": "vale",
        "code": "vale",
        "name": "Vale"
      }
    ]
  },
  {
    "_id": "GN",
    "code": "GN",
    "name": "Guinea",
    "states": [
      {
        "_id": "beyla",
        "code": "beyla",
        "name": "Beyla"
      },
      {
        "_id": "boffa",
        "code": "boffa",
        "name": "Boffa"
      },
      {
        "_id": "boke",
        "code": "boke",
        "name": "Boke"
      },
      {
        "_id": "conakry",
        "code": "conakry",
        "name": "Conakry"
      },
      {
        "_id": "coyah",
        "code": "coyah",
        "name": "Coyah"
      },
      {
        "_id": "dabola",
        "code": "dabola",
        "name": "Dabola"
      },
      {
        "_id": "dalaba",
        "code": "dalaba",
        "name": "Dalaba"
      },
      {
        "_id": "dinguiraye",
        "code": "dinguiraye",
        "name": "Dinguiraye"
      },
      {
        "_id": "faranah",
        "code": "faranah",
        "name": "Faranah"
      },
      {
        "_id": "forecariah",
        "code": "forecariah",
        "name": "Forecariah"
      },
      {
        "_id": "fria",
        "code": "fria",
        "name": "Fria"
      },
      {
        "_id": "gaoual",
        "code": "gaoual",
        "name": "Gaoual"
      },
      {
        "_id": "gueckedou",
        "code": "gueckedou",
        "name": "Gueckedou"
      },
      {
        "_id": "kankan",
        "code": "kankan",
        "name": "Kankan"
      },
      {
        "_id": "kerouane",
        "code": "kerouane",
        "name": "Kerouane"
      },
      {
        "_id": "kindia",
        "code": "kindia",
        "name": "Kindia"
      },
      {
        "_id": "kissidougou",
        "code": "kissidougou",
        "name": "Kissidougou"
      },
      {
        "_id": "koubia",
        "code": "koubia",
        "name": "Koubia"
      },
      {
        "_id": "koundara",
        "code": "koundara",
        "name": "Koundara"
      },
      {
        "_id": "kouroussa",
        "code": "kouroussa",
        "name": "Kouroussa"
      },
      {
        "_id": "labe",
        "code": "labe",
        "name": "Labe"
      },
      {
        "_id": "lola",
        "code": "lola",
        "name": "Lola"
      },
      {
        "_id": "macenta",
        "code": "macenta",
        "name": "Macenta"
      },
      {
        "_id": "mali",
        "code": "mali",
        "name": "Mali"
      },
      {
        "_id": "mamou",
        "code": "mamou",
        "name": "Mamou"
      },
      {
        "_id": "mandiana",
        "code": "mandiana",
        "name": "Mandiana"
      },
      {
        "_id": "nzerekore",
        "code": "nzerekore",
        "name": "Nzerekore"
      },
      {
        "_id": "pita",
        "code": "pita",
        "name": "Pita"
      },
      {
        "_id": "siguiri",
        "code": "siguiri",
        "name": "Siguiri"
      },
      {
        "_id": "telimele",
        "code": "telimele",
        "name": "Telimele"
      },
      {
        "_id": "tougue",
        "code": "tougue",
        "name": "Tougue"
      },
      {
        "_id": "yomou",
        "code": "yomou",
        "name": "Yomou"
      }
    ]
  },
  {
    "_id": "GW",
    "code": "GW",
    "name": "Guinea-Bissau",
    "states": [
      {
        "_id": "bafata",
        "code": "bafata",
        "name": "Bafata"
      },
      {
        "_id": "bissau",
        "code": "bissau",
        "name": "Bissau"
      },
      {
        "_id": "bolama",
        "code": "bolama",
        "name": "Bolama"
      },
      {
        "_id": "cacheu",
        "code": "cacheu",
        "name": "Cacheu"
      },
      {
        "_id": "gabu",
        "code": "gabu",
        "name": "Gabu"
      },
      {
        "_id": "oio",
        "code": "oio",
        "name": "Oio"
      },
      {
        "_id": "quinara",
        "code": "quinara",
        "name": "Quinara"
      },
      {
        "_id": "tombali",
        "code": "tombali",
        "name": "Tombali"
      }
    ]
  },
  {
    "_id": "GY",
    "code": "GY",
    "name": "Guyana",
    "states": [
      {
        "_id": "barima-waini",
        "code": "barima-waini",
        "name": "Barima-Waini"
      },
      {
        "_id": "cuyuni-mazaruni",
        "code": "cuyuni-mazaruni",
        "name": "Cuyuni-Mazaruni"
      },
      {
        "_id": "demerara-mahaica",
        "code": "demerara-mahaica",
        "name": "Demerara-Mahaica"
      },
      {
        "_id": "east_berbice-corentyne",
        "code": "east_berbice-corentyne",
        "name": "East Berbice-Corentyne"
      },
      {
        "_id": "essequibo_islands-west_demerar",
        "code": "essequibo_islands-west_demerar",
        "name": "Essequibo Islands-West Demerar"
      },
      {
        "_id": "mahaica-berbice",
        "code": "mahaica-berbice",
        "name": "Mahaica-Berbice"
      },
      {
        "_id": "pomeroon-supenaam",
        "code": "pomeroon-supenaam",
        "name": "Pomeroon-Supenaam"
      },
      {
        "_id": "potaro-siparuni",
        "code": "potaro-siparuni",
        "name": "Potaro-Siparuni"
      },
      {
        "_id": "upper_demerara-berbice",
        "code": "upper_demerara-berbice",
        "name": "Upper Demerara-Berbice"
      },
      {
        "_id": "upper_takutu-upper_essequibo",
        "code": "upper_takutu-upper_essequibo",
        "name": "Upper Takutu-Upper Essequibo"
      }
    ]
  },
  {
    "_id": "HT",
    "code": "HT",
    "name": "Haiti",
    "states": [
      {
        "_id": "artibonite",
        "code": "artibonite",
        "name": "Artibonite"
      },
      {
        "_id": "centre",
        "code": "centre",
        "name": "Centre"
      },
      {
        "_id": "grandanse",
        "code": "grandanse",
        "name": "GrandAnse"
      },
      {
        "_id": "nord",
        "code": "nord",
        "name": "Nord"
      },
      {
        "_id": "nord-est",
        "code": "nord-est",
        "name": "Nord-Est"
      },
      {
        "_id": "nord-ouest",
        "code": "nord-ouest",
        "name": "Nord-Ouest"
      },
      {
        "_id": "ouest",
        "code": "ouest",
        "name": "Ouest"
      },
      {
        "_id": "sud",
        "code": "sud",
        "name": "Sud"
      },
      {
        "_id": "sud-est",
        "code": "sud-est",
        "name": "Sud-Est"
      }
    ]
  },
  {
    "_id": "HM",
    "code": "HM",
    "name": "Heard and McDonald Islands",
    "states": [
      {
        "_id": "heard_and_mcdonald_islands",
        "code": "heard_and_mcdonald_islands",
        "name": "Heard and McDonald Islands"
      }
    ]
  },
  {
    "_id": "HN",
    "code": "HN",
    "name": "Honduras",
    "states": [
      {
        "_id": "atlantida",
        "code": "atlantida",
        "name": "Atlantida"
      },
      {
        "_id": "choluteca",
        "code": "choluteca",
        "name": "Choluteca"
      },
      {
        "_id": "colon",
        "code": "colon",
        "name": "Colon"
      },
      {
        "_id": "comayagua",
        "code": "comayagua",
        "name": "Comayagua"
      },
      {
        "_id": "copan",
        "code": "copan",
        "name": "Copan"
      },
      {
        "_id": "cortes",
        "code": "cortes",
        "name": "Cortes"
      },
      {
        "_id": "distrito_central",
        "code": "distrito_central",
        "name": "Distrito Central"
      },
      {
        "_id": "el_paraiso",
        "code": "el_paraiso",
        "name": "El Paraiso"
      },
      {
        "_id": "francisco_morazan",
        "code": "francisco_morazan",
        "name": "Francisco Morazan"
      },
      {
        "_id": "gracias_a_dios",
        "code": "gracias_a_dios",
        "name": "Gracias a Dios"
      },
      {
        "_id": "intibuca",
        "code": "intibuca",
        "name": "Intibuca"
      },
      {
        "_id": "islas_de_la_bahia",
        "code": "islas_de_la_bahia",
        "name": "Islas de la Bahia"
      },
      {
        "_id": "la_paz",
        "code": "la_paz",
        "name": "La Paz"
      },
      {
        "_id": "lempira",
        "code": "lempira",
        "name": "Lempira"
      },
      {
        "_id": "ocotepeque",
        "code": "ocotepeque",
        "name": "Ocotepeque"
      },
      {
        "_id": "olancho",
        "code": "olancho",
        "name": "Olancho"
      },
      {
        "_id": "santa_barbara",
        "code": "santa_barbara",
        "name": "Santa Barbara"
      },
      {
        "_id": "valle",
        "code": "valle",
        "name": "Valle"
      },
      {
        "_id": "yoro",
        "code": "yoro",
        "name": "Yoro"
      }
    ]
  },
  {
    "_id": "HK",
    "code": "HK",
    "name": "Hong Kong S.A.R.",
    "states": [
      {
        "_id": "hong_kong",
        "code": "hong_kong",
        "name": "Hong Kong"
      }
    ]
  },
  {
    "_id": "HU",
    "code": "HU",
    "name": "Hungary",
    "states": [
      {
        "_id": "bacs-kiskun",
        "code": "bacs-kiskun",
        "name": "Bacs-Kiskun"
      },
      {
        "_id": "baranya",
        "code": "baranya",
        "name": "Baranya"
      },
      {
        "_id": "bekes",
        "code": "bekes",
        "name": "Bekes"
      },
      {
        "_id": "borsod-abauj-zemplen",
        "code": "borsod-abauj-zemplen",
        "name": "Borsod-Abauj-Zemplen"
      },
      {
        "_id": "budapest",
        "code": "budapest",
        "name": "Budapest"
      },
      {
        "_id": "csongrad",
        "code": "csongrad",
        "name": "Csongrad"
      },
      {
        "_id": "fejer",
        "code": "fejer",
        "name": "Fejer"
      },
      {
        "_id": "gyor-moson-sopron",
        "code": "gyor-moson-sopron",
        "name": "Gyor-Moson-Sopron"
      },
      {
        "_id": "hajdu-bihar",
        "code": "hajdu-bihar",
        "name": "Hajdu-Bihar"
      },
      {
        "_id": "heves",
        "code": "heves",
        "name": "Heves"
      },
      {
        "_id": "jasz-nagykun-szolnok",
        "code": "jasz-nagykun-szolnok",
        "name": "Jasz-Nagykun-Szolnok"
      },
      {
        "_id": "komarom-esztergom",
        "code": "komarom-esztergom",
        "name": "Komarom-Esztergom"
      },
      {
        "_id": "nograd",
        "code": "nograd",
        "name": "Nograd"
      },
      {
        "_id": "pest",
        "code": "pest",
        "name": "Pest"
      },
      {
        "_id": "somogy",
        "code": "somogy",
        "name": "Somogy"
      },
      {
        "_id": "szabolcs-szatmar-bereg",
        "code": "szabolcs-szatmar-bereg",
        "name": "Szabolcs-Szatmar-Bereg"
      },
      {
        "_id": "tolna",
        "code": "tolna",
        "name": "Tolna"
      },
      {
        "_id": "vas",
        "code": "vas",
        "name": "Vas"
      },
      {
        "_id": "veszprem",
        "code": "veszprem",
        "name": "Veszprem"
      },
      {
        "_id": "zala",
        "code": "zala",
        "name": "Zala"
      }
    ]
  },
  {
    "_id": "IS",
    "code": "IS",
    "name": "Iceland",
    "states": [
      {
        "_id": "austurland",
        "code": "austurland",
        "name": "Austurland"
      },
      {
        "_id": "gullbringusysla",
        "code": "gullbringusysla",
        "name": "Gullbringusysla"
      },
      {
        "_id": "hofu_borgarsva_i",
        "code": "hofu_borgarsva_i",
        "name": "Hofu borgarsva i"
      },
      {
        "_id": "nor_urland_eystra",
        "code": "nor_urland_eystra",
        "name": "Nor urland eystra"
      },
      {
        "_id": "nor_urland_vestra",
        "code": "nor_urland_vestra",
        "name": "Nor urland vestra"
      },
      {
        "_id": "su_urland",
        "code": "su_urland",
        "name": "Su urland"
      },
      {
        "_id": "su_urnes",
        "code": "su_urnes",
        "name": "Su urnes"
      },
      {
        "_id": "vestfir_ir",
        "code": "vestfir_ir",
        "name": "Vestfir ir"
      },
      {
        "_id": "vesturland",
        "code": "vesturland",
        "name": "Vesturland"
      }
    ]
  },
  {
    "_id": "IN",
    "code": "IN",
    "name": "India",
    "states": [
      {
        "_id": "andaman_and_nicobar_islands",
        "code": "andaman_and_nicobar_islands",
        "name": "Andaman and Nicobar Islands"
      },
      {
        "_id": "andhra_pradesh",
        "code": "andhra_pradesh",
        "name": "Andhra Pradesh"
      },
      {
        "_id": "arunachal_pradesh",
        "code": "arunachal_pradesh",
        "name": "Arunachal Pradesh"
      },
      {
        "_id": "assam",
        "code": "assam",
        "name": "Assam"
      },
      {
        "_id": "bihar",
        "code": "bihar",
        "name": "Bihar"
      },
      {
        "_id": "chandigarh",
        "code": "chandigarh",
        "name": "Chandigarh"
      },
      {
        "_id": "chhattisgarh",
        "code": "chhattisgarh",
        "name": "Chhattisgarh"
      },
      {
        "_id": "dadra_and_nagar_haveli",
        "code": "dadra_and_nagar_haveli",
        "name": "Dadra and Nagar Haveli"
      },
      {
        "_id": "daman_and_diu",
        "code": "daman_and_diu",
        "name": "Daman and Diu"
      },
      {
        "_id": "delhi",
        "code": "delhi",
        "name": "Delhi"
      },
      {
        "_id": "goa",
        "code": "goa",
        "name": "Goa"
      },
      {
        "_id": "gujarat",
        "code": "gujarat",
        "name": "Gujarat"
      },
      {
        "_id": "haryana",
        "code": "haryana",
        "name": "Haryana"
      },
      {
        "_id": "himachal_pradesh",
        "code": "himachal_pradesh",
        "name": "Himachal Pradesh"
      },
      {
        "_id": "jammu_and_kashmir",
        "code": "jammu_and_kashmir",
        "name": "Jammu and Kashmir"
      },
      {
        "_id": "jharkhand",
        "code": "jharkhand",
        "name": "Jharkhand"
      },
      {
        "_id": "karnataka",
        "code": "karnataka",
        "name": "Karnataka"
      },
      {
        "_id": "kenmore",
        "code": "kenmore",
        "name": "Kenmore"
      },
      {
        "_id": "kerala",
        "code": "kerala",
        "name": "Kerala"
      },
      {
        "_id": "lakshadweep",
        "code": "lakshadweep",
        "name": "Lakshadweep"
      },
      {
        "_id": "madhya_pradesh",
        "code": "madhya_pradesh",
        "name": "Madhya Pradesh"
      },
      {
        "_id": "maharashtra",
        "code": "maharashtra",
        "name": "Maharashtra"
      },
      {
        "_id": "manipur",
        "code": "manipur",
        "name": "Manipur"
      },
      {
        "_id": "meghalaya",
        "code": "meghalaya",
        "name": "Meghalaya"
      },
      {
        "_id": "mizoram",
        "code": "mizoram",
        "name": "Mizoram"
      },
      {
        "_id": "nagaland",
        "code": "nagaland",
        "name": "Nagaland"
      },
      {
        "_id": "narora",
        "code": "narora",
        "name": "Narora"
      },
      {
        "_id": "natwar",
        "code": "natwar",
        "name": "Natwar"
      },
      {
        "_id": "odisha",
        "code": "odisha",
        "name": "Odisha"
      },
      {
        "_id": "paschim_medinipur",
        "code": "paschim_medinipur",
        "name": "Paschim Medinipur"
      },
      {
        "_id": "pondicherry",
        "code": "pondicherry",
        "name": "Pondicherry"
      },
      {
        "_id": "punjab",
        "code": "punjab",
        "name": "Punjab"
      },
      {
        "_id": "rajasthan",
        "code": "rajasthan",
        "name": "Rajasthan"
      },
      {
        "_id": "sikkim",
        "code": "sikkim",
        "name": "Sikkim"
      },
      {
        "_id": "tamil_nadu",
        "code": "tamil_nadu",
        "name": "Tamil Nadu"
      },
      {
        "_id": "telangana",
        "code": "telangana",
        "name": "Telangana"
      },
      {
        "_id": "tripura",
        "code": "tripura",
        "name": "Tripura"
      },
      {
        "_id": "uttar_pradesh",
        "code": "uttar_pradesh",
        "name": "Uttar Pradesh"
      },
      {
        "_id": "uttarakhand",
        "code": "uttarakhand",
        "name": "Uttarakhand"
      },
      {
        "_id": "vaishali",
        "code": "vaishali",
        "name": "Vaishali"
      },
      {
        "_id": "west_bengal",
        "code": "west_bengal",
        "name": "West Bengal"
      }
    ]
  },
  {
    "_id": "ID",
    "code": "ID",
    "name": "Indonesia",
    "states": [
      {
        "_id": "aceh",
        "code": "aceh",
        "name": "Aceh"
      },
      {
        "_id": "bali",
        "code": "bali",
        "name": "Bali"
      },
      {
        "_id": "bangka-belitung",
        "code": "bangka-belitung",
        "name": "Bangka-Belitung"
      },
      {
        "_id": "banten",
        "code": "banten",
        "name": "Banten"
      },
      {
        "_id": "bengkulu",
        "code": "bengkulu",
        "name": "Bengkulu"
      },
      {
        "_id": "gandaria",
        "code": "gandaria",
        "name": "Gandaria"
      },
      {
        "_id": "gorontalo",
        "code": "gorontalo",
        "name": "Gorontalo"
      },
      {
        "_id": "jakarta",
        "code": "jakarta",
        "name": "Jakarta"
      },
      {
        "_id": "jambi",
        "code": "jambi",
        "name": "Jambi"
      },
      {
        "_id": "jawa_barat",
        "code": "jawa_barat",
        "name": "Jawa Barat"
      },
      {
        "_id": "jawa_tengah",
        "code": "jawa_tengah",
        "name": "Jawa Tengah"
      },
      {
        "_id": "jawa_timur",
        "code": "jawa_timur",
        "name": "Jawa Timur"
      },
      {
        "_id": "kalimantan_barat",
        "code": "kalimantan_barat",
        "name": "Kalimantan Barat"
      },
      {
        "_id": "kalimantan_selatan",
        "code": "kalimantan_selatan",
        "name": "Kalimantan Selatan"
      },
      {
        "_id": "kalimantan_tengah",
        "code": "kalimantan_tengah",
        "name": "Kalimantan Tengah"
      },
      {
        "_id": "kalimantan_timur",
        "code": "kalimantan_timur",
        "name": "Kalimantan Timur"
      },
      {
        "_id": "kendal",
        "code": "kendal",
        "name": "Kendal"
      },
      {
        "_id": "lampung",
        "code": "lampung",
        "name": "Lampung"
      },
      {
        "_id": "maluku",
        "code": "maluku",
        "name": "Maluku"
      },
      {
        "_id": "maluku_utara",
        "code": "maluku_utara",
        "name": "Maluku Utara"
      },
      {
        "_id": "nusa_tenggara_barat",
        "code": "nusa_tenggara_barat",
        "name": "Nusa Tenggara Barat"
      },
      {
        "_id": "nusa_tenggara_timur",
        "code": "nusa_tenggara_timur",
        "name": "Nusa Tenggara Timur"
      },
      {
        "_id": "papua",
        "code": "papua",
        "name": "Papua"
      },
      {
        "_id": "riau",
        "code": "riau",
        "name": "Riau"
      },
      {
        "_id": "riau_kepulauan",
        "code": "riau_kepulauan",
        "name": "Riau Kepulauan"
      },
      {
        "_id": "solo",
        "code": "solo",
        "name": "Solo"
      },
      {
        "_id": "sulawesi_selatan",
        "code": "sulawesi_selatan",
        "name": "Sulawesi Selatan"
      },
      {
        "_id": "sulawesi_tengah",
        "code": "sulawesi_tengah",
        "name": "Sulawesi Tengah"
      },
      {
        "_id": "sulawesi_tenggara",
        "code": "sulawesi_tenggara",
        "name": "Sulawesi Tenggara"
      },
      {
        "_id": "sulawesi_utara",
        "code": "sulawesi_utara",
        "name": "Sulawesi Utara"
      },
      {
        "_id": "sumatera_barat",
        "code": "sumatera_barat",
        "name": "Sumatera Barat"
      },
      {
        "_id": "sumatera_selatan",
        "code": "sumatera_selatan",
        "name": "Sumatera Selatan"
      },
      {
        "_id": "sumatera_utara",
        "code": "sumatera_utara",
        "name": "Sumatera Utara"
      },
      {
        "_id": "yogyakarta",
        "code": "yogyakarta",
        "name": "Yogyakarta"
      }
    ]
  },
  {
    "_id": "IR",
    "code": "IR",
    "name": "Iran",
    "states": [
      {
        "_id": "ardabil",
        "code": "ardabil",
        "name": "Ardabil"
      },
      {
        "_id": "azarbayjan-e_bakhtari",
        "code": "azarbayjan-e_bakhtari",
        "name": "Azarbayjan-e Bakhtari"
      },
      {
        "_id": "azarbayjan-e_khavari",
        "code": "azarbayjan-e_khavari",
        "name": "Azarbayjan-e Khavari"
      },
      {
        "_id": "bushehr",
        "code": "bushehr",
        "name": "Bushehr"
      },
      {
        "_id": "chahar_mahal-e_bakhtiari",
        "code": "chahar_mahal-e_bakhtiari",
        "name": "Chahar Mahal-e Bakhtiari"
      },
      {
        "_id": "esfahan",
        "code": "esfahan",
        "name": "Esfahan"
      },
      {
        "_id": "fars",
        "code": "fars",
        "name": "Fars"
      },
      {
        "_id": "gilan",
        "code": "gilan",
        "name": "Gilan"
      },
      {
        "_id": "golestan",
        "code": "golestan",
        "name": "Golestan"
      },
      {
        "_id": "hamadan",
        "code": "hamadan",
        "name": "Hamadan"
      },
      {
        "_id": "hormozgan",
        "code": "hormozgan",
        "name": "Hormozgan"
      },
      {
        "_id": "ilam",
        "code": "ilam",
        "name": "Ilam"
      },
      {
        "_id": "kerman",
        "code": "kerman",
        "name": "Kerman"
      },
      {
        "_id": "kermanshah",
        "code": "kermanshah",
        "name": "Kermanshah"
      },
      {
        "_id": "khorasan",
        "code": "khorasan",
        "name": "Khorasan"
      },
      {
        "_id": "khuzestan",
        "code": "khuzestan",
        "name": "Khuzestan"
      },
      {
        "_id": "kohgiluyeh-e_boyerahmad",
        "code": "kohgiluyeh-e_boyerahmad",
        "name": "Kohgiluyeh-e Boyerahmad"
      },
      {
        "_id": "kordestan",
        "code": "kordestan",
        "name": "Kordestan"
      },
      {
        "_id": "lorestan",
        "code": "lorestan",
        "name": "Lorestan"
      },
      {
        "_id": "markazi",
        "code": "markazi",
        "name": "Markazi"
      },
      {
        "_id": "mazandaran",
        "code": "mazandaran",
        "name": "Mazandaran"
      },
      {
        "_id": "ostan-e_esfahan",
        "code": "ostan-e_esfahan",
        "name": "Ostan-e Esfahan"
      },
      {
        "_id": "qazvin",
        "code": "qazvin",
        "name": "Qazvin"
      },
      {
        "_id": "qom",
        "code": "qom",
        "name": "Qom"
      },
      {
        "_id": "semnan",
        "code": "semnan",
        "name": "Semnan"
      },
      {
        "_id": "sistan-e_baluchestan",
        "code": "sistan-e_baluchestan",
        "name": "Sistan-e Baluchestan"
      },
      {
        "_id": "tehran",
        "code": "tehran",
        "name": "Tehran"
      },
      {
        "_id": "yazd",
        "code": "yazd",
        "name": "Yazd"
      },
      {
        "_id": "zanjan",
        "code": "zanjan",
        "name": "Zanjan"
      }
    ]
  },
  {
    "_id": "IQ",
    "code": "IQ",
    "name": "Iraq",
    "states": [
      {
        "_id": "babil",
        "code": "babil",
        "name": "Babil"
      },
      {
        "_id": "baghdad",
        "code": "baghdad",
        "name": "Baghdad"
      },
      {
        "_id": "dahuk",
        "code": "dahuk",
        "name": "Dahuk"
      },
      {
        "_id": "dhi_qar",
        "code": "dhi_qar",
        "name": "Dhi Qar"
      },
      {
        "_id": "diyala",
        "code": "diyala",
        "name": "Diyala"
      },
      {
        "_id": "erbil",
        "code": "erbil",
        "name": "Erbil"
      },
      {
        "_id": "irbil",
        "code": "irbil",
        "name": "Irbil"
      },
      {
        "_id": "karbala",
        "code": "karbala",
        "name": "Karbala"
      },
      {
        "_id": "kurdistan",
        "code": "kurdistan",
        "name": "Kurdistan"
      },
      {
        "_id": "maysan",
        "code": "maysan",
        "name": "Maysan"
      },
      {
        "_id": "ninawa",
        "code": "ninawa",
        "name": "Ninawa"
      },
      {
        "_id": "salah-ad-din",
        "code": "salah-ad-din",
        "name": "Salah-ad-Din"
      },
      {
        "_id": "wasit",
        "code": "wasit",
        "name": "Wasit"
      },
      {
        "_id": "al-anbar",
        "code": "al-anbar",
        "name": "al-Anbar"
      },
      {
        "_id": "al-basrah",
        "code": "al-basrah",
        "name": "al-Basrah"
      },
      {
        "_id": "al-muthanna",
        "code": "al-muthanna",
        "name": "al-Muthanna"
      },
      {
        "_id": "al-qadisiyah",
        "code": "al-qadisiyah",
        "name": "al-Qadisiyah"
      },
      {
        "_id": "an-najaf",
        "code": "an-najaf",
        "name": "an-Najaf"
      },
      {
        "_id": "as-sulaymaniyah",
        "code": "as-sulaymaniyah",
        "name": "as-Sulaymaniyah"
      },
      {
        "_id": "at-tamim",
        "code": "at-tamim",
        "name": "at-Tamim"
      }
    ]
  },
  {
    "_id": "IE",
    "code": "IE",
    "name": "Ireland",
    "states": [
      {
        "_id": "armagh",
        "code": "armagh",
        "name": "Armagh"
      },
      {
        "_id": "carlow",
        "code": "carlow",
        "name": "Carlow"
      },
      {
        "_id": "cavan",
        "code": "cavan",
        "name": "Cavan"
      },
      {
        "_id": "clare",
        "code": "clare",
        "name": "Clare"
      },
      {
        "_id": "cork",
        "code": "cork",
        "name": "Cork"
      },
      {
        "_id": "donegal",
        "code": "donegal",
        "name": "Donegal"
      },
      {
        "_id": "dublin",
        "code": "dublin",
        "name": "Dublin"
      },
      {
        "_id": "galway",
        "code": "galway",
        "name": "Galway"
      },
      {
        "_id": "kerry",
        "code": "kerry",
        "name": "Kerry"
      },
      {
        "_id": "kildare",
        "code": "kildare",
        "name": "Kildare"
      },
      {
        "_id": "kilkenny",
        "code": "kilkenny",
        "name": "Kilkenny"
      },
      {
        "_id": "laois",
        "code": "laois",
        "name": "Laois"
      },
      {
        "_id": "leinster",
        "code": "leinster",
        "name": "Leinster"
      },
      {
        "_id": "leitrim",
        "code": "leitrim",
        "name": "Leitrim"
      },
      {
        "_id": "limerick",
        "code": "limerick",
        "name": "Limerick"
      },
      {
        "_id": "loch_garman",
        "code": "loch_garman",
        "name": "Loch Garman"
      },
      {
        "_id": "longford",
        "code": "longford",
        "name": "Longford"
      },
      {
        "_id": "louth",
        "code": "louth",
        "name": "Louth"
      },
      {
        "_id": "mayo",
        "code": "mayo",
        "name": "Mayo"
      },
      {
        "_id": "meath",
        "code": "meath",
        "name": "Meath"
      },
      {
        "_id": "monaghan",
        "code": "monaghan",
        "name": "Monaghan"
      },
      {
        "_id": "offaly",
        "code": "offaly",
        "name": "Offaly"
      },
      {
        "_id": "roscommon",
        "code": "roscommon",
        "name": "Roscommon"
      },
      {
        "_id": "sligo",
        "code": "sligo",
        "name": "Sligo"
      },
      {
        "_id": "tipperary_north_riding",
        "code": "tipperary_north_riding",
        "name": "Tipperary North Riding"
      },
      {
        "_id": "tipperary_south_riding",
        "code": "tipperary_south_riding",
        "name": "Tipperary South Riding"
      },
      {
        "_id": "ulster",
        "code": "ulster",
        "name": "Ulster"
      },
      {
        "_id": "waterford",
        "code": "waterford",
        "name": "Waterford"
      },
      {
        "_id": "westmeath",
        "code": "westmeath",
        "name": "Westmeath"
      },
      {
        "_id": "wexford",
        "code": "wexford",
        "name": "Wexford"
      },
      {
        "_id": "wicklow",
        "code": "wicklow",
        "name": "Wicklow"
      }
    ]
  },
  {
    "_id": "IL",
    "code": "IL",
    "name": "Israel",
    "states": [
      {
        "_id": "beit_hanania",
        "code": "beit_hanania",
        "name": "Beit Hanania"
      },
      {
        "_id": "ben_gurion_airport",
        "code": "ben_gurion_airport",
        "name": "Ben Gurion Airport"
      },
      {
        "_id": "bethlehem",
        "code": "bethlehem",
        "name": "Bethlehem"
      },
      {
        "_id": "caesarea",
        "code": "caesarea",
        "name": "Caesarea"
      },
      {
        "_id": "centre",
        "code": "centre",
        "name": "Centre"
      },
      {
        "_id": "gaza",
        "code": "gaza",
        "name": "Gaza"
      },
      {
        "_id": "hadaron",
        "code": "hadaron",
        "name": "Hadaron"
      },
      {
        "_id": "haifa_district",
        "code": "haifa_district",
        "name": "Haifa District"
      },
      {
        "_id": "hamerkaz",
        "code": "hamerkaz",
        "name": "Hamerkaz"
      },
      {
        "_id": "hazafon",
        "code": "hazafon",
        "name": "Hazafon"
      },
      {
        "_id": "hebron",
        "code": "hebron",
        "name": "Hebron"
      },
      {
        "_id": "jaffa",
        "code": "jaffa",
        "name": "Jaffa"
      },
      {
        "_id": "jerusalem",
        "code": "jerusalem",
        "name": "Jerusalem"
      },
      {
        "_id": "khefa",
        "code": "khefa",
        "name": "Khefa"
      },
      {
        "_id": "kiryat_yam",
        "code": "kiryat_yam",
        "name": "Kiryat Yam"
      },
      {
        "_id": "lower_galilee",
        "code": "lower_galilee",
        "name": "Lower Galilee"
      },
      {
        "_id": "qalqilya",
        "code": "qalqilya",
        "name": "Qalqilya"
      },
      {
        "_id": "talme_elazar",
        "code": "talme_elazar",
        "name": "Talme Elazar"
      },
      {
        "_id": "tel_aviv",
        "code": "tel_aviv",
        "name": "Tel Aviv"
      },
      {
        "_id": "tsafon",
        "code": "tsafon",
        "name": "Tsafon"
      },
      {
        "_id": "umm_el_fahem",
        "code": "umm_el_fahem",
        "name": "Umm El Fahem"
      },
      {
        "_id": "yerushalayim",
        "code": "yerushalayim",
        "name": "Yerushalayim"
      }
    ]
  },
  {
    "_id": "IT",
    "code": "IT",
    "name": "Italy",
    "states": [
      {
        "_id": "abruzzi",
        "code": "abruzzi",
        "name": "Abruzzi"
      },
      {
        "_id": "abruzzo",
        "code": "abruzzo",
        "name": "Abruzzo"
      },
      {
        "_id": "agrigento",
        "code": "agrigento",
        "name": "Agrigento"
      },
      {
        "_id": "alessandria",
        "code": "alessandria",
        "name": "Alessandria"
      },
      {
        "_id": "ancona",
        "code": "ancona",
        "name": "Ancona"
      },
      {
        "_id": "arezzo",
        "code": "arezzo",
        "name": "Arezzo"
      },
      {
        "_id": "ascoli_piceno",
        "code": "ascoli_piceno",
        "name": "Ascoli Piceno"
      },
      {
        "_id": "asti",
        "code": "asti",
        "name": "Asti"
      },
      {
        "_id": "avellino",
        "code": "avellino",
        "name": "Avellino"
      },
      {
        "_id": "bari",
        "code": "bari",
        "name": "Bari"
      },
      {
        "_id": "basilicata",
        "code": "basilicata",
        "name": "Basilicata"
      },
      {
        "_id": "belluno",
        "code": "belluno",
        "name": "Belluno"
      },
      {
        "_id": "benevento",
        "code": "benevento",
        "name": "Benevento"
      },
      {
        "_id": "bergamo",
        "code": "bergamo",
        "name": "Bergamo"
      },
      {
        "_id": "biella",
        "code": "biella",
        "name": "Biella"
      },
      {
        "_id": "bologna",
        "code": "bologna",
        "name": "Bologna"
      },
      {
        "_id": "bolzano",
        "code": "bolzano",
        "name": "Bolzano"
      },
      {
        "_id": "brescia",
        "code": "brescia",
        "name": "Brescia"
      },
      {
        "_id": "brindisi",
        "code": "brindisi",
        "name": "Brindisi"
      },
      {
        "_id": "calabria",
        "code": "calabria",
        "name": "Calabria"
      },
      {
        "_id": "campania",
        "code": "campania",
        "name": "Campania"
      },
      {
        "_id": "cartoceto",
        "code": "cartoceto",
        "name": "Cartoceto"
      },
      {
        "_id": "caserta",
        "code": "caserta",
        "name": "Caserta"
      },
      {
        "_id": "catania",
        "code": "catania",
        "name": "Catania"
      },
      {
        "_id": "chieti",
        "code": "chieti",
        "name": "Chieti"
      },
      {
        "_id": "como",
        "code": "como",
        "name": "Como"
      },
      {
        "_id": "cosenza",
        "code": "cosenza",
        "name": "Cosenza"
      },
      {
        "_id": "cremona",
        "code": "cremona",
        "name": "Cremona"
      },
      {
        "_id": "cuneo",
        "code": "cuneo",
        "name": "Cuneo"
      },
      {
        "_id": "emilia-romagna",
        "code": "emilia-romagna",
        "name": "Emilia-Romagna"
      },
      {
        "_id": "ferrara",
        "code": "ferrara",
        "name": "Ferrara"
      },
      {
        "_id": "firenze",
        "code": "firenze",
        "name": "Firenze"
      },
      {
        "_id": "florence",
        "code": "florence",
        "name": "Florence"
      },
      {
        "_id": "forli-cesena_",
        "code": "forli-cesena_",
        "name": "Forli-Cesena "
      },
      {
        "_id": "friuli-venezia_giulia",
        "code": "friuli-venezia_giulia",
        "name": "Friuli-Venezia Giulia"
      },
      {
        "_id": "frosinone",
        "code": "frosinone",
        "name": "Frosinone"
      },
      {
        "_id": "genoa",
        "code": "genoa",
        "name": "Genoa"
      },
      {
        "_id": "gorizia",
        "code": "gorizia",
        "name": "Gorizia"
      },
      {
        "_id": "laquila",
        "code": "laquila",
        "name": "LAquila"
      },
      {
        "_id": "lazio",
        "code": "lazio",
        "name": "Lazio"
      },
      {
        "_id": "lecce",
        "code": "lecce",
        "name": "Lecce"
      },
      {
        "_id": "lecco",
        "code": "lecco",
        "name": "Lecco"
      },
      {
        "_id": "lecco_province",
        "code": "lecco_province",
        "name": "Lecco Province"
      },
      {
        "_id": "liguria",
        "code": "liguria",
        "name": "Liguria"
      },
      {
        "_id": "lodi",
        "code": "lodi",
        "name": "Lodi"
      },
      {
        "_id": "lombardia",
        "code": "lombardia",
        "name": "Lombardia"
      },
      {
        "_id": "lombardy",
        "code": "lombardy",
        "name": "Lombardy"
      },
      {
        "_id": "macerata",
        "code": "macerata",
        "name": "Macerata"
      },
      {
        "_id": "mantova",
        "code": "mantova",
        "name": "Mantova"
      },
      {
        "_id": "marche",
        "code": "marche",
        "name": "Marche"
      },
      {
        "_id": "messina",
        "code": "messina",
        "name": "Messina"
      },
      {
        "_id": "milan",
        "code": "milan",
        "name": "Milan"
      },
      {
        "_id": "modena",
        "code": "modena",
        "name": "Modena"
      },
      {
        "_id": "molise",
        "code": "molise",
        "name": "Molise"
      },
      {
        "_id": "molteno",
        "code": "molteno",
        "name": "Molteno"
      },
      {
        "_id": "montenegro",
        "code": "montenegro",
        "name": "Montenegro"
      },
      {
        "_id": "monza_and_brianza",
        "code": "monza_and_brianza",
        "name": "Monza and Brianza"
      },
      {
        "_id": "naples",
        "code": "naples",
        "name": "Naples"
      },
      {
        "_id": "novara",
        "code": "novara",
        "name": "Novara"
      },
      {
        "_id": "padova",
        "code": "padova",
        "name": "Padova"
      },
      {
        "_id": "parma",
        "code": "parma",
        "name": "Parma"
      },
      {
        "_id": "pavia",
        "code": "pavia",
        "name": "Pavia"
      },
      {
        "_id": "perugia",
        "code": "perugia",
        "name": "Perugia"
      },
      {
        "_id": "pesaro-urbino",
        "code": "pesaro-urbino",
        "name": "Pesaro-Urbino"
      },
      {
        "_id": "piacenza",
        "code": "piacenza",
        "name": "Piacenza"
      },
      {
        "_id": "piedmont",
        "code": "piedmont",
        "name": "Piedmont"
      },
      {
        "_id": "piemonte",
        "code": "piemonte",
        "name": "Piemonte"
      },
      {
        "_id": "pisa",
        "code": "pisa",
        "name": "Pisa"
      },
      {
        "_id": "pordenone",
        "code": "pordenone",
        "name": "Pordenone"
      },
      {
        "_id": "potenza",
        "code": "potenza",
        "name": "Potenza"
      },
      {
        "_id": "puglia",
        "code": "puglia",
        "name": "Puglia"
      },
      {
        "_id": "reggio_emilia",
        "code": "reggio_emilia",
        "name": "Reggio Emilia"
      },
      {
        "_id": "rimini",
        "code": "rimini",
        "name": "Rimini"
      },
      {
        "_id": "roma",
        "code": "roma",
        "name": "Roma"
      },
      {
        "_id": "salerno",
        "code": "salerno",
        "name": "Salerno"
      },
      {
        "_id": "sardegna",
        "code": "sardegna",
        "name": "Sardegna"
      },
      {
        "_id": "sassari",
        "code": "sassari",
        "name": "Sassari"
      },
      {
        "_id": "savona",
        "code": "savona",
        "name": "Savona"
      },
      {
        "_id": "sicilia",
        "code": "sicilia",
        "name": "Sicilia"
      },
      {
        "_id": "siena",
        "code": "siena",
        "name": "Siena"
      },
      {
        "_id": "sondrio",
        "code": "sondrio",
        "name": "Sondrio"
      },
      {
        "_id": "south_tyrol",
        "code": "south_tyrol",
        "name": "South Tyrol"
      },
      {
        "_id": "taranto",
        "code": "taranto",
        "name": "Taranto"
      },
      {
        "_id": "teramo",
        "code": "teramo",
        "name": "Teramo"
      },
      {
        "_id": "torino",
        "code": "torino",
        "name": "Torino"
      },
      {
        "_id": "toscana",
        "code": "toscana",
        "name": "Toscana"
      },
      {
        "_id": "trapani",
        "code": "trapani",
        "name": "Trapani"
      },
      {
        "_id": "trentino-alto_adige",
        "code": "trentino-alto_adige",
        "name": "Trentino-Alto Adige"
      },
      {
        "_id": "trento",
        "code": "trento",
        "name": "Trento"
      },
      {
        "_id": "treviso",
        "code": "treviso",
        "name": "Treviso"
      },
      {
        "_id": "udine",
        "code": "udine",
        "name": "Udine"
      },
      {
        "_id": "umbria",
        "code": "umbria",
        "name": "Umbria"
      },
      {
        "_id": "valle_daosta",
        "code": "valle_daosta",
        "name": "Valle dAosta"
      },
      {
        "_id": "varese",
        "code": "varese",
        "name": "Varese"
      },
      {
        "_id": "veneto",
        "code": "veneto",
        "name": "Veneto"
      },
      {
        "_id": "venezia",
        "code": "venezia",
        "name": "Venezia"
      },
      {
        "_id": "verbano-cusio-ossola",
        "code": "verbano-cusio-ossola",
        "name": "Verbano-Cusio-Ossola"
      },
      {
        "_id": "vercelli",
        "code": "vercelli",
        "name": "Vercelli"
      },
      {
        "_id": "verona",
        "code": "verona",
        "name": "Verona"
      },
      {
        "_id": "vicenza",
        "code": "vicenza",
        "name": "Vicenza"
      },
      {
        "_id": "viterbo",
        "code": "viterbo",
        "name": "Viterbo"
      }
    ]
  },
  {
    "_id": "JM",
    "code": "JM",
    "name": "Jamaica",
    "states": [
      {
        "_id": "buxoro_viloyati",
        "code": "buxoro_viloyati",
        "name": "Buxoro Viloyati"
      },
      {
        "_id": "clarendon",
        "code": "clarendon",
        "name": "Clarendon"
      },
      {
        "_id": "hanover",
        "code": "hanover",
        "name": "Hanover"
      },
      {
        "_id": "kingston",
        "code": "kingston",
        "name": "Kingston"
      },
      {
        "_id": "manchester",
        "code": "manchester",
        "name": "Manchester"
      },
      {
        "_id": "portland",
        "code": "portland",
        "name": "Portland"
      },
      {
        "_id": "saint_andrews",
        "code": "saint_andrews",
        "name": "Saint Andrews"
      },
      {
        "_id": "saint_ann",
        "code": "saint_ann",
        "name": "Saint Ann"
      },
      {
        "_id": "saint_catherine",
        "code": "saint_catherine",
        "name": "Saint Catherine"
      },
      {
        "_id": "saint_elizabeth",
        "code": "saint_elizabeth",
        "name": "Saint Elizabeth"
      },
      {
        "_id": "saint_james",
        "code": "saint_james",
        "name": "Saint James"
      },
      {
        "_id": "saint_mary",
        "code": "saint_mary",
        "name": "Saint Mary"
      },
      {
        "_id": "saint_thomas",
        "code": "saint_thomas",
        "name": "Saint Thomas"
      },
      {
        "_id": "trelawney",
        "code": "trelawney",
        "name": "Trelawney"
      },
      {
        "_id": "westmoreland",
        "code": "westmoreland",
        "name": "Westmoreland"
      }
    ]
  },
  {
    "_id": "JP",
    "code": "JP",
    "name": "Japan",
    "states": [
      {
        "_id": "aichi",
        "code": "aichi",
        "name": "Aichi"
      },
      {
        "_id": "akita",
        "code": "akita",
        "name": "Akita"
      },
      {
        "_id": "aomori",
        "code": "aomori",
        "name": "Aomori"
      },
      {
        "_id": "chiba",
        "code": "chiba",
        "name": "Chiba"
      },
      {
        "_id": "ehime",
        "code": "ehime",
        "name": "Ehime"
      },
      {
        "_id": "fukui",
        "code": "fukui",
        "name": "Fukui"
      },
      {
        "_id": "fukuoka",
        "code": "fukuoka",
        "name": "Fukuoka"
      },
      {
        "_id": "fukushima",
        "code": "fukushima",
        "name": "Fukushima"
      },
      {
        "_id": "gifu",
        "code": "gifu",
        "name": "Gifu"
      },
      {
        "_id": "gumma",
        "code": "gumma",
        "name": "Gumma"
      },
      {
        "_id": "hiroshima",
        "code": "hiroshima",
        "name": "Hiroshima"
      },
      {
        "_id": "hokkaido",
        "code": "hokkaido",
        "name": "Hokkaido"
      },
      {
        "_id": "hyogo",
        "code": "hyogo",
        "name": "Hyogo"
      },
      {
        "_id": "ibaraki",
        "code": "ibaraki",
        "name": "Ibaraki"
      },
      {
        "_id": "ishikawa",
        "code": "ishikawa",
        "name": "Ishikawa"
      },
      {
        "_id": "iwate",
        "code": "iwate",
        "name": "Iwate"
      },
      {
        "_id": "kagawa",
        "code": "kagawa",
        "name": "Kagawa"
      },
      {
        "_id": "kagoshima",
        "code": "kagoshima",
        "name": "Kagoshima"
      },
      {
        "_id": "kanagawa",
        "code": "kanagawa",
        "name": "Kanagawa"
      },
      {
        "_id": "kanto",
        "code": "kanto",
        "name": "Kanto"
      },
      {
        "_id": "kochi",
        "code": "kochi",
        "name": "Kochi"
      },
      {
        "_id": "kumamoto",
        "code": "kumamoto",
        "name": "Kumamoto"
      },
      {
        "_id": "kyoto",
        "code": "kyoto",
        "name": "Kyoto"
      },
      {
        "_id": "mie",
        "code": "mie",
        "name": "Mie"
      },
      {
        "_id": "miyagi",
        "code": "miyagi",
        "name": "Miyagi"
      },
      {
        "_id": "miyazaki",
        "code": "miyazaki",
        "name": "Miyazaki"
      },
      {
        "_id": "nagano",
        "code": "nagano",
        "name": "Nagano"
      },
      {
        "_id": "nagasaki",
        "code": "nagasaki",
        "name": "Nagasaki"
      },
      {
        "_id": "nara",
        "code": "nara",
        "name": "Nara"
      },
      {
        "_id": "niigata",
        "code": "niigata",
        "name": "Niigata"
      },
      {
        "_id": "oita",
        "code": "oita",
        "name": "Oita"
      },
      {
        "_id": "okayama",
        "code": "okayama",
        "name": "Okayama"
      },
      {
        "_id": "okinawa",
        "code": "okinawa",
        "name": "Okinawa"
      },
      {
        "_id": "osaka",
        "code": "osaka",
        "name": "Osaka"
      },
      {
        "_id": "saga",
        "code": "saga",
        "name": "Saga"
      },
      {
        "_id": "saitama",
        "code": "saitama",
        "name": "Saitama"
      },
      {
        "_id": "shiga",
        "code": "shiga",
        "name": "Shiga"
      },
      {
        "_id": "shimane",
        "code": "shimane",
        "name": "Shimane"
      },
      {
        "_id": "shizuoka",
        "code": "shizuoka",
        "name": "Shizuoka"
      },
      {
        "_id": "tochigi",
        "code": "tochigi",
        "name": "Tochigi"
      },
      {
        "_id": "tokushima",
        "code": "tokushima",
        "name": "Tokushima"
      },
      {
        "_id": "tokyo",
        "code": "tokyo",
        "name": "Tokyo"
      },
      {
        "_id": "tottori",
        "code": "tottori",
        "name": "Tottori"
      },
      {
        "_id": "toyama",
        "code": "toyama",
        "name": "Toyama"
      },
      {
        "_id": "wakayama",
        "code": "wakayama",
        "name": "Wakayama"
      },
      {
        "_id": "yamagata",
        "code": "yamagata",
        "name": "Yamagata"
      },
      {
        "_id": "yamaguchi",
        "code": "yamaguchi",
        "name": "Yamaguchi"
      },
      {
        "_id": "yamanashi",
        "code": "yamanashi",
        "name": "Yamanashi"
      }
    ]
  },
  {
    "_id": "XJ",
    "code": "XJ",
    "name": "Jersey",
    "states": [
      {
        "_id": "grouville",
        "code": "grouville",
        "name": "Grouville"
      },
      {
        "_id": "saint_brelade",
        "code": "saint_brelade",
        "name": "Saint Brelade"
      },
      {
        "_id": "saint_clement",
        "code": "saint_clement",
        "name": "Saint Clement"
      },
      {
        "_id": "saint_helier",
        "code": "saint_helier",
        "name": "Saint Helier"
      },
      {
        "_id": "saint_john",
        "code": "saint_john",
        "name": "Saint John"
      },
      {
        "_id": "saint_lawrence",
        "code": "saint_lawrence",
        "name": "Saint Lawrence"
      },
      {
        "_id": "saint_martin",
        "code": "saint_martin",
        "name": "Saint Martin"
      },
      {
        "_id": "saint_mary",
        "code": "saint_mary",
        "name": "Saint Mary"
      },
      {
        "_id": "saint_peter",
        "code": "saint_peter",
        "name": "Saint Peter"
      },
      {
        "_id": "saint_saviour",
        "code": "saint_saviour",
        "name": "Saint Saviour"
      },
      {
        "_id": "trinity",
        "code": "trinity",
        "name": "Trinity"
      }
    ]
  },
  {
    "_id": "JO",
    "code": "JO",
    "name": "Jordan",
    "states": [
      {
        "_id": "ajlun",
        "code": "ajlun",
        "name": "Ajlun"
      },
      {
        "_id": "amman",
        "code": "amman",
        "name": "Amman"
      },
      {
        "_id": "irbid",
        "code": "irbid",
        "name": "Irbid"
      },
      {
        "_id": "jarash",
        "code": "jarash",
        "name": "Jarash"
      },
      {
        "_id": "maan",
        "code": "maan",
        "name": "Maan"
      },
      {
        "_id": "madaba",
        "code": "madaba",
        "name": "Madaba"
      },
      {
        "_id": "al-aqabah",
        "code": "al-aqabah",
        "name": "al-Aqabah"
      },
      {
        "_id": "al-balqa",
        "code": "al-balqa",
        "name": "al-Balqa"
      },
      {
        "_id": "al-karak",
        "code": "al-karak",
        "name": "al-Karak"
      },
      {
        "_id": "al-mafraq",
        "code": "al-mafraq",
        "name": "al-Mafraq"
      },
      {
        "_id": "at-tafilah",
        "code": "at-tafilah",
        "name": "at-Tafilah"
      },
      {
        "_id": "az-zarqa",
        "code": "az-zarqa",
        "name": "az-Zarqa"
      }
    ]
  },
  {
    "_id": "KZ",
    "code": "KZ",
    "name": "Kazakhstan",
    "states": [
      {
        "_id": "akmecet",
        "code": "akmecet",
        "name": "Akmecet"
      },
      {
        "_id": "akmola",
        "code": "akmola",
        "name": "Akmola"
      },
      {
        "_id": "aktobe",
        "code": "aktobe",
        "name": "Aktobe"
      },
      {
        "_id": "almati",
        "code": "almati",
        "name": "Almati"
      },
      {
        "_id": "atirau",
        "code": "atirau",
        "name": "Atirau"
      },
      {
        "_id": "batis_kazakstan",
        "code": "batis_kazakstan",
        "name": "Batis Kazakstan"
      },
      {
        "_id": "burlinsky_region",
        "code": "burlinsky_region",
        "name": "Burlinsky Region"
      },
      {
        "_id": "karagandi",
        "code": "karagandi",
        "name": "Karagandi"
      },
      {
        "_id": "kostanay",
        "code": "kostanay",
        "name": "Kostanay"
      },
      {
        "_id": "mankistau",
        "code": "mankistau",
        "name": "Mankistau"
      },
      {
        "_id": "ontustik_kazakstan",
        "code": "ontustik_kazakstan",
        "name": "Ontustik Kazakstan"
      },
      {
        "_id": "pavlodar",
        "code": "pavlodar",
        "name": "Pavlodar"
      },
      {
        "_id": "sigis_kazakstan",
        "code": "sigis_kazakstan",
        "name": "Sigis Kazakstan"
      },
      {
        "_id": "soltustik_kazakstan",
        "code": "soltustik_kazakstan",
        "name": "Soltustik Kazakstan"
      },
      {
        "_id": "taraz",
        "code": "taraz",
        "name": "Taraz"
      }
    ]
  },
  {
    "_id": "KE",
    "code": "KE",
    "name": "Kenya",
    "states": [
      {
        "_id": "central",
        "code": "central",
        "name": "Central"
      },
      {
        "_id": "coast",
        "code": "coast",
        "name": "Coast"
      },
      {
        "_id": "eastern",
        "code": "eastern",
        "name": "Eastern"
      },
      {
        "_id": "nairobi",
        "code": "nairobi",
        "name": "Nairobi"
      },
      {
        "_id": "north_eastern",
        "code": "north_eastern",
        "name": "North Eastern"
      },
      {
        "_id": "nyanza",
        "code": "nyanza",
        "name": "Nyanza"
      },
      {
        "_id": "rift_valley",
        "code": "rift_valley",
        "name": "Rift Valley"
      },
      {
        "_id": "western",
        "code": "western",
        "name": "Western"
      }
    ]
  },
  {
    "_id": "KI",
    "code": "KI",
    "name": "Kiribati",
    "states": [
      {
        "_id": "abaiang",
        "code": "abaiang",
        "name": "Abaiang"
      },
      {
        "_id": "abemana",
        "code": "abemana",
        "name": "Abemana"
      },
      {
        "_id": "aranuka",
        "code": "aranuka",
        "name": "Aranuka"
      },
      {
        "_id": "arorae",
        "code": "arorae",
        "name": "Arorae"
      },
      {
        "_id": "banaba",
        "code": "banaba",
        "name": "Banaba"
      },
      {
        "_id": "beru",
        "code": "beru",
        "name": "Beru"
      },
      {
        "_id": "butaritari",
        "code": "butaritari",
        "name": "Butaritari"
      },
      {
        "_id": "kiritimati",
        "code": "kiritimati",
        "name": "Kiritimati"
      },
      {
        "_id": "kuria",
        "code": "kuria",
        "name": "Kuria"
      },
      {
        "_id": "maiana",
        "code": "maiana",
        "name": "Maiana"
      },
      {
        "_id": "makin",
        "code": "makin",
        "name": "Makin"
      },
      {
        "_id": "marakei",
        "code": "marakei",
        "name": "Marakei"
      },
      {
        "_id": "nikunau",
        "code": "nikunau",
        "name": "Nikunau"
      },
      {
        "_id": "nonouti",
        "code": "nonouti",
        "name": "Nonouti"
      },
      {
        "_id": "onotoa",
        "code": "onotoa",
        "name": "Onotoa"
      },
      {
        "_id": "phoenix_islands",
        "code": "phoenix_islands",
        "name": "Phoenix Islands"
      },
      {
        "_id": "tabiteuea_north",
        "code": "tabiteuea_north",
        "name": "Tabiteuea North"
      },
      {
        "_id": "tabiteuea_south",
        "code": "tabiteuea_south",
        "name": "Tabiteuea South"
      },
      {
        "_id": "tabuaeran",
        "code": "tabuaeran",
        "name": "Tabuaeran"
      },
      {
        "_id": "tamana",
        "code": "tamana",
        "name": "Tamana"
      },
      {
        "_id": "tarawa_north",
        "code": "tarawa_north",
        "name": "Tarawa North"
      },
      {
        "_id": "tarawa_south",
        "code": "tarawa_south",
        "name": "Tarawa South"
      },
      {
        "_id": "teraina",
        "code": "teraina",
        "name": "Teraina"
      }
    ]
  },
  {
    "_id": "KP",
    "code": "KP",
    "name": "Korea North",
    "states": [
      {
        "_id": "chagangdo",
        "code": "chagangdo",
        "name": "Chagangdo"
      },
      {
        "_id": "hamgyeongbukto",
        "code": "hamgyeongbukto",
        "name": "Hamgyeongbukto"
      },
      {
        "_id": "hamgyeongnamdo",
        "code": "hamgyeongnamdo",
        "name": "Hamgyeongnamdo"
      },
      {
        "_id": "hwanghaebukto",
        "code": "hwanghaebukto",
        "name": "Hwanghaebukto"
      },
      {
        "_id": "hwanghaenamdo",
        "code": "hwanghaenamdo",
        "name": "Hwanghaenamdo"
      },
      {
        "_id": "kaeseong",
        "code": "kaeseong",
        "name": "Kaeseong"
      },
      {
        "_id": "kangweon",
        "code": "kangweon",
        "name": "Kangweon"
      },
      {
        "_id": "nampo",
        "code": "nampo",
        "name": "Nampo"
      },
      {
        "_id": "pyeonganbukto",
        "code": "pyeonganbukto",
        "name": "Pyeonganbukto"
      },
      {
        "_id": "pyeongannamdo",
        "code": "pyeongannamdo",
        "name": "Pyeongannamdo"
      },
      {
        "_id": "pyeongyang",
        "code": "pyeongyang",
        "name": "Pyeongyang"
      },
      {
        "_id": "yanggang",
        "code": "yanggang",
        "name": "Yanggang"
      }
    ]
  },
  {
    "_id": "KR",
    "code": "KR",
    "name": "Korea South",
    "states": [
      {
        "_id": "busan",
        "code": "busan",
        "name": "Busan"
      },
      {
        "_id": "cheju",
        "code": "cheju",
        "name": "Cheju"
      },
      {
        "_id": "chollabuk",
        "code": "chollabuk",
        "name": "Chollabuk"
      },
      {
        "_id": "chollanam",
        "code": "chollanam",
        "name": "Chollanam"
      },
      {
        "_id": "chungbuk",
        "code": "chungbuk",
        "name": "Chungbuk"
      },
      {
        "_id": "chungcheongbuk",
        "code": "chungcheongbuk",
        "name": "Chungcheongbuk"
      },
      {
        "_id": "chungcheongnam",
        "code": "chungcheongnam",
        "name": "Chungcheongnam"
      },
      {
        "_id": "chungnam",
        "code": "chungnam",
        "name": "Chungnam"
      },
      {
        "_id": "daegu",
        "code": "daegu",
        "name": "Daegu"
      },
      {
        "_id": "gangwon-do",
        "code": "gangwon-do",
        "name": "Gangwon-do"
      },
      {
        "_id": "goyang-si",
        "code": "goyang-si",
        "name": "Goyang-si"
      },
      {
        "_id": "gyeonggi-do",
        "code": "gyeonggi-do",
        "name": "Gyeonggi-do"
      },
      {
        "_id": "gyeongsang_",
        "code": "gyeongsang_",
        "name": "Gyeongsang "
      },
      {
        "_id": "gyeongsangnam-do",
        "code": "gyeongsangnam-do",
        "name": "Gyeongsangnam-do"
      },
      {
        "_id": "incheon",
        "code": "incheon",
        "name": "Incheon"
      },
      {
        "_id": "jeju-si",
        "code": "jeju-si",
        "name": "Jeju-Si"
      },
      {
        "_id": "jeonbuk",
        "code": "jeonbuk",
        "name": "Jeonbuk"
      },
      {
        "_id": "kangweon",
        "code": "kangweon",
        "name": "Kangweon"
      },
      {
        "_id": "kwangju",
        "code": "kwangju",
        "name": "Kwangju"
      },
      {
        "_id": "kyeonggi",
        "code": "kyeonggi",
        "name": "Kyeonggi"
      },
      {
        "_id": "kyeongsangbuk",
        "code": "kyeongsangbuk",
        "name": "Kyeongsangbuk"
      },
      {
        "_id": "kyeongsangnam",
        "code": "kyeongsangnam",
        "name": "Kyeongsangnam"
      },
      {
        "_id": "kyonggi-do",
        "code": "kyonggi-do",
        "name": "Kyonggi-do"
      },
      {
        "_id": "kyungbuk-do",
        "code": "kyungbuk-do",
        "name": "Kyungbuk-Do"
      },
      {
        "_id": "kyunggi-do",
        "code": "kyunggi-do",
        "name": "Kyunggi-Do"
      },
      {
        "_id": "kyunggi-do",
        "code": "kyunggi-do",
        "name": "Kyunggi-do"
      },
      {
        "_id": "pusan",
        "code": "pusan",
        "name": "Pusan"
      },
      {
        "_id": "seoul",
        "code": "seoul",
        "name": "Seoul"
      },
      {
        "_id": "sudogwon",
        "code": "sudogwon",
        "name": "Sudogwon"
      },
      {
        "_id": "taegu",
        "code": "taegu",
        "name": "Taegu"
      },
      {
        "_id": "taejeon",
        "code": "taejeon",
        "name": "Taejeon"
      },
      {
        "_id": "taejon-gwangyoksi",
        "code": "taejon-gwangyoksi",
        "name": "Taejon-gwangyoksi"
      },
      {
        "_id": "ulsan",
        "code": "ulsan",
        "name": "Ulsan"
      },
      {
        "_id": "wonju",
        "code": "wonju",
        "name": "Wonju"
      },
      {
        "_id": "gwangyoksi",
        "code": "gwangyoksi",
        "name": "gwangyoksi"
      }
    ]
  },
  {
    "_id": "KW",
    "code": "KW",
    "name": "Kuwait",
    "states": [
      {
        "_id": "al_asimah",
        "code": "al_asimah",
        "name": "Al Asimah"
      },
      {
        "_id": "hawalli",
        "code": "hawalli",
        "name": "Hawalli"
      },
      {
        "_id": "mishref",
        "code": "mishref",
        "name": "Mishref"
      },
      {
        "_id": "qadesiya",
        "code": "qadesiya",
        "name": "Qadesiya"
      },
      {
        "_id": "safat",
        "code": "safat",
        "name": "Safat"
      },
      {
        "_id": "salmiya",
        "code": "salmiya",
        "name": "Salmiya"
      },
      {
        "_id": "al-ahmadi",
        "code": "al-ahmadi",
        "name": "al-Ahmadi"
      },
      {
        "_id": "al-farwaniyah",
        "code": "al-farwaniyah",
        "name": "al-Farwaniyah"
      },
      {
        "_id": "al-jahra",
        "code": "al-jahra",
        "name": "al-Jahra"
      },
      {
        "_id": "al-kuwayt",
        "code": "al-kuwayt",
        "name": "al-Kuwayt"
      }
    ]
  },
  {
    "_id": "KG",
    "code": "KG",
    "name": "Kyrgyzstan",
    "states": [
      {
        "_id": "batken",
        "code": "batken",
        "name": "Batken"
      },
      {
        "_id": "bishkek",
        "code": "bishkek",
        "name": "Bishkek"
      },
      {
        "_id": "chui",
        "code": "chui",
        "name": "Chui"
      },
      {
        "_id": "issyk-kul",
        "code": "issyk-kul",
        "name": "Issyk-Kul"
      },
      {
        "_id": "jalal-abad",
        "code": "jalal-abad",
        "name": "Jalal-Abad"
      },
      {
        "_id": "naryn",
        "code": "naryn",
        "name": "Naryn"
      },
      {
        "_id": "osh",
        "code": "osh",
        "name": "Osh"
      },
      {
        "_id": "talas",
        "code": "talas",
        "name": "Talas"
      }
    ]
  },
  {
    "_id": "LA",
    "code": "LA",
    "name": "Laos",
    "states": [
      {
        "_id": "attopu",
        "code": "attopu",
        "name": "Attopu"
      },
      {
        "_id": "bokeo",
        "code": "bokeo",
        "name": "Bokeo"
      },
      {
        "_id": "bolikhamsay",
        "code": "bolikhamsay",
        "name": "Bolikhamsay"
      },
      {
        "_id": "champasak",
        "code": "champasak",
        "name": "Champasak"
      },
      {
        "_id": "houaphanh",
        "code": "houaphanh",
        "name": "Houaphanh"
      },
      {
        "_id": "khammouane",
        "code": "khammouane",
        "name": "Khammouane"
      },
      {
        "_id": "luang_nam_tha",
        "code": "luang_nam_tha",
        "name": "Luang Nam Tha"
      },
      {
        "_id": "luang_prabang",
        "code": "luang_prabang",
        "name": "Luang Prabang"
      },
      {
        "_id": "oudomxay",
        "code": "oudomxay",
        "name": "Oudomxay"
      },
      {
        "_id": "phongsaly",
        "code": "phongsaly",
        "name": "Phongsaly"
      },
      {
        "_id": "saravan",
        "code": "saravan",
        "name": "Saravan"
      },
      {
        "_id": "savannakhet",
        "code": "savannakhet",
        "name": "Savannakhet"
      },
      {
        "_id": "sekong",
        "code": "sekong",
        "name": "Sekong"
      },
      {
        "_id": "viangchan_prefecture",
        "code": "viangchan_prefecture",
        "name": "Viangchan Prefecture"
      },
      {
        "_id": "viangchan_province",
        "code": "viangchan_province",
        "name": "Viangchan Province"
      },
      {
        "_id": "xaignabury",
        "code": "xaignabury",
        "name": "Xaignabury"
      },
      {
        "_id": "xiang_khuang",
        "code": "xiang_khuang",
        "name": "Xiang Khuang"
      }
    ]
  },
  {
    "_id": "LV",
    "code": "LV",
    "name": "Latvia",
    "states": [
      {
        "_id": "aizkraukles",
        "code": "aizkraukles",
        "name": "Aizkraukles"
      },
      {
        "_id": "aluksnes",
        "code": "aluksnes",
        "name": "Aluksnes"
      },
      {
        "_id": "balvu",
        "code": "balvu",
        "name": "Balvu"
      },
      {
        "_id": "bauskas",
        "code": "bauskas",
        "name": "Bauskas"
      },
      {
        "_id": "cesu",
        "code": "cesu",
        "name": "Cesu"
      },
      {
        "_id": "daugavpils",
        "code": "daugavpils",
        "name": "Daugavpils"
      },
      {
        "_id": "daugavpils_city",
        "code": "daugavpils_city",
        "name": "Daugavpils City"
      },
      {
        "_id": "dobeles",
        "code": "dobeles",
        "name": "Dobeles"
      },
      {
        "_id": "gulbenes",
        "code": "gulbenes",
        "name": "Gulbenes"
      },
      {
        "_id": "jekabspils",
        "code": "jekabspils",
        "name": "Jekabspils"
      },
      {
        "_id": "jelgava",
        "code": "jelgava",
        "name": "Jelgava"
      },
      {
        "_id": "jelgavas",
        "code": "jelgavas",
        "name": "Jelgavas"
      },
      {
        "_id": "jurmala_city",
        "code": "jurmala_city",
        "name": "Jurmala City"
      },
      {
        "_id": "kraslavas",
        "code": "kraslavas",
        "name": "Kraslavas"
      },
      {
        "_id": "kuldigas",
        "code": "kuldigas",
        "name": "Kuldigas"
      },
      {
        "_id": "liepaja",
        "code": "liepaja",
        "name": "Liepaja"
      },
      {
        "_id": "liepajas",
        "code": "liepajas",
        "name": "Liepajas"
      },
      {
        "_id": "limbazhu",
        "code": "limbazhu",
        "name": "Limbazhu"
      },
      {
        "_id": "ludzas",
        "code": "ludzas",
        "name": "Ludzas"
      },
      {
        "_id": "madonas",
        "code": "madonas",
        "name": "Madonas"
      },
      {
        "_id": "ogres",
        "code": "ogres",
        "name": "Ogres"
      },
      {
        "_id": "preilu",
        "code": "preilu",
        "name": "Preilu"
      },
      {
        "_id": "rezekne",
        "code": "rezekne",
        "name": "Rezekne"
      },
      {
        "_id": "rezeknes",
        "code": "rezeknes",
        "name": "Rezeknes"
      },
      {
        "_id": "riga",
        "code": "riga",
        "name": "Riga"
      },
      {
        "_id": "rigas",
        "code": "rigas",
        "name": "Rigas"
      },
      {
        "_id": "saldus",
        "code": "saldus",
        "name": "Saldus"
      },
      {
        "_id": "talsu",
        "code": "talsu",
        "name": "Talsu"
      },
      {
        "_id": "tukuma",
        "code": "tukuma",
        "name": "Tukuma"
      },
      {
        "_id": "valkas",
        "code": "valkas",
        "name": "Valkas"
      },
      {
        "_id": "valmieras",
        "code": "valmieras",
        "name": "Valmieras"
      },
      {
        "_id": "ventspils",
        "code": "ventspils",
        "name": "Ventspils"
      },
      {
        "_id": "ventspils_city",
        "code": "ventspils_city",
        "name": "Ventspils City"
      }
    ]
  },
  {
    "_id": "LB",
    "code": "LB",
    "name": "Lebanon",
    "states": [
      {
        "_id": "beirut",
        "code": "beirut",
        "name": "Beirut"
      },
      {
        "_id": "jabal_lubnan",
        "code": "jabal_lubnan",
        "name": "Jabal Lubnan"
      },
      {
        "_id": "mohafazat_liban-nord",
        "code": "mohafazat_liban-nord",
        "name": "Mohafazat Liban-Nord"
      },
      {
        "_id": "mohafazat_mont-liban",
        "code": "mohafazat_mont-liban",
        "name": "Mohafazat Mont-Liban"
      },
      {
        "_id": "sidon",
        "code": "sidon",
        "name": "Sidon"
      },
      {
        "_id": "al-biqa",
        "code": "al-biqa",
        "name": "al-Biqa"
      },
      {
        "_id": "al-janub",
        "code": "al-janub",
        "name": "al-Janub"
      },
      {
        "_id": "an-nabatiyah",
        "code": "an-nabatiyah",
        "name": "an-Nabatiyah"
      },
      {
        "_id": "ash-shamal",
        "code": "ash-shamal",
        "name": "ash-Shamal"
      }
    ]
  },
  {
    "_id": "LS",
    "code": "LS",
    "name": "Lesotho",
    "states": [
      {
        "_id": "berea",
        "code": "berea",
        "name": "Berea"
      },
      {
        "_id": "butha-buthe",
        "code": "butha-buthe",
        "name": "Butha-Buthe"
      },
      {
        "_id": "leribe",
        "code": "leribe",
        "name": "Leribe"
      },
      {
        "_id": "mafeteng",
        "code": "mafeteng",
        "name": "Mafeteng"
      },
      {
        "_id": "maseru",
        "code": "maseru",
        "name": "Maseru"
      },
      {
        "_id": "mohales_hoek",
        "code": "mohales_hoek",
        "name": "Mohales Hoek"
      },
      {
        "_id": "mokhotlong",
        "code": "mokhotlong",
        "name": "Mokhotlong"
      },
      {
        "_id": "qachas_nek",
        "code": "qachas_nek",
        "name": "Qachas Nek"
      },
      {
        "_id": "quthing",
        "code": "quthing",
        "name": "Quthing"
      },
      {
        "_id": "thaba-tseka",
        "code": "thaba-tseka",
        "name": "Thaba-Tseka"
      }
    ]
  },
  {
    "_id": "LR",
    "code": "LR",
    "name": "Liberia",
    "states": [
      {
        "_id": "bomi",
        "code": "bomi",
        "name": "Bomi"
      },
      {
        "_id": "bong",
        "code": "bong",
        "name": "Bong"
      },
      {
        "_id": "grand_bassa",
        "code": "grand_bassa",
        "name": "Grand Bassa"
      },
      {
        "_id": "grand_cape_mount",
        "code": "grand_cape_mount",
        "name": "Grand Cape Mount"
      },
      {
        "_id": "grand_gedeh",
        "code": "grand_gedeh",
        "name": "Grand Gedeh"
      },
      {
        "_id": "loffa",
        "code": "loffa",
        "name": "Loffa"
      },
      {
        "_id": "margibi",
        "code": "margibi",
        "name": "Margibi"
      },
      {
        "_id": "maryland_and_grand_kru",
        "code": "maryland_and_grand_kru",
        "name": "Maryland and Grand Kru"
      },
      {
        "_id": "montserrado",
        "code": "montserrado",
        "name": "Montserrado"
      },
      {
        "_id": "nimba",
        "code": "nimba",
        "name": "Nimba"
      },
      {
        "_id": "rivercess",
        "code": "rivercess",
        "name": "Rivercess"
      },
      {
        "_id": "sinoe",
        "code": "sinoe",
        "name": "Sinoe"
      }
    ]
  },
  {
    "_id": "LY",
    "code": "LY",
    "name": "Libya",
    "states": [
      {
        "_id": "ajdabiya",
        "code": "ajdabiya",
        "name": "Ajdabiya"
      },
      {
        "_id": "fezzan",
        "code": "fezzan",
        "name": "Fezzan"
      },
      {
        "_id": "banghazi",
        "code": "banghazi",
        "name": "Banghazi"
      },
      {
        "_id": "darnah",
        "code": "darnah",
        "name": "Darnah"
      },
      {
        "_id": "ghadamis",
        "code": "ghadamis",
        "name": "Ghadamis"
      },
      {
        "_id": "gharyan",
        "code": "gharyan",
        "name": "Gharyan"
      },
      {
        "_id": "misratah",
        "code": "misratah",
        "name": "Misratah"
      },
      {
        "_id": "murzuq",
        "code": "murzuq",
        "name": "Murzuq"
      },
      {
        "_id": "sabha",
        "code": "sabha",
        "name": "Sabha"
      },
      {
        "_id": "sawfajjin",
        "code": "sawfajjin",
        "name": "Sawfajjin"
      },
      {
        "_id": "surt",
        "code": "surt",
        "name": "Surt"
      },
      {
        "_id": "tarabulus",
        "code": "tarabulus",
        "name": "Tarabulus"
      },
      {
        "_id": "tarhunah",
        "code": "tarhunah",
        "name": "Tarhunah"
      },
      {
        "_id": "tripolitania",
        "code": "tripolitania",
        "name": "Tripolitania"
      },
      {
        "_id": "tubruq",
        "code": "tubruq",
        "name": "Tubruq"
      },
      {
        "_id": "yafran",
        "code": "yafran",
        "name": "Yafran"
      },
      {
        "_id": "zlitan",
        "code": "zlitan",
        "name": "Zlitan"
      },
      {
        "_id": "al-aziziyah",
        "code": "al-aziziyah",
        "name": "al-Aziziyah"
      },
      {
        "_id": "al-fatih",
        "code": "al-fatih",
        "name": "al-Fatih"
      },
      {
        "_id": "al-jabal_al_akhdar",
        "code": "al-jabal_al_akhdar",
        "name": "al-Jabal al Akhdar"
      },
      {
        "_id": "al-jufrah",
        "code": "al-jufrah",
        "name": "al-Jufrah"
      },
      {
        "_id": "al-khums",
        "code": "al-khums",
        "name": "al-Khums"
      },
      {
        "_id": "al-kufrah",
        "code": "al-kufrah",
        "name": "al-Kufrah"
      },
      {
        "_id": "an-nuqat_al-khams",
        "code": "an-nuqat_al-khams",
        "name": "an-Nuqat al-Khams"
      },
      {
        "_id": "ash-shati",
        "code": "ash-shati",
        "name": "ash-Shati"
      },
      {
        "_id": "az-zawiyah",
        "code": "az-zawiyah",
        "name": "az-Zawiyah"
      }
    ]
  },
  {
    "_id": "LI",
    "code": "LI",
    "name": "Liechtenstein",
    "states": [
      {
        "_id": "balzers",
        "code": "balzers",
        "name": "Balzers"
      },
      {
        "_id": "eschen",
        "code": "eschen",
        "name": "Eschen"
      },
      {
        "_id": "gamprin",
        "code": "gamprin",
        "name": "Gamprin"
      },
      {
        "_id": "mauren",
        "code": "mauren",
        "name": "Mauren"
      },
      {
        "_id": "planken",
        "code": "planken",
        "name": "Planken"
      },
      {
        "_id": "ruggell",
        "code": "ruggell",
        "name": "Ruggell"
      },
      {
        "_id": "schaan",
        "code": "schaan",
        "name": "Schaan"
      },
      {
        "_id": "schellenberg",
        "code": "schellenberg",
        "name": "Schellenberg"
      },
      {
        "_id": "triesen",
        "code": "triesen",
        "name": "Triesen"
      },
      {
        "_id": "triesenberg",
        "code": "triesenberg",
        "name": "Triesenberg"
      },
      {
        "_id": "vaduz",
        "code": "vaduz",
        "name": "Vaduz"
      }
    ]
  },
  {
    "_id": "LT",
    "code": "LT",
    "name": "Lithuania",
    "states": [
      {
        "_id": "alytaus",
        "code": "alytaus",
        "name": "Alytaus"
      },
      {
        "_id": "anyksciai",
        "code": "anyksciai",
        "name": "Anyksciai"
      },
      {
        "_id": "kauno",
        "code": "kauno",
        "name": "Kauno"
      },
      {
        "_id": "klaipedos",
        "code": "klaipedos",
        "name": "Klaipedos"
      },
      {
        "_id": "marijampoles",
        "code": "marijampoles",
        "name": "Marijampoles"
      },
      {
        "_id": "panevezhio",
        "code": "panevezhio",
        "name": "Panevezhio"
      },
      {
        "_id": "panevezys",
        "code": "panevezys",
        "name": "Panevezys"
      },
      {
        "_id": "shiauliu",
        "code": "shiauliu",
        "name": "Shiauliu"
      },
      {
        "_id": "taurages",
        "code": "taurages",
        "name": "Taurages"
      },
      {
        "_id": "telshiu",
        "code": "telshiu",
        "name": "Telshiu"
      },
      {
        "_id": "telsiai",
        "code": "telsiai",
        "name": "Telsiai"
      },
      {
        "_id": "utenos",
        "code": "utenos",
        "name": "Utenos"
      },
      {
        "_id": "vilniaus",
        "code": "vilniaus",
        "name": "Vilniaus"
      }
    ]
  },
  {
    "_id": "LU",
    "code": "LU",
    "name": "Luxembourg",
    "states": [
      {
        "_id": "capellen",
        "code": "capellen",
        "name": "Capellen"
      },
      {
        "_id": "clervaux",
        "code": "clervaux",
        "name": "Clervaux"
      },
      {
        "_id": "diekirch",
        "code": "diekirch",
        "name": "Diekirch"
      },
      {
        "_id": "echternach",
        "code": "echternach",
        "name": "Echternach"
      },
      {
        "_id": "esch-sur-alzette",
        "code": "esch-sur-alzette",
        "name": "Esch-sur-Alzette"
      },
      {
        "_id": "grevenmacher",
        "code": "grevenmacher",
        "name": "Grevenmacher"
      },
      {
        "_id": "luxembourg",
        "code": "luxembourg",
        "name": "Luxembourg"
      },
      {
        "_id": "mersch",
        "code": "mersch",
        "name": "Mersch"
      },
      {
        "_id": "redange",
        "code": "redange",
        "name": "Redange"
      },
      {
        "_id": "remich",
        "code": "remich",
        "name": "Remich"
      },
      {
        "_id": "vianden",
        "code": "vianden",
        "name": "Vianden"
      },
      {
        "_id": "wiltz",
        "code": "wiltz",
        "name": "Wiltz"
      }
    ]
  },
  {
    "_id": "MO",
    "code": "MO",
    "name": "Macau S.A.R.",
    "states": [
      {
        "_id": "macau",
        "code": "macau",
        "name": "Macau"
      }
    ]
  },
  {
    "_id": "MK",
    "code": "MK",
    "name": "Macedonia",
    "states": [
      {
        "_id": "berovo",
        "code": "berovo",
        "name": "Berovo"
      },
      {
        "_id": "bitola",
        "code": "bitola",
        "name": "Bitola"
      },
      {
        "_id": "brod",
        "code": "brod",
        "name": "Brod"
      },
      {
        "_id": "debar",
        "code": "debar",
        "name": "Debar"
      },
      {
        "_id": "delchevo",
        "code": "delchevo",
        "name": "Delchevo"
      },
      {
        "_id": "demir_hisar",
        "code": "demir_hisar",
        "name": "Demir Hisar"
      },
      {
        "_id": "gevgelija",
        "code": "gevgelija",
        "name": "Gevgelija"
      },
      {
        "_id": "gostivar",
        "code": "gostivar",
        "name": "Gostivar"
      },
      {
        "_id": "kavadarci",
        "code": "kavadarci",
        "name": "Kavadarci"
      },
      {
        "_id": "kichevo",
        "code": "kichevo",
        "name": "Kichevo"
      },
      {
        "_id": "kochani",
        "code": "kochani",
        "name": "Kochani"
      },
      {
        "_id": "kratovo",
        "code": "kratovo",
        "name": "Kratovo"
      },
      {
        "_id": "kriva_palanka",
        "code": "kriva_palanka",
        "name": "Kriva Palanka"
      },
      {
        "_id": "krushevo",
        "code": "krushevo",
        "name": "Krushevo"
      },
      {
        "_id": "kumanovo",
        "code": "kumanovo",
        "name": "Kumanovo"
      },
      {
        "_id": "negotino",
        "code": "negotino",
        "name": "Negotino"
      },
      {
        "_id": "ohrid",
        "code": "ohrid",
        "name": "Ohrid"
      },
      {
        "_id": "prilep",
        "code": "prilep",
        "name": "Prilep"
      },
      {
        "_id": "probishtip",
        "code": "probishtip",
        "name": "Probishtip"
      },
      {
        "_id": "radovish",
        "code": "radovish",
        "name": "Radovish"
      },
      {
        "_id": "resen",
        "code": "resen",
        "name": "Resen"
      },
      {
        "_id": "shtip",
        "code": "shtip",
        "name": "Shtip"
      },
      {
        "_id": "skopje",
        "code": "skopje",
        "name": "Skopje"
      },
      {
        "_id": "struga",
        "code": "struga",
        "name": "Struga"
      },
      {
        "_id": "strumica",
        "code": "strumica",
        "name": "Strumica"
      },
      {
        "_id": "sveti_nikole",
        "code": "sveti_nikole",
        "name": "Sveti Nikole"
      },
      {
        "_id": "tetovo",
        "code": "tetovo",
        "name": "Tetovo"
      },
      {
        "_id": "valandovo",
        "code": "valandovo",
        "name": "Valandovo"
      },
      {
        "_id": "veles",
        "code": "veles",
        "name": "Veles"
      },
      {
        "_id": "vinica",
        "code": "vinica",
        "name": "Vinica"
      }
    ]
  },
  {
    "_id": "MG",
    "code": "MG",
    "name": "Madagascar",
    "states": [
      {
        "_id": "antananarivo",
        "code": "antananarivo",
        "name": "Antananarivo"
      },
      {
        "_id": "antsiranana",
        "code": "antsiranana",
        "name": "Antsiranana"
      },
      {
        "_id": "fianarantsoa",
        "code": "fianarantsoa",
        "name": "Fianarantsoa"
      },
      {
        "_id": "mahajanga",
        "code": "mahajanga",
        "name": "Mahajanga"
      },
      {
        "_id": "toamasina",
        "code": "toamasina",
        "name": "Toamasina"
      },
      {
        "_id": "toliary",
        "code": "toliary",
        "name": "Toliary"
      }
    ]
  },
  {
    "_id": "MW",
    "code": "MW",
    "name": "Malawi",
    "states": [
      {
        "_id": "balaka",
        "code": "balaka",
        "name": "Balaka"
      },
      {
        "_id": "blantyre_city",
        "code": "blantyre_city",
        "name": "Blantyre City"
      },
      {
        "_id": "chikwawa",
        "code": "chikwawa",
        "name": "Chikwawa"
      },
      {
        "_id": "chiradzulu",
        "code": "chiradzulu",
        "name": "Chiradzulu"
      },
      {
        "_id": "chitipa",
        "code": "chitipa",
        "name": "Chitipa"
      },
      {
        "_id": "dedza",
        "code": "dedza",
        "name": "Dedza"
      },
      {
        "_id": "dowa",
        "code": "dowa",
        "name": "Dowa"
      },
      {
        "_id": "karonga",
        "code": "karonga",
        "name": "Karonga"
      },
      {
        "_id": "kasungu",
        "code": "kasungu",
        "name": "Kasungu"
      },
      {
        "_id": "lilongwe_city",
        "code": "lilongwe_city",
        "name": "Lilongwe City"
      },
      {
        "_id": "machinga",
        "code": "machinga",
        "name": "Machinga"
      },
      {
        "_id": "mangochi",
        "code": "mangochi",
        "name": "Mangochi"
      },
      {
        "_id": "mchinji",
        "code": "mchinji",
        "name": "Mchinji"
      },
      {
        "_id": "mulanje",
        "code": "mulanje",
        "name": "Mulanje"
      },
      {
        "_id": "mwanza",
        "code": "mwanza",
        "name": "Mwanza"
      },
      {
        "_id": "mzimba",
        "code": "mzimba",
        "name": "Mzimba"
      },
      {
        "_id": "mzuzu_city",
        "code": "mzuzu_city",
        "name": "Mzuzu City"
      },
      {
        "_id": "nkhata_bay",
        "code": "nkhata_bay",
        "name": "Nkhata Bay"
      },
      {
        "_id": "nkhotakota",
        "code": "nkhotakota",
        "name": "Nkhotakota"
      },
      {
        "_id": "nsanje",
        "code": "nsanje",
        "name": "Nsanje"
      },
      {
        "_id": "ntcheu",
        "code": "ntcheu",
        "name": "Ntcheu"
      },
      {
        "_id": "ntchisi",
        "code": "ntchisi",
        "name": "Ntchisi"
      },
      {
        "_id": "phalombe",
        "code": "phalombe",
        "name": "Phalombe"
      },
      {
        "_id": "rumphi",
        "code": "rumphi",
        "name": "Rumphi"
      },
      {
        "_id": "salima",
        "code": "salima",
        "name": "Salima"
      },
      {
        "_id": "thyolo",
        "code": "thyolo",
        "name": "Thyolo"
      },
      {
        "_id": "zomba_municipality",
        "code": "zomba_municipality",
        "name": "Zomba Municipality"
      }
    ]
  },
  {
    "_id": "MY",
    "code": "MY",
    "name": "Malaysia",
    "states": [
      {
        "_id": "johor",
        "code": "johor",
        "name": "Johor"
      },
      {
        "_id": "kedah",
        "code": "kedah",
        "name": "Kedah"
      },
      {
        "_id": "kelantan",
        "code": "kelantan",
        "name": "Kelantan"
      },
      {
        "_id": "kuala_lumpur",
        "code": "kuala_lumpur",
        "name": "Kuala Lumpur"
      },
      {
        "_id": "labuan",
        "code": "labuan",
        "name": "Labuan"
      },
      {
        "_id": "melaka",
        "code": "melaka",
        "name": "Melaka"
      },
      {
        "_id": "negeri_johor",
        "code": "negeri_johor",
        "name": "Negeri Johor"
      },
      {
        "_id": "negeri_sembilan",
        "code": "negeri_sembilan",
        "name": "Negeri Sembilan"
      },
      {
        "_id": "pahang",
        "code": "pahang",
        "name": "Pahang"
      },
      {
        "_id": "penang",
        "code": "penang",
        "name": "Penang"
      },
      {
        "_id": "perak",
        "code": "perak",
        "name": "Perak"
      },
      {
        "_id": "perlis",
        "code": "perlis",
        "name": "Perlis"
      },
      {
        "_id": "pulau_pinang",
        "code": "pulau_pinang",
        "name": "Pulau Pinang"
      },
      {
        "_id": "sabah",
        "code": "sabah",
        "name": "Sabah"
      },
      {
        "_id": "sarawak",
        "code": "sarawak",
        "name": "Sarawak"
      },
      {
        "_id": "selangor",
        "code": "selangor",
        "name": "Selangor"
      },
      {
        "_id": "sembilan",
        "code": "sembilan",
        "name": "Sembilan"
      },
      {
        "_id": "terengganu",
        "code": "terengganu",
        "name": "Terengganu"
      }
    ]
  },
  {
    "_id": "MV",
    "code": "MV",
    "name": "Maldives",
    "states": [
      {
        "_id": "alif_alif",
        "code": "alif_alif",
        "name": "Alif Alif"
      },
      {
        "_id": "alif_dhaal",
        "code": "alif_dhaal",
        "name": "Alif Dhaal"
      },
      {
        "_id": "baa",
        "code": "baa",
        "name": "Baa"
      },
      {
        "_id": "dhaal",
        "code": "dhaal",
        "name": "Dhaal"
      },
      {
        "_id": "faaf",
        "code": "faaf",
        "name": "Faaf"
      },
      {
        "_id": "gaaf_alif",
        "code": "gaaf_alif",
        "name": "Gaaf Alif"
      },
      {
        "_id": "gaaf_dhaal",
        "code": "gaaf_dhaal",
        "name": "Gaaf Dhaal"
      },
      {
        "_id": "ghaviyani",
        "code": "ghaviyani",
        "name": "Ghaviyani"
      },
      {
        "_id": "haa_alif",
        "code": "haa_alif",
        "name": "Haa Alif"
      },
      {
        "_id": "haa_dhaal",
        "code": "haa_dhaal",
        "name": "Haa Dhaal"
      },
      {
        "_id": "kaaf",
        "code": "kaaf",
        "name": "Kaaf"
      },
      {
        "_id": "laam",
        "code": "laam",
        "name": "Laam"
      },
      {
        "_id": "lhaviyani",
        "code": "lhaviyani",
        "name": "Lhaviyani"
      },
      {
        "_id": "male",
        "code": "male",
        "name": "Male"
      },
      {
        "_id": "miim",
        "code": "miim",
        "name": "Miim"
      },
      {
        "_id": "nuun",
        "code": "nuun",
        "name": "Nuun"
      },
      {
        "_id": "raa",
        "code": "raa",
        "name": "Raa"
      },
      {
        "_id": "shaviyani",
        "code": "shaviyani",
        "name": "Shaviyani"
      },
      {
        "_id": "siin",
        "code": "siin",
        "name": "Siin"
      },
      {
        "_id": "thaa",
        "code": "thaa",
        "name": "Thaa"
      },
      {
        "_id": "vaav",
        "code": "vaav",
        "name": "Vaav"
      }
    ]
  },
  {
    "_id": "ML",
    "code": "ML",
    "name": "Mali",
    "states": [
      {
        "_id": "bamako",
        "code": "bamako",
        "name": "Bamako"
      },
      {
        "_id": "gao",
        "code": "gao",
        "name": "Gao"
      },
      {
        "_id": "kayes",
        "code": "kayes",
        "name": "Kayes"
      },
      {
        "_id": "kidal",
        "code": "kidal",
        "name": "Kidal"
      },
      {
        "_id": "koulikoro",
        "code": "koulikoro",
        "name": "Koulikoro"
      },
      {
        "_id": "mopti",
        "code": "mopti",
        "name": "Mopti"
      },
      {
        "_id": "segou",
        "code": "segou",
        "name": "Segou"
      },
      {
        "_id": "sikasso",
        "code": "sikasso",
        "name": "Sikasso"
      },
      {
        "_id": "tombouctou",
        "code": "tombouctou",
        "name": "Tombouctou"
      }
    ]
  },
  {
    "_id": "MT",
    "code": "MT",
    "name": "Malta",
    "states": [
      {
        "_id": "gozo_and_comino",
        "code": "gozo_and_comino",
        "name": "Gozo and Comino"
      },
      {
        "_id": "inner_harbour",
        "code": "inner_harbour",
        "name": "Inner Harbour"
      },
      {
        "_id": "northern",
        "code": "northern",
        "name": "Northern"
      },
      {
        "_id": "outer_harbour",
        "code": "outer_harbour",
        "name": "Outer Harbour"
      },
      {
        "_id": "south_eastern",
        "code": "south_eastern",
        "name": "South Eastern"
      },
      {
        "_id": "valletta",
        "code": "valletta",
        "name": "Valletta"
      },
      {
        "_id": "western",
        "code": "western",
        "name": "Western"
      }
    ]
  },
  {
    "_id": "XM",
    "code": "XM",
    "name": "Man Isle of",
    "states": [
      {
        "_id": "castletown",
        "code": "castletown",
        "name": "Castletown"
      },
      {
        "_id": "douglas",
        "code": "douglas",
        "name": "Douglas"
      },
      {
        "_id": "laxey",
        "code": "laxey",
        "name": "Laxey"
      },
      {
        "_id": "onchan",
        "code": "onchan",
        "name": "Onchan"
      },
      {
        "_id": "peel",
        "code": "peel",
        "name": "Peel"
      },
      {
        "_id": "port_erin",
        "code": "port_erin",
        "name": "Port Erin"
      },
      {
        "_id": "port_saint_mary",
        "code": "port_saint_mary",
        "name": "Port Saint Mary"
      },
      {
        "_id": "ramsey",
        "code": "ramsey",
        "name": "Ramsey"
      }
    ]
  },
  {
    "_id": "MH",
    "code": "MH",
    "name": "Marshall Islands",
    "states": [
      {
        "_id": "ailinlaplap",
        "code": "ailinlaplap",
        "name": "Ailinlaplap"
      },
      {
        "_id": "ailuk",
        "code": "ailuk",
        "name": "Ailuk"
      },
      {
        "_id": "arno",
        "code": "arno",
        "name": "Arno"
      },
      {
        "_id": "aur",
        "code": "aur",
        "name": "Aur"
      },
      {
        "_id": "bikini",
        "code": "bikini",
        "name": "Bikini"
      },
      {
        "_id": "ebon",
        "code": "ebon",
        "name": "Ebon"
      },
      {
        "_id": "enewetak",
        "code": "enewetak",
        "name": "Enewetak"
      },
      {
        "_id": "jabat",
        "code": "jabat",
        "name": "Jabat"
      },
      {
        "_id": "jaluit",
        "code": "jaluit",
        "name": "Jaluit"
      },
      {
        "_id": "kili",
        "code": "kili",
        "name": "Kili"
      },
      {
        "_id": "kwajalein",
        "code": "kwajalein",
        "name": "Kwajalein"
      },
      {
        "_id": "lae",
        "code": "lae",
        "name": "Lae"
      },
      {
        "_id": "lib",
        "code": "lib",
        "name": "Lib"
      },
      {
        "_id": "likiep",
        "code": "likiep",
        "name": "Likiep"
      },
      {
        "_id": "majuro",
        "code": "majuro",
        "name": "Majuro"
      },
      {
        "_id": "maloelap",
        "code": "maloelap",
        "name": "Maloelap"
      },
      {
        "_id": "mejit",
        "code": "mejit",
        "name": "Mejit"
      },
      {
        "_id": "mili",
        "code": "mili",
        "name": "Mili"
      },
      {
        "_id": "namorik",
        "code": "namorik",
        "name": "Namorik"
      },
      {
        "_id": "namu",
        "code": "namu",
        "name": "Namu"
      },
      {
        "_id": "rongelap",
        "code": "rongelap",
        "name": "Rongelap"
      },
      {
        "_id": "ujae",
        "code": "ujae",
        "name": "Ujae"
      },
      {
        "_id": "utrik",
        "code": "utrik",
        "name": "Utrik"
      },
      {
        "_id": "wotho",
        "code": "wotho",
        "name": "Wotho"
      },
      {
        "_id": "wotje",
        "code": "wotje",
        "name": "Wotje"
      }
    ]
  },
  {
    "_id": "MQ",
    "code": "MQ",
    "name": "Martinique",
    "states": [
      {
        "_id": "fort-de-france",
        "code": "fort-de-france",
        "name": "Fort-de-France"
      },
      {
        "_id": "la_trinite",
        "code": "la_trinite",
        "name": "La Trinite"
      },
      {
        "_id": "le_marin",
        "code": "le_marin",
        "name": "Le Marin"
      },
      {
        "_id": "saint-pierre",
        "code": "saint-pierre",
        "name": "Saint-Pierre"
      }
    ]
  },
  {
    "_id": "MR",
    "code": "MR",
    "name": "Mauritania",
    "states": [
      {
        "_id": "adrar",
        "code": "adrar",
        "name": "Adrar"
      },
      {
        "_id": "assaba",
        "code": "assaba",
        "name": "Assaba"
      },
      {
        "_id": "brakna",
        "code": "brakna",
        "name": "Brakna"
      },
      {
        "_id": "dhakhlat_nawadibu",
        "code": "dhakhlat_nawadibu",
        "name": "Dhakhlat Nawadibu"
      },
      {
        "_id": "hudh-al-gharbi",
        "code": "hudh-al-gharbi",
        "name": "Hudh-al-Gharbi"
      },
      {
        "_id": "hudh-ash-sharqi",
        "code": "hudh-ash-sharqi",
        "name": "Hudh-ash-Sharqi"
      },
      {
        "_id": "inshiri",
        "code": "inshiri",
        "name": "Inshiri"
      },
      {
        "_id": "nawakshut",
        "code": "nawakshut",
        "name": "Nawakshut"
      },
      {
        "_id": "qidimagha",
        "code": "qidimagha",
        "name": "Qidimagha"
      },
      {
        "_id": "qurqul",
        "code": "qurqul",
        "name": "Qurqul"
      },
      {
        "_id": "taqant",
        "code": "taqant",
        "name": "Taqant"
      },
      {
        "_id": "tiris_zammur",
        "code": "tiris_zammur",
        "name": "Tiris Zammur"
      },
      {
        "_id": "trarza",
        "code": "trarza",
        "name": "Trarza"
      }
    ]
  },
  {
    "_id": "MU",
    "code": "MU",
    "name": "Mauritius",
    "states": [
      {
        "_id": "black_river",
        "code": "black_river",
        "name": "Black River"
      },
      {
        "_id": "eau_coulee",
        "code": "eau_coulee",
        "name": "Eau Coulee"
      },
      {
        "_id": "flacq",
        "code": "flacq",
        "name": "Flacq"
      },
      {
        "_id": "floreal",
        "code": "floreal",
        "name": "Floreal"
      },
      {
        "_id": "grand_port",
        "code": "grand_port",
        "name": "Grand Port"
      },
      {
        "_id": "moka",
        "code": "moka",
        "name": "Moka"
      },
      {
        "_id": "pamplempousses",
        "code": "pamplempousses",
        "name": "Pamplempousses"
      },
      {
        "_id": "plaines_wilhelm",
        "code": "plaines_wilhelm",
        "name": "Plaines Wilhelm"
      },
      {
        "_id": "port_louis",
        "code": "port_louis",
        "name": "Port Louis"
      },
      {
        "_id": "riviere_du_rempart",
        "code": "riviere_du_rempart",
        "name": "Riviere du Rempart"
      },
      {
        "_id": "rodrigues",
        "code": "rodrigues",
        "name": "Rodrigues"
      },
      {
        "_id": "rose_hill",
        "code": "rose_hill",
        "name": "Rose Hill"
      },
      {
        "_id": "savanne",
        "code": "savanne",
        "name": "Savanne"
      }
    ]
  },
  {
    "_id": "YT",
    "code": "YT",
    "name": "Mayotte",
    "states": [
      {
        "_id": "mayotte",
        "code": "mayotte",
        "name": "Mayotte"
      },
      {
        "_id": "pamanzi",
        "code": "pamanzi",
        "name": "Pamanzi"
      }
    ]
  },
  {
    "_id": "MX",
    "code": "MX",
    "name": "Mexico",
    "states": [
      {
        "_id": "aguascalientes",
        "code": "aguascalientes",
        "name": "Aguascalientes"
      },
      {
        "_id": "baja_california",
        "code": "baja_california",
        "name": "Baja California"
      },
      {
        "_id": "baja_california_sur",
        "code": "baja_california_sur",
        "name": "Baja California Sur"
      },
      {
        "_id": "campeche",
        "code": "campeche",
        "name": "Campeche"
      },
      {
        "_id": "chiapas",
        "code": "chiapas",
        "name": "Chiapas"
      },
      {
        "_id": "chihuahua",
        "code": "chihuahua",
        "name": "Chihuahua"
      },
      {
        "_id": "coahuila",
        "code": "coahuila",
        "name": "Coahuila"
      },
      {
        "_id": "colima",
        "code": "colima",
        "name": "Colima"
      },
      {
        "_id": "distrito_federal",
        "code": "distrito_federal",
        "name": "Distrito Federal"
      },
      {
        "_id": "durango",
        "code": "durango",
        "name": "Durango"
      },
      {
        "_id": "estado_de_mexico",
        "code": "estado_de_mexico",
        "name": "Estado de Mexico"
      },
      {
        "_id": "guanajuato",
        "code": "guanajuato",
        "name": "Guanajuato"
      },
      {
        "_id": "guerrero",
        "code": "guerrero",
        "name": "Guerrero"
      },
      {
        "_id": "hidalgo",
        "code": "hidalgo",
        "name": "Hidalgo"
      },
      {
        "_id": "jalisco",
        "code": "jalisco",
        "name": "Jalisco"
      },
      {
        "_id": "mexico",
        "code": "mexico",
        "name": "Mexico"
      },
      {
        "_id": "michoacan",
        "code": "michoacan",
        "name": "Michoacan"
      },
      {
        "_id": "morelos",
        "code": "morelos",
        "name": "Morelos"
      },
      {
        "_id": "nayarit",
        "code": "nayarit",
        "name": "Nayarit"
      },
      {
        "_id": "nuevo_leon",
        "code": "nuevo_leon",
        "name": "Nuevo Leon"
      },
      {
        "_id": "oaxaca",
        "code": "oaxaca",
        "name": "Oaxaca"
      },
      {
        "_id": "puebla",
        "code": "puebla",
        "name": "Puebla"
      },
      {
        "_id": "queretaro",
        "code": "queretaro",
        "name": "Queretaro"
      },
      {
        "_id": "quintana_roo",
        "code": "quintana_roo",
        "name": "Quintana Roo"
      },
      {
        "_id": "san_luis_potosi",
        "code": "san_luis_potosi",
        "name": "San Luis Potosi"
      },
      {
        "_id": "sinaloa",
        "code": "sinaloa",
        "name": "Sinaloa"
      },
      {
        "_id": "sonora",
        "code": "sonora",
        "name": "Sonora"
      },
      {
        "_id": "tabasco",
        "code": "tabasco",
        "name": "Tabasco"
      },
      {
        "_id": "tamaulipas",
        "code": "tamaulipas",
        "name": "Tamaulipas"
      },
      {
        "_id": "tlaxcala",
        "code": "tlaxcala",
        "name": "Tlaxcala"
      },
      {
        "_id": "veracruz",
        "code": "veracruz",
        "name": "Veracruz"
      },
      {
        "_id": "yucatan",
        "code": "yucatan",
        "name": "Yucatan"
      },
      {
        "_id": "zacatecas",
        "code": "zacatecas",
        "name": "Zacatecas"
      }
    ]
  },
  {
    "_id": "FM",
    "code": "FM",
    "name": "Micronesia",
    "states": [
      {
        "_id": "chuuk",
        "code": "chuuk",
        "name": "Chuuk"
      },
      {
        "_id": "kusaie",
        "code": "kusaie",
        "name": "Kusaie"
      },
      {
        "_id": "pohnpei",
        "code": "pohnpei",
        "name": "Pohnpei"
      },
      {
        "_id": "yap",
        "code": "yap",
        "name": "Yap"
      }
    ]
  },
  {
    "_id": "MD",
    "code": "MD",
    "name": "Moldova",
    "states": [
      {
        "_id": "balti",
        "code": "balti",
        "name": "Balti"
      },
      {
        "_id": "cahul",
        "code": "cahul",
        "name": "Cahul"
      },
      {
        "_id": "chisinau",
        "code": "chisinau",
        "name": "Chisinau"
      },
      {
        "_id": "chisinau_oras",
        "code": "chisinau_oras",
        "name": "Chisinau Oras"
      },
      {
        "_id": "edinet",
        "code": "edinet",
        "name": "Edinet"
      },
      {
        "_id": "gagauzia",
        "code": "gagauzia",
        "name": "Gagauzia"
      },
      {
        "_id": "lapusna",
        "code": "lapusna",
        "name": "Lapusna"
      },
      {
        "_id": "orhei",
        "code": "orhei",
        "name": "Orhei"
      },
      {
        "_id": "soroca",
        "code": "soroca",
        "name": "Soroca"
      },
      {
        "_id": "taraclia",
        "code": "taraclia",
        "name": "Taraclia"
      },
      {
        "_id": "tighina",
        "code": "tighina",
        "name": "Tighina"
      },
      {
        "_id": "transnistria",
        "code": "transnistria",
        "name": "Transnistria"
      },
      {
        "_id": "ungheni",
        "code": "ungheni",
        "name": "Ungheni"
      }
    ]
  },
  {
    "_id": "MC",
    "code": "MC",
    "name": "Monaco",
    "states": [
      {
        "_id": "fontvieille",
        "code": "fontvieille",
        "name": "Fontvieille"
      },
      {
        "_id": "la_condamine",
        "code": "la_condamine",
        "name": "La Condamine"
      },
      {
        "_id": "monaco-ville",
        "code": "monaco-ville",
        "name": "Monaco-Ville"
      },
      {
        "_id": "monte_carlo",
        "code": "monte_carlo",
        "name": "Monte Carlo"
      }
    ]
  },
  {
    "_id": "MN",
    "code": "MN",
    "name": "Mongolia",
    "states": [
      {
        "_id": "arhangaj",
        "code": "arhangaj",
        "name": "Arhangaj"
      },
      {
        "_id": "bajan-olgij",
        "code": "bajan-olgij",
        "name": "Bajan-Olgij"
      },
      {
        "_id": "bajanhongor",
        "code": "bajanhongor",
        "name": "Bajanhongor"
      },
      {
        "_id": "bulgan",
        "code": "bulgan",
        "name": "Bulgan"
      },
      {
        "_id": "darhan-uul",
        "code": "darhan-uul",
        "name": "Darhan-Uul"
      },
      {
        "_id": "dornod",
        "code": "dornod",
        "name": "Dornod"
      },
      {
        "_id": "dornogovi",
        "code": "dornogovi",
        "name": "Dornogovi"
      },
      {
        "_id": "dundgovi",
        "code": "dundgovi",
        "name": "Dundgovi"
      },
      {
        "_id": "govi-altaj",
        "code": "govi-altaj",
        "name": "Govi-Altaj"
      },
      {
        "_id": "govisumber",
        "code": "govisumber",
        "name": "Govisumber"
      },
      {
        "_id": "hentij",
        "code": "hentij",
        "name": "Hentij"
      },
      {
        "_id": "hovd",
        "code": "hovd",
        "name": "Hovd"
      },
      {
        "_id": "hovsgol",
        "code": "hovsgol",
        "name": "Hovsgol"
      },
      {
        "_id": "omnogovi",
        "code": "omnogovi",
        "name": "Omnogovi"
      },
      {
        "_id": "orhon",
        "code": "orhon",
        "name": "Orhon"
      },
      {
        "_id": "ovorhangaj",
        "code": "ovorhangaj",
        "name": "Ovorhangaj"
      },
      {
        "_id": "selenge",
        "code": "selenge",
        "name": "Selenge"
      },
      {
        "_id": "suhbaatar",
        "code": "suhbaatar",
        "name": "Suhbaatar"
      },
      {
        "_id": "tov",
        "code": "tov",
        "name": "Tov"
      },
      {
        "_id": "ulaanbaatar",
        "code": "ulaanbaatar",
        "name": "Ulaanbaatar"
      },
      {
        "_id": "uvs",
        "code": "uvs",
        "name": "Uvs"
      },
      {
        "_id": "zavhan",
        "code": "zavhan",
        "name": "Zavhan"
      }
    ]
  },
  {
    "_id": "MS",
    "code": "MS",
    "name": "Montserrat",
    "states": [
      {
        "_id": "montserrat",
        "code": "montserrat",
        "name": "Montserrat"
      }
    ]
  },
  {
    "_id": "MA",
    "code": "MA",
    "name": "Morocco",
    "states": [
      {
        "_id": "agadir",
        "code": "agadir",
        "name": "Agadir"
      },
      {
        "_id": "casablanca",
        "code": "casablanca",
        "name": "Casablanca"
      },
      {
        "_id": "chaouia-ouardigha",
        "code": "chaouia-ouardigha",
        "name": "Chaouia-Ouardigha"
      },
      {
        "_id": "doukkala-abda",
        "code": "doukkala-abda",
        "name": "Doukkala-Abda"
      },
      {
        "_id": "fes-boulemane",
        "code": "fes-boulemane",
        "name": "Fes-Boulemane"
      },
      {
        "_id": "gharb-chrarda-beni_hssen",
        "code": "gharb-chrarda-beni_hssen",
        "name": "Gharb-Chrarda-Beni Hssen"
      },
      {
        "_id": "guelmim",
        "code": "guelmim",
        "name": "Guelmim"
      },
      {
        "_id": "kenitra",
        "code": "kenitra",
        "name": "Kenitra"
      },
      {
        "_id": "marrakech-tensift-al_haouz",
        "code": "marrakech-tensift-al_haouz",
        "name": "Marrakech-Tensift-Al Haouz"
      },
      {
        "_id": "meknes-tafilalet",
        "code": "meknes-tafilalet",
        "name": "Meknes-Tafilalet"
      },
      {
        "_id": "oriental",
        "code": "oriental",
        "name": "Oriental"
      },
      {
        "_id": "oujda",
        "code": "oujda",
        "name": "Oujda"
      },
      {
        "_id": "province_de_tanger",
        "code": "province_de_tanger",
        "name": "Province de Tanger"
      },
      {
        "_id": "rabat-sale-zammour-zaer",
        "code": "rabat-sale-zammour-zaer",
        "name": "Rabat-Sale-Zammour-Zaer"
      },
      {
        "_id": "sala_al_jadida",
        "code": "sala_al_jadida",
        "name": "Sala Al Jadida"
      },
      {
        "_id": "settat",
        "code": "settat",
        "name": "Settat"
      },
      {
        "_id": "souss_massa-draa",
        "code": "souss_massa-draa",
        "name": "Souss Massa-Draa"
      },
      {
        "_id": "tadla-azilal",
        "code": "tadla-azilal",
        "name": "Tadla-Azilal"
      },
      {
        "_id": "tangier-tetouan",
        "code": "tangier-tetouan",
        "name": "Tangier-Tetouan"
      },
      {
        "_id": "taza-al_hoceima-taounate",
        "code": "taza-al_hoceima-taounate",
        "name": "Taza-Al Hoceima-Taounate"
      },
      {
        "_id": "wilaya_de_casablanca",
        "code": "wilaya_de_casablanca",
        "name": "Wilaya de Casablanca"
      },
      {
        "_id": "wilaya_de_rabat-sale",
        "code": "wilaya_de_rabat-sale",
        "name": "Wilaya de Rabat-Sale"
      }
    ]
  },
  {
    "_id": "MZ",
    "code": "MZ",
    "name": "Mozambique",
    "states": [
      {
        "_id": "cabo_delgado",
        "code": "cabo_delgado",
        "name": "Cabo Delgado"
      },
      {
        "_id": "gaza",
        "code": "gaza",
        "name": "Gaza"
      },
      {
        "_id": "inhambane",
        "code": "inhambane",
        "name": "Inhambane"
      },
      {
        "_id": "manica",
        "code": "manica",
        "name": "Manica"
      },
      {
        "_id": "maputo",
        "code": "maputo",
        "name": "Maputo"
      },
      {
        "_id": "maputo_provincia",
        "code": "maputo_provincia",
        "name": "Maputo Provincia"
      },
      {
        "_id": "nampula",
        "code": "nampula",
        "name": "Nampula"
      },
      {
        "_id": "niassa",
        "code": "niassa",
        "name": "Niassa"
      },
      {
        "_id": "sofala",
        "code": "sofala",
        "name": "Sofala"
      },
      {
        "_id": "tete",
        "code": "tete",
        "name": "Tete"
      },
      {
        "_id": "zambezia",
        "code": "zambezia",
        "name": "Zambezia"
      }
    ]
  },
  {
    "_id": "MM",
    "code": "MM",
    "name": "Myanmar",
    "states": [
      {
        "_id": "ayeyarwady",
        "code": "ayeyarwady",
        "name": "Ayeyarwady"
      },
      {
        "_id": "bago",
        "code": "bago",
        "name": "Bago"
      },
      {
        "_id": "chin",
        "code": "chin",
        "name": "Chin"
      },
      {
        "_id": "kachin",
        "code": "kachin",
        "name": "Kachin"
      },
      {
        "_id": "kayah",
        "code": "kayah",
        "name": "Kayah"
      },
      {
        "_id": "kayin",
        "code": "kayin",
        "name": "Kayin"
      },
      {
        "_id": "magway",
        "code": "magway",
        "name": "Magway"
      },
      {
        "_id": "mandalay",
        "code": "mandalay",
        "name": "Mandalay"
      },
      {
        "_id": "mon",
        "code": "mon",
        "name": "Mon"
      },
      {
        "_id": "nay_pyi_taw",
        "code": "nay_pyi_taw",
        "name": "Nay Pyi Taw"
      },
      {
        "_id": "rakhine",
        "code": "rakhine",
        "name": "Rakhine"
      },
      {
        "_id": "sagaing",
        "code": "sagaing",
        "name": "Sagaing"
      },
      {
        "_id": "shan",
        "code": "shan",
        "name": "Shan"
      },
      {
        "_id": "tanintharyi",
        "code": "tanintharyi",
        "name": "Tanintharyi"
      },
      {
        "_id": "yangon",
        "code": "yangon",
        "name": "Yangon"
      }
    ]
  },
  {
    "_id": "NA",
    "code": "NA",
    "name": "Namibia",
    "states": [
      {
        "_id": "caprivi",
        "code": "caprivi",
        "name": "Caprivi"
      },
      {
        "_id": "erongo",
        "code": "erongo",
        "name": "Erongo"
      },
      {
        "_id": "hardap",
        "code": "hardap",
        "name": "Hardap"
      },
      {
        "_id": "karas",
        "code": "karas",
        "name": "Karas"
      },
      {
        "_id": "kavango",
        "code": "kavango",
        "name": "Kavango"
      },
      {
        "_id": "khomas",
        "code": "khomas",
        "name": "Khomas"
      },
      {
        "_id": "kunene",
        "code": "kunene",
        "name": "Kunene"
      },
      {
        "_id": "ohangwena",
        "code": "ohangwena",
        "name": "Ohangwena"
      },
      {
        "_id": "omaheke",
        "code": "omaheke",
        "name": "Omaheke"
      },
      {
        "_id": "omusati",
        "code": "omusati",
        "name": "Omusati"
      },
      {
        "_id": "oshana",
        "code": "oshana",
        "name": "Oshana"
      },
      {
        "_id": "oshikoto",
        "code": "oshikoto",
        "name": "Oshikoto"
      },
      {
        "_id": "otjozondjupa",
        "code": "otjozondjupa",
        "name": "Otjozondjupa"
      }
    ]
  },
  {
    "_id": "NR",
    "code": "NR",
    "name": "Nauru",
    "states": [
      {
        "_id": "yaren",
        "code": "yaren",
        "name": "Yaren"
      }
    ]
  },
  {
    "_id": "NP",
    "code": "NP",
    "name": "Nepal",
    "states": [
      {
        "_id": "bagmati",
        "code": "bagmati",
        "name": "Bagmati"
      },
      {
        "_id": "bheri",
        "code": "bheri",
        "name": "Bheri"
      },
      {
        "_id": "dhawalagiri",
        "code": "dhawalagiri",
        "name": "Dhawalagiri"
      },
      {
        "_id": "gandaki",
        "code": "gandaki",
        "name": "Gandaki"
      },
      {
        "_id": "janakpur",
        "code": "janakpur",
        "name": "Janakpur"
      },
      {
        "_id": "karnali",
        "code": "karnali",
        "name": "Karnali"
      },
      {
        "_id": "koshi",
        "code": "koshi",
        "name": "Koshi"
      },
      {
        "_id": "lumbini",
        "code": "lumbini",
        "name": "Lumbini"
      },
      {
        "_id": "mahakali",
        "code": "mahakali",
        "name": "Mahakali"
      },
      {
        "_id": "mechi",
        "code": "mechi",
        "name": "Mechi"
      },
      {
        "_id": "narayani",
        "code": "narayani",
        "name": "Narayani"
      },
      {
        "_id": "rapti",
        "code": "rapti",
        "name": "Rapti"
      },
      {
        "_id": "sagarmatha",
        "code": "sagarmatha",
        "name": "Sagarmatha"
      },
      {
        "_id": "seti",
        "code": "seti",
        "name": "Seti"
      }
    ]
  },
  {
    "_id": "AN",
    "code": "AN",
    "name": "Netherlands Antilles",
    "states": [
      {
        "_id": "bonaire",
        "code": "bonaire",
        "name": "Bonaire"
      },
      {
        "_id": "curacao",
        "code": "curacao",
        "name": "Curacao"
      },
      {
        "_id": "saba",
        "code": "saba",
        "name": "Saba"
      },
      {
        "_id": "sint_eustatius",
        "code": "sint_eustatius",
        "name": "Sint Eustatius"
      },
      {
        "_id": "sint_maarten",
        "code": "sint_maarten",
        "name": "Sint Maarten"
      }
    ]
  },
  {
    "_id": "NL",
    "code": "NL",
    "name": "Netherlands The",
    "states": [
      {
        "_id": "amsterdam",
        "code": "amsterdam",
        "name": "Amsterdam"
      },
      {
        "_id": "benelux",
        "code": "benelux",
        "name": "Benelux"
      },
      {
        "_id": "drenthe",
        "code": "drenthe",
        "name": "Drenthe"
      },
      {
        "_id": "flevoland",
        "code": "flevoland",
        "name": "Flevoland"
      },
      {
        "_id": "friesland",
        "code": "friesland",
        "name": "Friesland"
      },
      {
        "_id": "gelderland",
        "code": "gelderland",
        "name": "Gelderland"
      },
      {
        "_id": "groningen",
        "code": "groningen",
        "name": "Groningen"
      },
      {
        "_id": "limburg",
        "code": "limburg",
        "name": "Limburg"
      },
      {
        "_id": "noord-brabant",
        "code": "noord-brabant",
        "name": "Noord-Brabant"
      },
      {
        "_id": "noord-holland",
        "code": "noord-holland",
        "name": "Noord-Holland"
      },
      {
        "_id": "overijssel",
        "code": "overijssel",
        "name": "Overijssel"
      },
      {
        "_id": "south_holland",
        "code": "south_holland",
        "name": "South Holland"
      },
      {
        "_id": "utrecht",
        "code": "utrecht",
        "name": "Utrecht"
      },
      {
        "_id": "zeeland",
        "code": "zeeland",
        "name": "Zeeland"
      },
      {
        "_id": "zuid-holland",
        "code": "zuid-holland",
        "name": "Zuid-Holland"
      }
    ]
  },
  {
    "_id": "NC",
    "code": "NC",
    "name": "New Caledonia",
    "states": [
      {
        "_id": "iles",
        "code": "iles",
        "name": "Iles"
      },
      {
        "_id": "nord",
        "code": "nord",
        "name": "Nord"
      },
      {
        "_id": "sud",
        "code": "sud",
        "name": "Sud"
      }
    ]
  },
  {
    "_id": "NZ",
    "code": "NZ",
    "name": "New Zealand",
    "states": [
      {
        "_id": "area_outside_region",
        "code": "area_outside_region",
        "name": "Area Outside Region"
      },
      {
        "_id": "auckland",
        "code": "auckland",
        "name": "Auckland"
      },
      {
        "_id": "bay_of_plenty",
        "code": "bay_of_plenty",
        "name": "Bay of Plenty"
      },
      {
        "_id": "canterbury",
        "code": "canterbury",
        "name": "Canterbury"
      },
      {
        "_id": "christchurch",
        "code": "christchurch",
        "name": "Christchurch"
      },
      {
        "_id": "gisborne",
        "code": "gisborne",
        "name": "Gisborne"
      },
      {
        "_id": "hawkes_bay",
        "code": "hawkes_bay",
        "name": "Hawkes Bay"
      },
      {
        "_id": "manawatu-wanganui",
        "code": "manawatu-wanganui",
        "name": "Manawatu-Wanganui"
      },
      {
        "_id": "marlborough",
        "code": "marlborough",
        "name": "Marlborough"
      },
      {
        "_id": "nelson",
        "code": "nelson",
        "name": "Nelson"
      },
      {
        "_id": "northland",
        "code": "northland",
        "name": "Northland"
      },
      {
        "_id": "otago",
        "code": "otago",
        "name": "Otago"
      },
      {
        "_id": "rodney",
        "code": "rodney",
        "name": "Rodney"
      },
      {
        "_id": "southland",
        "code": "southland",
        "name": "Southland"
      },
      {
        "_id": "taranaki",
        "code": "taranaki",
        "name": "Taranaki"
      },
      {
        "_id": "tasman",
        "code": "tasman",
        "name": "Tasman"
      },
      {
        "_id": "waikato",
        "code": "waikato",
        "name": "Waikato"
      },
      {
        "_id": "wellington",
        "code": "wellington",
        "name": "Wellington"
      },
      {
        "_id": "west_coast",
        "code": "west_coast",
        "name": "West Coast"
      }
    ]
  },
  {
    "_id": "NI",
    "code": "NI",
    "name": "Nicaragua",
    "states": [
      {
        "_id": "atlantico_norte",
        "code": "atlantico_norte",
        "name": "Atlantico Norte"
      },
      {
        "_id": "atlantico_sur",
        "code": "atlantico_sur",
        "name": "Atlantico Sur"
      },
      {
        "_id": "boaco",
        "code": "boaco",
        "name": "Boaco"
      },
      {
        "_id": "carazo",
        "code": "carazo",
        "name": "Carazo"
      },
      {
        "_id": "chinandega",
        "code": "chinandega",
        "name": "Chinandega"
      },
      {
        "_id": "chontales",
        "code": "chontales",
        "name": "Chontales"
      },
      {
        "_id": "esteli",
        "code": "esteli",
        "name": "Esteli"
      },
      {
        "_id": "granada",
        "code": "granada",
        "name": "Granada"
      },
      {
        "_id": "jinotega",
        "code": "jinotega",
        "name": "Jinotega"
      },
      {
        "_id": "leon",
        "code": "leon",
        "name": "Leon"
      },
      {
        "_id": "madriz",
        "code": "madriz",
        "name": "Madriz"
      },
      {
        "_id": "managua",
        "code": "managua",
        "name": "Managua"
      },
      {
        "_id": "masaya",
        "code": "masaya",
        "name": "Masaya"
      },
      {
        "_id": "matagalpa",
        "code": "matagalpa",
        "name": "Matagalpa"
      },
      {
        "_id": "nueva_segovia",
        "code": "nueva_segovia",
        "name": "Nueva Segovia"
      },
      {
        "_id": "rio_san_juan",
        "code": "rio_san_juan",
        "name": "Rio San Juan"
      },
      {
        "_id": "rivas",
        "code": "rivas",
        "name": "Rivas"
      }
    ]
  },
  {
    "_id": "NE",
    "code": "NE",
    "name": "Niger",
    "states": [
      {
        "_id": "agadez",
        "code": "agadez",
        "name": "Agadez"
      },
      {
        "_id": "diffa",
        "code": "diffa",
        "name": "Diffa"
      },
      {
        "_id": "dosso",
        "code": "dosso",
        "name": "Dosso"
      },
      {
        "_id": "maradi",
        "code": "maradi",
        "name": "Maradi"
      },
      {
        "_id": "niamey",
        "code": "niamey",
        "name": "Niamey"
      },
      {
        "_id": "tahoua",
        "code": "tahoua",
        "name": "Tahoua"
      },
      {
        "_id": "tillabery",
        "code": "tillabery",
        "name": "Tillabery"
      },
      {
        "_id": "zinder",
        "code": "zinder",
        "name": "Zinder"
      }
    ]
  },
  {
    "_id": "NG",
    "code": "NG",
    "name": "Nigeria",
    "states": [
      {
        "_id": "abia",
        "code": "abia",
        "name": "Abia"
      },
      {
        "_id": "abuja_federal_capital_territor",
        "code": "abuja_federal_capital_territor",
        "name": "Abuja Federal Capital Territor"
      },
      {
        "_id": "adamawa",
        "code": "adamawa",
        "name": "Adamawa"
      },
      {
        "_id": "akwa_ibom",
        "code": "akwa_ibom",
        "name": "Akwa Ibom"
      },
      {
        "_id": "anambra",
        "code": "anambra",
        "name": "Anambra"
      },
      {
        "_id": "bauchi",
        "code": "bauchi",
        "name": "Bauchi"
      },
      {
        "_id": "bayelsa",
        "code": "bayelsa",
        "name": "Bayelsa"
      },
      {
        "_id": "benue",
        "code": "benue",
        "name": "Benue"
      },
      {
        "_id": "borno",
        "code": "borno",
        "name": "Borno"
      },
      {
        "_id": "cross_river",
        "code": "cross_river",
        "name": "Cross River"
      },
      {
        "_id": "delta",
        "code": "delta",
        "name": "Delta"
      },
      {
        "_id": "ebonyi",
        "code": "ebonyi",
        "name": "Ebonyi"
      },
      {
        "_id": "edo",
        "code": "edo",
        "name": "Edo"
      },
      {
        "_id": "ekiti",
        "code": "ekiti",
        "name": "Ekiti"
      },
      {
        "_id": "enugu",
        "code": "enugu",
        "name": "Enugu"
      },
      {
        "_id": "gombe",
        "code": "gombe",
        "name": "Gombe"
      },
      {
        "_id": "imo",
        "code": "imo",
        "name": "Imo"
      },
      {
        "_id": "jigawa",
        "code": "jigawa",
        "name": "Jigawa"
      },
      {
        "_id": "kaduna",
        "code": "kaduna",
        "name": "Kaduna"
      },
      {
        "_id": "kano",
        "code": "kano",
        "name": "Kano"
      },
      {
        "_id": "katsina",
        "code": "katsina",
        "name": "Katsina"
      },
      {
        "_id": "kebbi",
        "code": "kebbi",
        "name": "Kebbi"
      },
      {
        "_id": "kogi",
        "code": "kogi",
        "name": "Kogi"
      },
      {
        "_id": "kwara",
        "code": "kwara",
        "name": "Kwara"
      },
      {
        "_id": "lagos",
        "code": "lagos",
        "name": "Lagos"
      },
      {
        "_id": "nassarawa",
        "code": "nassarawa",
        "name": "Nassarawa"
      },
      {
        "_id": "niger",
        "code": "niger",
        "name": "Niger"
      },
      {
        "_id": "ogun",
        "code": "ogun",
        "name": "Ogun"
      },
      {
        "_id": "ondo",
        "code": "ondo",
        "name": "Ondo"
      },
      {
        "_id": "osun",
        "code": "osun",
        "name": "Osun"
      },
      {
        "_id": "oyo",
        "code": "oyo",
        "name": "Oyo"
      },
      {
        "_id": "plateau",
        "code": "plateau",
        "name": "Plateau"
      },
      {
        "_id": "rivers",
        "code": "rivers",
        "name": "Rivers"
      },
      {
        "_id": "sokoto",
        "code": "sokoto",
        "name": "Sokoto"
      },
      {
        "_id": "taraba",
        "code": "taraba",
        "name": "Taraba"
      },
      {
        "_id": "yobe",
        "code": "yobe",
        "name": "Yobe"
      },
      {
        "_id": "zamfara",
        "code": "zamfara",
        "name": "Zamfara"
      }
    ]
  },
  {
    "_id": "NU",
    "code": "NU",
    "name": "Niue",
    "states": [
      {
        "_id": "niue",
        "code": "niue",
        "name": "Niue"
      }
    ]
  },
  {
    "_id": "NF",
    "code": "NF",
    "name": "Norfolk Island",
    "states": [
      {
        "_id": "norfolk_island",
        "code": "norfolk_island",
        "name": "Norfolk Island"
      }
    ]
  },
  {
    "_id": "MP",
    "code": "MP",
    "name": "Northern Mariana Islands",
    "states": [
      {
        "_id": "northern_islands",
        "code": "northern_islands",
        "name": "Northern Islands"
      },
      {
        "_id": "rota",
        "code": "rota",
        "name": "Rota"
      },
      {
        "_id": "saipan",
        "code": "saipan",
        "name": "Saipan"
      },
      {
        "_id": "tinian",
        "code": "tinian",
        "name": "Tinian"
      }
    ]
  },
  {
    "_id": "NO",
    "code": "NO",
    "name": "Norway",
    "states": [
      {
        "_id": "akershus",
        "code": "akershus",
        "name": "Akershus"
      },
      {
        "_id": "aust_agder",
        "code": "aust_agder",
        "name": "Aust Agder"
      },
      {
        "_id": "bergen",
        "code": "bergen",
        "name": "Bergen"
      },
      {
        "_id": "buskerud",
        "code": "buskerud",
        "name": "Buskerud"
      },
      {
        "_id": "finnmark",
        "code": "finnmark",
        "name": "Finnmark"
      },
      {
        "_id": "hedmark",
        "code": "hedmark",
        "name": "Hedmark"
      },
      {
        "_id": "hordaland",
        "code": "hordaland",
        "name": "Hordaland"
      },
      {
        "_id": "moere_og_romsdal",
        "code": "moere_og_romsdal",
        "name": "Moere og Romsdal"
      },
      {
        "_id": "nord_trondelag",
        "code": "nord_trondelag",
        "name": "Nord Trondelag"
      },
      {
        "_id": "nordland",
        "code": "nordland",
        "name": "Nordland"
      },
      {
        "_id": "oestfold",
        "code": "oestfold",
        "name": "Oestfold"
      },
      {
        "_id": "oppland",
        "code": "oppland",
        "name": "Oppland"
      },
      {
        "_id": "oslo",
        "code": "oslo",
        "name": "Oslo"
      },
      {
        "_id": "rogaland",
        "code": "rogaland",
        "name": "Rogaland"
      },
      {
        "_id": "soer_troendelag",
        "code": "soer_troendelag",
        "name": "Soer Troendelag"
      },
      {
        "_id": "sogn_og_fjordane",
        "code": "sogn_og_fjordane",
        "name": "Sogn og Fjordane"
      },
      {
        "_id": "stavern",
        "code": "stavern",
        "name": "Stavern"
      },
      {
        "_id": "sykkylven",
        "code": "sykkylven",
        "name": "Sykkylven"
      },
      {
        "_id": "telemark",
        "code": "telemark",
        "name": "Telemark"
      },
      {
        "_id": "troms",
        "code": "troms",
        "name": "Troms"
      },
      {
        "_id": "vest_agder",
        "code": "vest_agder",
        "name": "Vest Agder"
      },
      {
        "_id": "vestfold",
        "code": "vestfold",
        "name": "Vestfold"
      },
      {
        "_id": "ãƒâ˜stfold",
        "code": "ãƒâ˜stfold",
        "name": "ÃƒÂ˜stfold"
      }
    ]
  },
  {
    "_id": "OM",
    "code": "OM",
    "name": "Oman",
    "states": [
      {
        "_id": "al_buraimi",
        "code": "al_buraimi",
        "name": "Al Buraimi"
      },
      {
        "_id": "dhufar",
        "code": "dhufar",
        "name": "Dhufar"
      },
      {
        "_id": "masqat",
        "code": "masqat",
        "name": "Masqat"
      },
      {
        "_id": "musandam",
        "code": "musandam",
        "name": "Musandam"
      },
      {
        "_id": "rusayl",
        "code": "rusayl",
        "name": "Rusayl"
      },
      {
        "_id": "wadi_kabir",
        "code": "wadi_kabir",
        "name": "Wadi Kabir"
      },
      {
        "_id": "ad-dakhiliyah",
        "code": "ad-dakhiliyah",
        "name": "ad-Dakhiliyah"
      },
      {
        "_id": "adh-dhahirah",
        "code": "adh-dhahirah",
        "name": "adh-Dhahirah"
      },
      {
        "_id": "al-batinah",
        "code": "al-batinah",
        "name": "al-Batinah"
      },
      {
        "_id": "ash-sharqiyah",
        "code": "ash-sharqiyah",
        "name": "ash-Sharqiyah"
      }
    ]
  },
  {
    "_id": "PK",
    "code": "PK",
    "name": "Pakistan",
    "states": [
      {
        "_id": "baluchistan",
        "code": "baluchistan",
        "name": "Baluchistan"
      },
      {
        "_id": "federal_capital_area",
        "code": "federal_capital_area",
        "name": "Federal Capital Area"
      },
      {
        "_id": "federally_administered_tribal_",
        "code": "federally_administered_tribal_",
        "name": "Federally administered Tribal "
      },
      {
        "_id": "north-west_frontier",
        "code": "north-west_frontier",
        "name": "North-West Frontier"
      },
      {
        "_id": "northern_areas",
        "code": "northern_areas",
        "name": "Northern Areas"
      },
      {
        "_id": "punjab",
        "code": "punjab",
        "name": "Punjab"
      },
      {
        "_id": "sind",
        "code": "sind",
        "name": "Sind"
      }
    ]
  },
  {
    "_id": "PW",
    "code": "PW",
    "name": "Palau",
    "states": [
      {
        "_id": "aimeliik",
        "code": "aimeliik",
        "name": "Aimeliik"
      },
      {
        "_id": "airai",
        "code": "airai",
        "name": "Airai"
      },
      {
        "_id": "angaur",
        "code": "angaur",
        "name": "Angaur"
      },
      {
        "_id": "hatobohei",
        "code": "hatobohei",
        "name": "Hatobohei"
      },
      {
        "_id": "kayangel",
        "code": "kayangel",
        "name": "Kayangel"
      },
      {
        "_id": "koror",
        "code": "koror",
        "name": "Koror"
      },
      {
        "_id": "melekeok",
        "code": "melekeok",
        "name": "Melekeok"
      },
      {
        "_id": "ngaraard",
        "code": "ngaraard",
        "name": "Ngaraard"
      },
      {
        "_id": "ngardmau",
        "code": "ngardmau",
        "name": "Ngardmau"
      },
      {
        "_id": "ngaremlengui",
        "code": "ngaremlengui",
        "name": "Ngaremlengui"
      },
      {
        "_id": "ngatpang",
        "code": "ngatpang",
        "name": "Ngatpang"
      },
      {
        "_id": "ngchesar",
        "code": "ngchesar",
        "name": "Ngchesar"
      },
      {
        "_id": "ngerchelong",
        "code": "ngerchelong",
        "name": "Ngerchelong"
      },
      {
        "_id": "ngiwal",
        "code": "ngiwal",
        "name": "Ngiwal"
      },
      {
        "_id": "peleliu",
        "code": "peleliu",
        "name": "Peleliu"
      },
      {
        "_id": "sonsorol",
        "code": "sonsorol",
        "name": "Sonsorol"
      }
    ]
  },
  {
    "_id": "PS",
    "code": "PS",
    "name": "Palestinian Territory Occupied",
    "states": [
      {
        "_id": "ariha",
        "code": "ariha",
        "name": "Ariha"
      },
      {
        "_id": "bayt_lahm",
        "code": "bayt_lahm",
        "name": "Bayt Lahm"
      },
      {
        "_id": "bethlehem",
        "code": "bethlehem",
        "name": "Bethlehem"
      },
      {
        "_id": "dayr-al-balah",
        "code": "dayr-al-balah",
        "name": "Dayr-al-Balah"
      },
      {
        "_id": "ghazzah",
        "code": "ghazzah",
        "name": "Ghazzah"
      },
      {
        "_id": "ghazzah_ash-shamaliyah",
        "code": "ghazzah_ash-shamaliyah",
        "name": "Ghazzah ash-Shamaliyah"
      },
      {
        "_id": "janin",
        "code": "janin",
        "name": "Janin"
      },
      {
        "_id": "khan_yunis",
        "code": "khan_yunis",
        "name": "Khan Yunis"
      },
      {
        "_id": "nabulus",
        "code": "nabulus",
        "name": "Nabulus"
      },
      {
        "_id": "qalqilyah",
        "code": "qalqilyah",
        "name": "Qalqilyah"
      },
      {
        "_id": "rafah",
        "code": "rafah",
        "name": "Rafah"
      },
      {
        "_id": "ram_allah_wal-birah",
        "code": "ram_allah_wal-birah",
        "name": "Ram Allah wal-Birah"
      },
      {
        "_id": "salfit",
        "code": "salfit",
        "name": "Salfit"
      },
      {
        "_id": "tubas",
        "code": "tubas",
        "name": "Tubas"
      },
      {
        "_id": "tulkarm",
        "code": "tulkarm",
        "name": "Tulkarm"
      },
      {
        "_id": "al-khalil",
        "code": "al-khalil",
        "name": "al-Khalil"
      },
      {
        "_id": "al-quds",
        "code": "al-quds",
        "name": "al-Quds"
      }
    ]
  },
  {
    "_id": "PA",
    "code": "PA",
    "name": "Panama",
    "states": [
      {
        "_id": "bocas_del_toro",
        "code": "bocas_del_toro",
        "name": "Bocas del Toro"
      },
      {
        "_id": "chiriqui",
        "code": "chiriqui",
        "name": "Chiriqui"
      },
      {
        "_id": "cocle",
        "code": "cocle",
        "name": "Cocle"
      },
      {
        "_id": "colon",
        "code": "colon",
        "name": "Colon"
      },
      {
        "_id": "darien",
        "code": "darien",
        "name": "Darien"
      },
      {
        "_id": "embera",
        "code": "embera",
        "name": "Embera"
      },
      {
        "_id": "herrera",
        "code": "herrera",
        "name": "Herrera"
      },
      {
        "_id": "kuna_yala",
        "code": "kuna_yala",
        "name": "Kuna Yala"
      },
      {
        "_id": "los_santos",
        "code": "los_santos",
        "name": "Los Santos"
      },
      {
        "_id": "ngobe_bugle",
        "code": "ngobe_bugle",
        "name": "Ngobe Bugle"
      },
      {
        "_id": "panama",
        "code": "panama",
        "name": "Panama"
      },
      {
        "_id": "veraguas",
        "code": "veraguas",
        "name": "Veraguas"
      }
    ]
  },
  {
    "_id": "PG",
    "code": "PG",
    "name": "Papua new Guinea",
    "states": [
      {
        "_id": "east_new_britain",
        "code": "east_new_britain",
        "name": "East New Britain"
      },
      {
        "_id": "east_sepik",
        "code": "east_sepik",
        "name": "East Sepik"
      },
      {
        "_id": "eastern_highlands",
        "code": "eastern_highlands",
        "name": "Eastern Highlands"
      },
      {
        "_id": "enga",
        "code": "enga",
        "name": "Enga"
      },
      {
        "_id": "fly_river",
        "code": "fly_river",
        "name": "Fly River"
      },
      {
        "_id": "gulf",
        "code": "gulf",
        "name": "Gulf"
      },
      {
        "_id": "madang",
        "code": "madang",
        "name": "Madang"
      },
      {
        "_id": "manus",
        "code": "manus",
        "name": "Manus"
      },
      {
        "_id": "milne_bay",
        "code": "milne_bay",
        "name": "Milne Bay"
      },
      {
        "_id": "morobe",
        "code": "morobe",
        "name": "Morobe"
      },
      {
        "_id": "national_capital_district",
        "code": "national_capital_district",
        "name": "National Capital District"
      },
      {
        "_id": "new_ireland",
        "code": "new_ireland",
        "name": "New Ireland"
      },
      {
        "_id": "north_solomons",
        "code": "north_solomons",
        "name": "North Solomons"
      },
      {
        "_id": "oro",
        "code": "oro",
        "name": "Oro"
      },
      {
        "_id": "sandaun",
        "code": "sandaun",
        "name": "Sandaun"
      },
      {
        "_id": "simbu",
        "code": "simbu",
        "name": "Simbu"
      },
      {
        "_id": "southern_highlands",
        "code": "southern_highlands",
        "name": "Southern Highlands"
      },
      {
        "_id": "west_new_britain",
        "code": "west_new_britain",
        "name": "West New Britain"
      },
      {
        "_id": "western_highlands",
        "code": "western_highlands",
        "name": "Western Highlands"
      }
    ]
  },
  {
    "_id": "PY",
    "code": "PY",
    "name": "Paraguay",
    "states": [
      {
        "_id": "alto_paraguay",
        "code": "alto_paraguay",
        "name": "Alto Paraguay"
      },
      {
        "_id": "alto_parana",
        "code": "alto_parana",
        "name": "Alto Parana"
      },
      {
        "_id": "amambay",
        "code": "amambay",
        "name": "Amambay"
      },
      {
        "_id": "asuncion",
        "code": "asuncion",
        "name": "Asuncion"
      },
      {
        "_id": "boqueron",
        "code": "boqueron",
        "name": "Boqueron"
      },
      {
        "_id": "caaguazu",
        "code": "caaguazu",
        "name": "Caaguazu"
      },
      {
        "_id": "caazapa",
        "code": "caazapa",
        "name": "Caazapa"
      },
      {
        "_id": "canendiyu",
        "code": "canendiyu",
        "name": "Canendiyu"
      },
      {
        "_id": "central",
        "code": "central",
        "name": "Central"
      },
      {
        "_id": "concepcion",
        "code": "concepcion",
        "name": "Concepcion"
      },
      {
        "_id": "cordillera",
        "code": "cordillera",
        "name": "Cordillera"
      },
      {
        "_id": "guaira",
        "code": "guaira",
        "name": "Guaira"
      },
      {
        "_id": "itapua",
        "code": "itapua",
        "name": "Itapua"
      },
      {
        "_id": "misiones",
        "code": "misiones",
        "name": "Misiones"
      },
      {
        "_id": "neembucu",
        "code": "neembucu",
        "name": "Neembucu"
      },
      {
        "_id": "paraguari",
        "code": "paraguari",
        "name": "Paraguari"
      },
      {
        "_id": "presidente_hayes",
        "code": "presidente_hayes",
        "name": "Presidente Hayes"
      },
      {
        "_id": "san_pedro",
        "code": "san_pedro",
        "name": "San Pedro"
      }
    ]
  },
  {
    "_id": "PE",
    "code": "PE",
    "name": "Peru",
    "states": [
      {
        "_id": "amazonas",
        "code": "amazonas",
        "name": "Amazonas"
      },
      {
        "_id": "ancash",
        "code": "ancash",
        "name": "Ancash"
      },
      {
        "_id": "apurimac",
        "code": "apurimac",
        "name": "Apurimac"
      },
      {
        "_id": "arequipa",
        "code": "arequipa",
        "name": "Arequipa"
      },
      {
        "_id": "ayacucho",
        "code": "ayacucho",
        "name": "Ayacucho"
      },
      {
        "_id": "cajamarca",
        "code": "cajamarca",
        "name": "Cajamarca"
      },
      {
        "_id": "cusco",
        "code": "cusco",
        "name": "Cusco"
      },
      {
        "_id": "huancavelica",
        "code": "huancavelica",
        "name": "Huancavelica"
      },
      {
        "_id": "huanuco",
        "code": "huanuco",
        "name": "Huanuco"
      },
      {
        "_id": "ica",
        "code": "ica",
        "name": "Ica"
      },
      {
        "_id": "junin",
        "code": "junin",
        "name": "Junin"
      },
      {
        "_id": "la_libertad",
        "code": "la_libertad",
        "name": "La Libertad"
      },
      {
        "_id": "lambayeque",
        "code": "lambayeque",
        "name": "Lambayeque"
      },
      {
        "_id": "lima_y_callao",
        "code": "lima_y_callao",
        "name": "Lima y Callao"
      },
      {
        "_id": "loreto",
        "code": "loreto",
        "name": "Loreto"
      },
      {
        "_id": "madre_de_dios",
        "code": "madre_de_dios",
        "name": "Madre de Dios"
      },
      {
        "_id": "moquegua",
        "code": "moquegua",
        "name": "Moquegua"
      },
      {
        "_id": "pasco",
        "code": "pasco",
        "name": "Pasco"
      },
      {
        "_id": "piura",
        "code": "piura",
        "name": "Piura"
      },
      {
        "_id": "puno",
        "code": "puno",
        "name": "Puno"
      },
      {
        "_id": "san_martin",
        "code": "san_martin",
        "name": "San Martin"
      },
      {
        "_id": "tacna",
        "code": "tacna",
        "name": "Tacna"
      },
      {
        "_id": "tumbes",
        "code": "tumbes",
        "name": "Tumbes"
      },
      {
        "_id": "ucayali",
        "code": "ucayali",
        "name": "Ucayali"
      }
    ]
  },
  {
    "_id": "PH",
    "code": "PH",
    "name": "Philippines",
    "states": [
      {
        "_id": "batangas",
        "code": "batangas",
        "name": "Batangas"
      },
      {
        "_id": "bicol",
        "code": "bicol",
        "name": "Bicol"
      },
      {
        "_id": "bulacan",
        "code": "bulacan",
        "name": "Bulacan"
      },
      {
        "_id": "cagayan",
        "code": "cagayan",
        "name": "Cagayan"
      },
      {
        "_id": "caraga",
        "code": "caraga",
        "name": "Caraga"
      },
      {
        "_id": "central_luzon",
        "code": "central_luzon",
        "name": "Central Luzon"
      },
      {
        "_id": "central_mindanao",
        "code": "central_mindanao",
        "name": "Central Mindanao"
      },
      {
        "_id": "central_visayas",
        "code": "central_visayas",
        "name": "Central Visayas"
      },
      {
        "_id": "cordillera",
        "code": "cordillera",
        "name": "Cordillera"
      },
      {
        "_id": "davao",
        "code": "davao",
        "name": "Davao"
      },
      {
        "_id": "eastern_visayas",
        "code": "eastern_visayas",
        "name": "Eastern Visayas"
      },
      {
        "_id": "greater_metropolitan_area",
        "code": "greater_metropolitan_area",
        "name": "Greater Metropolitan Area"
      },
      {
        "_id": "ilocos",
        "code": "ilocos",
        "name": "Ilocos"
      },
      {
        "_id": "laguna",
        "code": "laguna",
        "name": "Laguna"
      },
      {
        "_id": "luzon",
        "code": "luzon",
        "name": "Luzon"
      },
      {
        "_id": "mactan",
        "code": "mactan",
        "name": "Mactan"
      },
      {
        "_id": "metropolitan_manila_area",
        "code": "metropolitan_manila_area",
        "name": "Metropolitan Manila Area"
      },
      {
        "_id": "muslim_mindanao",
        "code": "muslim_mindanao",
        "name": "Muslim Mindanao"
      },
      {
        "_id": "northern_mindanao",
        "code": "northern_mindanao",
        "name": "Northern Mindanao"
      },
      {
        "_id": "southern_mindanao",
        "code": "southern_mindanao",
        "name": "Southern Mindanao"
      },
      {
        "_id": "southern_tagalog",
        "code": "southern_tagalog",
        "name": "Southern Tagalog"
      },
      {
        "_id": "western_mindanao",
        "code": "western_mindanao",
        "name": "Western Mindanao"
      },
      {
        "_id": "western_visayas",
        "code": "western_visayas",
        "name": "Western Visayas"
      }
    ]
  },
  {
    "_id": "PN",
    "code": "PN",
    "name": "Pitcairn Island",
    "states": [
      {
        "_id": "pitcairn_island",
        "code": "pitcairn_island",
        "name": "Pitcairn Island"
      }
    ]
  },
  {
    "_id": "PL",
    "code": "PL",
    "name": "Poland",
    "states": [
      {
        "_id": "biale_blota",
        "code": "biale_blota",
        "name": "Biale Blota"
      },
      {
        "_id": "dobroszyce",
        "code": "dobroszyce",
        "name": "Dobroszyce"
      },
      {
        "_id": "dolnoslaskie",
        "code": "dolnoslaskie",
        "name": "Dolnoslaskie"
      },
      {
        "_id": "dziekanow_lesny",
        "code": "dziekanow_lesny",
        "name": "Dziekanow Lesny"
      },
      {
        "_id": "hopowo",
        "code": "hopowo",
        "name": "Hopowo"
      },
      {
        "_id": "kartuzy",
        "code": "kartuzy",
        "name": "Kartuzy"
      },
      {
        "_id": "koscian",
        "code": "koscian",
        "name": "Koscian"
      },
      {
        "_id": "krakow",
        "code": "krakow",
        "name": "Krakow"
      },
      {
        "_id": "kujawsko-pomorskie",
        "code": "kujawsko-pomorskie",
        "name": "Kujawsko-Pomorskie"
      },
      {
        "_id": "lodzkie",
        "code": "lodzkie",
        "name": "Lodzkie"
      },
      {
        "_id": "lubelskie",
        "code": "lubelskie",
        "name": "Lubelskie"
      },
      {
        "_id": "lubuskie",
        "code": "lubuskie",
        "name": "Lubuskie"
      },
      {
        "_id": "malomice",
        "code": "malomice",
        "name": "Malomice"
      },
      {
        "_id": "malopolskie",
        "code": "malopolskie",
        "name": "Malopolskie"
      },
      {
        "_id": "mazowieckie",
        "code": "mazowieckie",
        "name": "Mazowieckie"
      },
      {
        "_id": "mirkow",
        "code": "mirkow",
        "name": "Mirkow"
      },
      {
        "_id": "opolskie",
        "code": "opolskie",
        "name": "Opolskie"
      },
      {
        "_id": "ostrowiec",
        "code": "ostrowiec",
        "name": "Ostrowiec"
      },
      {
        "_id": "podkarpackie",
        "code": "podkarpackie",
        "name": "Podkarpackie"
      },
      {
        "_id": "podlaskie",
        "code": "podlaskie",
        "name": "Podlaskie"
      },
      {
        "_id": "polska",
        "code": "polska",
        "name": "Polska"
      },
      {
        "_id": "pomorskie",
        "code": "pomorskie",
        "name": "Pomorskie"
      },
      {
        "_id": "poznan",
        "code": "poznan",
        "name": "Poznan"
      },
      {
        "_id": "pruszkow",
        "code": "pruszkow",
        "name": "Pruszkow"
      },
      {
        "_id": "rymanowska",
        "code": "rymanowska",
        "name": "Rymanowska"
      },
      {
        "_id": "rzeszow",
        "code": "rzeszow",
        "name": "Rzeszow"
      },
      {
        "_id": "slaskie",
        "code": "slaskie",
        "name": "Slaskie"
      },
      {
        "_id": "stare_pole",
        "code": "stare_pole",
        "name": "Stare Pole"
      },
      {
        "_id": "swietokrzyskie",
        "code": "swietokrzyskie",
        "name": "Swietokrzyskie"
      },
      {
        "_id": "warminsko-mazurskie",
        "code": "warminsko-mazurskie",
        "name": "Warminsko-Mazurskie"
      },
      {
        "_id": "warsaw",
        "code": "warsaw",
        "name": "Warsaw"
      },
      {
        "_id": "wejherowo",
        "code": "wejherowo",
        "name": "Wejherowo"
      },
      {
        "_id": "wielkopolskie",
        "code": "wielkopolskie",
        "name": "Wielkopolskie"
      },
      {
        "_id": "wroclaw",
        "code": "wroclaw",
        "name": "Wroclaw"
      },
      {
        "_id": "zachodnio-pomorskie",
        "code": "zachodnio-pomorskie",
        "name": "Zachodnio-Pomorskie"
      },
      {
        "_id": "zukowo",
        "code": "zukowo",
        "name": "Zukowo"
      }
    ]
  },
  {
    "_id": "PT",
    "code": "PT",
    "name": "Portugal",
    "states": [
      {
        "_id": "abrantes",
        "code": "abrantes",
        "name": "Abrantes"
      },
      {
        "_id": "acores",
        "code": "acores",
        "name": "Acores"
      },
      {
        "_id": "alentejo",
        "code": "alentejo",
        "name": "Alentejo"
      },
      {
        "_id": "algarve",
        "code": "algarve",
        "name": "Algarve"
      },
      {
        "_id": "braga",
        "code": "braga",
        "name": "Braga"
      },
      {
        "_id": "centro",
        "code": "centro",
        "name": "Centro"
      },
      {
        "_id": "distrito_de_leiria",
        "code": "distrito_de_leiria",
        "name": "Distrito de Leiria"
      },
      {
        "_id": "distrito_de_viana_do_castelo",
        "code": "distrito_de_viana_do_castelo",
        "name": "Distrito de Viana do Castelo"
      },
      {
        "_id": "distrito_de_vila_real",
        "code": "distrito_de_vila_real",
        "name": "Distrito de Vila Real"
      },
      {
        "_id": "distrito_do_porto",
        "code": "distrito_do_porto",
        "name": "Distrito do Porto"
      },
      {
        "_id": "lisboa_e_vale_do_tejo",
        "code": "lisboa_e_vale_do_tejo",
        "name": "Lisboa e Vale do Tejo"
      },
      {
        "_id": "madeira",
        "code": "madeira",
        "name": "Madeira"
      },
      {
        "_id": "norte",
        "code": "norte",
        "name": "Norte"
      },
      {
        "_id": "paivas",
        "code": "paivas",
        "name": "Paivas"
      }
    ]
  },
  {
    "_id": "PR",
    "code": "PR",
    "name": "Puerto Rico",
    "states": [
      {
        "_id": "arecibo",
        "code": "arecibo",
        "name": "Arecibo"
      },
      {
        "_id": "bayamon",
        "code": "bayamon",
        "name": "Bayamon"
      },
      {
        "_id": "carolina",
        "code": "carolina",
        "name": "Carolina"
      },
      {
        "_id": "florida",
        "code": "florida",
        "name": "Florida"
      },
      {
        "_id": "guayama",
        "code": "guayama",
        "name": "Guayama"
      },
      {
        "_id": "humacao",
        "code": "humacao",
        "name": "Humacao"
      },
      {
        "_id": "mayaguez-aguadilla",
        "code": "mayaguez-aguadilla",
        "name": "Mayaguez-Aguadilla"
      },
      {
        "_id": "ponce",
        "code": "ponce",
        "name": "Ponce"
      },
      {
        "_id": "salinas",
        "code": "salinas",
        "name": "Salinas"
      },
      {
        "_id": "san_juan",
        "code": "san_juan",
        "name": "San Juan"
      }
    ]
  },
  {
    "_id": "QA",
    "code": "QA",
    "name": "Qatar",
    "states": [
      {
        "_id": "doha",
        "code": "doha",
        "name": "Doha"
      },
      {
        "_id": "jarian-al-batnah",
        "code": "jarian-al-batnah",
        "name": "Jarian-al-Batnah"
      },
      {
        "_id": "umm_salal",
        "code": "umm_salal",
        "name": "Umm Salal"
      },
      {
        "_id": "ad-dawhah",
        "code": "ad-dawhah",
        "name": "ad-Dawhah"
      },
      {
        "_id": "al-ghuwayriyah",
        "code": "al-ghuwayriyah",
        "name": "al-Ghuwayriyah"
      },
      {
        "_id": "al-jumayliyah",
        "code": "al-jumayliyah",
        "name": "al-Jumayliyah"
      },
      {
        "_id": "al-khawr",
        "code": "al-khawr",
        "name": "al-Khawr"
      },
      {
        "_id": "al-wakrah",
        "code": "al-wakrah",
        "name": "al-Wakrah"
      },
      {
        "_id": "ar-rayyan",
        "code": "ar-rayyan",
        "name": "ar-Rayyan"
      },
      {
        "_id": "ash-shamal",
        "code": "ash-shamal",
        "name": "ash-Shamal"
      }
    ]
  },
  {
    "_id": "CG",
    "code": "CG",
    "name": "Republic Of The Congo",
    "states": [
      {
        "_id": "bouenza",
        "code": "bouenza",
        "name": "Bouenza"
      },
      {
        "_id": "brazzaville",
        "code": "brazzaville",
        "name": "Brazzaville"
      },
      {
        "_id": "cuvette",
        "code": "cuvette",
        "name": "Cuvette"
      },
      {
        "_id": "kouilou",
        "code": "kouilou",
        "name": "Kouilou"
      },
      {
        "_id": "lekoumou",
        "code": "lekoumou",
        "name": "Lekoumou"
      },
      {
        "_id": "likouala",
        "code": "likouala",
        "name": "Likouala"
      },
      {
        "_id": "niari",
        "code": "niari",
        "name": "Niari"
      },
      {
        "_id": "plateaux",
        "code": "plateaux",
        "name": "Plateaux"
      },
      {
        "_id": "pool",
        "code": "pool",
        "name": "Pool"
      },
      {
        "_id": "sangha",
        "code": "sangha",
        "name": "Sangha"
      }
    ]
  },
  {
    "_id": "RE",
    "code": "RE",
    "name": "Reunion",
    "states": [
      {
        "_id": "saint-benoit",
        "code": "saint-benoit",
        "name": "Saint-Benoit"
      },
      {
        "_id": "saint-denis",
        "code": "saint-denis",
        "name": "Saint-Denis"
      },
      {
        "_id": "saint-paul",
        "code": "saint-paul",
        "name": "Saint-Paul"
      },
      {
        "_id": "saint-pierre",
        "code": "saint-pierre",
        "name": "Saint-Pierre"
      }
    ]
  },
  {
    "_id": "RO",
    "code": "RO",
    "name": "Romania",
    "states": [
      {
        "_id": "alba",
        "code": "alba",
        "name": "Alba"
      },
      {
        "_id": "arad",
        "code": "arad",
        "name": "Arad"
      },
      {
        "_id": "arges",
        "code": "arges",
        "name": "Arges"
      },
      {
        "_id": "bacau",
        "code": "bacau",
        "name": "Bacau"
      },
      {
        "_id": "bihor",
        "code": "bihor",
        "name": "Bihor"
      },
      {
        "_id": "bistrita-nasaud",
        "code": "bistrita-nasaud",
        "name": "Bistrita-Nasaud"
      },
      {
        "_id": "botosani",
        "code": "botosani",
        "name": "Botosani"
      },
      {
        "_id": "braila",
        "code": "braila",
        "name": "Braila"
      },
      {
        "_id": "brasov",
        "code": "brasov",
        "name": "Brasov"
      },
      {
        "_id": "bucuresti",
        "code": "bucuresti",
        "name": "Bucuresti"
      },
      {
        "_id": "buzau",
        "code": "buzau",
        "name": "Buzau"
      },
      {
        "_id": "calarasi",
        "code": "calarasi",
        "name": "Calarasi"
      },
      {
        "_id": "caras-severin",
        "code": "caras-severin",
        "name": "Caras-Severin"
      },
      {
        "_id": "cluj",
        "code": "cluj",
        "name": "Cluj"
      },
      {
        "_id": "constanta",
        "code": "constanta",
        "name": "Constanta"
      },
      {
        "_id": "covasna",
        "code": "covasna",
        "name": "Covasna"
      },
      {
        "_id": "dambovita",
        "code": "dambovita",
        "name": "Dambovita"
      },
      {
        "_id": "dolj",
        "code": "dolj",
        "name": "Dolj"
      },
      {
        "_id": "galati",
        "code": "galati",
        "name": "Galati"
      },
      {
        "_id": "giurgiu",
        "code": "giurgiu",
        "name": "Giurgiu"
      },
      {
        "_id": "gorj",
        "code": "gorj",
        "name": "Gorj"
      },
      {
        "_id": "harghita",
        "code": "harghita",
        "name": "Harghita"
      },
      {
        "_id": "hunedoara",
        "code": "hunedoara",
        "name": "Hunedoara"
      },
      {
        "_id": "ialomita",
        "code": "ialomita",
        "name": "Ialomita"
      },
      {
        "_id": "iasi",
        "code": "iasi",
        "name": "Iasi"
      },
      {
        "_id": "ilfov",
        "code": "ilfov",
        "name": "Ilfov"
      },
      {
        "_id": "maramures",
        "code": "maramures",
        "name": "Maramures"
      },
      {
        "_id": "mehedinti",
        "code": "mehedinti",
        "name": "Mehedinti"
      },
      {
        "_id": "mures",
        "code": "mures",
        "name": "Mures"
      },
      {
        "_id": "neamt",
        "code": "neamt",
        "name": "Neamt"
      },
      {
        "_id": "olt",
        "code": "olt",
        "name": "Olt"
      },
      {
        "_id": "prahova",
        "code": "prahova",
        "name": "Prahova"
      },
      {
        "_id": "salaj",
        "code": "salaj",
        "name": "Salaj"
      },
      {
        "_id": "satu_mare",
        "code": "satu_mare",
        "name": "Satu Mare"
      },
      {
        "_id": "sibiu",
        "code": "sibiu",
        "name": "Sibiu"
      },
      {
        "_id": "sondelor",
        "code": "sondelor",
        "name": "Sondelor"
      },
      {
        "_id": "suceava",
        "code": "suceava",
        "name": "Suceava"
      },
      {
        "_id": "teleorman",
        "code": "teleorman",
        "name": "Teleorman"
      },
      {
        "_id": "timis",
        "code": "timis",
        "name": "Timis"
      },
      {
        "_id": "tulcea",
        "code": "tulcea",
        "name": "Tulcea"
      },
      {
        "_id": "valcea",
        "code": "valcea",
        "name": "Valcea"
      },
      {
        "_id": "vaslui",
        "code": "vaslui",
        "name": "Vaslui"
      },
      {
        "_id": "vrancea",
        "code": "vrancea",
        "name": "Vrancea"
      }
    ]
  },
  {
    "_id": "RU",
    "code": "RU",
    "name": "Russia",
    "states": [
      {
        "_id": "adygeja",
        "code": "adygeja",
        "name": "Adygeja"
      },
      {
        "_id": "aga",
        "code": "aga",
        "name": "Aga"
      },
      {
        "_id": "alanija",
        "code": "alanija",
        "name": "Alanija"
      },
      {
        "_id": "altaj",
        "code": "altaj",
        "name": "Altaj"
      },
      {
        "_id": "amur",
        "code": "amur",
        "name": "Amur"
      },
      {
        "_id": "arhangelsk",
        "code": "arhangelsk",
        "name": "Arhangelsk"
      },
      {
        "_id": "astrahan",
        "code": "astrahan",
        "name": "Astrahan"
      },
      {
        "_id": "bashkortostan",
        "code": "bashkortostan",
        "name": "Bashkortostan"
      },
      {
        "_id": "belgorod",
        "code": "belgorod",
        "name": "Belgorod"
      },
      {
        "_id": "brjansk",
        "code": "brjansk",
        "name": "Brjansk"
      },
      {
        "_id": "burjatija",
        "code": "burjatija",
        "name": "Burjatija"
      },
      {
        "_id": "chechenija",
        "code": "chechenija",
        "name": "Chechenija"
      },
      {
        "_id": "cheljabinsk",
        "code": "cheljabinsk",
        "name": "Cheljabinsk"
      },
      {
        "_id": "chita",
        "code": "chita",
        "name": "Chita"
      },
      {
        "_id": "chukotka",
        "code": "chukotka",
        "name": "Chukotka"
      },
      {
        "_id": "chuvashija",
        "code": "chuvashija",
        "name": "Chuvashija"
      },
      {
        "_id": "dagestan",
        "code": "dagestan",
        "name": "Dagestan"
      },
      {
        "_id": "evenkija",
        "code": "evenkija",
        "name": "Evenkija"
      },
      {
        "_id": "gorno-altaj",
        "code": "gorno-altaj",
        "name": "Gorno-Altaj"
      },
      {
        "_id": "habarovsk",
        "code": "habarovsk",
        "name": "Habarovsk"
      },
      {
        "_id": "hakasija",
        "code": "hakasija",
        "name": "Hakasija"
      },
      {
        "_id": "hanty-mansija",
        "code": "hanty-mansija",
        "name": "Hanty-Mansija"
      },
      {
        "_id": "ingusetija",
        "code": "ingusetija",
        "name": "Ingusetija"
      },
      {
        "_id": "irkutsk",
        "code": "irkutsk",
        "name": "Irkutsk"
      },
      {
        "_id": "ivanovo",
        "code": "ivanovo",
        "name": "Ivanovo"
      },
      {
        "_id": "jamalo-nenets",
        "code": "jamalo-nenets",
        "name": "Jamalo-Nenets"
      },
      {
        "_id": "jaroslavl",
        "code": "jaroslavl",
        "name": "Jaroslavl"
      },
      {
        "_id": "jevrej",
        "code": "jevrej",
        "name": "Jevrej"
      },
      {
        "_id": "kabardino-balkarija",
        "code": "kabardino-balkarija",
        "name": "Kabardino-Balkarija"
      },
      {
        "_id": "kaliningrad",
        "code": "kaliningrad",
        "name": "Kaliningrad"
      },
      {
        "_id": "kalmykija",
        "code": "kalmykija",
        "name": "Kalmykija"
      },
      {
        "_id": "kaluga",
        "code": "kaluga",
        "name": "Kaluga"
      },
      {
        "_id": "kamchatka",
        "code": "kamchatka",
        "name": "Kamchatka"
      },
      {
        "_id": "karachaj-cherkessija",
        "code": "karachaj-cherkessija",
        "name": "Karachaj-Cherkessija"
      },
      {
        "_id": "karelija",
        "code": "karelija",
        "name": "Karelija"
      },
      {
        "_id": "kemerovo",
        "code": "kemerovo",
        "name": "Kemerovo"
      },
      {
        "_id": "khabarovskiy_kray",
        "code": "khabarovskiy_kray",
        "name": "Khabarovskiy Kray"
      },
      {
        "_id": "kirov",
        "code": "kirov",
        "name": "Kirov"
      },
      {
        "_id": "komi",
        "code": "komi",
        "name": "Komi"
      },
      {
        "_id": "komi-permjakija",
        "code": "komi-permjakija",
        "name": "Komi-Permjakija"
      },
      {
        "_id": "korjakija",
        "code": "korjakija",
        "name": "Korjakija"
      },
      {
        "_id": "kostroma",
        "code": "kostroma",
        "name": "Kostroma"
      },
      {
        "_id": "krasnodar",
        "code": "krasnodar",
        "name": "Krasnodar"
      },
      {
        "_id": "krasnojarsk",
        "code": "krasnojarsk",
        "name": "Krasnojarsk"
      },
      {
        "_id": "krasnoyarskiy_kray",
        "code": "krasnoyarskiy_kray",
        "name": "Krasnoyarskiy Kray"
      },
      {
        "_id": "kurgan",
        "code": "kurgan",
        "name": "Kurgan"
      },
      {
        "_id": "kursk",
        "code": "kursk",
        "name": "Kursk"
      },
      {
        "_id": "leningrad",
        "code": "leningrad",
        "name": "Leningrad"
      },
      {
        "_id": "lipeck",
        "code": "lipeck",
        "name": "Lipeck"
      },
      {
        "_id": "magadan",
        "code": "magadan",
        "name": "Magadan"
      },
      {
        "_id": "marij_el",
        "code": "marij_el",
        "name": "Marij El"
      },
      {
        "_id": "mordovija",
        "code": "mordovija",
        "name": "Mordovija"
      },
      {
        "_id": "moscow",
        "code": "moscow",
        "name": "Moscow"
      },
      {
        "_id": "moskovskaja_oblast",
        "code": "moskovskaja_oblast",
        "name": "Moskovskaja Oblast"
      },
      {
        "_id": "moskovskaya_oblast",
        "code": "moskovskaya_oblast",
        "name": "Moskovskaya Oblast"
      },
      {
        "_id": "moskva",
        "code": "moskva",
        "name": "Moskva"
      },
      {
        "_id": "murmansk",
        "code": "murmansk",
        "name": "Murmansk"
      },
      {
        "_id": "nenets",
        "code": "nenets",
        "name": "Nenets"
      },
      {
        "_id": "nizhnij_novgorod",
        "code": "nizhnij_novgorod",
        "name": "Nizhnij Novgorod"
      },
      {
        "_id": "novgorod",
        "code": "novgorod",
        "name": "Novgorod"
      },
      {
        "_id": "novokusnezk",
        "code": "novokusnezk",
        "name": "Novokusnezk"
      },
      {
        "_id": "novosibirsk",
        "code": "novosibirsk",
        "name": "Novosibirsk"
      },
      {
        "_id": "omsk",
        "code": "omsk",
        "name": "Omsk"
      },
      {
        "_id": "orenburg",
        "code": "orenburg",
        "name": "Orenburg"
      },
      {
        "_id": "orjol",
        "code": "orjol",
        "name": "Orjol"
      },
      {
        "_id": "penza",
        "code": "penza",
        "name": "Penza"
      },
      {
        "_id": "perm",
        "code": "perm",
        "name": "Perm"
      },
      {
        "_id": "primorje",
        "code": "primorje",
        "name": "Primorje"
      },
      {
        "_id": "pskov",
        "code": "pskov",
        "name": "Pskov"
      },
      {
        "_id": "pskovskaya_oblast",
        "code": "pskovskaya_oblast",
        "name": "Pskovskaya Oblast"
      },
      {
        "_id": "rjazan",
        "code": "rjazan",
        "name": "Rjazan"
      },
      {
        "_id": "rostov",
        "code": "rostov",
        "name": "Rostov"
      },
      {
        "_id": "saha",
        "code": "saha",
        "name": "Saha"
      },
      {
        "_id": "sahalin",
        "code": "sahalin",
        "name": "Sahalin"
      },
      {
        "_id": "samara",
        "code": "samara",
        "name": "Samara"
      },
      {
        "_id": "samarskaya",
        "code": "samarskaya",
        "name": "Samarskaya"
      },
      {
        "_id": "sankt-peterburg",
        "code": "sankt-peterburg",
        "name": "Sankt-Peterburg"
      },
      {
        "_id": "saratov",
        "code": "saratov",
        "name": "Saratov"
      },
      {
        "_id": "smolensk",
        "code": "smolensk",
        "name": "Smolensk"
      },
      {
        "_id": "stavropol",
        "code": "stavropol",
        "name": "Stavropol"
      },
      {
        "_id": "sverdlovsk",
        "code": "sverdlovsk",
        "name": "Sverdlovsk"
      },
      {
        "_id": "tajmyrija",
        "code": "tajmyrija",
        "name": "Tajmyrija"
      },
      {
        "_id": "tambov",
        "code": "tambov",
        "name": "Tambov"
      },
      {
        "_id": "tatarstan",
        "code": "tatarstan",
        "name": "Tatarstan"
      },
      {
        "_id": "tjumen",
        "code": "tjumen",
        "name": "Tjumen"
      },
      {
        "_id": "tomsk",
        "code": "tomsk",
        "name": "Tomsk"
      },
      {
        "_id": "tula",
        "code": "tula",
        "name": "Tula"
      },
      {
        "_id": "tver",
        "code": "tver",
        "name": "Tver"
      },
      {
        "_id": "tyva",
        "code": "tyva",
        "name": "Tyva"
      },
      {
        "_id": "udmurtija",
        "code": "udmurtija",
        "name": "Udmurtija"
      },
      {
        "_id": "uljanovsk",
        "code": "uljanovsk",
        "name": "Uljanovsk"
      },
      {
        "_id": "ulyanovskaya_oblast",
        "code": "ulyanovskaya_oblast",
        "name": "Ulyanovskaya Oblast"
      },
      {
        "_id": "ust-orda",
        "code": "ust-orda",
        "name": "Ust-Orda"
      },
      {
        "_id": "vladimir",
        "code": "vladimir",
        "name": "Vladimir"
      },
      {
        "_id": "volgograd",
        "code": "volgograd",
        "name": "Volgograd"
      },
      {
        "_id": "vologda",
        "code": "vologda",
        "name": "Vologda"
      },
      {
        "_id": "voronezh",
        "code": "voronezh",
        "name": "Voronezh"
      }
    ]
  },
  {
    "_id": "RW",
    "code": "RW",
    "name": "Rwanda",
    "states": [
      {
        "_id": "butare",
        "code": "butare",
        "name": "Butare"
      },
      {
        "_id": "byumba",
        "code": "byumba",
        "name": "Byumba"
      },
      {
        "_id": "cyangugu",
        "code": "cyangugu",
        "name": "Cyangugu"
      },
      {
        "_id": "gikongoro",
        "code": "gikongoro",
        "name": "Gikongoro"
      },
      {
        "_id": "gisenyi",
        "code": "gisenyi",
        "name": "Gisenyi"
      },
      {
        "_id": "gitarama",
        "code": "gitarama",
        "name": "Gitarama"
      },
      {
        "_id": "kibungo",
        "code": "kibungo",
        "name": "Kibungo"
      },
      {
        "_id": "kibuye",
        "code": "kibuye",
        "name": "Kibuye"
      },
      {
        "_id": "kigali-ngali",
        "code": "kigali-ngali",
        "name": "Kigali-ngali"
      },
      {
        "_id": "ruhengeri",
        "code": "ruhengeri",
        "name": "Ruhengeri"
      }
    ]
  },
  {
    "_id": "SH",
    "code": "SH",
    "name": "Saint Helena",
    "states": [
      {
        "_id": "ascension",
        "code": "ascension",
        "name": "Ascension"
      },
      {
        "_id": "gough_island",
        "code": "gough_island",
        "name": "Gough Island"
      },
      {
        "_id": "saint_helena",
        "code": "saint_helena",
        "name": "Saint Helena"
      },
      {
        "_id": "tristan_da_cunha",
        "code": "tristan_da_cunha",
        "name": "Tristan da Cunha"
      }
    ]
  },
  {
    "_id": "KN",
    "code": "KN",
    "name": "Saint Kitts And Nevis",
    "states": [
      {
        "_id": "christ_church_nichola_town",
        "code": "christ_church_nichola_town",
        "name": "Christ Church Nichola Town"
      },
      {
        "_id": "saint_anne_sandy_point",
        "code": "saint_anne_sandy_point",
        "name": "Saint Anne Sandy Point"
      },
      {
        "_id": "saint_george_basseterre",
        "code": "saint_george_basseterre",
        "name": "Saint George Basseterre"
      },
      {
        "_id": "saint_george_gingerland",
        "code": "saint_george_gingerland",
        "name": "Saint George Gingerland"
      },
      {
        "_id": "saint_james_windward",
        "code": "saint_james_windward",
        "name": "Saint James Windward"
      },
      {
        "_id": "saint_john_capesterre",
        "code": "saint_john_capesterre",
        "name": "Saint John Capesterre"
      },
      {
        "_id": "saint_john_figtree",
        "code": "saint_john_figtree",
        "name": "Saint John Figtree"
      },
      {
        "_id": "saint_mary_cayon",
        "code": "saint_mary_cayon",
        "name": "Saint Mary Cayon"
      },
      {
        "_id": "saint_paul_capesterre",
        "code": "saint_paul_capesterre",
        "name": "Saint Paul Capesterre"
      },
      {
        "_id": "saint_paul_charlestown",
        "code": "saint_paul_charlestown",
        "name": "Saint Paul Charlestown"
      },
      {
        "_id": "saint_peter_basseterre",
        "code": "saint_peter_basseterre",
        "name": "Saint Peter Basseterre"
      },
      {
        "_id": "saint_thomas_lowland",
        "code": "saint_thomas_lowland",
        "name": "Saint Thomas Lowland"
      },
      {
        "_id": "saint_thomas_middle_island",
        "code": "saint_thomas_middle_island",
        "name": "Saint Thomas Middle Island"
      },
      {
        "_id": "trinity_palmetto_point",
        "code": "trinity_palmetto_point",
        "name": "Trinity Palmetto Point"
      }
    ]
  },
  {
    "_id": "LC",
    "code": "LC",
    "name": "Saint Lucia",
    "states": [
      {
        "_id": "anse-la-raye",
        "code": "anse-la-raye",
        "name": "Anse-la-Raye"
      },
      {
        "_id": "canaries",
        "code": "canaries",
        "name": "Canaries"
      },
      {
        "_id": "castries",
        "code": "castries",
        "name": "Castries"
      },
      {
        "_id": "choiseul",
        "code": "choiseul",
        "name": "Choiseul"
      },
      {
        "_id": "dennery",
        "code": "dennery",
        "name": "Dennery"
      },
      {
        "_id": "gros_inlet",
        "code": "gros_inlet",
        "name": "Gros Inlet"
      },
      {
        "_id": "laborie",
        "code": "laborie",
        "name": "Laborie"
      },
      {
        "_id": "micoud",
        "code": "micoud",
        "name": "Micoud"
      },
      {
        "_id": "soufriere",
        "code": "soufriere",
        "name": "Soufriere"
      },
      {
        "_id": "vieux_fort",
        "code": "vieux_fort",
        "name": "Vieux Fort"
      }
    ]
  },
  {
    "_id": "PM",
    "code": "PM",
    "name": "Saint Pierre and Miquelon",
    "states": [
      {
        "_id": "miquelon-langlade",
        "code": "miquelon-langlade",
        "name": "Miquelon-Langlade"
      },
      {
        "_id": "saint-pierre",
        "code": "saint-pierre",
        "name": "Saint-Pierre"
      }
    ]
  },
  {
    "_id": "VC",
    "code": "VC",
    "name": "Saint Vincent And The Grenadines",
    "states": [
      {
        "_id": "charlotte",
        "code": "charlotte",
        "name": "Charlotte"
      },
      {
        "_id": "grenadines",
        "code": "grenadines",
        "name": "Grenadines"
      },
      {
        "_id": "saint_andrew",
        "code": "saint_andrew",
        "name": "Saint Andrew"
      },
      {
        "_id": "saint_david",
        "code": "saint_david",
        "name": "Saint David"
      },
      {
        "_id": "saint_george",
        "code": "saint_george",
        "name": "Saint George"
      },
      {
        "_id": "saint_patrick",
        "code": "saint_patrick",
        "name": "Saint Patrick"
      }
    ]
  },
  {
    "_id": "WS",
    "code": "WS",
    "name": "Samoa",
    "states": [
      {
        "_id": "aana",
        "code": "aana",
        "name": "Aana"
      },
      {
        "_id": "aiga-i-le-tai",
        "code": "aiga-i-le-tai",
        "name": "Aiga-i-le-Tai"
      },
      {
        "_id": "atua",
        "code": "atua",
        "name": "Atua"
      },
      {
        "_id": "faasaleleaga",
        "code": "faasaleleaga",
        "name": "Faasaleleaga"
      },
      {
        "_id": "gagaemauga",
        "code": "gagaemauga",
        "name": "Gagaemauga"
      },
      {
        "_id": "gagaifomauga",
        "code": "gagaifomauga",
        "name": "Gagaifomauga"
      },
      {
        "_id": "palauli",
        "code": "palauli",
        "name": "Palauli"
      },
      {
        "_id": "satupaitea",
        "code": "satupaitea",
        "name": "Satupaitea"
      },
      {
        "_id": "tuamasaga",
        "code": "tuamasaga",
        "name": "Tuamasaga"
      },
      {
        "_id": "vaa-o-fonoti",
        "code": "vaa-o-fonoti",
        "name": "Vaa-o-Fonoti"
      },
      {
        "_id": "vaisigano",
        "code": "vaisigano",
        "name": "Vaisigano"
      }
    ]
  },
  {
    "_id": "SM",
    "code": "SM",
    "name": "San Marino",
    "states": [
      {
        "_id": "acquaviva",
        "code": "acquaviva",
        "name": "Acquaviva"
      },
      {
        "_id": "borgo_maggiore",
        "code": "borgo_maggiore",
        "name": "Borgo Maggiore"
      },
      {
        "_id": "chiesanuova",
        "code": "chiesanuova",
        "name": "Chiesanuova"
      },
      {
        "_id": "domagnano",
        "code": "domagnano",
        "name": "Domagnano"
      },
      {
        "_id": "faetano",
        "code": "faetano",
        "name": "Faetano"
      },
      {
        "_id": "fiorentino",
        "code": "fiorentino",
        "name": "Fiorentino"
      },
      {
        "_id": "montegiardino",
        "code": "montegiardino",
        "name": "Montegiardino"
      },
      {
        "_id": "san_marino",
        "code": "san_marino",
        "name": "San Marino"
      },
      {
        "_id": "serravalle",
        "code": "serravalle",
        "name": "Serravalle"
      }
    ]
  },
  {
    "_id": "ST",
    "code": "ST",
    "name": "Sao Tome and Principe",
    "states": [
      {
        "_id": "agua_grande",
        "code": "agua_grande",
        "name": "Agua Grande"
      },
      {
        "_id": "cantagalo",
        "code": "cantagalo",
        "name": "Cantagalo"
      },
      {
        "_id": "lemba",
        "code": "lemba",
        "name": "Lemba"
      },
      {
        "_id": "lobata",
        "code": "lobata",
        "name": "Lobata"
      },
      {
        "_id": "me-zochi",
        "code": "me-zochi",
        "name": "Me-Zochi"
      },
      {
        "_id": "pague",
        "code": "pague",
        "name": "Pague"
      }
    ]
  },
  {
    "_id": "SA",
    "code": "SA",
    "name": "Saudi Arabia",
    "states": [
      {
        "_id": "al_khobar",
        "code": "al_khobar",
        "name": "Al Khobar"
      },
      {
        "_id": "aseer",
        "code": "aseer",
        "name": "Aseer"
      },
      {
        "_id": "ash_sharqiyah",
        "code": "ash_sharqiyah",
        "name": "Ash Sharqiyah"
      },
      {
        "_id": "asir",
        "code": "asir",
        "name": "Asir"
      },
      {
        "_id": "central_province",
        "code": "central_province",
        "name": "Central Province"
      },
      {
        "_id": "eastern_province",
        "code": "eastern_province",
        "name": "Eastern Province"
      },
      {
        "_id": "hail",
        "code": "hail",
        "name": "Hail"
      },
      {
        "_id": "jawf",
        "code": "jawf",
        "name": "Jawf"
      },
      {
        "_id": "jizan",
        "code": "jizan",
        "name": "Jizan"
      },
      {
        "_id": "makkah",
        "code": "makkah",
        "name": "Makkah"
      },
      {
        "_id": "najran",
        "code": "najran",
        "name": "Najran"
      },
      {
        "_id": "qasim",
        "code": "qasim",
        "name": "Qasim"
      },
      {
        "_id": "tabuk",
        "code": "tabuk",
        "name": "Tabuk"
      },
      {
        "_id": "western_province",
        "code": "western_province",
        "name": "Western Province"
      },
      {
        "_id": "al-bahah",
        "code": "al-bahah",
        "name": "al-Bahah"
      },
      {
        "_id": "al-hudud-ash-shamaliyah",
        "code": "al-hudud-ash-shamaliyah",
        "name": "al-Hudud-ash-Shamaliyah"
      },
      {
        "_id": "al-madinah",
        "code": "al-madinah",
        "name": "al-Madinah"
      },
      {
        "_id": "ar-riyad",
        "code": "ar-riyad",
        "name": "ar-Riyad"
      }
    ]
  },
  {
    "_id": "SN",
    "code": "SN",
    "name": "Senegal",
    "states": [
      {
        "_id": "dakar",
        "code": "dakar",
        "name": "Dakar"
      },
      {
        "_id": "diourbel",
        "code": "diourbel",
        "name": "Diourbel"
      },
      {
        "_id": "fatick",
        "code": "fatick",
        "name": "Fatick"
      },
      {
        "_id": "kaolack",
        "code": "kaolack",
        "name": "Kaolack"
      },
      {
        "_id": "kolda",
        "code": "kolda",
        "name": "Kolda"
      },
      {
        "_id": "louga",
        "code": "louga",
        "name": "Louga"
      },
      {
        "_id": "saint-louis",
        "code": "saint-louis",
        "name": "Saint-Louis"
      },
      {
        "_id": "tambacounda",
        "code": "tambacounda",
        "name": "Tambacounda"
      },
      {
        "_id": "thies",
        "code": "thies",
        "name": "Thies"
      },
      {
        "_id": "ziguinchor",
        "code": "ziguinchor",
        "name": "Ziguinchor"
      }
    ]
  },
  {
    "_id": "RS",
    "code": "RS",
    "name": "Serbia",
    "states": [
      {
        "_id": "central_serbia",
        "code": "central_serbia",
        "name": "Central Serbia"
      },
      {
        "_id": "kosovo_and_metohija",
        "code": "kosovo_and_metohija",
        "name": "Kosovo and Metohija"
      },
      {
        "_id": "vojvodina",
        "code": "vojvodina",
        "name": "Vojvodina"
      }
    ]
  },
  {
    "_id": "SC",
    "code": "SC",
    "name": "Seychelles",
    "states": [
      {
        "_id": "anse_boileau",
        "code": "anse_boileau",
        "name": "Anse Boileau"
      },
      {
        "_id": "anse_royale",
        "code": "anse_royale",
        "name": "Anse Royale"
      },
      {
        "_id": "cascade",
        "code": "cascade",
        "name": "Cascade"
      },
      {
        "_id": "takamaka",
        "code": "takamaka",
        "name": "Takamaka"
      },
      {
        "_id": "victoria",
        "code": "victoria",
        "name": "Victoria"
      }
    ]
  },
  {
    "_id": "SL",
    "code": "SL",
    "name": "Sierra Leone",
    "states": [
      {
        "_id": "eastern",
        "code": "eastern",
        "name": "Eastern"
      },
      {
        "_id": "northern",
        "code": "northern",
        "name": "Northern"
      },
      {
        "_id": "southern",
        "code": "southern",
        "name": "Southern"
      },
      {
        "_id": "western",
        "code": "western",
        "name": "Western"
      }
    ]
  },
  {
    "_id": "SG",
    "code": "SG",
    "name": "Singapore",
    "states": [
      {
        "_id": "singapore",
        "code": "singapore",
        "name": "Singapore"
      }
    ]
  },
  {
    "_id": "SK",
    "code": "SK",
    "name": "Slovakia",
    "states": [
      {
        "_id": "banskobystricky",
        "code": "banskobystricky",
        "name": "Banskobystricky"
      },
      {
        "_id": "bratislavsky",
        "code": "bratislavsky",
        "name": "Bratislavsky"
      },
      {
        "_id": "kosicky",
        "code": "kosicky",
        "name": "Kosicky"
      },
      {
        "_id": "nitriansky",
        "code": "nitriansky",
        "name": "Nitriansky"
      },
      {
        "_id": "presovsky",
        "code": "presovsky",
        "name": "Presovsky"
      },
      {
        "_id": "trenciansky",
        "code": "trenciansky",
        "name": "Trenciansky"
      },
      {
        "_id": "trnavsky",
        "code": "trnavsky",
        "name": "Trnavsky"
      },
      {
        "_id": "zilinsky",
        "code": "zilinsky",
        "name": "Zilinsky"
      }
    ]
  },
  {
    "_id": "SI",
    "code": "SI",
    "name": "Slovenia",
    "states": [
      {
        "_id": "benedikt",
        "code": "benedikt",
        "name": "Benedikt"
      },
      {
        "_id": "gorenjska",
        "code": "gorenjska",
        "name": "Gorenjska"
      },
      {
        "_id": "gorishka",
        "code": "gorishka",
        "name": "Gorishka"
      },
      {
        "_id": "jugovzhodna_slovenija",
        "code": "jugovzhodna_slovenija",
        "name": "Jugovzhodna Slovenija"
      },
      {
        "_id": "koroshka",
        "code": "koroshka",
        "name": "Koroshka"
      },
      {
        "_id": "notranjsko-krashka",
        "code": "notranjsko-krashka",
        "name": "Notranjsko-krashka"
      },
      {
        "_id": "obalno-krashka",
        "code": "obalno-krashka",
        "name": "Obalno-krashka"
      },
      {
        "_id": "obcina_domzale",
        "code": "obcina_domzale",
        "name": "Obcina Domzale"
      },
      {
        "_id": "obcina_vitanje",
        "code": "obcina_vitanje",
        "name": "Obcina Vitanje"
      },
      {
        "_id": "osrednjeslovenska",
        "code": "osrednjeslovenska",
        "name": "Osrednjeslovenska"
      },
      {
        "_id": "podravska",
        "code": "podravska",
        "name": "Podravska"
      },
      {
        "_id": "pomurska",
        "code": "pomurska",
        "name": "Pomurska"
      },
      {
        "_id": "savinjska",
        "code": "savinjska",
        "name": "Savinjska"
      },
      {
        "_id": "slovenian_littoral",
        "code": "slovenian_littoral",
        "name": "Slovenian Littoral"
      },
      {
        "_id": "spodnjeposavska",
        "code": "spodnjeposavska",
        "name": "Spodnjeposavska"
      },
      {
        "_id": "zasavska",
        "code": "zasavska",
        "name": "Zasavska"
      }
    ]
  },
  {
    "_id": "XG",
    "code": "XG",
    "name": "Smaller Territories of the UK",
    "states": [
      {
        "_id": "pitcairn",
        "code": "pitcairn",
        "name": "Pitcairn"
      }
    ]
  },
  {
    "_id": "SB",
    "code": "SB",
    "name": "Solomon Islands",
    "states": [
      {
        "_id": "central",
        "code": "central",
        "name": "Central"
      },
      {
        "_id": "choiseul",
        "code": "choiseul",
        "name": "Choiseul"
      },
      {
        "_id": "guadalcanal",
        "code": "guadalcanal",
        "name": "Guadalcanal"
      },
      {
        "_id": "isabel",
        "code": "isabel",
        "name": "Isabel"
      },
      {
        "_id": "makira_and_ulawa",
        "code": "makira_and_ulawa",
        "name": "Makira and Ulawa"
      },
      {
        "_id": "malaita",
        "code": "malaita",
        "name": "Malaita"
      },
      {
        "_id": "rennell_and_bellona",
        "code": "rennell_and_bellona",
        "name": "Rennell and Bellona"
      },
      {
        "_id": "temotu",
        "code": "temotu",
        "name": "Temotu"
      },
      {
        "_id": "western",
        "code": "western",
        "name": "Western"
      }
    ]
  },
  {
    "_id": "SO",
    "code": "SO",
    "name": "Somalia",
    "states": [
      {
        "_id": "awdal",
        "code": "awdal",
        "name": "Awdal"
      },
      {
        "_id": "bakol",
        "code": "bakol",
        "name": "Bakol"
      },
      {
        "_id": "banadir",
        "code": "banadir",
        "name": "Banadir"
      },
      {
        "_id": "bari",
        "code": "bari",
        "name": "Bari"
      },
      {
        "_id": "bay",
        "code": "bay",
        "name": "Bay"
      },
      {
        "_id": "galgudug",
        "code": "galgudug",
        "name": "Galgudug"
      },
      {
        "_id": "gedo",
        "code": "gedo",
        "name": "Gedo"
      },
      {
        "_id": "hiran",
        "code": "hiran",
        "name": "Hiran"
      },
      {
        "_id": "jubbada_hose",
        "code": "jubbada_hose",
        "name": "Jubbada Hose"
      },
      {
        "_id": "jubbadha_dexe",
        "code": "jubbadha_dexe",
        "name": "Jubbadha Dexe"
      },
      {
        "_id": "mudug",
        "code": "mudug",
        "name": "Mudug"
      },
      {
        "_id": "nugal",
        "code": "nugal",
        "name": "Nugal"
      },
      {
        "_id": "sanag",
        "code": "sanag",
        "name": "Sanag"
      },
      {
        "_id": "shabellaha_dhexe",
        "code": "shabellaha_dhexe",
        "name": "Shabellaha Dhexe"
      },
      {
        "_id": "shabellaha_hose",
        "code": "shabellaha_hose",
        "name": "Shabellaha Hose"
      },
      {
        "_id": "togdher",
        "code": "togdher",
        "name": "Togdher"
      },
      {
        "_id": "woqoyi_galbed",
        "code": "woqoyi_galbed",
        "name": "Woqoyi Galbed"
      }
    ]
  },
  {
    "_id": "ZA",
    "code": "ZA",
    "name": "South Africa",
    "states": [
      {
        "_id": "eastern_cape",
        "code": "eastern_cape",
        "name": "Eastern Cape"
      },
      {
        "_id": "free_state",
        "code": "free_state",
        "name": "Free State"
      },
      {
        "_id": "gauteng",
        "code": "gauteng",
        "name": "Gauteng"
      },
      {
        "_id": "kempton_park",
        "code": "kempton_park",
        "name": "Kempton Park"
      },
      {
        "_id": "kramerville",
        "code": "kramerville",
        "name": "Kramerville"
      },
      {
        "_id": "kwazulu_natal",
        "code": "kwazulu_natal",
        "name": "KwaZulu Natal"
      },
      {
        "_id": "limpopo",
        "code": "limpopo",
        "name": "Limpopo"
      },
      {
        "_id": "mpumalanga",
        "code": "mpumalanga",
        "name": "Mpumalanga"
      },
      {
        "_id": "north_west",
        "code": "north_west",
        "name": "North West"
      },
      {
        "_id": "northern_cape",
        "code": "northern_cape",
        "name": "Northern Cape"
      },
      {
        "_id": "parow",
        "code": "parow",
        "name": "Parow"
      },
      {
        "_id": "table_view",
        "code": "table_view",
        "name": "Table View"
      },
      {
        "_id": "umtentweni",
        "code": "umtentweni",
        "name": "Umtentweni"
      },
      {
        "_id": "western_cape",
        "code": "western_cape",
        "name": "Western Cape"
      }
    ]
  },
  {
    "_id": "GS",
    "code": "GS",
    "name": "South Georgia",
    "states": [
      {
        "_id": "south_georgia",
        "code": "south_georgia",
        "name": "South Georgia"
      }
    ]
  },
  {
    "_id": "SS",
    "code": "SS",
    "name": "South Sudan",
    "states": [
      {
        "_id": "central_equatoria",
        "code": "central_equatoria",
        "name": "Central Equatoria"
      }
    ]
  },
  {
    "_id": "ES",
    "code": "ES",
    "name": "Spain",
    "states": [
      {
        "_id": "a_coruna",
        "code": "a_coruna",
        "name": "A Coruna"
      },
      {
        "_id": "alacant",
        "code": "alacant",
        "name": "Alacant"
      },
      {
        "_id": "alava",
        "code": "alava",
        "name": "Alava"
      },
      {
        "_id": "albacete",
        "code": "albacete",
        "name": "Albacete"
      },
      {
        "_id": "almeria",
        "code": "almeria",
        "name": "Almeria"
      },
      {
        "_id": "andalucia",
        "code": "andalucia",
        "name": "Andalucia"
      },
      {
        "_id": "asturias",
        "code": "asturias",
        "name": "Asturias"
      },
      {
        "_id": "avila",
        "code": "avila",
        "name": "Avila"
      },
      {
        "_id": "badajoz",
        "code": "badajoz",
        "name": "Badajoz"
      },
      {
        "_id": "balears",
        "code": "balears",
        "name": "Balears"
      },
      {
        "_id": "barcelona",
        "code": "barcelona",
        "name": "Barcelona"
      },
      {
        "_id": "bertamirans",
        "code": "bertamirans",
        "name": "Bertamirans"
      },
      {
        "_id": "biscay",
        "code": "biscay",
        "name": "Biscay"
      },
      {
        "_id": "burgos",
        "code": "burgos",
        "name": "Burgos"
      },
      {
        "_id": "caceres",
        "code": "caceres",
        "name": "Caceres"
      },
      {
        "_id": "cadiz",
        "code": "cadiz",
        "name": "Cadiz"
      },
      {
        "_id": "cantabria",
        "code": "cantabria",
        "name": "Cantabria"
      },
      {
        "_id": "castello",
        "code": "castello",
        "name": "Castello"
      },
      {
        "_id": "catalunya",
        "code": "catalunya",
        "name": "Catalunya"
      },
      {
        "_id": "ceuta",
        "code": "ceuta",
        "name": "Ceuta"
      },
      {
        "_id": "ciudad_real",
        "code": "ciudad_real",
        "name": "Ciudad Real"
      },
      {
        "_id": "comunidad_autonoma_de_canarias",
        "code": "comunidad_autonoma_de_canarias",
        "name": "Comunidad Autonoma de Canarias"
      },
      {
        "_id": "comunidad_autonoma_de_cataluna",
        "code": "comunidad_autonoma_de_cataluna",
        "name": "Comunidad Autonoma de Cataluna"
      },
      {
        "_id": "comunidad_autonoma_de_galicia",
        "code": "comunidad_autonoma_de_galicia",
        "name": "Comunidad Autonoma de Galicia"
      },
      {
        "_id": "comunidad_autonoma_de_las_isla",
        "code": "comunidad_autonoma_de_las_isla",
        "name": "Comunidad Autonoma de las Isla"
      },
      {
        "_id": "comunidad_autonoma_del_princip",
        "code": "comunidad_autonoma_del_princip",
        "name": "Comunidad Autonoma del Princip"
      },
      {
        "_id": "comunidad_valenciana",
        "code": "comunidad_valenciana",
        "name": "Comunidad Valenciana"
      },
      {
        "_id": "cordoba",
        "code": "cordoba",
        "name": "Cordoba"
      },
      {
        "_id": "cuenca",
        "code": "cuenca",
        "name": "Cuenca"
      },
      {
        "_id": "gipuzkoa",
        "code": "gipuzkoa",
        "name": "Gipuzkoa"
      },
      {
        "_id": "girona",
        "code": "girona",
        "name": "Girona"
      },
      {
        "_id": "granada",
        "code": "granada",
        "name": "Granada"
      },
      {
        "_id": "guadalajara",
        "code": "guadalajara",
        "name": "Guadalajara"
      },
      {
        "_id": "guipuzcoa",
        "code": "guipuzcoa",
        "name": "Guipuzcoa"
      },
      {
        "_id": "huelva",
        "code": "huelva",
        "name": "Huelva"
      },
      {
        "_id": "huesca",
        "code": "huesca",
        "name": "Huesca"
      },
      {
        "_id": "jaen",
        "code": "jaen",
        "name": "Jaen"
      },
      {
        "_id": "la_rioja",
        "code": "la_rioja",
        "name": "La Rioja"
      },
      {
        "_id": "las_palmas",
        "code": "las_palmas",
        "name": "Las Palmas"
      },
      {
        "_id": "leon",
        "code": "leon",
        "name": "Leon"
      },
      {
        "_id": "lerida",
        "code": "lerida",
        "name": "Lerida"
      },
      {
        "_id": "lleida",
        "code": "lleida",
        "name": "Lleida"
      },
      {
        "_id": "lugo",
        "code": "lugo",
        "name": "Lugo"
      },
      {
        "_id": "madrid",
        "code": "madrid",
        "name": "Madrid"
      },
      {
        "_id": "malaga",
        "code": "malaga",
        "name": "Malaga"
      },
      {
        "_id": "melilla",
        "code": "melilla",
        "name": "Melilla"
      },
      {
        "_id": "murcia",
        "code": "murcia",
        "name": "Murcia"
      },
      {
        "_id": "navarra",
        "code": "navarra",
        "name": "Navarra"
      },
      {
        "_id": "ourense",
        "code": "ourense",
        "name": "Ourense"
      },
      {
        "_id": "pais_vasco",
        "code": "pais_vasco",
        "name": "Pais Vasco"
      },
      {
        "_id": "palencia",
        "code": "palencia",
        "name": "Palencia"
      },
      {
        "_id": "pontevedra",
        "code": "pontevedra",
        "name": "Pontevedra"
      },
      {
        "_id": "salamanca",
        "code": "salamanca",
        "name": "Salamanca"
      },
      {
        "_id": "santa_cruz_de_tenerife",
        "code": "santa_cruz_de_tenerife",
        "name": "Santa Cruz de Tenerife"
      },
      {
        "_id": "segovia",
        "code": "segovia",
        "name": "Segovia"
      },
      {
        "_id": "sevilla",
        "code": "sevilla",
        "name": "Sevilla"
      },
      {
        "_id": "soria",
        "code": "soria",
        "name": "Soria"
      },
      {
        "_id": "tarragona",
        "code": "tarragona",
        "name": "Tarragona"
      },
      {
        "_id": "tenerife",
        "code": "tenerife",
        "name": "Tenerife"
      },
      {
        "_id": "teruel",
        "code": "teruel",
        "name": "Teruel"
      },
      {
        "_id": "toledo",
        "code": "toledo",
        "name": "Toledo"
      },
      {
        "_id": "valencia",
        "code": "valencia",
        "name": "Valencia"
      },
      {
        "_id": "valladolid",
        "code": "valladolid",
        "name": "Valladolid"
      },
      {
        "_id": "vizcaya",
        "code": "vizcaya",
        "name": "Vizcaya"
      },
      {
        "_id": "zamora",
        "code": "zamora",
        "name": "Zamora"
      },
      {
        "_id": "zaragoza",
        "code": "zaragoza",
        "name": "Zaragoza"
      }
    ]
  },
  {
    "_id": "LK",
    "code": "LK",
    "name": "Sri Lanka",
    "states": [
      {
        "_id": "amparai",
        "code": "amparai",
        "name": "Amparai"
      },
      {
        "_id": "anuradhapuraya",
        "code": "anuradhapuraya",
        "name": "Anuradhapuraya"
      },
      {
        "_id": "badulla",
        "code": "badulla",
        "name": "Badulla"
      },
      {
        "_id": "boralesgamuwa",
        "code": "boralesgamuwa",
        "name": "Boralesgamuwa"
      },
      {
        "_id": "colombo",
        "code": "colombo",
        "name": "Colombo"
      },
      {
        "_id": "galla",
        "code": "galla",
        "name": "Galla"
      },
      {
        "_id": "gampaha",
        "code": "gampaha",
        "name": "Gampaha"
      },
      {
        "_id": "hambantota",
        "code": "hambantota",
        "name": "Hambantota"
      },
      {
        "_id": "kalatura",
        "code": "kalatura",
        "name": "Kalatura"
      },
      {
        "_id": "kegalla",
        "code": "kegalla",
        "name": "Kegalla"
      },
      {
        "_id": "kilinochchi",
        "code": "kilinochchi",
        "name": "Kilinochchi"
      },
      {
        "_id": "kurunegala",
        "code": "kurunegala",
        "name": "Kurunegala"
      },
      {
        "_id": "madakalpuwa",
        "code": "madakalpuwa",
        "name": "Madakalpuwa"
      },
      {
        "_id": "maha_nuwara",
        "code": "maha_nuwara",
        "name": "Maha Nuwara"
      },
      {
        "_id": "malwana",
        "code": "malwana",
        "name": "Malwana"
      },
      {
        "_id": "mannarama",
        "code": "mannarama",
        "name": "Mannarama"
      },
      {
        "_id": "matale",
        "code": "matale",
        "name": "Matale"
      },
      {
        "_id": "matara",
        "code": "matara",
        "name": "Matara"
      },
      {
        "_id": "monaragala",
        "code": "monaragala",
        "name": "Monaragala"
      },
      {
        "_id": "mullaitivu",
        "code": "mullaitivu",
        "name": "Mullaitivu"
      },
      {
        "_id": "north_eastern_province",
        "code": "north_eastern_province",
        "name": "North Eastern Province"
      },
      {
        "_id": "north_western_province",
        "code": "north_western_province",
        "name": "North Western Province"
      },
      {
        "_id": "nuwara_eliya",
        "code": "nuwara_eliya",
        "name": "Nuwara Eliya"
      },
      {
        "_id": "polonnaruwa",
        "code": "polonnaruwa",
        "name": "Polonnaruwa"
      },
      {
        "_id": "puttalama",
        "code": "puttalama",
        "name": "Puttalama"
      },
      {
        "_id": "ratnapuraya",
        "code": "ratnapuraya",
        "name": "Ratnapuraya"
      },
      {
        "_id": "southern_province",
        "code": "southern_province",
        "name": "Southern Province"
      },
      {
        "_id": "tirikunamalaya",
        "code": "tirikunamalaya",
        "name": "Tirikunamalaya"
      },
      {
        "_id": "tuscany",
        "code": "tuscany",
        "name": "Tuscany"
      },
      {
        "_id": "vavuniyawa",
        "code": "vavuniyawa",
        "name": "Vavuniyawa"
      },
      {
        "_id": "western_province",
        "code": "western_province",
        "name": "Western Province"
      },
      {
        "_id": "yapanaya",
        "code": "yapanaya",
        "name": "Yapanaya"
      },
      {
        "_id": "kadawatha",
        "code": "kadawatha",
        "name": "kadawatha"
      }
    ]
  },
  {
    "_id": "SD",
    "code": "SD",
    "name": "Sudan",
    "states": [
      {
        "_id": "aali-an-nil",
        "code": "aali-an-nil",
        "name": "Aali-an-Nil"
      },
      {
        "_id": "bahr-al-jabal",
        "code": "bahr-al-jabal",
        "name": "Bahr-al-Jabal"
      },
      {
        "_id": "central_equatoria",
        "code": "central_equatoria",
        "name": "Central Equatoria"
      },
      {
        "_id": "gharb_bahr-al-ghazal",
        "code": "gharb_bahr-al-ghazal",
        "name": "Gharb Bahr-al-Ghazal"
      },
      {
        "_id": "gharb_darfur",
        "code": "gharb_darfur",
        "name": "Gharb Darfur"
      },
      {
        "_id": "gharb_kurdufan",
        "code": "gharb_kurdufan",
        "name": "Gharb Kurdufan"
      },
      {
        "_id": "gharb-al-istiwaiyah",
        "code": "gharb-al-istiwaiyah",
        "name": "Gharb-al-Istiwaiyah"
      },
      {
        "_id": "janub_darfur",
        "code": "janub_darfur",
        "name": "Janub Darfur"
      },
      {
        "_id": "janub_kurdufan",
        "code": "janub_kurdufan",
        "name": "Janub Kurdufan"
      },
      {
        "_id": "junqali",
        "code": "junqali",
        "name": "Junqali"
      },
      {
        "_id": "kassala",
        "code": "kassala",
        "name": "Kassala"
      },
      {
        "_id": "nahr-an-nil",
        "code": "nahr-an-nil",
        "name": "Nahr-an-Nil"
      },
      {
        "_id": "shamal_bahr-al-ghazal",
        "code": "shamal_bahr-al-ghazal",
        "name": "Shamal Bahr-al-Ghazal"
      },
      {
        "_id": "shamal_darfur",
        "code": "shamal_darfur",
        "name": "Shamal Darfur"
      },
      {
        "_id": "shamal_kurdufan",
        "code": "shamal_kurdufan",
        "name": "Shamal Kurdufan"
      },
      {
        "_id": "sharq-al-istiwaiyah",
        "code": "sharq-al-istiwaiyah",
        "name": "Sharq-al-Istiwaiyah"
      },
      {
        "_id": "sinnar",
        "code": "sinnar",
        "name": "Sinnar"
      },
      {
        "_id": "warab",
        "code": "warab",
        "name": "Warab"
      },
      {
        "_id": "wilayat_al_khartum",
        "code": "wilayat_al_khartum",
        "name": "Wilayat al Khartum"
      },
      {
        "_id": "al-bahr-al-ahmar",
        "code": "al-bahr-al-ahmar",
        "name": "al-Bahr-al-Ahmar"
      },
      {
        "_id": "al-buhayrat",
        "code": "al-buhayrat",
        "name": "al-Buhayrat"
      },
      {
        "_id": "al-jazirah",
        "code": "al-jazirah",
        "name": "al-Jazirah"
      },
      {
        "_id": "al-khartum",
        "code": "al-khartum",
        "name": "al-Khartum"
      },
      {
        "_id": "al-qadarif",
        "code": "al-qadarif",
        "name": "al-Qadarif"
      },
      {
        "_id": "al-wahdah",
        "code": "al-wahdah",
        "name": "al-Wahdah"
      },
      {
        "_id": "an-nil-al-abyad",
        "code": "an-nil-al-abyad",
        "name": "an-Nil-al-Abyad"
      },
      {
        "_id": "an-nil-al-azraq",
        "code": "an-nil-al-azraq",
        "name": "an-Nil-al-Azraq"
      },
      {
        "_id": "ash-shamaliyah",
        "code": "ash-shamaliyah",
        "name": "ash-Shamaliyah"
      }
    ]
  },
  {
    "_id": "SR",
    "code": "SR",
    "name": "Suriname",
    "states": [
      {
        "_id": "brokopondo",
        "code": "brokopondo",
        "name": "Brokopondo"
      },
      {
        "_id": "commewijne",
        "code": "commewijne",
        "name": "Commewijne"
      },
      {
        "_id": "coronie",
        "code": "coronie",
        "name": "Coronie"
      },
      {
        "_id": "marowijne",
        "code": "marowijne",
        "name": "Marowijne"
      },
      {
        "_id": "nickerie",
        "code": "nickerie",
        "name": "Nickerie"
      },
      {
        "_id": "para",
        "code": "para",
        "name": "Para"
      },
      {
        "_id": "paramaribo",
        "code": "paramaribo",
        "name": "Paramaribo"
      },
      {
        "_id": "saramacca",
        "code": "saramacca",
        "name": "Saramacca"
      },
      {
        "_id": "wanica",
        "code": "wanica",
        "name": "Wanica"
      }
    ]
  },
  {
    "_id": "SJ",
    "code": "SJ",
    "name": "Svalbard And Jan Mayen Islands",
    "states": [
      {
        "_id": "svalbard",
        "code": "svalbard",
        "name": "Svalbard"
      }
    ]
  },
  {
    "_id": "SZ",
    "code": "SZ",
    "name": "Swaziland",
    "states": [
      {
        "_id": "hhohho",
        "code": "hhohho",
        "name": "Hhohho"
      },
      {
        "_id": "lubombo",
        "code": "lubombo",
        "name": "Lubombo"
      },
      {
        "_id": "manzini",
        "code": "manzini",
        "name": "Manzini"
      },
      {
        "_id": "shiselweni",
        "code": "shiselweni",
        "name": "Shiselweni"
      }
    ]
  },
  {
    "_id": "SE",
    "code": "SE",
    "name": "Sweden",
    "states": [
      {
        "_id": "alvsborgs_lan",
        "code": "alvsborgs_lan",
        "name": "Alvsborgs Lan"
      },
      {
        "_id": "angermanland",
        "code": "angermanland",
        "name": "Angermanland"
      },
      {
        "_id": "blekinge",
        "code": "blekinge",
        "name": "Blekinge"
      },
      {
        "_id": "bohuslan",
        "code": "bohuslan",
        "name": "Bohuslan"
      },
      {
        "_id": "dalarna",
        "code": "dalarna",
        "name": "Dalarna"
      },
      {
        "_id": "gavleborg",
        "code": "gavleborg",
        "name": "Gavleborg"
      },
      {
        "_id": "gaza",
        "code": "gaza",
        "name": "Gaza"
      },
      {
        "_id": "gotland",
        "code": "gotland",
        "name": "Gotland"
      },
      {
        "_id": "halland",
        "code": "halland",
        "name": "Halland"
      },
      {
        "_id": "jamtland",
        "code": "jamtland",
        "name": "Jamtland"
      },
      {
        "_id": "jonkoping",
        "code": "jonkoping",
        "name": "Jonkoping"
      },
      {
        "_id": "kalmar",
        "code": "kalmar",
        "name": "Kalmar"
      },
      {
        "_id": "kristianstads",
        "code": "kristianstads",
        "name": "Kristianstads"
      },
      {
        "_id": "kronoberg",
        "code": "kronoberg",
        "name": "Kronoberg"
      },
      {
        "_id": "norrbotten",
        "code": "norrbotten",
        "name": "Norrbotten"
      },
      {
        "_id": "orebro",
        "code": "orebro",
        "name": "Orebro"
      },
      {
        "_id": "ostergotland",
        "code": "ostergotland",
        "name": "Ostergotland"
      },
      {
        "_id": "saltsjo-boo",
        "code": "saltsjo-boo",
        "name": "Saltsjo-Boo"
      },
      {
        "_id": "skane",
        "code": "skane",
        "name": "Skane"
      },
      {
        "_id": "smaland",
        "code": "smaland",
        "name": "Smaland"
      },
      {
        "_id": "sodermanland",
        "code": "sodermanland",
        "name": "Sodermanland"
      },
      {
        "_id": "stockholm",
        "code": "stockholm",
        "name": "Stockholm"
      },
      {
        "_id": "uppsala",
        "code": "uppsala",
        "name": "Uppsala"
      },
      {
        "_id": "varmland",
        "code": "varmland",
        "name": "Varmland"
      },
      {
        "_id": "vasterbotten",
        "code": "vasterbotten",
        "name": "Vasterbotten"
      },
      {
        "_id": "vastergotland",
        "code": "vastergotland",
        "name": "Vastergotland"
      },
      {
        "_id": "vasternorrland",
        "code": "vasternorrland",
        "name": "Vasternorrland"
      },
      {
        "_id": "vastmanland",
        "code": "vastmanland",
        "name": "Vastmanland"
      },
      {
        "_id": "vastra_gotaland",
        "code": "vastra_gotaland",
        "name": "Vastra Gotaland"
      }
    ]
  },
  {
    "_id": "CH",
    "code": "CH",
    "name": "Switzerland",
    "states": [
      {
        "_id": "aargau",
        "code": "aargau",
        "name": "Aargau"
      },
      {
        "_id": "appenzell_inner-rhoden",
        "code": "appenzell_inner-rhoden",
        "name": "Appenzell Inner-Rhoden"
      },
      {
        "_id": "appenzell-ausser_rhoden",
        "code": "appenzell-ausser_rhoden",
        "name": "Appenzell-Ausser Rhoden"
      },
      {
        "_id": "basel-landschaft",
        "code": "basel-landschaft",
        "name": "Basel-Landschaft"
      },
      {
        "_id": "basel-stadt",
        "code": "basel-stadt",
        "name": "Basel-Stadt"
      },
      {
        "_id": "bern",
        "code": "bern",
        "name": "Bern"
      },
      {
        "_id": "canton_ticino",
        "code": "canton_ticino",
        "name": "Canton Ticino"
      },
      {
        "_id": "fribourg",
        "code": "fribourg",
        "name": "Fribourg"
      },
      {
        "_id": "geneve",
        "code": "geneve",
        "name": "Geneve"
      },
      {
        "_id": "glarus",
        "code": "glarus",
        "name": "Glarus"
      },
      {
        "_id": "graubunden",
        "code": "graubunden",
        "name": "Graubunden"
      },
      {
        "_id": "heerbrugg",
        "code": "heerbrugg",
        "name": "Heerbrugg"
      },
      {
        "_id": "jura",
        "code": "jura",
        "name": "Jura"
      },
      {
        "_id": "kanton_aargau",
        "code": "kanton_aargau",
        "name": "Kanton Aargau"
      },
      {
        "_id": "luzern",
        "code": "luzern",
        "name": "Luzern"
      },
      {
        "_id": "morbio_inferiore",
        "code": "morbio_inferiore",
        "name": "Morbio Inferiore"
      },
      {
        "_id": "muhen",
        "code": "muhen",
        "name": "Muhen"
      },
      {
        "_id": "neuchatel",
        "code": "neuchatel",
        "name": "Neuchatel"
      },
      {
        "_id": "nidwalden",
        "code": "nidwalden",
        "name": "Nidwalden"
      },
      {
        "_id": "obwalden",
        "code": "obwalden",
        "name": "Obwalden"
      },
      {
        "_id": "sankt_gallen",
        "code": "sankt_gallen",
        "name": "Sankt Gallen"
      },
      {
        "_id": "schaffhausen",
        "code": "schaffhausen",
        "name": "Schaffhausen"
      },
      {
        "_id": "schwyz",
        "code": "schwyz",
        "name": "Schwyz"
      },
      {
        "_id": "solothurn",
        "code": "solothurn",
        "name": "Solothurn"
      },
      {
        "_id": "thurgau",
        "code": "thurgau",
        "name": "Thurgau"
      },
      {
        "_id": "ticino",
        "code": "ticino",
        "name": "Ticino"
      },
      {
        "_id": "uri",
        "code": "uri",
        "name": "Uri"
      },
      {
        "_id": "valais",
        "code": "valais",
        "name": "Valais"
      },
      {
        "_id": "vaud",
        "code": "vaud",
        "name": "Vaud"
      },
      {
        "_id": "vauffelin",
        "code": "vauffelin",
        "name": "Vauffelin"
      },
      {
        "_id": "zug",
        "code": "zug",
        "name": "Zug"
      },
      {
        "_id": "zurich",
        "code": "zurich",
        "name": "Zurich"
      }
    ]
  },
  {
    "_id": "SY",
    "code": "SY",
    "name": "Syria",
    "states": [
      {
        "_id": "aleppo",
        "code": "aleppo",
        "name": "Aleppo"
      },
      {
        "_id": "dara",
        "code": "dara",
        "name": "Dara"
      },
      {
        "_id": "dayr-az-zawr",
        "code": "dayr-az-zawr",
        "name": "Dayr-az-Zawr"
      },
      {
        "_id": "dimashq",
        "code": "dimashq",
        "name": "Dimashq"
      },
      {
        "_id": "halab",
        "code": "halab",
        "name": "Halab"
      },
      {
        "_id": "hamah",
        "code": "hamah",
        "name": "Hamah"
      },
      {
        "_id": "hims",
        "code": "hims",
        "name": "Hims"
      },
      {
        "_id": "idlib",
        "code": "idlib",
        "name": "Idlib"
      },
      {
        "_id": "madinat_dimashq",
        "code": "madinat_dimashq",
        "name": "Madinat Dimashq"
      },
      {
        "_id": "tartus",
        "code": "tartus",
        "name": "Tartus"
      },
      {
        "_id": "al-hasakah",
        "code": "al-hasakah",
        "name": "al-Hasakah"
      },
      {
        "_id": "al-ladhiqiyah",
        "code": "al-ladhiqiyah",
        "name": "al-Ladhiqiyah"
      },
      {
        "_id": "al-qunaytirah",
        "code": "al-qunaytirah",
        "name": "al-Qunaytirah"
      },
      {
        "_id": "ar-raqqah",
        "code": "ar-raqqah",
        "name": "ar-Raqqah"
      },
      {
        "_id": "as-suwayda",
        "code": "as-suwayda",
        "name": "as-Suwayda"
      }
    ]
  },
  {
    "_id": "TW",
    "code": "TW",
    "name": "Taiwan",
    "states": [
      {
        "_id": "changhwa",
        "code": "changhwa",
        "name": "Changhwa"
      },
      {
        "_id": "chiayi_hsien",
        "code": "chiayi_hsien",
        "name": "Chiayi Hsien"
      },
      {
        "_id": "chiayi_shih",
        "code": "chiayi_shih",
        "name": "Chiayi Shih"
      },
      {
        "_id": "eastern_taipei",
        "code": "eastern_taipei",
        "name": "Eastern Taipei"
      },
      {
        "_id": "hsinchu_hsien",
        "code": "hsinchu_hsien",
        "name": "Hsinchu Hsien"
      },
      {
        "_id": "hsinchu_shih",
        "code": "hsinchu_shih",
        "name": "Hsinchu Shih"
      },
      {
        "_id": "hualien",
        "code": "hualien",
        "name": "Hualien"
      },
      {
        "_id": "ilan",
        "code": "ilan",
        "name": "Ilan"
      },
      {
        "_id": "kaohsiung_hsien",
        "code": "kaohsiung_hsien",
        "name": "Kaohsiung Hsien"
      },
      {
        "_id": "kaohsiung_shih",
        "code": "kaohsiung_shih",
        "name": "Kaohsiung Shih"
      },
      {
        "_id": "keelung_shih",
        "code": "keelung_shih",
        "name": "Keelung Shih"
      },
      {
        "_id": "kinmen",
        "code": "kinmen",
        "name": "Kinmen"
      },
      {
        "_id": "miaoli",
        "code": "miaoli",
        "name": "Miaoli"
      },
      {
        "_id": "nantou",
        "code": "nantou",
        "name": "Nantou"
      },
      {
        "_id": "northern_taiwan",
        "code": "northern_taiwan",
        "name": "Northern Taiwan"
      },
      {
        "_id": "penghu",
        "code": "penghu",
        "name": "Penghu"
      },
      {
        "_id": "pingtung",
        "code": "pingtung",
        "name": "Pingtung"
      },
      {
        "_id": "taichung",
        "code": "taichung",
        "name": "Taichung"
      },
      {
        "_id": "taichung_hsien",
        "code": "taichung_hsien",
        "name": "Taichung Hsien"
      },
      {
        "_id": "taichung_shih",
        "code": "taichung_shih",
        "name": "Taichung Shih"
      },
      {
        "_id": "tainan_hsien",
        "code": "tainan_hsien",
        "name": "Tainan Hsien"
      },
      {
        "_id": "tainan_shih",
        "code": "tainan_shih",
        "name": "Tainan Shih"
      },
      {
        "_id": "taipei_hsien",
        "code": "taipei_hsien",
        "name": "Taipei Hsien"
      },
      {
        "_id": "taipei_shih_/_taipei_hsien",
        "code": "taipei_shih_/_taipei_hsien",
        "name": "Taipei Shih / Taipei Hsien"
      },
      {
        "_id": "taitung",
        "code": "taitung",
        "name": "Taitung"
      },
      {
        "_id": "taoyuan",
        "code": "taoyuan",
        "name": "Taoyuan"
      },
      {
        "_id": "yilan",
        "code": "yilan",
        "name": "Yilan"
      },
      {
        "_id": "yun-lin_hsien",
        "code": "yun-lin_hsien",
        "name": "Yun-Lin Hsien"
      },
      {
        "_id": "yunlin",
        "code": "yunlin",
        "name": "Yunlin"
      }
    ]
  },
  {
    "_id": "TJ",
    "code": "TJ",
    "name": "Tajikistan",
    "states": [
      {
        "_id": "dushanbe",
        "code": "dushanbe",
        "name": "Dushanbe"
      },
      {
        "_id": "gorno-badakhshan",
        "code": "gorno-badakhshan",
        "name": "Gorno-Badakhshan"
      },
      {
        "_id": "karotegin",
        "code": "karotegin",
        "name": "Karotegin"
      },
      {
        "_id": "khatlon",
        "code": "khatlon",
        "name": "Khatlon"
      },
      {
        "_id": "sughd",
        "code": "sughd",
        "name": "Sughd"
      }
    ]
  },
  {
    "_id": "TZ",
    "code": "TZ",
    "name": "Tanzania",
    "states": [
      {
        "_id": "arusha",
        "code": "arusha",
        "name": "Arusha"
      },
      {
        "_id": "dar_es_salaam",
        "code": "dar_es_salaam",
        "name": "Dar es Salaam"
      },
      {
        "_id": "dodoma",
        "code": "dodoma",
        "name": "Dodoma"
      },
      {
        "_id": "iringa",
        "code": "iringa",
        "name": "Iringa"
      },
      {
        "_id": "kagera",
        "code": "kagera",
        "name": "Kagera"
      },
      {
        "_id": "kigoma",
        "code": "kigoma",
        "name": "Kigoma"
      },
      {
        "_id": "kilimanjaro",
        "code": "kilimanjaro",
        "name": "Kilimanjaro"
      },
      {
        "_id": "lindi",
        "code": "lindi",
        "name": "Lindi"
      },
      {
        "_id": "mara",
        "code": "mara",
        "name": "Mara"
      },
      {
        "_id": "mbeya",
        "code": "mbeya",
        "name": "Mbeya"
      },
      {
        "_id": "morogoro",
        "code": "morogoro",
        "name": "Morogoro"
      },
      {
        "_id": "mtwara",
        "code": "mtwara",
        "name": "Mtwara"
      },
      {
        "_id": "mwanza",
        "code": "mwanza",
        "name": "Mwanza"
      },
      {
        "_id": "pwani",
        "code": "pwani",
        "name": "Pwani"
      },
      {
        "_id": "rukwa",
        "code": "rukwa",
        "name": "Rukwa"
      },
      {
        "_id": "ruvuma",
        "code": "ruvuma",
        "name": "Ruvuma"
      },
      {
        "_id": "shinyanga",
        "code": "shinyanga",
        "name": "Shinyanga"
      },
      {
        "_id": "singida",
        "code": "singida",
        "name": "Singida"
      },
      {
        "_id": "tabora",
        "code": "tabora",
        "name": "Tabora"
      },
      {
        "_id": "tanga",
        "code": "tanga",
        "name": "Tanga"
      },
      {
        "_id": "zanzibar_and_pemba",
        "code": "zanzibar_and_pemba",
        "name": "Zanzibar and Pemba"
      }
    ]
  },
  {
    "_id": "TH",
    "code": "TH",
    "name": "Thailand",
    "states": [
      {
        "_id": "amnat_charoen",
        "code": "amnat_charoen",
        "name": "Amnat Charoen"
      },
      {
        "_id": "ang_thong",
        "code": "ang_thong",
        "name": "Ang Thong"
      },
      {
        "_id": "bangkok",
        "code": "bangkok",
        "name": "Bangkok"
      },
      {
        "_id": "buri_ram",
        "code": "buri_ram",
        "name": "Buri Ram"
      },
      {
        "_id": "chachoengsao",
        "code": "chachoengsao",
        "name": "Chachoengsao"
      },
      {
        "_id": "chai_nat",
        "code": "chai_nat",
        "name": "Chai Nat"
      },
      {
        "_id": "chaiyaphum",
        "code": "chaiyaphum",
        "name": "Chaiyaphum"
      },
      {
        "_id": "changwat_chaiyaphum",
        "code": "changwat_chaiyaphum",
        "name": "Changwat Chaiyaphum"
      },
      {
        "_id": "chanthaburi",
        "code": "chanthaburi",
        "name": "Chanthaburi"
      },
      {
        "_id": "chiang_mai",
        "code": "chiang_mai",
        "name": "Chiang Mai"
      },
      {
        "_id": "chiang_rai",
        "code": "chiang_rai",
        "name": "Chiang Rai"
      },
      {
        "_id": "chon_buri",
        "code": "chon_buri",
        "name": "Chon Buri"
      },
      {
        "_id": "chumphon",
        "code": "chumphon",
        "name": "Chumphon"
      },
      {
        "_id": "kalasin",
        "code": "kalasin",
        "name": "Kalasin"
      },
      {
        "_id": "kamphaeng_phet",
        "code": "kamphaeng_phet",
        "name": "Kamphaeng Phet"
      },
      {
        "_id": "kanchanaburi",
        "code": "kanchanaburi",
        "name": "Kanchanaburi"
      },
      {
        "_id": "khon_kaen",
        "code": "khon_kaen",
        "name": "Khon Kaen"
      },
      {
        "_id": "krabi",
        "code": "krabi",
        "name": "Krabi"
      },
      {
        "_id": "krung_thep",
        "code": "krung_thep",
        "name": "Krung Thep"
      },
      {
        "_id": "lampang",
        "code": "lampang",
        "name": "Lampang"
      },
      {
        "_id": "lamphun",
        "code": "lamphun",
        "name": "Lamphun"
      },
      {
        "_id": "loei",
        "code": "loei",
        "name": "Loei"
      },
      {
        "_id": "lop_buri",
        "code": "lop_buri",
        "name": "Lop Buri"
      },
      {
        "_id": "mae_hong_son",
        "code": "mae_hong_son",
        "name": "Mae Hong Son"
      },
      {
        "_id": "maha_sarakham",
        "code": "maha_sarakham",
        "name": "Maha Sarakham"
      },
      {
        "_id": "mukdahan",
        "code": "mukdahan",
        "name": "Mukdahan"
      },
      {
        "_id": "nakhon_nayok",
        "code": "nakhon_nayok",
        "name": "Nakhon Nayok"
      },
      {
        "_id": "nakhon_pathom",
        "code": "nakhon_pathom",
        "name": "Nakhon Pathom"
      },
      {
        "_id": "nakhon_phanom",
        "code": "nakhon_phanom",
        "name": "Nakhon Phanom"
      },
      {
        "_id": "nakhon_ratchasima",
        "code": "nakhon_ratchasima",
        "name": "Nakhon Ratchasima"
      },
      {
        "_id": "nakhon_sawan",
        "code": "nakhon_sawan",
        "name": "Nakhon Sawan"
      },
      {
        "_id": "nakhon_si_thammarat",
        "code": "nakhon_si_thammarat",
        "name": "Nakhon Si Thammarat"
      },
      {
        "_id": "nan",
        "code": "nan",
        "name": "Nan"
      },
      {
        "_id": "narathiwat",
        "code": "narathiwat",
        "name": "Narathiwat"
      },
      {
        "_id": "nong_bua_lam_phu",
        "code": "nong_bua_lam_phu",
        "name": "Nong Bua Lam Phu"
      },
      {
        "_id": "nong_khai",
        "code": "nong_khai",
        "name": "Nong Khai"
      },
      {
        "_id": "nonthaburi",
        "code": "nonthaburi",
        "name": "Nonthaburi"
      },
      {
        "_id": "pathum_thani",
        "code": "pathum_thani",
        "name": "Pathum Thani"
      },
      {
        "_id": "pattani",
        "code": "pattani",
        "name": "Pattani"
      },
      {
        "_id": "phangnga",
        "code": "phangnga",
        "name": "Phangnga"
      },
      {
        "_id": "phatthalung",
        "code": "phatthalung",
        "name": "Phatthalung"
      },
      {
        "_id": "phayao",
        "code": "phayao",
        "name": "Phayao"
      },
      {
        "_id": "phetchabun",
        "code": "phetchabun",
        "name": "Phetchabun"
      },
      {
        "_id": "phetchaburi",
        "code": "phetchaburi",
        "name": "Phetchaburi"
      },
      {
        "_id": "phichit",
        "code": "phichit",
        "name": "Phichit"
      },
      {
        "_id": "phitsanulok",
        "code": "phitsanulok",
        "name": "Phitsanulok"
      },
      {
        "_id": "phra_nakhon_si_ayutthaya",
        "code": "phra_nakhon_si_ayutthaya",
        "name": "Phra Nakhon Si Ayutthaya"
      },
      {
        "_id": "phrae",
        "code": "phrae",
        "name": "Phrae"
      },
      {
        "_id": "phuket",
        "code": "phuket",
        "name": "Phuket"
      },
      {
        "_id": "prachin_buri",
        "code": "prachin_buri",
        "name": "Prachin Buri"
      },
      {
        "_id": "prachuap_khiri_khan",
        "code": "prachuap_khiri_khan",
        "name": "Prachuap Khiri Khan"
      },
      {
        "_id": "ranong",
        "code": "ranong",
        "name": "Ranong"
      },
      {
        "_id": "ratchaburi",
        "code": "ratchaburi",
        "name": "Ratchaburi"
      },
      {
        "_id": "rayong",
        "code": "rayong",
        "name": "Rayong"
      },
      {
        "_id": "roi_et",
        "code": "roi_et",
        "name": "Roi Et"
      },
      {
        "_id": "sa_kaeo",
        "code": "sa_kaeo",
        "name": "Sa Kaeo"
      },
      {
        "_id": "sakon_nakhon",
        "code": "sakon_nakhon",
        "name": "Sakon Nakhon"
      },
      {
        "_id": "samut_prakan",
        "code": "samut_prakan",
        "name": "Samut Prakan"
      },
      {
        "_id": "samut_sakhon",
        "code": "samut_sakhon",
        "name": "Samut Sakhon"
      },
      {
        "_id": "samut_songkhran",
        "code": "samut_songkhran",
        "name": "Samut Songkhran"
      },
      {
        "_id": "saraburi",
        "code": "saraburi",
        "name": "Saraburi"
      },
      {
        "_id": "satun",
        "code": "satun",
        "name": "Satun"
      },
      {
        "_id": "si_sa_ket",
        "code": "si_sa_ket",
        "name": "Si Sa Ket"
      },
      {
        "_id": "sing_buri",
        "code": "sing_buri",
        "name": "Sing Buri"
      },
      {
        "_id": "songkhla",
        "code": "songkhla",
        "name": "Songkhla"
      },
      {
        "_id": "sukhothai",
        "code": "sukhothai",
        "name": "Sukhothai"
      },
      {
        "_id": "suphan_buri",
        "code": "suphan_buri",
        "name": "Suphan Buri"
      },
      {
        "_id": "surat_thani",
        "code": "surat_thani",
        "name": "Surat Thani"
      },
      {
        "_id": "surin",
        "code": "surin",
        "name": "Surin"
      },
      {
        "_id": "tak",
        "code": "tak",
        "name": "Tak"
      },
      {
        "_id": "trang",
        "code": "trang",
        "name": "Trang"
      },
      {
        "_id": "trat",
        "code": "trat",
        "name": "Trat"
      },
      {
        "_id": "ubon_ratchathani",
        "code": "ubon_ratchathani",
        "name": "Ubon Ratchathani"
      },
      {
        "_id": "udon_thani",
        "code": "udon_thani",
        "name": "Udon Thani"
      },
      {
        "_id": "uthai_thani",
        "code": "uthai_thani",
        "name": "Uthai Thani"
      },
      {
        "_id": "uttaradit",
        "code": "uttaradit",
        "name": "Uttaradit"
      },
      {
        "_id": "yala",
        "code": "yala",
        "name": "Yala"
      },
      {
        "_id": "yasothon",
        "code": "yasothon",
        "name": "Yasothon"
      }
    ]
  },
  {
    "_id": "TG",
    "code": "TG",
    "name": "Togo",
    "states": [
      {
        "_id": "centre",
        "code": "centre",
        "name": "Centre"
      },
      {
        "_id": "kara",
        "code": "kara",
        "name": "Kara"
      },
      {
        "_id": "maritime",
        "code": "maritime",
        "name": "Maritime"
      },
      {
        "_id": "plateaux",
        "code": "plateaux",
        "name": "Plateaux"
      },
      {
        "_id": "savanes",
        "code": "savanes",
        "name": "Savanes"
      }
    ]
  },
  {
    "_id": "TK",
    "code": "TK",
    "name": "Tokelau",
    "states": [
      {
        "_id": "atafu",
        "code": "atafu",
        "name": "Atafu"
      },
      {
        "_id": "fakaofo",
        "code": "fakaofo",
        "name": "Fakaofo"
      },
      {
        "_id": "nukunonu",
        "code": "nukunonu",
        "name": "Nukunonu"
      }
    ]
  },
  {
    "_id": "TO",
    "code": "TO",
    "name": "Tonga",
    "states": [
      {
        "_id": "eua",
        "code": "eua",
        "name": "Eua"
      },
      {
        "_id": "haapai",
        "code": "haapai",
        "name": "Haapai"
      },
      {
        "_id": "niuas",
        "code": "niuas",
        "name": "Niuas"
      },
      {
        "_id": "tongatapu",
        "code": "tongatapu",
        "name": "Tongatapu"
      },
      {
        "_id": "vavau",
        "code": "vavau",
        "name": "Vavau"
      }
    ]
  },
  {
    "_id": "TT",
    "code": "TT",
    "name": "Trinidad And Tobago",
    "states": [
      {
        "_id": "arima-tunapuna-piarco",
        "code": "arima-tunapuna-piarco",
        "name": "Arima-Tunapuna-Piarco"
      },
      {
        "_id": "caroni",
        "code": "caroni",
        "name": "Caroni"
      },
      {
        "_id": "chaguanas",
        "code": "chaguanas",
        "name": "Chaguanas"
      },
      {
        "_id": "couva-tabaquite-talparo",
        "code": "couva-tabaquite-talparo",
        "name": "Couva-Tabaquite-Talparo"
      },
      {
        "_id": "diego_martin",
        "code": "diego_martin",
        "name": "Diego Martin"
      },
      {
        "_id": "glencoe",
        "code": "glencoe",
        "name": "Glencoe"
      },
      {
        "_id": "penal_debe",
        "code": "penal_debe",
        "name": "Penal Debe"
      },
      {
        "_id": "point_fortin",
        "code": "point_fortin",
        "name": "Point Fortin"
      },
      {
        "_id": "port_of_spain",
        "code": "port_of_spain",
        "name": "Port of Spain"
      },
      {
        "_id": "princes_town",
        "code": "princes_town",
        "name": "Princes Town"
      },
      {
        "_id": "saint_george",
        "code": "saint_george",
        "name": "Saint George"
      },
      {
        "_id": "san_fernando",
        "code": "san_fernando",
        "name": "San Fernando"
      },
      {
        "_id": "san_juan",
        "code": "san_juan",
        "name": "San Juan"
      },
      {
        "_id": "sangre_grande",
        "code": "sangre_grande",
        "name": "Sangre Grande"
      },
      {
        "_id": "siparia",
        "code": "siparia",
        "name": "Siparia"
      },
      {
        "_id": "tobago",
        "code": "tobago",
        "name": "Tobago"
      }
    ]
  },
  {
    "_id": "TN",
    "code": "TN",
    "name": "Tunisia",
    "states": [
      {
        "_id": "aryanah",
        "code": "aryanah",
        "name": "Aryanah"
      },
      {
        "_id": "bajah",
        "code": "bajah",
        "name": "Bajah"
      },
      {
        "_id": "bin_arus",
        "code": "bin_arus",
        "name": "Bin Arus"
      },
      {
        "_id": "binzart",
        "code": "binzart",
        "name": "Binzart"
      },
      {
        "_id": "gouvernorat_de_ariana",
        "code": "gouvernorat_de_ariana",
        "name": "Gouvernorat de Ariana"
      },
      {
        "_id": "gouvernorat_de_nabeul",
        "code": "gouvernorat_de_nabeul",
        "name": "Gouvernorat de Nabeul"
      },
      {
        "_id": "gouvernorat_de_sousse",
        "code": "gouvernorat_de_sousse",
        "name": "Gouvernorat de Sousse"
      },
      {
        "_id": "hammamet_yasmine",
        "code": "hammamet_yasmine",
        "name": "Hammamet Yasmine"
      },
      {
        "_id": "jundubah",
        "code": "jundubah",
        "name": "Jundubah"
      },
      {
        "_id": "madaniyin",
        "code": "madaniyin",
        "name": "Madaniyin"
      },
      {
        "_id": "manubah",
        "code": "manubah",
        "name": "Manubah"
      },
      {
        "_id": "monastir",
        "code": "monastir",
        "name": "Monastir"
      },
      {
        "_id": "nabul",
        "code": "nabul",
        "name": "Nabul"
      },
      {
        "_id": "qabis",
        "code": "qabis",
        "name": "Qabis"
      },
      {
        "_id": "qafsah",
        "code": "qafsah",
        "name": "Qafsah"
      },
      {
        "_id": "qibili",
        "code": "qibili",
        "name": "Qibili"
      },
      {
        "_id": "safaqis",
        "code": "safaqis",
        "name": "Safaqis"
      },
      {
        "_id": "sfax",
        "code": "sfax",
        "name": "Sfax"
      },
      {
        "_id": "sidi_bu_zayd",
        "code": "sidi_bu_zayd",
        "name": "Sidi Bu Zayd"
      },
      {
        "_id": "silyanah",
        "code": "silyanah",
        "name": "Silyanah"
      },
      {
        "_id": "susah",
        "code": "susah",
        "name": "Susah"
      },
      {
        "_id": "tatawin",
        "code": "tatawin",
        "name": "Tatawin"
      },
      {
        "_id": "tawzar",
        "code": "tawzar",
        "name": "Tawzar"
      },
      {
        "_id": "tunis",
        "code": "tunis",
        "name": "Tunis"
      },
      {
        "_id": "zaghwan",
        "code": "zaghwan",
        "name": "Zaghwan"
      },
      {
        "_id": "al-kaf",
        "code": "al-kaf",
        "name": "al-Kaf"
      },
      {
        "_id": "al-mahdiyah",
        "code": "al-mahdiyah",
        "name": "al-Mahdiyah"
      },
      {
        "_id": "al-munastir",
        "code": "al-munastir",
        "name": "al-Munastir"
      },
      {
        "_id": "al-qasrayn",
        "code": "al-qasrayn",
        "name": "al-Qasrayn"
      },
      {
        "_id": "al-qayrawan",
        "code": "al-qayrawan",
        "name": "al-Qayrawan"
      }
    ]
  },
  {
    "_id": "TR",
    "code": "TR",
    "name": "Turkey",
    "states": [
      {
        "_id": "adana",
        "code": "adana",
        "name": "Adana"
      },
      {
        "_id": "adiyaman",
        "code": "adiyaman",
        "name": "Adiyaman"
      },
      {
        "_id": "afyon",
        "code": "afyon",
        "name": "Afyon"
      },
      {
        "_id": "agri",
        "code": "agri",
        "name": "Agri"
      },
      {
        "_id": "aksaray",
        "code": "aksaray",
        "name": "Aksaray"
      },
      {
        "_id": "amasya",
        "code": "amasya",
        "name": "Amasya"
      },
      {
        "_id": "ankara",
        "code": "ankara",
        "name": "Ankara"
      },
      {
        "_id": "antalya",
        "code": "antalya",
        "name": "Antalya"
      },
      {
        "_id": "ardahan",
        "code": "ardahan",
        "name": "Ardahan"
      },
      {
        "_id": "artvin",
        "code": "artvin",
        "name": "Artvin"
      },
      {
        "_id": "aydin",
        "code": "aydin",
        "name": "Aydin"
      },
      {
        "_id": "balikesir",
        "code": "balikesir",
        "name": "Balikesir"
      },
      {
        "_id": "bartin",
        "code": "bartin",
        "name": "Bartin"
      },
      {
        "_id": "batman",
        "code": "batman",
        "name": "Batman"
      },
      {
        "_id": "bayburt",
        "code": "bayburt",
        "name": "Bayburt"
      },
      {
        "_id": "bilecik",
        "code": "bilecik",
        "name": "Bilecik"
      },
      {
        "_id": "bingol",
        "code": "bingol",
        "name": "Bingol"
      },
      {
        "_id": "bitlis",
        "code": "bitlis",
        "name": "Bitlis"
      },
      {
        "_id": "bolu",
        "code": "bolu",
        "name": "Bolu"
      },
      {
        "_id": "burdur",
        "code": "burdur",
        "name": "Burdur"
      },
      {
        "_id": "bursa",
        "code": "bursa",
        "name": "Bursa"
      },
      {
        "_id": "canakkale",
        "code": "canakkale",
        "name": "Canakkale"
      },
      {
        "_id": "cankiri",
        "code": "cankiri",
        "name": "Cankiri"
      },
      {
        "_id": "corum",
        "code": "corum",
        "name": "Corum"
      },
      {
        "_id": "denizli",
        "code": "denizli",
        "name": "Denizli"
      },
      {
        "_id": "diyarbakir",
        "code": "diyarbakir",
        "name": "Diyarbakir"
      },
      {
        "_id": "duzce",
        "code": "duzce",
        "name": "Duzce"
      },
      {
        "_id": "edirne",
        "code": "edirne",
        "name": "Edirne"
      },
      {
        "_id": "elazig",
        "code": "elazig",
        "name": "Elazig"
      },
      {
        "_id": "erzincan",
        "code": "erzincan",
        "name": "Erzincan"
      },
      {
        "_id": "erzurum",
        "code": "erzurum",
        "name": "Erzurum"
      },
      {
        "_id": "eskisehir",
        "code": "eskisehir",
        "name": "Eskisehir"
      },
      {
        "_id": "gaziantep",
        "code": "gaziantep",
        "name": "Gaziantep"
      },
      {
        "_id": "giresun",
        "code": "giresun",
        "name": "Giresun"
      },
      {
        "_id": "gumushane",
        "code": "gumushane",
        "name": "Gumushane"
      },
      {
        "_id": "hakkari",
        "code": "hakkari",
        "name": "Hakkari"
      },
      {
        "_id": "hatay",
        "code": "hatay",
        "name": "Hatay"
      },
      {
        "_id": "icel",
        "code": "icel",
        "name": "Icel"
      },
      {
        "_id": "igdir",
        "code": "igdir",
        "name": "Igdir"
      },
      {
        "_id": "isparta",
        "code": "isparta",
        "name": "Isparta"
      },
      {
        "_id": "istanbul",
        "code": "istanbul",
        "name": "Istanbul"
      },
      {
        "_id": "izmir",
        "code": "izmir",
        "name": "Izmir"
      },
      {
        "_id": "kahramanmaras",
        "code": "kahramanmaras",
        "name": "Kahramanmaras"
      },
      {
        "_id": "karabuk",
        "code": "karabuk",
        "name": "Karabuk"
      },
      {
        "_id": "karaman",
        "code": "karaman",
        "name": "Karaman"
      },
      {
        "_id": "kars",
        "code": "kars",
        "name": "Kars"
      },
      {
        "_id": "karsiyaka",
        "code": "karsiyaka",
        "name": "Karsiyaka"
      },
      {
        "_id": "kastamonu",
        "code": "kastamonu",
        "name": "Kastamonu"
      },
      {
        "_id": "kayseri",
        "code": "kayseri",
        "name": "Kayseri"
      },
      {
        "_id": "kilis",
        "code": "kilis",
        "name": "Kilis"
      },
      {
        "_id": "kirikkale",
        "code": "kirikkale",
        "name": "Kirikkale"
      },
      {
        "_id": "kirklareli",
        "code": "kirklareli",
        "name": "Kirklareli"
      },
      {
        "_id": "kirsehir",
        "code": "kirsehir",
        "name": "Kirsehir"
      },
      {
        "_id": "kocaeli",
        "code": "kocaeli",
        "name": "Kocaeli"
      },
      {
        "_id": "konya",
        "code": "konya",
        "name": "Konya"
      },
      {
        "_id": "kutahya",
        "code": "kutahya",
        "name": "Kutahya"
      },
      {
        "_id": "lefkosa",
        "code": "lefkosa",
        "name": "Lefkosa"
      },
      {
        "_id": "malatya",
        "code": "malatya",
        "name": "Malatya"
      },
      {
        "_id": "manisa",
        "code": "manisa",
        "name": "Manisa"
      },
      {
        "_id": "mardin",
        "code": "mardin",
        "name": "Mardin"
      },
      {
        "_id": "mugla",
        "code": "mugla",
        "name": "Mugla"
      },
      {
        "_id": "mus",
        "code": "mus",
        "name": "Mus"
      },
      {
        "_id": "nevsehir",
        "code": "nevsehir",
        "name": "Nevsehir"
      },
      {
        "_id": "nigde",
        "code": "nigde",
        "name": "Nigde"
      },
      {
        "_id": "ordu",
        "code": "ordu",
        "name": "Ordu"
      },
      {
        "_id": "osmaniye",
        "code": "osmaniye",
        "name": "Osmaniye"
      },
      {
        "_id": "rize",
        "code": "rize",
        "name": "Rize"
      },
      {
        "_id": "sakarya",
        "code": "sakarya",
        "name": "Sakarya"
      },
      {
        "_id": "samsun",
        "code": "samsun",
        "name": "Samsun"
      },
      {
        "_id": "sanliurfa",
        "code": "sanliurfa",
        "name": "Sanliurfa"
      },
      {
        "_id": "siirt",
        "code": "siirt",
        "name": "Siirt"
      },
      {
        "_id": "sinop",
        "code": "sinop",
        "name": "Sinop"
      },
      {
        "_id": "sirnak",
        "code": "sirnak",
        "name": "Sirnak"
      },
      {
        "_id": "sivas",
        "code": "sivas",
        "name": "Sivas"
      },
      {
        "_id": "tekirdag",
        "code": "tekirdag",
        "name": "Tekirdag"
      },
      {
        "_id": "tokat",
        "code": "tokat",
        "name": "Tokat"
      },
      {
        "_id": "trabzon",
        "code": "trabzon",
        "name": "Trabzon"
      },
      {
        "_id": "tunceli",
        "code": "tunceli",
        "name": "Tunceli"
      },
      {
        "_id": "usak",
        "code": "usak",
        "name": "Usak"
      },
      {
        "_id": "van",
        "code": "van",
        "name": "Van"
      },
      {
        "_id": "yalova",
        "code": "yalova",
        "name": "Yalova"
      },
      {
        "_id": "yozgat",
        "code": "yozgat",
        "name": "Yozgat"
      },
      {
        "_id": "zonguldak",
        "code": "zonguldak",
        "name": "Zonguldak"
      }
    ]
  },
  {
    "_id": "TM",
    "code": "TM",
    "name": "Turkmenistan",
    "states": [
      {
        "_id": "ahal",
        "code": "ahal",
        "name": "Ahal"
      },
      {
        "_id": "asgabat",
        "code": "asgabat",
        "name": "Asgabat"
      },
      {
        "_id": "balkan",
        "code": "balkan",
        "name": "Balkan"
      },
      {
        "_id": "dasoguz",
        "code": "dasoguz",
        "name": "Dasoguz"
      },
      {
        "_id": "lebap",
        "code": "lebap",
        "name": "Lebap"
      },
      {
        "_id": "mari",
        "code": "mari",
        "name": "Mari"
      }
    ]
  },
  {
    "_id": "TC",
    "code": "TC",
    "name": "Turks And Caicos Islands",
    "states": [
      {
        "_id": "grand_turk",
        "code": "grand_turk",
        "name": "Grand Turk"
      },
      {
        "_id": "south_caicos_and_east_caicos",
        "code": "south_caicos_and_east_caicos",
        "name": "South Caicos and East Caicos"
      }
    ]
  },
  {
    "_id": "TV",
    "code": "TV",
    "name": "Tuvalu",
    "states": [
      {
        "_id": "funafuti",
        "code": "funafuti",
        "name": "Funafuti"
      },
      {
        "_id": "nanumanga",
        "code": "nanumanga",
        "name": "Nanumanga"
      },
      {
        "_id": "nanumea",
        "code": "nanumea",
        "name": "Nanumea"
      },
      {
        "_id": "niutao",
        "code": "niutao",
        "name": "Niutao"
      },
      {
        "_id": "nui",
        "code": "nui",
        "name": "Nui"
      },
      {
        "_id": "nukufetau",
        "code": "nukufetau",
        "name": "Nukufetau"
      },
      {
        "_id": "nukulaelae",
        "code": "nukulaelae",
        "name": "Nukulaelae"
      },
      {
        "_id": "vaitupu",
        "code": "vaitupu",
        "name": "Vaitupu"
      }
    ]
  },
  {
    "_id": "UG",
    "code": "UG",
    "name": "Uganda",
    "states": [
      {
        "_id": "central",
        "code": "central",
        "name": "Central"
      },
      {
        "_id": "eastern",
        "code": "eastern",
        "name": "Eastern"
      },
      {
        "_id": "northern",
        "code": "northern",
        "name": "Northern"
      },
      {
        "_id": "western",
        "code": "western",
        "name": "Western"
      }
    ]
  },
  {
    "_id": "UA",
    "code": "UA",
    "name": "Ukraine",
    "states": [
      {
        "_id": "cherkaska",
        "code": "cherkaska",
        "name": "Cherkaska"
      },
      {
        "_id": "chernihivska",
        "code": "chernihivska",
        "name": "Chernihivska"
      },
      {
        "_id": "chernivetska",
        "code": "chernivetska",
        "name": "Chernivetska"
      },
      {
        "_id": "crimea",
        "code": "crimea",
        "name": "Crimea"
      },
      {
        "_id": "dnipropetrovska",
        "code": "dnipropetrovska",
        "name": "Dnipropetrovska"
      },
      {
        "_id": "donetska",
        "code": "donetska",
        "name": "Donetska"
      },
      {
        "_id": "ivano-frankivska",
        "code": "ivano-frankivska",
        "name": "Ivano-Frankivska"
      },
      {
        "_id": "kharkiv",
        "code": "kharkiv",
        "name": "Kharkiv"
      },
      {
        "_id": "kharkov",
        "code": "kharkov",
        "name": "Kharkov"
      },
      {
        "_id": "khersonska",
        "code": "khersonska",
        "name": "Khersonska"
      },
      {
        "_id": "khmelnytska",
        "code": "khmelnytska",
        "name": "Khmelnytska"
      },
      {
        "_id": "kirovohrad",
        "code": "kirovohrad",
        "name": "Kirovohrad"
      },
      {
        "_id": "krym",
        "code": "krym",
        "name": "Krym"
      },
      {
        "_id": "kyyiv",
        "code": "kyyiv",
        "name": "Kyyiv"
      },
      {
        "_id": "kyyivska",
        "code": "kyyivska",
        "name": "Kyyivska"
      },
      {
        "_id": "lvivska",
        "code": "lvivska",
        "name": "Lvivska"
      },
      {
        "_id": "luhanska",
        "code": "luhanska",
        "name": "Luhanska"
      },
      {
        "_id": "mykolayivska",
        "code": "mykolayivska",
        "name": "Mykolayivska"
      },
      {
        "_id": "odeska",
        "code": "odeska",
        "name": "Odeska"
      },
      {
        "_id": "odessa",
        "code": "odessa",
        "name": "Odessa"
      },
      {
        "_id": "poltavska",
        "code": "poltavska",
        "name": "Poltavska"
      },
      {
        "_id": "rivnenska",
        "code": "rivnenska",
        "name": "Rivnenska"
      },
      {
        "_id": "sevastopol",
        "code": "sevastopol",
        "name": "Sevastopol"
      },
      {
        "_id": "sumska",
        "code": "sumska",
        "name": "Sumska"
      },
      {
        "_id": "ternopilska",
        "code": "ternopilska",
        "name": "Ternopilska"
      },
      {
        "_id": "volynska",
        "code": "volynska",
        "name": "Volynska"
      },
      {
        "_id": "vynnytska",
        "code": "vynnytska",
        "name": "Vynnytska"
      },
      {
        "_id": "zakarpatska",
        "code": "zakarpatska",
        "name": "Zakarpatska"
      },
      {
        "_id": "zaporizhia",
        "code": "zaporizhia",
        "name": "Zaporizhia"
      },
      {
        "_id": "zhytomyrska",
        "code": "zhytomyrska",
        "name": "Zhytomyrska"
      }
    ]
  },
  {
    "_id": "AE",
    "code": "AE",
    "name": "United Arab Emirates",
    "states": [
      {
        "_id": "abu_zabi",
        "code": "abu_zabi",
        "name": "Abu Zabi"
      },
      {
        "_id": "ajman",
        "code": "ajman",
        "name": "Ajman"
      },
      {
        "_id": "dubai",
        "code": "dubai",
        "name": "Dubai"
      },
      {
        "_id": "ras_al-khaymah",
        "code": "ras_al-khaymah",
        "name": "Ras al-Khaymah"
      },
      {
        "_id": "sharjah",
        "code": "sharjah",
        "name": "Sharjah"
      },
      {
        "_id": "sharjha",
        "code": "sharjha",
        "name": "Sharjha"
      },
      {
        "_id": "umm_al_qaywayn",
        "code": "umm_al_qaywayn",
        "name": "Umm al Qaywayn"
      },
      {
        "_id": "al-fujayrah",
        "code": "al-fujayrah",
        "name": "al-Fujayrah"
      },
      {
        "_id": "ash-shariqah",
        "code": "ash-shariqah",
        "name": "ash-Shariqah"
      }
    ]
  },
  {
    "_id": "GB",
    "code": "GB",
    "name": "United Kingdom",
    "states": [
      {
        "_id": "aberdeen",
        "code": "aberdeen",
        "name": "Aberdeen"
      },
      {
        "_id": "aberdeenshire",
        "code": "aberdeenshire",
        "name": "Aberdeenshire"
      },
      {
        "_id": "argyll",
        "code": "argyll",
        "name": "Argyll"
      },
      {
        "_id": "armagh",
        "code": "armagh",
        "name": "Armagh"
      },
      {
        "_id": "bedfordshire",
        "code": "bedfordshire",
        "name": "Bedfordshire"
      },
      {
        "_id": "belfast",
        "code": "belfast",
        "name": "Belfast"
      },
      {
        "_id": "berkshire",
        "code": "berkshire",
        "name": "Berkshire"
      },
      {
        "_id": "birmingham",
        "code": "birmingham",
        "name": "Birmingham"
      },
      {
        "_id": "brechin",
        "code": "brechin",
        "name": "Brechin"
      },
      {
        "_id": "bridgnorth",
        "code": "bridgnorth",
        "name": "Bridgnorth"
      },
      {
        "_id": "bristol",
        "code": "bristol",
        "name": "Bristol"
      },
      {
        "_id": "buckinghamshire",
        "code": "buckinghamshire",
        "name": "Buckinghamshire"
      },
      {
        "_id": "cambridge",
        "code": "cambridge",
        "name": "Cambridge"
      },
      {
        "_id": "cambridgeshire",
        "code": "cambridgeshire",
        "name": "Cambridgeshire"
      },
      {
        "_id": "channel_islands",
        "code": "channel_islands",
        "name": "Channel Islands"
      },
      {
        "_id": "cheshire",
        "code": "cheshire",
        "name": "Cheshire"
      },
      {
        "_id": "cleveland",
        "code": "cleveland",
        "name": "Cleveland"
      },
      {
        "_id": "co_fermanagh",
        "code": "co_fermanagh",
        "name": "Co Fermanagh"
      },
      {
        "_id": "conwy",
        "code": "conwy",
        "name": "Conwy"
      },
      {
        "_id": "cornwall",
        "code": "cornwall",
        "name": "Cornwall"
      },
      {
        "_id": "coventry",
        "code": "coventry",
        "name": "Coventry"
      },
      {
        "_id": "craven_arms",
        "code": "craven_arms",
        "name": "Craven Arms"
      },
      {
        "_id": "cumbria",
        "code": "cumbria",
        "name": "Cumbria"
      },
      {
        "_id": "denbighshire",
        "code": "denbighshire",
        "name": "Denbighshire"
      },
      {
        "_id": "derby",
        "code": "derby",
        "name": "Derby"
      },
      {
        "_id": "derbyshire",
        "code": "derbyshire",
        "name": "Derbyshire"
      },
      {
        "_id": "devon",
        "code": "devon",
        "name": "Devon"
      },
      {
        "_id": "dial_code_dungannon",
        "code": "dial_code_dungannon",
        "name": "Dial Code Dungannon"
      },
      {
        "_id": "didcot",
        "code": "didcot",
        "name": "Didcot"
      },
      {
        "_id": "dorset",
        "code": "dorset",
        "name": "Dorset"
      },
      {
        "_id": "dunbartonshire",
        "code": "dunbartonshire",
        "name": "Dunbartonshire"
      },
      {
        "_id": "durham",
        "code": "durham",
        "name": "Durham"
      },
      {
        "_id": "east_dunbartonshire",
        "code": "east_dunbartonshire",
        "name": "East Dunbartonshire"
      },
      {
        "_id": "east_lothian",
        "code": "east_lothian",
        "name": "East Lothian"
      },
      {
        "_id": "east_midlands",
        "code": "east_midlands",
        "name": "East Midlands"
      },
      {
        "_id": "east_sussex",
        "code": "east_sussex",
        "name": "East Sussex"
      },
      {
        "_id": "east_yorkshire",
        "code": "east_yorkshire",
        "name": "East Yorkshire"
      },
      {
        "_id": "england",
        "code": "england",
        "name": "England"
      },
      {
        "_id": "essex",
        "code": "essex",
        "name": "Essex"
      },
      {
        "_id": "fermanagh",
        "code": "fermanagh",
        "name": "Fermanagh"
      },
      {
        "_id": "fife",
        "code": "fife",
        "name": "Fife"
      },
      {
        "_id": "flintshire",
        "code": "flintshire",
        "name": "Flintshire"
      },
      {
        "_id": "fulham",
        "code": "fulham",
        "name": "Fulham"
      },
      {
        "_id": "gainsborough",
        "code": "gainsborough",
        "name": "Gainsborough"
      },
      {
        "_id": "glocestershire",
        "code": "glocestershire",
        "name": "Glocestershire"
      },
      {
        "_id": "gwent",
        "code": "gwent",
        "name": "Gwent"
      },
      {
        "_id": "hampshire",
        "code": "hampshire",
        "name": "Hampshire"
      },
      {
        "_id": "hants",
        "code": "hants",
        "name": "Hants"
      },
      {
        "_id": "herefordshire",
        "code": "herefordshire",
        "name": "Herefordshire"
      },
      {
        "_id": "hertfordshire",
        "code": "hertfordshire",
        "name": "Hertfordshire"
      },
      {
        "_id": "ireland",
        "code": "ireland",
        "name": "Ireland"
      },
      {
        "_id": "isle_of_man",
        "code": "isle_of_man",
        "name": "Isle Of Man"
      },
      {
        "_id": "isle_of_wight",
        "code": "isle_of_wight",
        "name": "Isle of Wight"
      },
      {
        "_id": "kenford",
        "code": "kenford",
        "name": "Kenford"
      },
      {
        "_id": "kent",
        "code": "kent",
        "name": "Kent"
      },
      {
        "_id": "kilmarnock",
        "code": "kilmarnock",
        "name": "Kilmarnock"
      },
      {
        "_id": "lanarkshire",
        "code": "lanarkshire",
        "name": "Lanarkshire"
      },
      {
        "_id": "lancashire",
        "code": "lancashire",
        "name": "Lancashire"
      },
      {
        "_id": "leicestershire",
        "code": "leicestershire",
        "name": "Leicestershire"
      },
      {
        "_id": "lincolnshire",
        "code": "lincolnshire",
        "name": "Lincolnshire"
      },
      {
        "_id": "llanymynech",
        "code": "llanymynech",
        "name": "Llanymynech"
      },
      {
        "_id": "london",
        "code": "london",
        "name": "London"
      },
      {
        "_id": "ludlow",
        "code": "ludlow",
        "name": "Ludlow"
      },
      {
        "_id": "manchester",
        "code": "manchester",
        "name": "Manchester"
      },
      {
        "_id": "mayfair",
        "code": "mayfair",
        "name": "Mayfair"
      },
      {
        "_id": "merseyside",
        "code": "merseyside",
        "name": "Merseyside"
      },
      {
        "_id": "mid_glamorgan",
        "code": "mid_glamorgan",
        "name": "Mid Glamorgan"
      },
      {
        "_id": "middlesex",
        "code": "middlesex",
        "name": "Middlesex"
      },
      {
        "_id": "mildenhall",
        "code": "mildenhall",
        "name": "Mildenhall"
      },
      {
        "_id": "monmouthshire",
        "code": "monmouthshire",
        "name": "Monmouthshire"
      },
      {
        "_id": "newton_stewart",
        "code": "newton_stewart",
        "name": "Newton Stewart"
      },
      {
        "_id": "norfolk",
        "code": "norfolk",
        "name": "Norfolk"
      },
      {
        "_id": "north_humberside",
        "code": "north_humberside",
        "name": "North Humberside"
      },
      {
        "_id": "north_yorkshire",
        "code": "north_yorkshire",
        "name": "North Yorkshire"
      },
      {
        "_id": "northamptonshire",
        "code": "northamptonshire",
        "name": "Northamptonshire"
      },
      {
        "_id": "northants",
        "code": "northants",
        "name": "Northants"
      },
      {
        "_id": "northern_ireland",
        "code": "northern_ireland",
        "name": "Northern Ireland"
      },
      {
        "_id": "northumberland",
        "code": "northumberland",
        "name": "Northumberland"
      },
      {
        "_id": "nottinghamshire",
        "code": "nottinghamshire",
        "name": "Nottinghamshire"
      },
      {
        "_id": "oxford",
        "code": "oxford",
        "name": "Oxford"
      },
      {
        "_id": "powys",
        "code": "powys",
        "name": "Powys"
      },
      {
        "_id": "roos-shire",
        "code": "roos-shire",
        "name": "Roos-shire"
      },
      {
        "_id": "sussex",
        "code": "sussex",
        "name": "SUSSEX"
      },
      {
        "_id": "sark",
        "code": "sark",
        "name": "Sark"
      },
      {
        "_id": "scotland",
        "code": "scotland",
        "name": "Scotland"
      },
      {
        "_id": "scottish_borders",
        "code": "scottish_borders",
        "name": "Scottish Borders"
      },
      {
        "_id": "shropshire",
        "code": "shropshire",
        "name": "Shropshire"
      },
      {
        "_id": "somerset",
        "code": "somerset",
        "name": "Somerset"
      },
      {
        "_id": "south_glamorgan",
        "code": "south_glamorgan",
        "name": "South Glamorgan"
      },
      {
        "_id": "south_wales",
        "code": "south_wales",
        "name": "South Wales"
      },
      {
        "_id": "south_yorkshire",
        "code": "south_yorkshire",
        "name": "South Yorkshire"
      },
      {
        "_id": "southwell",
        "code": "southwell",
        "name": "Southwell"
      },
      {
        "_id": "staffordshire",
        "code": "staffordshire",
        "name": "Staffordshire"
      },
      {
        "_id": "strabane",
        "code": "strabane",
        "name": "Strabane"
      },
      {
        "_id": "suffolk",
        "code": "suffolk",
        "name": "Suffolk"
      },
      {
        "_id": "surrey",
        "code": "surrey",
        "name": "Surrey"
      },
      {
        "_id": "sussex",
        "code": "sussex",
        "name": "Sussex"
      },
      {
        "_id": "twickenham",
        "code": "twickenham",
        "name": "Twickenham"
      },
      {
        "_id": "tyne_and_wear",
        "code": "tyne_and_wear",
        "name": "Tyne and Wear"
      },
      {
        "_id": "tyrone",
        "code": "tyrone",
        "name": "Tyrone"
      },
      {
        "_id": "utah",
        "code": "utah",
        "name": "Utah"
      },
      {
        "_id": "wales",
        "code": "wales",
        "name": "Wales"
      },
      {
        "_id": "warwickshire",
        "code": "warwickshire",
        "name": "Warwickshire"
      },
      {
        "_id": "west_lothian",
        "code": "west_lothian",
        "name": "West Lothian"
      },
      {
        "_id": "west_midlands",
        "code": "west_midlands",
        "name": "West Midlands"
      },
      {
        "_id": "west_sussex",
        "code": "west_sussex",
        "name": "West Sussex"
      },
      {
        "_id": "west_yorkshire",
        "code": "west_yorkshire",
        "name": "West Yorkshire"
      },
      {
        "_id": "whissendine",
        "code": "whissendine",
        "name": "Whissendine"
      },
      {
        "_id": "wiltshire",
        "code": "wiltshire",
        "name": "Wiltshire"
      },
      {
        "_id": "wokingham",
        "code": "wokingham",
        "name": "Wokingham"
      },
      {
        "_id": "worcestershire",
        "code": "worcestershire",
        "name": "Worcestershire"
      },
      {
        "_id": "wrexham",
        "code": "wrexham",
        "name": "Wrexham"
      },
      {
        "_id": "wurttemberg",
        "code": "wurttemberg",
        "name": "Wurttemberg"
      },
      {
        "_id": "yorkshire",
        "code": "yorkshire",
        "name": "Yorkshire"
      }
    ]
  },
  {
    "_id": "UM",
    "code": "UM",
    "name": "United States Minor Outlying Islands"
  },
  {
    "_id": "UY",
    "code": "UY",
    "name": "Uruguay",
    "states": [
      {
        "_id": "artigas",
        "code": "artigas",
        "name": "Artigas"
      },
      {
        "_id": "canelones",
        "code": "canelones",
        "name": "Canelones"
      },
      {
        "_id": "cerro_largo",
        "code": "cerro_largo",
        "name": "Cerro Largo"
      },
      {
        "_id": "colonia",
        "code": "colonia",
        "name": "Colonia"
      },
      {
        "_id": "durazno",
        "code": "durazno",
        "name": "Durazno"
      },
      {
        "_id": "florida",
        "code": "florida",
        "name": "FLorida"
      },
      {
        "_id": "flores",
        "code": "flores",
        "name": "Flores"
      },
      {
        "_id": "lavalleja",
        "code": "lavalleja",
        "name": "Lavalleja"
      },
      {
        "_id": "maldonado",
        "code": "maldonado",
        "name": "Maldonado"
      },
      {
        "_id": "montevideo",
        "code": "montevideo",
        "name": "Montevideo"
      },
      {
        "_id": "paysandu",
        "code": "paysandu",
        "name": "Paysandu"
      },
      {
        "_id": "rio_negro",
        "code": "rio_negro",
        "name": "Rio Negro"
      },
      {
        "_id": "rivera",
        "code": "rivera",
        "name": "Rivera"
      },
      {
        "_id": "rocha",
        "code": "rocha",
        "name": "Rocha"
      },
      {
        "_id": "salto",
        "code": "salto",
        "name": "Salto"
      },
      {
        "_id": "san_jose",
        "code": "san_jose",
        "name": "San Jose"
      },
      {
        "_id": "soriano",
        "code": "soriano",
        "name": "Soriano"
      },
      {
        "_id": "tacuarembo",
        "code": "tacuarembo",
        "name": "Tacuarembo"
      },
      {
        "_id": "treinta_y_tres",
        "code": "treinta_y_tres",
        "name": "Treinta y Tres"
      }
    ]
  },
  {
    "_id": "UZ",
    "code": "UZ",
    "name": "Uzbekistan",
    "states": [
      {
        "_id": "andijon",
        "code": "andijon",
        "name": "Andijon"
      },
      {
        "_id": "buhoro",
        "code": "buhoro",
        "name": "Buhoro"
      },
      {
        "_id": "buxoro_viloyati",
        "code": "buxoro_viloyati",
        "name": "Buxoro Viloyati"
      },
      {
        "_id": "cizah",
        "code": "cizah",
        "name": "Cizah"
      },
      {
        "_id": "fargona",
        "code": "fargona",
        "name": "Fargona"
      },
      {
        "_id": "horazm",
        "code": "horazm",
        "name": "Horazm"
      },
      {
        "_id": "kaskadar",
        "code": "kaskadar",
        "name": "Kaskadar"
      },
      {
        "_id": "korakalpogiston",
        "code": "korakalpogiston",
        "name": "Korakalpogiston"
      },
      {
        "_id": "namangan",
        "code": "namangan",
        "name": "Namangan"
      },
      {
        "_id": "navoi",
        "code": "navoi",
        "name": "Navoi"
      },
      {
        "_id": "samarkand",
        "code": "samarkand",
        "name": "Samarkand"
      },
      {
        "_id": "sirdare",
        "code": "sirdare",
        "name": "Sirdare"
      },
      {
        "_id": "surhondar",
        "code": "surhondar",
        "name": "Surhondar"
      },
      {
        "_id": "toskent",
        "code": "toskent",
        "name": "Toskent"
      }
    ]
  },
  {
    "_id": "VU",
    "code": "VU",
    "name": "Vanuatu",
    "states": [
      {
        "_id": "malampa",
        "code": "malampa",
        "name": "Malampa"
      },
      {
        "_id": "penama",
        "code": "penama",
        "name": "Penama"
      },
      {
        "_id": "sanma",
        "code": "sanma",
        "name": "Sanma"
      },
      {
        "_id": "shefa",
        "code": "shefa",
        "name": "Shefa"
      },
      {
        "_id": "tafea",
        "code": "tafea",
        "name": "Tafea"
      },
      {
        "_id": "torba",
        "code": "torba",
        "name": "Torba"
      }
    ]
  },
  {
    "_id": "VA",
    "code": "VA",
    "name": "Vatican City State Holy See",
    "states": [
      {
        "_id": "vatican_city_state_(holy_see",
        "code": "vatican_city_state_(holy_see",
        "name": "Vatican City State (Holy See"
      }
    ]
  },
  {
    "_id": "VE",
    "code": "VE",
    "name": "Venezuela",
    "states": [
      {
        "_id": "amazonas",
        "code": "amazonas",
        "name": "Amazonas"
      },
      {
        "_id": "anzoategui",
        "code": "anzoategui",
        "name": "Anzoategui"
      },
      {
        "_id": "apure",
        "code": "apure",
        "name": "Apure"
      },
      {
        "_id": "aragua",
        "code": "aragua",
        "name": "Aragua"
      },
      {
        "_id": "barinas",
        "code": "barinas",
        "name": "Barinas"
      },
      {
        "_id": "bolivar",
        "code": "bolivar",
        "name": "Bolivar"
      },
      {
        "_id": "carabobo",
        "code": "carabobo",
        "name": "Carabobo"
      },
      {
        "_id": "cojedes",
        "code": "cojedes",
        "name": "Cojedes"
      },
      {
        "_id": "delta_amacuro",
        "code": "delta_amacuro",
        "name": "Delta Amacuro"
      },
      {
        "_id": "distrito_federal",
        "code": "distrito_federal",
        "name": "Distrito Federal"
      },
      {
        "_id": "falcon",
        "code": "falcon",
        "name": "Falcon"
      },
      {
        "_id": "guarico",
        "code": "guarico",
        "name": "Guarico"
      },
      {
        "_id": "lara",
        "code": "lara",
        "name": "Lara"
      },
      {
        "_id": "merida",
        "code": "merida",
        "name": "Merida"
      },
      {
        "_id": "miranda",
        "code": "miranda",
        "name": "Miranda"
      },
      {
        "_id": "monagas",
        "code": "monagas",
        "name": "Monagas"
      },
      {
        "_id": "nueva_esparta",
        "code": "nueva_esparta",
        "name": "Nueva Esparta"
      },
      {
        "_id": "portuguesa",
        "code": "portuguesa",
        "name": "Portuguesa"
      },
      {
        "_id": "sucre",
        "code": "sucre",
        "name": "Sucre"
      },
      {
        "_id": "tachira",
        "code": "tachira",
        "name": "Tachira"
      },
      {
        "_id": "trujillo",
        "code": "trujillo",
        "name": "Trujillo"
      },
      {
        "_id": "vargas",
        "code": "vargas",
        "name": "Vargas"
      },
      {
        "_id": "yaracuy",
        "code": "yaracuy",
        "name": "Yaracuy"
      },
      {
        "_id": "zulia",
        "code": "zulia",
        "name": "Zulia"
      }
    ]
  },
  {
    "_id": "VN",
    "code": "VN",
    "name": "Vietnam",
    "states": [
      {
        "_id": "bac_giang",
        "code": "bac_giang",
        "name": "Bac Giang"
      },
      {
        "_id": "binh_dinh",
        "code": "binh_dinh",
        "name": "Binh Dinh"
      },
      {
        "_id": "binh_duong",
        "code": "binh_duong",
        "name": "Binh Duong"
      },
      {
        "_id": "da_nang",
        "code": "da_nang",
        "name": "Da Nang"
      },
      {
        "_id": "dong_bang_song_cuu_long",
        "code": "dong_bang_song_cuu_long",
        "name": "Dong Bang Song Cuu Long"
      },
      {
        "_id": "dong_bang_song_hong",
        "code": "dong_bang_song_hong",
        "name": "Dong Bang Song Hong"
      },
      {
        "_id": "dong_nai",
        "code": "dong_nai",
        "name": "Dong Nai"
      },
      {
        "_id": "dong_nam_bo",
        "code": "dong_nam_bo",
        "name": "Dong Nam Bo"
      },
      {
        "_id": "duyen_hai_mien_trung",
        "code": "duyen_hai_mien_trung",
        "name": "Duyen Hai Mien Trung"
      },
      {
        "_id": "hanoi",
        "code": "hanoi",
        "name": "Hanoi"
      },
      {
        "_id": "hung_yen",
        "code": "hung_yen",
        "name": "Hung Yen"
      },
      {
        "_id": "khu_bon_cu",
        "code": "khu_bon_cu",
        "name": "Khu Bon Cu"
      },
      {
        "_id": "long_an",
        "code": "long_an",
        "name": "Long An"
      },
      {
        "_id": "mien_nui_va_trung_du",
        "code": "mien_nui_va_trung_du",
        "name": "Mien Nui Va Trung Du"
      },
      {
        "_id": "thai_nguyen",
        "code": "thai_nguyen",
        "name": "Thai Nguyen"
      },
      {
        "_id": "thanh_pho_ho_chi_minh",
        "code": "thanh_pho_ho_chi_minh",
        "name": "Thanh Pho Ho Chi Minh"
      },
      {
        "_id": "thu_do_ha_noi",
        "code": "thu_do_ha_noi",
        "name": "Thu Do Ha Noi"
      },
      {
        "_id": "tinh_can_tho",
        "code": "tinh_can_tho",
        "name": "Tinh Can Tho"
      },
      {
        "_id": "tinh_da_nang",
        "code": "tinh_da_nang",
        "name": "Tinh Da Nang"
      },
      {
        "_id": "tinh_gia_lai",
        "code": "tinh_gia_lai",
        "name": "Tinh Gia Lai"
      }
    ]
  },
  {
    "_id": "VG",
    "code": "VG",
    "name": "Virgin Islands British",
    "states": [
      {
        "_id": "anegada",
        "code": "anegada",
        "name": "Anegada"
      },
      {
        "_id": "jost_van_dyke",
        "code": "jost_van_dyke",
        "name": "Jost van Dyke"
      },
      {
        "_id": "tortola",
        "code": "tortola",
        "name": "Tortola"
      }
    ]
  },
  {
    "_id": "VI",
    "code": "VI",
    "name": "Virgin Islands US",
    "states": [
      {
        "_id": "saint_croix",
        "code": "saint_croix",
        "name": "Saint Croix"
      },
      {
        "_id": "saint_john",
        "code": "saint_john",
        "name": "Saint John"
      },
      {
        "_id": "saint_thomas",
        "code": "saint_thomas",
        "name": "Saint Thomas"
      }
    ]
  },
  {
    "_id": "WF",
    "code": "WF",
    "name": "Wallis And Futuna Islands",
    "states": [
      {
        "_id": "alo",
        "code": "alo",
        "name": "Alo"
      },
      {
        "_id": "singave",
        "code": "singave",
        "name": "Singave"
      },
      {
        "_id": "wallis",
        "code": "wallis",
        "name": "Wallis"
      }
    ]
  },
  {
    "_id": "EH",
    "code": "EH",
    "name": "Western Sahara",
    "states": [
      {
        "_id": "bu_jaydur",
        "code": "bu_jaydur",
        "name": "Bu Jaydur"
      },
      {
        "_id": "wad-adh-dhahab",
        "code": "wad-adh-dhahab",
        "name": "Wad-adh-Dhahab"
      },
      {
        "_id": "al-ayun",
        "code": "al-ayun",
        "name": "al-Ayun"
      },
      {
        "_id": "as-samarah",
        "code": "as-samarah",
        "name": "as-Samarah"
      }
    ]
  },
  {
    "_id": "YE",
    "code": "YE",
    "name": "Yemen",
    "states": [
      {
        "_id": "adan",
        "code": "adan",
        "name": "Adan"
      },
      {
        "_id": "abyan",
        "code": "abyan",
        "name": "Abyan"
      },
      {
        "_id": "dhamar",
        "code": "dhamar",
        "name": "Dhamar"
      },
      {
        "_id": "hadramaut",
        "code": "hadramaut",
        "name": "Hadramaut"
      },
      {
        "_id": "hajjah",
        "code": "hajjah",
        "name": "Hajjah"
      },
      {
        "_id": "hudaydah",
        "code": "hudaydah",
        "name": "Hudaydah"
      },
      {
        "_id": "ibb",
        "code": "ibb",
        "name": "Ibb"
      },
      {
        "_id": "lahij",
        "code": "lahij",
        "name": "Lahij"
      },
      {
        "_id": "marib",
        "code": "marib",
        "name": "Marib"
      },
      {
        "_id": "madinat_sana",
        "code": "madinat_sana",
        "name": "Madinat Sana"
      },
      {
        "_id": "sadah",
        "code": "sadah",
        "name": "Sadah"
      },
      {
        "_id": "sana",
        "code": "sana",
        "name": "Sana"
      },
      {
        "_id": "shabwah",
        "code": "shabwah",
        "name": "Shabwah"
      },
      {
        "_id": "taizz",
        "code": "taizz",
        "name": "Taizz"
      },
      {
        "_id": "al-bayda",
        "code": "al-bayda",
        "name": "al-Bayda"
      },
      {
        "_id": "al-hudaydah",
        "code": "al-hudaydah",
        "name": "al-Hudaydah"
      },
      {
        "_id": "al-jawf",
        "code": "al-jawf",
        "name": "al-Jawf"
      },
      {
        "_id": "al-mahrah",
        "code": "al-mahrah",
        "name": "al-Mahrah"
      },
      {
        "_id": "al-mahwit",
        "code": "al-mahwit",
        "name": "al-Mahwit"
      }
    ]
  },
  {
    "_id": "YU",
    "code": "YU",
    "name": "Yugoslavia",
    "states": [
      {
        "_id": "central_serbia",
        "code": "central_serbia",
        "name": "Central Serbia"
      },
      {
        "_id": "kosovo_and_metohija",
        "code": "kosovo_and_metohija",
        "name": "Kosovo and Metohija"
      },
      {
        "_id": "montenegro",
        "code": "montenegro",
        "name": "Montenegro"
      },
      {
        "_id": "republic_of_serbia",
        "code": "republic_of_serbia",
        "name": "Republic of Serbia"
      },
      {
        "_id": "serbia",
        "code": "serbia",
        "name": "Serbia"
      },
      {
        "_id": "vojvodina",
        "code": "vojvodina",
        "name": "Vojvodina"
      }
    ]
  },
  {
    "_id": "ZM",
    "code": "ZM",
    "name": "Zambia",
    "states": [
      {
        "_id": "central",
        "code": "central",
        "name": "Central"
      },
      {
        "_id": "copperbelt",
        "code": "copperbelt",
        "name": "Copperbelt"
      },
      {
        "_id": "eastern",
        "code": "eastern",
        "name": "Eastern"
      },
      {
        "_id": "luapala",
        "code": "luapala",
        "name": "Luapala"
      },
      {
        "_id": "lusaka",
        "code": "lusaka",
        "name": "Lusaka"
      },
      {
        "_id": "north-western",
        "code": "north-western",
        "name": "North-Western"
      },
      {
        "_id": "northern",
        "code": "northern",
        "name": "Northern"
      },
      {
        "_id": "southern",
        "code": "southern",
        "name": "Southern"
      },
      {
        "_id": "western",
        "code": "western",
        "name": "Western"
      }
    ]
  },
  {
    "_id": "ZW",
    "code": "ZW",
    "name": "Zimbabwe",
    "states": [
      {
        "_id": "bulawayo",
        "code": "bulawayo",
        "name": "Bulawayo"
      },
      {
        "_id": "harare",
        "code": "harare",
        "name": "Harare"
      },
      {
        "_id": "manicaland",
        "code": "manicaland",
        "name": "Manicaland"
      },
      {
        "_id": "mashonaland_central",
        "code": "mashonaland_central",
        "name": "Mashonaland Central"
      },
      {
        "_id": "mashonaland_east",
        "code": "mashonaland_east",
        "name": "Mashonaland East"
      },
      {
        "_id": "mashonaland_west",
        "code": "mashonaland_west",
        "name": "Mashonaland West"
      },
      {
        "_id": "masvingo",
        "code": "masvingo",
        "name": "Masvingo"
      },
      {
        "_id": "matabeleland_north",
        "code": "matabeleland_north",
        "name": "Matabeleland North"
      },
      {
        "_id": "matabeleland_south",
        "code": "matabeleland_south",
        "name": "Matabeleland South"
      },
      {
        "_id": "midlands",
        "code": "midlands",
        "name": "Midlands"
      }
    ]
  }
]
