<template>
  <v-card height="560" width="100%" :color="`${result}-lighten`" class="border-20" light flat>
    <div class="d-flex align-center fill-height justify-center flex-column flex-wrap">
      <div class="alert-content text-center">

        <div class="d-block mb-8">
          <v-icon size="70" :color="result" class="mb-5">{{ icon }}</v-icon>
          <h3 :class="`${result}--text`" class="mb-3">{{ title }}</h3>
          <p class="mb-0">{{ description}}</p>
        </div>
      </div>
      <v-btn v-show="!isSuccess" @click="$emit('click:button')"  dark depressed color="app-black" height="40">
        Try Again
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'PaymentResult',
  props: {
    result: {
      type: String, // error, success,
      default: () => 'success'
    }
  },
  computed: {
    isSuccess() {
      return this.result === 'success'
    },
    title() {
      return this.isSuccess ? 'Payment Success' : 'Payment Failed'
    },
    description() {
      return this.isSuccess ? 'Thank you. Your payment has been processed successfully.' : 'Payment declined,  please check your information and try again.'
    },
    icon() {
      return this.isSuccess ? 'icon-u-shield-check' : 'icon-exclamation-mark'
    }
  }
}
</script>

<style scoped lang="scss">
  .v-card {
    color: #676162;
    &.success-lighten {
      background-image: url(/img/success-alert-bg.png);
    }
    &.error-lighten {
      background-image: url(/img/error-alert-bg.png);
    }
  }
  h3 {
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.05em;
  }
  .alert-content {
    max-width: 300px;
  }
</style>
