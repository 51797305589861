export const creditCardMixins = {
  computed: {
    isAmex() {
      return this.cardBrand === 'AMEX'
    },
    cardMaskPattern() {
      return this.isAmex ? '#### ###### #####' : '#### #### #### ####'
    },

    cardNumber() {
      return this.payment.cardNumber || ''
    },
    cvcNumberLength() {
      return this.isAmex ? '4' : '3'
    },
    cvcMaskPattern() {
      return this.isAmex ? '####' : '###'
    },
    cardBrand() {
      // visa
      let re = new RegExp("^4");
      if (this.cardNumber.match(re) != null)
        return "Visa";

      // Mastercard
      // Updated for Mastercard 2017 BINs expansion
      re = new RegExp("^(5[1-5]|2(22[1-9]|2[3-9]|[3-6]|7[0-1]|720))")
      if (this.cardNumber.match(re) != null)
        return "Mastercard";

      // AMEX
      re = new RegExp("^3[47]");
      if (this.cardNumber.match(re) != null)
        return "AMEX";

      // Discover
      re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
      if (this.cardNumber.match(re) != null)
        return "Discover";

      return null
    },

    logo() {
      return this.cardBrand ? `/img/payment/${this.cardBrand.toLowerCase()}-logo.svg` : null
    },

    month() {
      return this.payment.month || 'XX'
    },

    year() {
      return (this.payment.year || 'XXX').slice(-2)
    },

    cardDate() {
      return `${this.month}/${this.year}`
    },
  }
}
