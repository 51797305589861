<script>
import {isString, orderBy, pickBy} from 'lodash' //cloneDeep
import {creditCardMixins} from '@/mixins/creditCardMixins'
import {sampleInvoiceData} from '@/const/sampleInvoiceData'
import VueFlip from 'vue-flip'
import PaymentResult from '@/components/ui/PaymentResult'
import {countries} from "@/data/countryStateList";

export default {
  name: 'Home',
  components: {
    PaymentResult,
    'vue-flip': VueFlip
  },
  mixins: [creditCardMixins],
  watch: {
    sameAsBillingAddress: {
      handler(val) {
        if (!val)
          this.addressFormFields.forEach((field) =>
              this.$set(this.payment, field.name, null)
          )
      }
    }
  },
  data: () => ({
    result: null,
    payment: {
      last_name: null,
      first_name: null,
      cc_vv: null,
      cc_ex_month: null,
      cc_ex_year: null,
      cc_number: null
    },
    invoiceLoading: false,
    loading: false,
    sameAsBillingAddress: true,
    invoiceData: null,
    sampleInvoiceData,
    printLoading: false,
    errorMessages: []
  }),
  methods: {
    printInvoice() {
      //! Burada Templates'e istek atip bize bastırılabilir bir HTML kopyasi çıkartmasını isteyeceğiz. Sonrasında onunla ya PDF'e cevirip bastiririz ya da print preview penceresi.
      this.printLoading = true
      this.$axios
          .get('web_invoices/print_viewer', {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/pdf'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = `Invoice(${this.invoiceData?.invoice?.auto_id}).pdf`
            link.click()
            URL.revokeObjectURL(link.href)
          })
          .finally(() => this.printLoading = false)
    },
    getInvoiceData() {
      return this.$axios.get('web_invoices')
    },
    validateAndSubmit() {
      this.$refs.paymentForm.$validator
          .validateAll()
          .then((v) => {
            if (v)
              return this.sameAsBillingAddress
                  ? true
                  : this.$refs.addressForm?.$validator.validateAll()

            throw new Error('an error occurred')
          })
          .then((v) => {
            if (v) this.submitData()
          })
    },
    submitData() {
      this.loading = true
      this.$axios
          .post('/web_invoices/make_instant_payment', {
            payment: {
              ...this.payment,
              amount: this.webInvoiceAmount
            }
          })
          .then(({data}) => {
            const resultCode = data?.response?.createtransactionresponse?.messages?.resultCode
            if (resultCode === 'Ok') {
              this.result = 'success'
            } else {
              this.result = 'error'
              this.errorMessages = data.messages
            }
          })
          .catch(err => {
            this.result = 'error'
            this.errorMessages = err?.response?.data?.messages || []
          })
          .finally(() => this.loading = false)
    },
    getAddressText_(obj) {
      console.log("obj", obj)
      const orderedFields = ['street1', 'street2', 'city', 'state', 'country', 'zipcode']
      return orderedFields.reduce((acc, field) => {
        return [...acc, ...obj?.[field] ? [obj[field]] : []]
      }, []).join(', ')
    },
    getAddressText(address){
      if (Object.keys(address).length) {
        let addressLine = "";

        if (address.street1 !== "" && address.street1 !== undefined && address.street1 !== null) {
          addressLine = addressLine + address.street1 + ", ";
        }

        if (address.street2 !== "" && address.street2 !== undefined && address.street2 !== null) {
          addressLine = addressLine + address.street2 + ", ";
        }

        if (isString(address.country) && address.country?.length >= 2) {
          const country = countries.find(s => s.code === address.country);
          const state = country?.states?.find(s => s._id === address.state);

          const stateName = state?.name || address?.state // ?.charAt(0).toUpperCase() + address?.state?.slice(1);
          addressLine = `${addressLine} ${stateName}, <strong>${country?.name || address?.country}</strong>`;
        }
        return addressLine;
      }
    }
  },
  mounted() {
    this.$axios.defaults.headers.common['pp-t'] = this.$route.query.tkn
    this.invoiceLoading = true
    this.getInvoiceData()
        .then((response) => {
          this.invoiceData = response.data
          this.invoiceLoading = false
          document.title = `${response?.data?.client?.company_name} - Payment Request for ${response?.data?.invoice?.auto_id}`
        })
        .catch(() => {
          this.$router.push({name: 'error', query: {token_is_not_valid: 1}})
        })
  },
  computed: {
    subTotal() {
      return this.getProp(this.invoiceData, 'invoice.total_amount_wo_tax') || 0
    },
    calculatedTaxes() {
      return this.appliedTaxes.map((tax) => ({
        title: `${tax.name} <span class="rate-text">(%${tax.value})</span>`,
        value: tax.amount
      }))
    },
    shippingCosts() {
      return this.getProp(this.invoiceData, 'invoice.total_shipping_price') || 0
    },
    totalDiscountAmount() {
      return this.getProp(this.invoiceData, 'invoice.total_discount') || 0
    },
    totals() {

      let totalItems = [
        {title: 'Shipping', value: this.shippingCosts},
      ]

      if (this.totalDiscountAmount > 0) totalItems.push({
        title: 'Discount',
        key: "discount",
        class: "red--text",
        value: this.totalDiscountAmount
      })

      totalItems = [
        ...totalItems,
        {title: 'Subtotal', value: this.subTotal},
      ]


      totalItems = [
        ...totalItems,
        ...this.calculatedTaxes
      ]

      return totalItems;
    },
    appliedTaxes() {
      return this.getProp(this.invoiceData, 'invoice.applied_taxes') || []
    },
    months() {
      return Array.from({length: 12}, (_, i) => `0${i + 1}`.slice(-2))
    },
    years() {
      return Array.from(
          {length: 20},
          (_, i) => i + new Date().getFullYear() + ''
      )
    },
    countries() {
      let countries = this.$store.getters.countries.map((item) =>
          pickBy(item, (__, key) => ['_id', 'name'].includes(key))
      )
      const ca_and_usa = countries.filter(c => ['CA', 'US'].includes(c._id))
      countries.sort((a, b) => {
        return a.name?.localeCompare(b.name)
      })
      countries.unshift(...ca_and_usa)

      return countries

    },
    states() {
      return (
          (
              this.$store.getters.countries.find(
                  (item) => item._id === this.payment.country
              ) || {}
          ).states || []
      )
    },
    paymentFormFields() {
      return [
        {
          comp: 'AppFormInput',
          icon: '',
          colProps: {
            cols: 6
          },
          props: {
            color: '#9DA3A9',
            placeholder: 'First Name',
            prependInnerIcon: 'icon-user-square',
            bsTypeLabel: true
          },
          label: 'Card Holder First Name',
          name: 'first_name',
          validation: 'required|alpha_spaces'
        },
        {
          comp: 'AppFormInput',
          icon: '',
          colProps: {
            cols: 6
          },
          props: {
            color: '#9DA3A9',
            placeholder: 'Last Name',
            prependInnerIcon: 'icon-user-square',
            bsTypeLabel: true
          },
          label: 'Card Holder Last Name',
          name: 'last_name',
          validation: 'required|alpha_spaces'
        },
        {
          comp: 'AppCreditCardInput',
          icon: '',
          colProps: {
            cols: 12
          },
          props: {
            placeholder: '0000 0000 0000 0000',
            bsTypeLabel: true
          },
          label: 'Card Number',
          name: 'cc_number',
          validation: 'required|luhn',
          mask: '#### #### #### ####'
        },
        {
          comp: 'AppSelect',
          icon: '',
          colProps: {
            cols: 4
          },
          props: {
            items: this.months,
            bsTypeLabel: true,
            prependInnerIcon: 'icon-calendar'
          },
          label: 'MM',
          name: 'cc_ex_month',
          validation: 'required|numeric'
        },
        {
          comp: 'AppSelect',
          icon: '',
          colProps: {
            cols: 4
          },
          props: {
            items: this.years,
            bsTypeLabel: true,
            prependInnerIcon: 'icon-calendar'
          },
          label: 'YYYY',
          name: 'cc_ex_year',
          validation: 'required|numeric'
        },
        {
          comp: 'AppFormInput',
          icon: '',
          colProps: {
            cols: 4
          },
          props: {
            placeholder: '***',
            bsTypeLabel: true,
            prependInnerIcon: 'icon-password-check'
          },
          label: 'CVC/CVV',
          name: 'cc_vv',
          validation: `required|numeric|length:${this.cvcNumberLength}`
        }
      ]
    },
    addressFormFields() {
      return [
        {
          comp: 'AppFormInput',
          icon: '',
          colProps: {
            cols: 12
          },
          props: {
            color: '#9DA3A9'
          },
          label: 'Address (street, suite, etc..)',
          name: 'street1',
          validation: 'required|max:300'
        },
        {
          classes: 'col-12 col-lg-6',
          comp: 'AppSelect',
          icon: '',
          colProps: {
            cols: 12,
            lg: 6
          },
          label: 'Country',
          name: 'country',
          props: {
            items: this.countries,
            'item-value': '_id',
            'item-text': 'name'
          },
          validation: 'required',
          mask: ''
        },
        {
          classes: 'col-12 col-lg-6',
          comp: 'AppSelect',
          icon: '',
          label: !this.isBillingAddressCA ? 'State' : 'Province',
          name: 'state',
          colProps: {
            cols: 12,
            lg: 6
          },
          props: {
            items: this.states,
            'item-value': '_id',
            'item-text': 'name'
          },
          validation: 'required',
          mask: ''
        },
        {
          classes: 'col-12 col-lg-6',
          comp: 'AppFormInput',
          icon: '',
          label: !this.isBillingAddressCA ? 'Zip Code' : 'Postal Code',
          name: 'zipcode',
          validation: this.zipCodeValidation,
          mask: this.zipCodeMask
        },
        {
          comp: 'AppFormInput',
          icon: '',
          colProps: {
            cols: 12,
            lg: 6
          },
          label: 'Phone',
          name: 'phone',
          validation: 'required|numeric'
        }
      ]
    },
    invoiceSections() {
      return [
        [
          {
            label: 'Invoice Id',
            path: 'invoice.auto_id'
          },
          {
            label: 'Invoice Date',
            path: 'invoice.invoice_date',
            format: (val) => this.$moment(val).format('YYYY-MM-DD')
          },
          {
            html: true,
            label: 'Billing Address',
            path: 'invoice.billing_address',
            format: (val) => this.getAddressText(val)
          }
        ],
        [
          {
            label: 'Sales Person',
            path: 'invoice.user_representatives.0.user'
          },
          {
            label: 'Payment terms',
            path: 'invoice.payment_term'
          },
          {
            html: true,
            label: 'Shipping Address',
            path: 'invoice.shipping_address',
            format: (val) => this.getAddressText(val)
          }
        ]
      ]
    },
    showResult() {
      return this.result === 'success'
    },
    logoUrl() {
      return this.getProp(this.invoiceData, 'client.logo_file') || '/img/puresol_t.png'
    },
    hasLogo() {
      return !!this.getProp(this.invoiceData, 'client.logo_file')
    },
    paymentSuccess() {
      return this.result === 'success'
    },
    paymentError() {
      return this.result === 'error'
    },
    amountWillBePaid() {
      return new Intl.NumberFormat(navigator.language, {
        style: 'currency',
        currency: 'CAD',
        currencyDisplay: 'narrowSymbol'
      }).format(this.webInvoiceAmount)
    },
    webInvoiceAmount() {
      return this.getProp(this.invoiceData, 'web_invoice.amount', 0)
    },
    invoiceItems() {
      const items = this.getProp(this.invoiceData, 'invoice.invoice_items', [])

      return orderBy(items, 'order', 'asc')
    },
    hideThumbnailArea() {
      return this.invoiceItems?.every(item => !item.thumbnail)
    },
    isBillingAddressCA() {
      if (this.sameAsBillingAddress) return this.invoiceData?.invoice?.billing_address?.country === 'CA'
      else return this.payment?.country === 'CA'
    },
    isBillingAddressUSA() {
      if (this.sameAsBillingAddress) return this.invoiceData?.invoice?.billing_address?.country === 'US'
      else return this.payment?.country === 'US'
    },
    zipCodeValidation() {
      if (this.isBillingAddressUSA) return 'required|zipCode'
      else if (this.isBillingAddressCA) return 'required|min:7|max:7'
      else return "required"
    },
    zipCodeMask() {
      // if (this.isBillingAddressUSA) return '#####'
      if (this.isBillingAddressCA) return 'A#A #A#'
      else return ''
    },
    totalAmount() {
      return this.invoiceData?.invoice?.total_amount || 0
    }
  }
}
</script>

<template>
  <v-container :fluid="$vuetify.breakpoint.lgOnly">
    <v-row class="page-wrapper">
      <v-col cols="12" lg="6">
        <template v-if="invoiceData">
          <div class="mb-8 d-flex">
            <img
                :src="logoUrl"
                style="display: inline-block"
                :style="{
                maxHeight: !hasLogo ? '30px' : '120px',
                maxWidth: '100%'
              }"
            />
          </div>
          <div class="d-flex justify-end flex-wrap">
            <v-btn :loading="printLoading" outlined color="app-black" height="32" @click="printInvoice">
              <v-icon left>icon-printer</v-icon>
              Print Invoice
            </v-btn>
          </div>
          <SectionTitle> Invoice Details</SectionTitle>
        </template>
        <v-card
            color="app-grey"
            flat
            class="border-10 mt-5 mb-8"
            v-if="!invoiceLoading"
        >
          <v-container class="pa-5">
            <v-row>
              <template v-for="(section, sIndex) in invoiceSections">
                <v-col cols="12" lg="6" :key="sIndex">
                  <table>
                    <tbody>
                    <tr
                        style="vertical-align: baseline"
                        v-for="(field, fIndex) in section"
                        :key="fIndex"
                    >
                      <td class="app-black--text font-weight-bold">
                        {{ field.label }}
                      </td>
                      <td class="px-2">:</td>
                      <td v-if="!field.html">
                        {{
                          field.format
                              ? field.format(getProp(invoiceData, field.path))
                              : getProp(invoiceData, field.path)
                        }}
                      </td>
                      <td v-else v-html="field.format(getProp(invoiceData, field.path))"></td>
                    </tr>
                    </tbody>
                  </table>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-card>
        <v-skeleton-loader
            v-else
            style="height: 132px"
            type="image"
            class="my-2"
        />
        <SectionTitle> Order Summary</SectionTitle>
        <v-card
            color="app-black"
            flat
            class="border-10 my-2"
            v-if="!loading"
        >
          <div
              class="white--text pa-5 font-weight-bold"
              style="display:grid;"
              :style="{
              gridTemplateColumns: hideThumbnailArea ? '1fr 100px 100px' : '100px 1fr 100px 100px'
            }"
          >
            <div v-if="!hideThumbnailArea">Image</div>
            <div>Product</div>
            <div class="text-center">Total Qty</div>
            <div class="text-right">Total Price</div>
          </div>
        </v-card>

        <template v-for="(item, index) in invoiceItems">
          <ProductListItem
              :item="item"
              :key="item.id || index"
              :loading="invoiceLoading"
              :hideThumbnailArea="hideThumbnailArea"
          />
        </template>

        <SectionTitle class="pb-0"/>

        <v-row justify-lg="end">
          <v-col cols="12" lg="6">
            <div
                v-if="invoiceLoading"
                style="height: 200px"
                class="d-flex justify-center"
            >
              <v-progress-circular indeterminate color="app-black"/>
            </div>
            <OrderTotals v-else :total-amount="totalAmount" :totals="totals"/>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="6">
        <div class="d-flex flex-column">
          <SectionTitle> Payment Details</SectionTitle>

          <v-card
              color="app-grey"
              flat
              class="border-10 my-2 mb-5 pa-5 product-list-item"
          >
            <div class="d-flex justify-space-between align-center fill-height">
              <h3 class="app-black--text">TOTAL AMOUNT TO BE PAID</h3>
              <v-divider style="height: 22px; min-height: 22px; max-height: 22px;" vertical></v-divider>
              <h3 class="app-black--text">{{ amountWillBePaid }}</h3>
            </div>
          </v-card>

          <vue-flip v-model="showResult" height="100%" width="100%">
            <template v-slot:front>
              <v-card color="primary" flat class="border-10 payment-form-card">
                <v-container v-if="invoiceData">
                  <AppForm
                      ref="paymentForm"
                      :loading="loading"
                      :form-fields="paymentFormFields"
                      :form-data="payment"
                  />
                  <v-checkbox
                      label="Use same billing address as invoice."
                      class="mt-0"
                      color="app-black"
                      v-model="sameAsBillingAddress"
                  >
                    <template v-slot:label>
                      <span
                          class="app-black--text same-address-checkbox-label"
                      >Use same billing address as invoice.</span
                      >
                    </template>
                  </v-checkbox>
                  <AppForm
                      v-if="!sameAsBillingAddress"
                      ref="addressForm"
                      :loading="loading"
                      :form-fields="addressFormFields"
                      :form-data="payment"
                  />
                  <template v-if="paymentError">
                    <v-alert
                        dense
                        outlined
                        type="error"
                        v-for="(error, index) in errorMessages"
                        :key="`error-${index}`"
                    >
                      {{ error }}
                    </v-alert>
                  </template>
                  <v-btn
                      block
                      :color="!loading ? 'app-black' : ''"
                      dark
                      depressed
                      height="57"
                      @click="validateAndSubmit"
                      :loading="loading"
                  >
                    <span class="payment-button-text">Complete Payment</span>
                    <v-icon right size="24">icon-u-shield-check</v-icon>
                    <template v-slot:loader>
                      PROCESSING...
                      <v-progress-circular
                          indeterminate
                          color="white"
                          size="20"
                          class="ml-3"
                      />
                    </template>
                  </v-btn>
                  <p class="mt-5 mb-0">
                    <v-icon left>icon-lock</v-icon>
                    Your transaction is secured with SSL encryption.
                  </p>
                </v-container>
              </v-card>
            </template>
            <template v-slot:back>
              <PaymentResult
                  @click:button="
                result = null;
                loading = false;
              "
                  :result="result"
              />
            </template>
          </vue-flip>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped lang="scss">
.container {
  padding-top: 96px;
  padding-bottom: 140px;
  @media (min-width: 1280px) {
    > .row.page-wrapper {
      //margin: -12px -85px;

      > [class*="col"] {
        padding: 12px 20px;
      }
    }
  }
  @media (max-width: 1905px) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.payment-form-card {
  .container {
    padding: 32px 56px;
  }
}

.payment-button-text {
  font-size: 20px;
  letter-spacing: 0.05em;
}

.same-address-checkbox-label {
  font-size: 20px;
  letter-spacing: -0.02em;
  font-weight: normal;
}

td {
  font-style: normal;
  letter-spacing: -0.02em;

  &.font-weight-bold {
    min-width: 132px;
  }
}
</style>
