export const sampleInvoiceData = {
  "web_invoice": {
    "note_to_contact": null,
    "note_from_contact": null,
    "sent_date": "2021-12-23T09:26:36.224-05:00",
    "amount": 1.0,
    "status": "open"
  },
  "invoice": {
    "auto_id": "269229",
    "taxable": true,
    "invoice_date": "2021-11-11T00:00:00.000-05:00",
    "total_amount": 7582.13,
    "total_amount_due": 7361.13,
    "total_amount_wo_tax": 6784.4,
    "total_shipping_price": 0.0,
    "billing_same_shipping": null,
    "shipping_address": "500 Rue Leger, Montreal., QC, CA, H8H8H8",
    "payment_term": "RONA",
    "invoice_items": [
      {
        "name": "( 700ml.) Epson Noir",
        "description": "",
        "type": "product",
        "quantity": 20.0,
        "total_price": 5964.4
      },
      {
        "name": "DiBond rouge 1/8\" (48 x 96)",
        "description": "",
        "type": "product",
        "quantity": 10.0,
        "total_price": 820.0
      }
    ],
    "applied_taxes": [
      {
        "name": "Rate 1",
        "value": 12.0,
        "amount": 715.73
      },
      {
        "name": "Sale Tax 1.1",
        "value": 10.0,
        "amount": 82.0
      }
    ],
    "user_representatives": [
      {
        "user": "Soner Zorbakir"
      }
    ],
    "billing_address": "500 Rue Leger, Montreal., QC, CA, H8H8H8"
  }
}
